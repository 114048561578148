import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Loader from '../../uikit/Loader/Loader';
import Button from '../../uikit/Button/Button';
import { AppDispatch, RootState } from '../../store';
import { SUNRAY } from '../../uikit/Colors/colors';
import { qualificationFilterHelper } from '../common/commonHelper';
import SvgSearch from '../../icons/SvgSearch';
import SvgJobTitleIcon from '../../icons/SvgJobTitleIcon';
import SvgJobselection from '../../icons/SvgJobselection';
import SvgLocation from '../../icons/SvgLocation';
import { Card, InputSearch, Modal, Toast } from '../../uikit';
import InputText from '../../uikit/InputText/InputText';
import { myJobPostingDataMiddleWare } from '../myjobposting/store/middleware/myjobpostingmiddleware';
import { ERROR_MESSAGE } from '../constValue';
import SvgIntomark from '../../icons/SvgCancel';

import {
  checkAuthMiddleware,
  jdMatchMiddleWare,
  PipelineStagesGetMiddleWare,
} from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import { routesPath } from '../../routes/routesPath';
import {
  WeightagematchinggetMiddleWare,
  WeightagematchingpostMiddleWare,
  WeightagematchingscoreMiddleWare,
} from '../createjdmodule/store/middleware/createjdmiddleware';
import ComparativeModal from '../comparativeanalysis/RecommendationScreen';
import SvgRefresh from '../../icons/SvgRefresh';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import SvgNotinterests from '../../icons/SvgNotinterests';
import { isEmpty } from '../../uikit/helper';
import PipelinePopup from './pipelinepopup';
import {
  applicantPipeLineDataMiddleWare,
  applicantPipeLineMiddleWare,
  downloadApplicantsMiddleware,
  emailAutomationMiddleware,
  emailcontentpostMiddleWare,
  getKanbanStagesMiddleWare,
  kanbanUpdateMiddleWare,
} from './store/middleware/applicantpipelinemiddleware';
import DndTitle from './DndTitle';
import ProfileView from './ProfileView';
import TotalApplicant from './TotalApplicant';
import JobTitleCard from './JobTitleCard';
import DndBoardScreen from './DndBoardScreen';

import ApplicantPipeLineFilter, { ListValue } from './ApplicantPipeLineFilter';
import styles from './applicantpipelinescreen.module.css';
import {
  ApplicantEntity,
  ICardSelectionMap,
  JobDetailsEntity,
} from './applicantPipeLineTypes';
import { columnTypes, IStageColumn } from './dndBoardTypes';
import PipelinePopupTwo from './pipelinepopupTwo';

type ParamsType = {
  jdId: string;
};
const initial = {
  location: '',
  StageStatus: '',
};
interface CreateObj {
  columnId: number;
  arg: string;
  backword: boolean;
}

type FormProps = {};
const REJECTED_COLUMN = 'Rejected';
const NEW_APPLICANT_COLUMN = 'New Applicants';
const ApplicantPipeLineScreen = ({}: FormProps) => {
  const { jdId } = useParams<ParamsType>();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [isMatchRadio, setMatchRadio] = useState('');
  const [isProfile, setProfile] = useState('');
  const [isBachelors, setBachelors] = useState(false);
  const [isDiploma, setDiploma] = useState(false);
  const [isDoctorate, setDoctorate] = useState(false);
  const [isMasters, setMasters] = useState(false);
  const [isAny, setAny] = useState(true);
  const [isOther, setOther] = useState(false);
  const [isSearch, setSearch] = useState('');
  const [isSkills, setSkills] = useState<any>();
  const [isSkillOption, setSkillOption] = useState<any>('');
  const [isExperience, setExperience] = useState('');
  const [isTotalFav, setTotalFav] = useState(false);
  const [isSortApplicant, setSortApplicant] = useState('match');
  const [sendData, setsendData] = useState('');
  const [sendAddon, setsendAddon] = useState(null);
  const [isStages, setStages] = useState(null);
  const [isstatus, setstatus] = useState(false);
  const [checkstages, setcheckstages] = useState('');
  const [isstagestatus, setstagestatus] = useState<any>();
  const [qualification1, setqualification1] = useState<
    | {
        value: string;
        label: string;
        checked: boolean;
        onChange: () => void;
      }[]
    | undefined
  >();

  const [Stageid, setStageid] = useState<any>(null);

  const myRef = useRef<any>();
  //showpop
  const [showPipelinePopup, setShowPipelinePopup] = useState(false);
  const [model, setmodel] = useState(false);
  const [cardSelection, setCardSelection] = useState<ICardSelectionMap>(
    new Map(),
  );

  const [truecount, setTruecount] = useState(0);
  const [falsecount, setfalsecount] = useState(0);

  const [change, setchange] = useState(false);
  const [aimodel, setaimodel] = useState(false);
  const [Comparmodel, setComparmodel] = useState(false);
  const [islodermatch, setloadermatch] = useState(false);
  const [Matching, setmatching] = useState<any>([]);
  const favAdd = isTotalFav ? 'add' : '';

  const [isnextLoader, setnextLoader] = useState(false);

  const [rangeValueskill, setRangeValueskill] = useState<any>(20);
  const [rangeValuerolles, setRangeValuerolles] = useState<any>(20);
  const [rangeValueexperience, setRangeValueexperience] = useState<any>(20);
  const [rangeValueQualifications, setRangeValueQualifications] =
    useState<any>(10);
  const [rangeValueTechnical, setRangeValueTechnical] = useState<any>(20);
  const [rangeValueSoft, setRangeValueSoft] = useState<any>(10);
  const [istrigger, settrigger] = useState(false);
  const [rangeValueIndustry, setRangeValueIndustry] = useState<any>(20);
  const [rangeValueDomain, setRangeValueDomain] = useState<any>(20);
  const [rangeValueCertifications, setRangeValueCertifications] =
    useState<any>(20);
  const [rangeValueLocation, setRangeValueLocation] = useState<any>(10);
  const [rangeValueCultural, setRangeValueCultural] = useState<any>(20);
  const [rangeValueReferences, setRangeValueReferences] = useState<any>(10);

  const [technicalPercent, setTechnicalPercent] = useState(0);
  const [nonTechnicalPercent, setNonTechnicalPercent] = useState(0);

  const [totaltechnical, settotaltechnical] = useState(0);
  const [totalnontechnical, settotalnontechnical] = useState(0);
  const [isopensubcription, setopensubcription] = useState(false);

  const [addonloader, setaddonloader] = useState(false);

  const [purpose, setpurpose] = useState('');

  const [freetrial, setfreetrial] = useState('');

  const [purpose_addon, setpurpose_addon] = useState('');
  const [backfor, setbackfor] = useState(0);
  const [checkmove, setcheckmove] = useState<any>('');
  const [isemailtemplate, setemailtemplate] = useState<any>();
  const [isselectedstatus, setselectedstatus] = useState<any>(null);

  const [showAutomatePopup, setShowAutomatePopup] = useState(false);
  const [sortFunction, setSortFunction] = useState<any>([]);
  const [back, setback] = useState(false);
  const [loadingFav, setLoadingFav] = useState<{
    [key: number]: boolean;
  }>({});
  const [istriggeroverall, settriggeroverall] = useState(false);
  const [match1, setmatch1] = useState<
    { label: string; value: any } | undefined
  >();
  const [profile1, setprofile1] = useState<
    { label: string; value: any } | undefined
  >();
  const [experience1, setexperience1] = useState<
    { label: string; value: any } | undefined
  >();

  const [skill1, setskill1] = useState<{ value: string; label: string }[]>();
  const [showskill1, setshowskill1] =
    useState<{ value: string; label: string }[]>();
  const currentParams = new URLSearchParams(window.location.search);
  const getAppliedView = JSON.parse(currentParams.get('applied_view'));
  const [isApplicantView, setApplicantView] = useState(getAppliedView);

  // document.title = 'Applicant pipeline';
  // const updateTechnicalPercent = () => {
  //   const totalTechnicalPercent =
  //     rangeValueskill +
  //     rangeValuerolles +
  //     rangeValueexperience +
  //     rangeValueQualifications +
  //     rangeValueTechnical +
  //     rangeValueSoft;
  //   setTechnicalPercent(totalTechnicalPercent);
  //   settotaltechnical(totalTechnicalPercent)
  // };
  const updateTechnicalPercent = () => {
    const rangeValues = [
      rangeValueskill,
      rangeValuerolles,
      rangeValueexperience,
      rangeValueQualifications,
      rangeValueTechnical,
      rangeValueSoft,
    ];

    // Filter out empty or falsy values (you can add more conditions if needed)
    const validRangeValues = rangeValues.filter(
      (value) => value !== '' && value !== 0,
    );

    // Sum the valid values
    const totalTechnicalPercent = validRangeValues.reduce(
      (acc, value) => acc + value,
      0,
    );

    setTechnicalPercent(totalTechnicalPercent);
    settotaltechnical(totalTechnicalPercent);
  };

  const updateNonTechnicalPercent = () => {
    const rangeValues = [
      rangeValueIndustry,
      rangeValueDomain,
      rangeValueCertifications,
      rangeValueLocation,
      rangeValueCultural,
      rangeValueReferences,
    ];

    const validRangeValues = rangeValues.filter(
      (value) => value !== '' && value !== 0 && !isNaN(value),
    );

    const totalNonTechnicalPercent = validRangeValues.reduce(
      (acc, value) => acc + value,
      0,
    );

    setNonTechnicalPercent(totalNonTechnicalPercent);
    settotalnontechnical(totalNonTechnicalPercent);
  };
  const isValidURL = (string) => {
    const regex = /^(https?:\/\/)?([^\s$.?#].[^\s]*)$/i;
    return regex.test(string);
  };

  useEffect(() => {
    const skill = currentParams.get('skill');
    const experience = currentParams.get('experience');
    const stageStatus = JSON.parse(currentParams.get('StageStatus'));
    const profileValue = currentParams.get('profileValue');
    const matchValue = currentParams.get('matchValue');
    const qualificationValue = currentParams.get('qualificationValue');
    const location = currentParams.get('location');
    const search = currentParams.get('search');
    // Default values for each state
    let bachelors = false;
    let masters = false;
    let doctorate = false;
    let diploma = false;
    let other = false;

    if (qualificationValue) {
      const degrees = JSON.parse(qualificationValue);
      setqualification1(degrees);
      // Check if each degree exists in the degrees array and set the boolean states accordingly
      bachelors = degrees.some((degree) => degree.value === 'Bachelors');
      masters = degrees.some((degree) => degree.value === 'Masters');
      doctorate = degrees.some((degree) => degree.value === 'Doctorate');
      diploma = degrees.some((degree) => degree.value === 'Diploma');
      other = degrees.some((degree) => degree.value === 'Others');
      // Set the boolean states based on the presence of the degree values
      setBachelors(bachelors);
      setMasters(masters);
      setDoctorate(doctorate);
      setDiploma(diploma);
      setOther(other);
      setAny(false);
    }
    if (skill) {
      const parsedSkills = skill ? JSON.parse(`[${skill}]`) : [];
      setshowskill1(parsedSkills);
      setSkillOption(parsedSkills);
      setskill1(parsedSkills);
    }
    if (profileValue) {
      const val = JSON.parse(profileValue);
      setProfile(val.value);
      setprofile1(val);
    }
    if (matchValue) {
      const val = JSON.parse(matchValue);
      setMatchRadio(val.value);
      setmatch1(val);
    }
    if (stageStatus) {
      setselectedstatus(stageStatus);
      setstagestatus(stageStatus);
    }
    if (experience) {
      const val = JSON.parse(experience);
      setExperience(val.value);
      setexperience1(val);
    }
    if (location) {
      formik.setFieldValue('location', location);
    }
    if (search) {
      setSearch(search);
    }
    settriggeroverall(!istriggeroverall);
  }, []);

  // dispatching the API's when the user buy the add-on
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const session_id: any = query.get('session_id');
  const session: any = query.get('session');
  useEffect(() => {
    if (!isEmpty(session)) {
      setaddonloader(true);
      setTimeout(() => {
        dispatch(
          applicantPipeLineDataMiddleWare({
            jd_id: jdId,
          }),
        );
        setaddonloader(false);
      }, 1000);
    }
  }, [session]);

  useEffect(() => {
    updateTechnicalPercent();
    updateNonTechnicalPercent();
  }, [
    rangeValueskill,
    rangeValuerolles,
    rangeValueexperience,
    rangeValueQualifications,
    rangeValueTechnical,
    rangeValueSoft,
    rangeValueIndustry,
    rangeValueDomain,
    rangeValueCertifications,
    rangeValueLocation,
    rangeValueCultural,
    rangeValueReferences,
  ]); // Empty dependency array ensures this runs only once after initial render

  const nextfunction = () => {
    let formData = new FormData();
    if (
      totaltechnical === 100 &&
      (totalnontechnical === 100 || totalnontechnical === 0)
    ) {
      const list = [
        {
          skills: rangeValueskill,
          roles: rangeValuerolles,
          exp: rangeValueexperience,
          qualification: rangeValueQualifications,
          tech_tools: rangeValueTechnical,
          soft_skills: rangeValueSoft,
          industry_exp: rangeValueIndustry,
          domain_exp: rangeValueDomain,
          certification: rangeValueCertifications,
          location: rangeValueLocation,
          cultural_fit: rangeValueCultural,
          nice: rangeValueReferences,
        },
      ];
      formData.append('tech', JSON.stringify(list));
      formData.append('jd_id', jdId);
      setnextLoader(true);
      dispatch(
        WeightagematchingpostMiddleWare({
          formData,
        }),
      ).then((res) => {
        if (res.payload.success === false) {
          setnextLoader(false);
          handleWeightageClose();
          Toast(
            'Oops! Something went wrong. Please try again shortly.',
            'LONG',
            'error',
          );
        } else {
          setnextLoader(false);
          handleWeightageClose();

          dispatch(WeightagematchingscoreMiddleWare(jd_id)).then((responce) => {
            if (responce.payload.success === true) {
              Toast('Weightage setting saved successfully.', 'LONG');
              getApplicanPipelineData();
            } else {
              Toast(
                'Oops! Something went wrong. Please try again shortly.',
                'LONG',
                'error',
              );
            }
          });
        }
      });
    }
  };

  const technicalresetfunction = () => {
    setRangeValueskill(20);
    setRangeValuerolles(20);
    setRangeValueexperience(20);
    setRangeValueQualifications(10);
    setRangeValueTechnical(20);
    setRangeValueSoft(10);
  };

  const nontechnicalresetfunction = () => {
    setRangeValueIndustry(20);
    setRangeValueDomain(20);
    setRangeValueCertifications(20);
    setRangeValueCultural(20);
    if (isstatus === true) {
      setRangeValueLocation(10);
      setRangeValueReferences(10);
    } else {
      setRangeValueLocation(20);
      setRangeValueReferences(0);
    }
  };

  const clear_filter = () => {
    setCardSelection(new Map());
    setcheckmove('');
    setmatching([]);
    setSortFunction([]);
  };

  const handleRangeChange = (e: any) => {
    const skillinputValue = e.target.value;
    if (/^\d*$/.test(skillinputValue)) {
      const numericValue = parseInt(skillinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueskill(numericValue);
        updateTechnicalPercent();
      } else if (skillinputValue === '') {
        setRangeValueskill('');
      }
    }
  };
  const handleRangeChangerole = (e: any) => {
    const rolesinputValue = e.target.value;
    if (/^\d*$/.test(rolesinputValue)) {
      const numericValue = parseInt(rolesinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValuerolles(numericValue);
        updateTechnicalPercent();
      } else if (rolesinputValue === '') {
        setRangeValuerolles('');
      }
    }
  };
  const handleRangeChangeexperience = (e: any) => {
    const experinputValue = e.target.value;
    if (/^\d*$/.test(experinputValue)) {
      const numericValue = parseInt(experinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueexperience(numericValue);
        updateTechnicalPercent();
      } else if (experinputValue === '') {
        setRangeValueexperience('');
      }
    }
  };
  const handleRangeChangequalifications = (e: any) => {
    const qualifinputValue = e.target.value;
    if (/^\d*$/.test(qualifinputValue)) {
      const numericValue = parseInt(qualifinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueQualifications(numericValue);
        updateTechnicalPercent();
      } else if (qualifinputValue === '') {
        setRangeValueQualifications('');
      }
    }
  };
  const handleRangeChangetechnical = (e: any) => {
    const techinputValue = e.target.value;
    if (/^\d*$/.test(techinputValue)) {
      const numericValue = parseInt(techinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueTechnical(numericValue);
        updateTechnicalPercent();
      } else if (techinputValue === '') {
        setRangeValueTechnical('');
      }
    }
  };
  const handleRangeChangesoft = (e: any) => {
    const softinputValue = e.target.value;
    if (/^\d*$/.test(softinputValue)) {
      const numericValue = parseInt(softinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueSoft(numericValue);
        updateTechnicalPercent();
      } else if (softinputValue === '') {
        setRangeValueSoft('');
      }
    }
  };

  const handleRangeChangeindustry = (e: any) => {
    const industryinputValue = e.target.value;
    if (/^\d*$/.test(industryinputValue)) {
      const numericValue = parseInt(industryinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueIndustry(numericValue);
        updateNonTechnicalPercent();
      } else if (industryinputValue === '') {
        setRangeValueIndustry('');
      }
    }
  };
  const handleRangeChangedomain = (e: any) => {
    const domaininputValue = e.target.value;
    if (/^\d*$/.test(domaininputValue)) {
      const numericValue = parseInt(domaininputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueDomain(numericValue);
        updateNonTechnicalPercent();
      } else if (domaininputValue === '') {
        setRangeValueDomain('');
      }
    }
  };
  const handleRangeChangecertification = (e: any) => {
    const certifiinputValue = e.target.value;
    if (/^\d*$/.test(certifiinputValue)) {
      const numericValue = parseInt(certifiinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueCertifications(numericValue);
        updateNonTechnicalPercent();
      } else if (certifiinputValue === '') {
        setRangeValueCertifications('');
      }
    }
  };
  const handleRangeChangelocation = (e: any) => {
    const locationinputValue = e.target.value;
    if (/^\d*$/.test(locationinputValue)) {
      const numericValue = parseInt(locationinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueLocation(numericValue);
        updateNonTechnicalPercent();
      } else if (locationinputValue === '') {
        setRangeValueLocation('');
      }
    }
  };
  const handleRangeChangecultural = (e: any) => {
    const culturalinputValue = e.target.value;
    if (/^\d*$/.test(culturalinputValue)) {
      const numericValue = parseInt(culturalinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueCultural(numericValue);
        updateNonTechnicalPercent();
      } else if (culturalinputValue === '') {
        setRangeValueCultural('');
      }
    }
  };
  const handleRangeChangereferences = (e: any) => {
    const refinputValue = e.target.value;
    if (/^\d*$/.test(refinputValue)) {
      const numericValue = parseInt(refinputValue, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setRangeValueReferences(numericValue);
        updateNonTechnicalPercent();
      } else if (refinputValue === '') {
        setRangeValueReferences('');
      }
    }
  };

  // getting the pipeline stages name so dispatching the API
  useEffect(() => {
    dispatch(PipelineStagesGetMiddleWare({}));
  }, []);

  const {
    isLoading,
    location_list,
    jd_id,
    workflow_id,
    stages,
    locations,
    showStagesPopup,
    applicants,
    applicantDataLoader,
    favLoader,
    favSuccess,
    total_applicants,
    pipeLineLoader,
    outlook,
    google,
    job_details,

    // updateLoader,
    zita_match_count,
    is_plan,
    non_tech,
    tech,
    success,
    downloadState,
    active_resume,
    new_apply_count,
    permission,
    current_resume_count,
    comparative,
    plan_details,
    current_plan,
    user_credits,
    ai_matched_count,
    stages_name,
    candidate_name_mail,
    pipelinestages,
  } = useSelector(
    ({
      myJobPosingReducers,
      applicantPipeLineReducers,
      applicantPipeLineDataReducers,
      applicantFavReducers,
      weightageReducers,
      // applicantPipeLineUpdateReducers,
      permissionReducers,
      templatePageReducers,
      kanbanStagesReducers,
      applicantPipelineDownloadReducers,
      SubscriptionReducers,
      PipelineStagesReducers,
    }: RootState) => {
      return {
        isLoading: applicantPipeLineDataReducers.isLoading,
        location_list: applicantPipeLineDataReducers.candidate_location,
        jd_id: applicantPipeLineReducers.jd_id,
        workflow_id: applicantPipeLineDataReducers.workflow_id,
        applicants: applicantPipeLineDataReducers.applicants,
        stages: kanbanStagesReducers.stages,
        locations: applicantPipeLineDataReducers.locations,
        showStagesPopup: kanbanStagesReducers.selectPipeline,
        applicantDataLoader: applicantPipeLineDataReducers.isLoading,
        favLoader: applicantFavReducers.isLoading,
        favSuccess: applicantFavReducers.success,
        total_applicants: applicantPipeLineDataReducers.total_applicant,
        pipeLineLoader: applicantPipeLineReducers.isLoading,
        google: applicantPipeLineDataReducers.google,
        outlook: applicantPipeLineDataReducers.outlook,
        job_details: applicantPipeLineReducers.job_details,
        // updateLoader: applicantPipeLineUpdateReducers.isLoading,
        zita_match_count: applicantPipeLineReducers.zita_match_count,
        is_plan: permissionReducers.is_plan,
        downloadState: applicantPipelineDownloadReducers,
        success: weightageReducers.success,
        non_tech: weightageReducers.non_tech,
        tech: weightageReducers.tech_skills,
        active_resume: applicantPipeLineDataReducers.active_resume,
        new_apply_count: applicantPipeLineDataReducers.new_apply_count,
        permission: permissionReducers.data,
        current_resume_count: SubscriptionReducers.current_resume_count,
        user_credits: SubscriptionReducers.user_credits,
        comparative: applicantPipeLineDataReducers.comparative,
        plan_details: applicantPipeLineDataReducers.plan_details,
        current_plan: applicantPipeLineDataReducers.current_plan,
        ai_matched_count: applicantPipeLineDataReducers.ai_matched_count,
        stages_name: PipelineStagesReducers?.stages,
        candidate_name_mail: applicantPipeLineDataReducers.candidate_name_mail,
        pipelinestages: PipelineStagesReducers?.stages,
      };
    },
  );

  // passing the pipeline stages to array format to pass as a option in select tag
  const transformedData = pipelinestages?.map((item) => ({
    value: item?.stage_id,
    label: item?.stage_name,
  }));

  useEffect(() => {
    handlefunction();
  }, []);

  const handlefunction = () => {
    dispatch(WeightagematchinggetMiddleWare({ jd_id: jdId })).then((res) => {
      if (res.payload.success === true) {
        if (res.payload !== undefined) {
          setRangeValueskill(res.payload.tech_skills.skills);
          setRangeValuerolles(res.payload.tech_skills.roles);
          setRangeValueexperience(res.payload.tech_skills.exp);
          setRangeValueQualifications(res.payload.tech_skills.qualification);
          setRangeValueTechnical(res.payload.tech_skills.tech_tools);
          setRangeValueSoft(res.payload.tech_skills.soft_skills);
          setRangeValueIndustry(res.payload.non_tech.industry_exp);
          setRangeValueDomain(res.payload.non_tech.domain_exp);
          setRangeValueCertifications(res.payload.non_tech.certification);
          setRangeValueLocation(res.payload.non_tech.location);
          setRangeValueCultural(res.payload.non_tech.cultural_fit);
          setRangeValueReferences(res.payload.non_tech.nice);
          setstatus(res.payload.status);
        }
      }

      if (res.payload.success === false) {
        Toast(
          'Oops! Something went wrong. Please try again shortly.',
          'LONG',
          'error',
        );
      }
    });
  };

  //While Favorites is True Remove the UnFaved Profiles
  const Remove_Fav_Profile = async (can_id: number) => {
    // Remove entries with candidate_id_id == 15095 from each "lines" list
    if (isTotalFav) {
      const data = columns;
      let total_column_candidates = 0; // Use `let` since this value will change

      // Remove entries with candidate_id_id == can_id and update total
      Object.keys(data).forEach((key) => {
        const column = data[+key]; // Access the column
        column.items = column.items.filter(
          (line) => line.candidate_id_id !== can_id,
        ); // Filter lines
        column.total = column.items.length; // Update total with the length of filtered items
        total_column_candidates += column.total; // Accumulate the total count
      });
    }
  };

  //loader Fav_icon//

  const Fav_loader = (id, val) => {
    Remove_Fav_Profile(id);
    if (val === 0) {
      setLoadingFav((prev) => ({ ...prev, [id]: true }));
    } else if (val === 1) {
      setLoadingFav((prev) => {
        const { [id]: ignored, ...rest } = prev;
        return rest;
      });
      updateFavValues(id);
    }
    setFavLoad(true);
  };

  // Fav State value update function

  const updateFavValues = (id) => {
    setColumns((prevColumns) => {
      const updatedColumns = Object.keys(prevColumns).reduce((acc, key) => {
        acc[key] = {
          ...prevColumns[key],
          items: prevColumns[key].items.map((item) => {
            if (item.candidate_id_id === id) {
              return {
                ...item,
                fav: item.fav === null ? id : null, // Toggle fav
              };
            }
            return item;
          }),
        };
        return acc;
      }, {});
      setColumns1(updatedColumns);
      return updatedColumns;
    });
  };

  const closefunction = () => {
    setRangeValueskill(tech.skills);
    setRangeValuerolles(tech.roles);
    setRangeValueexperience(tech.exp);
    setRangeValueQualifications(tech.qualification);
    setRangeValueTechnical(tech.tech_tools);
    setRangeValueSoft(tech.soft_skills);

    setRangeValueIndustry(non_tech.industry_exp);
    setRangeValueDomain(non_tech.domain_exp);
    setRangeValueCertifications(non_tech.certification);
    setRangeValueLocation(non_tech.location);
    setRangeValueCultural(non_tech.cultural_fit);
    setRangeValueReferences(non_tech.nice);
  };
  const areRequiredFieldsValid = () => {
    return (
      (isMatchRadio !== null &&
        isMatchRadio !== undefined &&
        isMatchRadio !== '') ||
      (isExperience !== null &&
        isExperience !== undefined &&
        isExperience !== '') ||
      (isProfile !== null && isProfile !== undefined && isProfile !== '') ||
      (qaValue !== null && qaValue !== undefined && qaValue !== '') ||
      (optionsList !== null &&
        optionsList !== undefined &&
        optionsList.length > 0) ||
      (isselectedstatus !== null &&
        isselectedstatus !== undefined &&
        isselectedstatus !== '') ||
      (formik.values.location !== null &&
        formik.values.location !== undefined &&
        formik.values.location !== '') ||
      (isSearch !== null && isSearch !== undefined && isSearch !== '')
    );
  };

  useEffect(() => {
    dispatch(checkAuthMiddleware());
    // dispatch(getKanbanStagesMiddleWare());
    dispatch(getKanbanStagesMiddleWare({ jd_id: parseInt(jdId) }));
    dispatch(applicantPipeLineMiddleWare({ jd_id: jdId })).then(() => {
      if (isValidURL(currentParams)) {
        if (areRequiredFieldsValid()) {
          dispatch(
            applicantPipeLineDataMiddleWare({
              jd_id: jdId,
              profile_match: isMatchRadio,
              work_experience: isExperience,
              profile_view: isProfile,
              education_level: qaValue,
              skill_match: optionsList,
              candidate: isSearch,
              StageStatus:
                isselectedstatus !== null && isselectedstatus?.label
                  ? isselectedstatus.label
                  : '',
              location: formik.values.location,
            }),
          ).then(() => {
            setloadermatch(false);
          });
        }
      } else {
        dispatch(
          applicantPipeLineDataMiddleWare({
            jd_id: jdId,
          }),
        ).then(() => {
          setloadermatch(false);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (Comparmodel === true) {
      setComparmodel(true);
    }
  }, [Comparmodel]);

  useEffect(() => {
    if (!workflow_id) {
      setShowPipelinePopup(true);
    } else {
      dispatch(
        getKanbanStagesMiddleWare({ jd_id: parseInt(jd_id), workflow_id }),
      ).then(() => {
        setloadermatch(false);
      });
    }
  }, [workflow_id]);

  useEffect(() => {
    if (!is_plan) {
      history.push('/account_setting/subscription');
    }
  });

  const formik = useFormik({
    initialValues: initial,
    onSubmit: () => {
      getApplicanPipelineData();
    },
  });
  useEffect(() => {
    if (!isValidURL(currentParams)) {
      dispatch(
        myJobPostingDataMiddleWare({
          location: formik.values.location,
        }),
      );
    }
  }, [formik.values]);

  // select card //
  const select_candidate = (data, verify) => {
    if (verify === 1) {
      var selectdata = {
        candidate_id: data.task.candidate_id_id,
        first_name: data.task.first_name,
        last_name: data.task.last_name,
        email: data.task.email,
        profile_image: data.task.image,
      };
      setmatching([...Matching, selectdata]);
    } else if (verify === 0) {
      var NewArray = Matching.filter(
        (item) => item.candidate_id !== data.task.candidate_id_id,
      );

      setmatching(NewArray);
    } else if (verify === 2) {
      var arr = [];
      data.map((val) => {
        var selectdata1 = {
          candidate_id: val.candidate_id_id,
          first_name: val.first_name,
          last_name: val.last_name,
          email: val.email,
          profile_image: val.image,
        };
        arr.push(selectdata1);
      });
      setmatching([...Matching, ...arr]);
    } else if (verify === 3) {
      let uniqueIds = new Set(data.map((item) => item.candidate_id_id));
      let newArray1 = Matching.filter(
        (item) => !uniqueIds.has(item.candidate_id),
      );
      setmatching(newArray1);
    } else if (verify === 4) {
      var selectdata4 = {
        candidate_id: data.candidate_id,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        profile_image: data.profile_image,
      };
      setmatching([...Matching, selectdata4]);
    } else if (verify === 5) {
      var Newvalue = Matching.filter(
        (item) => item.candidate_id !== parseInt(data.candidate_id),
      );

      setmatching(Newvalue);
    } else if (verify === 6) {
      setmatching(data);
    } else if (verify === 7) {
      if (data.length !== 0) {
        setmatching(data);
      }
    }
  };

  //card selection

  const handleCardSelection = (data: {
    task: ApplicantEntity;
    section: number;
    index: number;
    columnId: number;
    job_details: JobDetailsEntity;
  }) => {
    const newCardSelection = new Map(cardSelection);
    if (cardSelection.has(data.task.id)) {
      select_candidate(data, 0);
      newCardSelection.delete(data.task.id);
    } else {
      select_candidate(data, 1);
      newCardSelection.set(data.task.id, {
        task: data.task,
        section: data.section,
        columnId: data.columnId,
      });
    }
    setCardSelection(newCardSelection);
    column_restriction();
  };

  const removeDuplicatesval = (arr, prop) => {
    return arr.filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o[prop] === obj[prop]),
    );
  };

  // select All columns
  const handleColumnSelect = (data: IStageColumn) => {
    const { section, columnId } = data;
    const list = columns[columnId].items as any[];

    const newCardSelection = new Map(cardSelection);
    const newList = list.filter((doc) => !cardSelection.has(doc.id));
    const filtervalue = newList.filter((val) =>
      active_resume.includes(val.candidate_id_id),
    );

    filtervalue.forEach((task) =>
      newCardSelection.set(task.id, { task, section, columnId }),
    );
    setCardSelection(newCardSelection);
    select_candidate(filtervalue, 2);
    setCardSelection(newCardSelection);
    column_restriction();
  };
  const handleColumnUnselect = (data: IStageColumn) => {
    const { section, columnId } = data;
    const list = columns[columnId].items as any[];
    const newCardSelection = new Map(cardSelection);
    const newList = list.filter((doc) => cardSelection.has(doc.id));
    const filtervalue = newList.filter((val) =>
      active_resume.includes(val.candidate_id_id),
    );
    filtervalue.forEach((task) => newCardSelection.delete(task.id));

    select_candidate(newList, 3);
    setCardSelection(newCardSelection);
  };

  // filter match function
  const hanldeMatch = (listValue: ListValue) => {
    setMatchRadio(listValue.value);
  };

  const hanldeProfile = (listValue: ListValue) => {
    setProfile(listValue.value);
  };
  // filter bachelor function
  const handleBachelor = () => {
    setBachelors(!isBachelors);
    setAny(false);
  };
  // filter doctorate function
  const handleDoctorate = () => {
    setDoctorate(!isDoctorate);
    setAny(false);
  };
  // filter diploma function
  const handleDiploma = () => {
    setDiploma(!isDiploma);
    setAny(false);
  };
  // filter master function
  const handleMaster = () => {
    setMasters(!isMasters);
    setAny(false);
  };
  // filter other function
  const handleOther = () => {
    setOther(!isOther);
    setAny(false);
  };

  // filter any function
  const handleAny = () => {
    setAny(!isAny);
    setBachelors(false);
    setDoctorate(false);
    setDiploma(false);
    setMasters(false);
    setOther(false);
  };

  const qualificationOption = [
    {
      value: 'Bachelors',
      label: 'Bachelor',
      checked: isBachelors,
      onChange: handleBachelor,
    },
    {
      value: 'Masters',
      label: 'Master',
      checked: isMasters,
      onChange: handleMaster,
    },
    {
      value: 'Doctorate',
      label: 'Doctorate',
      checked: isDoctorate,
      onChange: handleDoctorate,
    },
    {
      value: 'Diploma',
      label: 'Diploma',
      checked: isDiploma,
      onChange: handleDiploma,
    },
    {
      value: 'Others',
      label: 'Other',
      checked: isOther,
      onChange: handleOther,
    },
    {
      value: 'any',
      label: 'Any',
      checked: isAny,
      onChange: handleAny,
    },
  ];
  useEffect(() => {
    const qualificationValue = currentParams.get('qualificationValue');
    if (
      isBachelors === false &&
      isDoctorate === false &&
      isMasters === false &&
      isOther === false &&
      isDiploma === false &&
      !qualificationValue
    ) {
      setAny(true);
    }
  }, [isBachelors, isDoctorate, isDiploma, isMasters, isOther]);

  const qaValue = qualificationFilterHelper(
    isAny,
    isBachelors,
    isDoctorate,
    isDiploma,
    isMasters,
    isOther,
  );
  const optionsList =
    isSkillOption &&
    isSkillOption.map((optionList: { value: string }) => {
      return optionList.value;
    });
  function getApplicanPipelineData() {
    if (isValidURL(currentParams)) {
      if (areRequiredFieldsValid()) {
        dispatch(
          applicantPipeLineDataMiddleWare({
            jd_id: jdId,
            profile_match: isMatchRadio,
            candidate: isSearch,
            work_experience: isExperience,
            profile_view: isProfile,
            education_level: qaValue,
            skill_match: optionsList,
            fav: favAdd,
            sortApplicant: isSortApplicant,
            sortSortList: isSortApplicant,
            sortInterview: isSortApplicant,
            sortSelected: isSortApplicant,
            sortRejected: isSortApplicant,
            location: formik.values.location || '',
            StageStatus:
              (isselectedstatus !== null && isselectedstatus?.label) || '',
          }),
        ).then((res) => {
          if (sortFunction.length !== 0) {
            sortFunction.forEach((item) => {
              // Renamed sortFunction to item
              handleSortColumn(item.columnId, item.arg, item.backword);
            });
          }
          setloadermatch(false);
        });
        setFavLoad(false);
        setColumns1(columns);
      }
    } else {
      dispatch(
        applicantPipeLineDataMiddleWare({
          jd_id: jdId,
          profile_match: isMatchRadio,
          candidate: isSearch,
          work_experience: isExperience,
          profile_view: isProfile,
          education_level: qaValue,
          skill_match: optionsList,
          fav: favAdd,
          sortApplicant: isSortApplicant,
          sortSortList: isSortApplicant,
          sortInterview: isSortApplicant,
          sortSelected: isSortApplicant,
          sortRejected: isSortApplicant,
          location: formik.values.location || '',
          StageStatus:
            (isselectedstatus !== null && isselectedstatus?.label) || '',
        }),
      ).then((res) => {
        if (sortFunction.length !== 0) {
          sortFunction.forEach((item) => {
            // Renamed sortFunction to item
            handleSortColumn(item.columnId, item.arg, item.backword);
          });
        }
        setloadermatch(false);
      });
      setFavLoad(false);
      setColumns1(columns);
    }
  }

  // filter api call
  useEffect(() => {
    if (!change) {
      setloadermatch(true);

      getApplicanPipelineData();
    }
  }, [
    isSkillOption,
    isBachelors,
    isDoctorate,
    isDiploma,
    isMasters,
    isAny,
    isOther,
    isMatchRadio,
    isProfile, 
    isTotalFav,
    isExperience,
    isSortApplicant,
    isselectedstatus,
    change,

    // updateLoader,
  ]);

  // enter key submit api call
  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setloadermatch(true);
      dispatch(
        applicantPipeLineDataMiddleWare({
          jd_id: jdId,
          profile_match: isMatchRadio,
          candidate: isSearch,
          work_experience: isExperience,
          profile_view: isProfile,
          education_level: qaValue,
          skill_match: optionsList,
          fav: favAdd,
          sortApplicant: isSortApplicant,
          sortSortList: isSortApplicant,
          sortInterview: isSortApplicant,
          sortSelected: isSortApplicant,
          sortRejected: isSortApplicant,
          location: formik.values.location || '',
          StageStatus:
            (isselectedstatus !== null && isselectedstatus?.label) || '',
        }),
      ).then(() => {
        setloadermatch(false);
      });
    }
  };

  // search api call function
  const handleSearch = () => {
    dispatch(
      applicantPipeLineDataMiddleWare({
        jd_id: jdId,
        profile_match: isMatchRadio,
        candidate: isSearch,
        work_experience: isExperience,
        profile_view: isProfile,
        education_level: qaValue,
        skill_match: optionsList,
        fav: favAdd,
        sortApplicant: isSortApplicant,
        sortSortList: isSortApplicant,
        sortInterview: isSortApplicant,
        sortSelected: isSortApplicant,
        sortRejected: isSortApplicant,
        location: formik.values.location || '',
        StageStatus:
          (isselectedstatus !== null && isselectedstatus?.label) || '',
      }),
    );
    setSortFunction([]);
  };

  const applicant_collection = (check, id) => {
    const objcollection = Object.values(applicants);
    let flattenedArray = objcollection.flat();

    if (check === 0) {
      var matchingCandidates = flattenedArray.filter(
        (candidate) => candidate.candidate_id_id === id,
      );

      if (matchingCandidates.length > 0) {
        return matchingCandidates[0].is_move;
      } else {
        return false;
      }
    } else {
      const selectedList = Array.from(cardSelection.values());
      const allCandidateIds = selectedList.map(
        (item) => item.task.candidate_id_id,
      );
      var datas = flattenedArray.filter((ids) =>
        allCandidateIds.includes(ids.candidate_id_id),
      );

      const TrueCount = datas.filter((item) => item.is_move);
      const FalseCount = datas.filter((item) => !item.is_move);
      setTruecount(TrueCount.length);
      setfalsecount(FalseCount.length);
      setcheckstages(TrueCount.length === 0 ? '' : 'bulk');
      return datas.every(
        (user) =>
          !allCandidateIds.includes(user.candidate_id_id) ||
          user.is_move === true,
      );
    }
  };

  // filter fav function
  const filterTotalFav = () => {
    setTotalFav(!isTotalFav);
  };

  // filter refresh function
  const hanldeRefresh = () => {
    const [baseUrl] = window.location.href.split('?');
    window.history.replaceState(null, '', baseUrl);
    setSortFunction([]);
    setDoctorate(false);
    setMasters(false);
    setAny(true);
    setback(false);
    setBachelors(false);
    setDiploma(false);
    setOther(false);
    setSearch('');
    formik.handleChange('location')('');
    setMatchRadio('');
    setExperience('');
    setexperience1(undefined);
    setProfile('');
    setSkillOption('');
    dispatch(
      applicantPipeLineDataMiddleWare({
        jd_id: jdId,
        profile_match: '',
        candidate: '',
        work_experience: '',
        profile_view: '',
        education_level: '',
        skill_match: '',
        fav: favAdd,
        sortApplicant: isSortApplicant,
        sortSortList: isSortApplicant,
        sortInterview: isSortApplicant,
        sortSelected: isSortApplicant,
        sortRejected: isSortApplicant,
        location: '',
        StageStatus:
          (isselectedstatus !== null && isselectedstatus?.label) || '',
      }),
    );
  };

  // close popup
  const handleClosePipelinePopup = () => {
    setShowPipelinePopup(false);
  };

  const getAppliedCanId: any = localStorage.getItem('applied_can_id');
  const getAppliedJd: any = localStorage.getItem('applied_jd_id');
  const _debounceSearch = _.debounce(() => handleSearch(), 2000);

  const location = useLocation();
  const [isActive, setIsActive] = useState(0); // Default isActive value

  useEffect(() => {
    // Check if there is a query string
    if (location.search) {
      const searchParams = new URLSearchParams(location.search); // Parse query parameters
      const activeParam = searchParams.get('active'); // Get the value of the 'active' parameter

      if (activeParam !== null) {
        setIsActive(Number(activeParam)); // Set isActive to the value from the URL if it exists
      } else {
        setIsActive(0); // If activeParam doesn't exist, ensure isActive is 1
      }
    } else {
      setIsActive(0); // If there is no query string, keep isActive as 1
    }
  }, [location.search]);

  useEffect(() => {
    if (getAppliedView) {
      setApplicantView(true);
      currentParams.delete('applied_view'); // Delete it if it does
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [currentParams]);
  // useEffect(() => {
  //   _debounceSearch();
  // }, [formik.values.location]);

  /// Column Drag & Drop

  // initial value
  const stageColumns = stages?.reduce((o, v) => {
    return {
      ...o,
      [v.id]: {
        ...v,
        columnId: v?.id,
        title: v?.stage_name,
        items: applicants[v.id] || [],
        total: (applicants[v.id] || [])?.length,
        section: v?.id,
        left: '0px',
      } as IStageColumn,
    };
  }, {});
  const columnsFromBackend = {
    [0]: {
      id: 0,
      columnId: 0,
      title: 'New Applicants',
      items: applicants[0] || [],
      total: new_apply_count.length || 0,
      section: 0,
      left: '0px',
      borderColor: SUNRAY,
      stage_color: SUNRAY,
      stage_name: 'New Applicants',
      stage_order: 0,
    } as unknown as IStageColumn,
    ...stageColumns,
  };

  const columnOrder = Object.keys(columnsFromBackend) || [];
  const allColumnsItemsLength = columnOrder
    ?.map((key) => columnsFromBackend[key].total as number)
    .reduce((t, v) => (t = t + v), 0);

  const getIsMultiMoveDisabled = () => {
    const selectedList = Array.from(cardSelection.values());
    const isSameColumn = selectedList
      .map((doc) => doc.columnId)
      .every((val, i, arr) => val === arr[0]);
    return allColumnsItemsLength === cardSelection.size && !isSameColumn;
  };

  const [isAlert, setAlert] = useState<{
    type: 'single' | 'bulk';
    source: string;
    destination: string;
    open: boolean;
    droppableId: number;
    taskId?: any;
    candidateId?: number;
  } | null>(null);
  const [isNoLoader, setNoLoader] = useState(false);
  const [columns, setColumns] = useState<columnTypes>(columnsFromBackend || {});
  const [columns1, setColumns1] = useState<columnTypes>(
    columnsFromBackend || {},
  );
  const [isIndex, setIndex] = useState<any>();
  const [favLoad, setFavLoad] = useState(false);

  useEffect(() => {
    if (backfor === 0) {
      if (favLoad) {
        setColumns(columns);
        return;
      }
      if (applicants) setColumns(columnsFromBackend);
    }
  }, [stages, applicants, isNoLoader]);

  const onDragStart = (start: { source: { droppableId: string } }) => {
    const homeIndex = columnOrder.indexOf(start.source.droppableId);
    setIndex(homeIndex);
    if (homeIndex === 3) {
      setIndex(columnOrder?.length + 5);
    }
  };

  // card drag function
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    setIndex(null);
    if (!destination) return;

    const sourceDropId = parseInt(source.droppableId);
    const destinationDropId = parseInt(destination.droppableId);

    if (destinationDropId === 0 && sourceDropId !== 0) return;
    if (
      columns[destinationDropId].stage_name !== REJECTED_COLUMN &&
      columns[sourceDropId].stage_name === REJECTED_COLUMN
    )
      return;

    if (sourceDropId !== destinationDropId) {
      setColumns((prevColumns) => {
        const sourceColumn = prevColumns[sourceDropId];
        const destColumn = prevColumns[destinationDropId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        const candidate_id = removed.candidate_id_id;
        destItems.splice(destination.index, 0, removed);
        var check = applicant_collection(0, removed.candidate_id_id);
        if (
          columns[sourceDropId].stage_name === NEW_APPLICANT_COLUMN ||
          columns[destinationDropId].stage_name === REJECTED_COLUMN
        ) {
          if (check) {
            setAlert({
              type: 'single',
              source: columns[sourceDropId].stage_name,
              destination: columns[destinationDropId].stage_name,
              open: true,
              droppableId: destinationDropId,
              taskId: removed.id,
              candidateId: removed.candidate_id_id,
            });
          } else {
            setStages(true);
            if (favLoad) {
              setColumns(columns);
              return;
            }
            if (applicants) setColumns(columnsFromBackend);
          }
        } else {
          if (check) {
            handleCardUpdate({
              stage_name: columns[destinationDropId].stage_name,
              taskId: removed.id,
              candidateId: removed.candidate_id_id,
              droppableId: destinationDropId,
            });
            if (favLoad) {
              setColumns(columns);
            }
          } else {
            setStages(true);
            if (favLoad) {
              setColumns(columns);
              return;
            }
            if (applicants) setColumns(columnsFromBackend);
          }
        }

        return {
          ...prevColumns,
          [sourceDropId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        };
      });
    }
  };

  const handleCardUpdate = (destination: {
    id?: any;
    stage_name: string;
    droppableId: number;
    taskId: number;
    candidateId: number;
  }) => {
    dispatch(
      kanbanUpdateMiddleWare({
        jd_id: parseInt(jd_id),
        candidate_id: [destination.candidateId],
        stages: destination.stage_name,
      }),
    )
      .then(() => {
        getApplicanPipelineData();
        // Toast(`Applicant ${destination.stage_name} successfully`);
        Toast(`Applicant moved successfully.`);
        setcheckstages('');
        dispatch(
          applicantPipeLineDataMiddleWare({
            jd_id: jdId,
            profile_match: isMatchRadio,
            candidate: isSearch,
            work_experience: isExperience,
            profile_view: isProfile,
            education_level: qaValue,
            skill_match: optionsList,
            fav: favAdd,
            sortApplicant: isSortApplicant,
            sortSortList: isSortApplicant,
            sortInterview: isSortApplicant,
            sortSelected: isSortApplicant,
            sortRejected: isSortApplicant,
            location: formik.values.location || '',
            StageStatus:
              (isselectedstatus !== null && isselectedstatus?.label) || '',
          }),
        ).then((res) => {
          setloadermatch(false);
        });
      })
      .catch(() => {
        setNoLoader(true);
        setTimeout(() => setNoLoader(false), 100);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });

    let formData = new FormData();

    if (typeof destination.candidateId === 'number') {
      let emptyarray = new Array(1).fill(destination.candidateId);
      formData.append('candidate_id', JSON.stringify(emptyarray));
    } else {
      formData.append('candidate_id', destination.candidateId);
    }
    formData.append('jd_id', parseInt(jd_id).toString());
    formData.append('stages', destination.stage_name);

    dispatch(emailcontentpostMiddleWare({ formData }));
    dispatch(emailAutomationMiddleware({ jd_id: jd_id.toString() }));
  };

  const hanldeAlertComplete = () => {
    const { taskId, candidateId, droppableId, type } = isAlert;
    if (type === 'single') {
      if (isStages === null) {
        dispatch(
          kanbanUpdateMiddleWare({
            jd_id: parseInt(jd_id),
            candidate_id: [candidateId],
            stages: getSTData(columns[droppableId]),
          }),
        )
          .then(() => {
            // getApplicanPipelineData();
            // Toast(`Applicant ${getSTData(columns[droppableId])} successfully`);
            Toast(`Applicant moved successfully.`);
            setAlert(null);
            setcheckstages('');
            dispatch(
              applicantPipeLineDataMiddleWare({
                jd_id: jdId,
                profile_match: isMatchRadio,
                candidate: isSearch,
                work_experience: isExperience,
                profile_view: isProfile,
                education_level: qaValue,
                skill_match: optionsList,
                fav: favAdd,
                sortApplicant: isSortApplicant,
                sortSortList: isSortApplicant,
                sortInterview: isSortApplicant,
                sortSelected: isSortApplicant,
                sortRejected: isSortApplicant,
                location: formik.values.location || '',
                StageStatus:
                  (isselectedstatus !== null && isselectedstatus?.label) || '',
              }),
            ).then((res) => {
              setloadermatch(false);
            });
          })
          .catch(() => {
            setNoLoader(true);
            setTimeout(() => setNoLoader(false), 100);
            Toast(ERROR_MESSAGE, 'LONG', 'error');
          });

        let formData = new FormData();

        formData.append('jd_id', parseInt(jd_id).toString());
        formData.append('stages', getSTData(columns[droppableId]));
        formData.append('candidate_id', JSON.stringify([candidateId]));

        dispatch(emailcontentpostMiddleWare({ formData }));
        dispatch(emailAutomationMiddleware({ jd_id: jd_id.toString() }));
      }
    }

    if (type === 'bulk') {
      performBulkMove(droppableId);
      setAlert(null);
    }
  };

  const getSelectedCandidateList = () => {
    const selectedList = Array.from(cardSelection.values());
    return selectedList.map((doc) => doc.task.candidate_id_id);
  };

  // popup cancel function
  const hanldeCancel = () => {
    setNoLoader(true);
    setAlert(null);
    setStages(null);
    setColumns(columns1);
    setTimeout(() => setNoLoader(false), 100);
  };

  const stagesCancel = () => {
    getApplicanPipelineData();
    setStages(null);
  };

  const handleMove = (droppableId: number) => {
    //const selectedList = Array.from(cardSelection.values());

    var check = applicant_collection(1, 'test');
    // const allTasksAreMove = selectedList.every((item) => item.task.is_move);
    // const TrueCount = selectedList.filter((item) => item.task.is_move);
    // const FalseCount = selectedList.filter((item) => !item.task.is_move);
    // setTruecount(TrueCount.length);
    // setfalsecount(FalseCount.length);

    if (columns[droppableId].stage_name === REJECTED_COLUMN) {
      if (check) {
        setAlert({
          type: 'bulk',
          source: '',
          destination: columns[droppableId].stage_name,
          open: true,
          droppableId,
        });
      } else {
        setStages(true);
      }
    } else {
      if (check) {
        performBulkMove(droppableId);
      } else {
        // setcheckstages('bulk');
        setStages(true);
      }
      //  performBulkMove(droppableId);
      // performBulkMove(droppableId);
    }
  };

  // confirm move
  const Confirm_Move = () => {
    var check = applicant_collection(1, 'test');
    if (columns[Stageid].stage_name === REJECTED_COLUMN) {
      if (check) {
        setAlert({
          type: 'bulk',
          source: '',
          destination: columns[Stageid].stage_name,
          open: true,
          droppableId: Stageid,
        });
      } else {
        performBulkMove(Stageid);
        setStages(null);
        setcheckstages('');
      }
    } else {
      performBulkMove(Stageid);
      setStages(null);
      setcheckstages('');
    }
  };

  const count_check = () => {
    const values = new_apply_count.filter(
      (val) => !active_resume.includes(val),
    );

    return values.length;
  };

  const performBulkMove = (droppableId: number) => {
    const selectedList = Array.from(cardSelection.values());
    setColumns((previous) => {
      const removedList = selectedList?.reduce((o, v) => {
        if (droppableId === v.columnId) {
          return {
            ...o,
            [v.columnId]: {
              ...previous[v.columnId],
              ...o[v.columnId],
            },
          };
        }
        if (previous[v.columnId].stage_name === REJECTED_COLUMN) {
          return {
            ...o,
            [v.columnId]: {
              ...previous[v.columnId],
              ...o[v.columnId],
            },
          };
        }
        /// Previous return data
        const previousItems =
          o[v.columnId]?.items || previous[v.columnId]?.items;
        const previousRemovedItems = previous[v.columnId]?.items.filter(
          (doc) => !previousItems.includes(doc),
        );
        /// Filter out previous existing ones
        let filteredList = previousItems.filter((doc) => {
          return doc.id !== v.task.id;
        });
        const newItems = [...filteredList];

        return {
          ...o,
          [v.columnId]: {
            ...previous[v.columnId],
            ...o[v.columnId],
            items: newItems,
            total: newItems.length,
          },
        };
      }, {}) as columnTypes;

      const movedList = getMovedList();

      function getMovedList() {
        const selectedItems = selectedList
          .filter(
            (doc) => previous[doc.columnId].stage_name !== REJECTED_COLUMN,
          )
          .map((doc) => doc.task);
        let filterItems = previous[droppableId].items.filter(
          (doc) => !selectedItems.includes(doc),
        );
        return [...filterItems, ...selectedItems];
      }
      updateBulkKanbanStage(droppableId, new Map(cardSelection));
      if (favLoad) {
        setColumns(columns);
        return;
      }
      if (applicants) setColumns(columnsFromBackend);
      setCardSelection(new Map());
      setcheckmove('');
      return {
        ...previous,
        ...removedList,
        [droppableId]: {
          ...previous[droppableId],
          items: movedList,
          total: movedList.length,
        },
      };
    });
  };

  const updateBulkKanbanStage = (
    droppableId: number,
    map: ICardSelectionMap,
  ) => {
    const selectedList = Array.from(cardSelection.values());

    const objcollection = Object.values(applicants);
    let flattenedArray = objcollection.flat();

    const updated_data = selectedList.map((aObject) => {
      const selectobj = flattenedArray.find(
        (chgobj) => chgobj.candidate_id_id === aObject.task.candidate_id_id,
      );
      return selectobj ? { ...aObject, task: selectobj } : aObject;
    });

    const move_data = updated_data.filter(
      (item) => item.task.is_move === true && item.columnId !== droppableId,
    );

    const candidateIdList = move_data
      .filter((doc) => columns[doc.columnId].stage_name !== REJECTED_COLUMN)
      .map((doc) => doc.task.candidate_id_id);

    const move_data_samecolumn = updated_data.filter(
      (item) => item.task.is_move === true && item.columnId === droppableId,
    );

    const stageIds = move_data
      .filter((doc) => columns[doc.columnId].stage_name !== REJECTED_COLUMN)
      .map((doc) => doc.columnId);

    if (candidateIdList.length === 0) {
      if (move_data_samecolumn.length > 0) {
        Toast('Applicants moved successfully.', 'LONG');
      }
      return;
    }

    dispatch(
      kanbanUpdateMiddleWare({
        jd_id: parseInt(jd_id),
        candidate_id: candidateIdList,
        stages: getSTData(columns[droppableId]),
      }),
    ).then(() => {
      if (stageIds.length > 0) {
        Toast('Applicants moved successfully.', 'LONG');
      }
      setcheckstages('');
      dispatch(
        applicantPipeLineDataMiddleWare({
          jd_id: jdId,
          profile_match: isMatchRadio,
          candidate: isSearch,
          work_experience: isExperience,
          profile_view: isProfile,
          education_level: qaValue,
          skill_match: optionsList,
          fav: favAdd,
          sortApplicant: isSortApplicant,
          sortSortList: isSortApplicant,
          sortInterview: isSortApplicant,
          sortSelected: isSortApplicant,
          sortRejected: isSortApplicant,
          location: formik.values.location || '',
          StageStatus:
            (isselectedstatus !== null && isselectedstatus?.label) || '',
        }),
      ).then((res) => {
        setloadermatch(false);
      });
    });

    let formData = new FormData();

    formData.append('jd_id', jd_id.toString());
    formData.append('stages', getSTData(columns[droppableId]));
    formData.append('candidate_id', JSON.stringify([candidateIdList]));

    dispatch(emailcontentpostMiddleWare({ formData }));
    dispatch(emailAutomationMiddleware({ jd_id: jd_id.toString() }));
  };

  function getSTData(data: IStageColumn) {
    const { stage_name } = data;
    return stage_name;
  }

  const handleNewPipeline = () => {
    sessionStorage.setItem('template', '1');
    sessionStorage.setItem('pipeline', '0');
    sessionStorage.setItem('wk_id', 'undefined');
    sessionStorage.setItem('button', '1');
    sessionStorage.setItem('JobsDropdown', '1');
    history.push('/account_setting/hiring_workflow');
  };

  //sort value save function
  const updateSortFunction = (newObject) => {
    setSortFunction((prevFunctions) => {
      // Check if the columnId already exists
      const index = prevFunctions.findIndex(
        (func) => func.columnId === newObject.columnId,
      );

      if (index !== -1) {
        // If it exists, update the existing object
        const updatedFunctions = [...prevFunctions];
        updatedFunctions[index] = { ...updatedFunctions[index], ...newObject };
        return updatedFunctions;
      } else {
        // If it doesn't exist, push the new object
        return [...prevFunctions, newObject];
      }
    });
  };

  // sorting the column based on option
  const handleSortColumn = (
    columnId: number,
    arg: string,
    backword?: boolean,
  ) => {
    var createobj = {
      columnId: columnId,
      arg: arg,
      backword: backword,
    };
    updateSortFunction(createobj);

    const data = stages_name?.find((e) => e?.stage_name === arg) || null;
    setColumns((prevColumns) => {
      if (backword === false) {
        if (data !== null) {
          if (data && data.stage_name === arg) {
            const newItem = [...prevColumns[columnId].items];
            newItem.sort((a, b) => {
              if (a.current_stage === arg && b.current_stage !== arg) {
                return -1;
              } else if (a.current_stage !== arg && b.current_stage === arg) {
                return 1;
              } else {
                return a.current_stage.localeCompare(b.current_stage);
              }
            });

            // Group items by current_stage using reduce
            const groupedByStage = newItem.reduce((acc, item) => {
              const stage = item.current_stage;
              acc[stage] = acc[stage] || [];
              acc[stage].push(item);
              return acc;
            }, {});

            //stages  arrange //
            const groupstages = sort_order(groupedByStage, arg);

            return {
              ...prevColumns,
              [columnId]: {
                ...prevColumns[columnId],
                items: groupstages,
              },
            };
          }
        }
        if (arg === 'scheduled') {
          const newItem = [...prevColumns[columnId].items];
          newItem.sort((a: ApplicantEntity, b: ApplicantEntity) => {
            // Sorting based on boolean values
            return b.interview_scheduled
              ? 1
              : -1 - (a.interview_scheduled ? 1 : -1);
          });
          return {
            ...prevColumns,
            [columnId]: {
              ...prevColumns[columnId],
              items: newItem,
            },
          };
        }
        if (arg !== 'match') {
          const newItem = [...prevColumns[columnId].items];
          newItem.sort((a, b) => {
            if (arg === 'date') {
              return (
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
              );
            }
            if (arg === 'name') {
              return a.name.localeCompare(b.name);
            }
          });
          return {
            ...prevColumns,
            [columnId]: {
              ...prevColumns[columnId],
              items: newItem,
            },
          };
        }
        if (arg === 'match') {
          const newItem = [...prevColumns[columnId].items];
          newItem.sort((a, b) => {
            const aMatch = String(a.match)
              ?.toLowerCase()
              ?.replaceAll('null', '');
            const bMatch = String(b.match)
              ?.toLowerCase()
              ?.replaceAll('null', '');
            return bMatch.localeCompare(aMatch);
          });

          return {
            ...prevColumns,
            [columnId]: {
              ...prevColumns[columnId],
              items: newItem,
            },
          };
        }
      } else {
        if (data !== null) {
          if (data && data.stage_name === arg) {
            const newItem = [...prevColumns[columnId].items];

            newItem.sort((a, b) => {
              if (a.current_stage === arg && b.current_stage !== arg) {
                return -1;
              } else if (a.current_stage !== arg && b.current_stage === arg) {
                return 1;
              } else {
                return a.current_stage.localeCompare(b.current_stage);
              }
            });

            // Group items by current_stage using reduce
            const groupedByStage = newItem.reduce((acc, item) => {
              const stage = item.current_stage;
              acc[stage] = acc[stage] || [];
              acc[stage].push(item);
              return acc;
            }, {});

            //stages  arrange //
            const groupstages = sort_order(groupedByStage, arg);

            return {
              ...prevColumns,
              [columnId]: {
                ...prevColumns[columnId],
                items: groupstages,
              },
            };
          }
        }
        if (arg === 'notscheduled') {
          const newItem = [...prevColumns[columnId].items];
          newItem.sort((a: ApplicantEntity, b: ApplicantEntity) => {
            // Sorting based on boolean values
            return a.interview_scheduled
              ? 1
              : -1 - (b.interview_scheduled ? 1 : -1);
          });
          return {
            ...prevColumns,
            [columnId]: {
              ...prevColumns[columnId],
              items: newItem,
            },
          };
        }
        if (arg !== 'matchbackword') {
          const newItem = [...prevColumns[columnId].items];
          newItem.sort((a, b) => {
            if (arg === 'datebackword') {
              return (
                new Date(a.created_on).getTime() -
                new Date(b.created_on).getTime()
              );
            }
            if (arg === 'namebackword') {
              return b.name.localeCompare(a.name);
            }
          });
          return {
            ...prevColumns,
            [columnId]: {
              ...prevColumns[columnId],
              items: newItem,
            },
          };
        }
        if (arg === 'matchbackword') {
          const newItem = [...prevColumns[columnId].items];
          newItem.sort((a, b) => {
            const aMatch = String(a.match)
              ?.toLowerCase()
              ?.replaceAll('null', '');
            const bMatch = String(b.match)
              ?.toLowerCase()
              ?.replaceAll('null', '');
            return aMatch.localeCompare(bMatch);
          });

          return {
            ...prevColumns,
            [columnId]: {
              ...prevColumns[columnId],
              items: newItem,
            },
          };
        }
      }
      return {
        ...prevColumns,
        [columnId]: {
          ...prevColumns[columnId],
          items: applicants[columnId],
        },
      };
    });
  };

  // update function status sort//
  const sort_order = (groupedByStage, arg) => {
    if (arg === 'Completed') {
      const sortedRecords = [
        ...(groupedByStage['Completed'] || []),
        ...(groupedByStage['To Do'] || []),
        ...(groupedByStage['In Progress'] || []),
        ...(groupedByStage['On-Hold'] || []),
      ];
      return sortedRecords;
    } else if (arg === 'In Progress') {
      const sortedRecords = [
        ...(groupedByStage['In Progress'] || []),
        ...(groupedByStage['On-Hold'] || []),
        ...(groupedByStage['Completed'] || []),
        ...(groupedByStage['To Do'] || []),
      ];
      return sortedRecords;
    } else if (arg === 'On-Hold') {
      const sortedRecords = [
        ...(groupedByStage['On-Hold'] || []),
        ...(groupedByStage['Completed'] || []),
        ...(groupedByStage['To Do'] || []),
        ...(groupedByStage['In Progress'] || []),
      ];
      return sortedRecords;
    } else if (arg === 'To Do') {
      const sortedRecords = [
        ...(groupedByStage['To Do'] || []),
        ...(groupedByStage['In Progress'] || []),
        ...(groupedByStage['On-Hold'] || []),
        ...(groupedByStage['Completed'] || []),
      ];
      return sortedRecords;
    }
  };

  /// Search ------------------->
  const handlecleardata = (val) => {
    const url = new URL(window.location.href);
    if (val === 1) {
      const locationParam = url.searchParams.get('location');
      url.search = '';
      if (locationParam !== null) {
        url.searchParams.set('location', locationParam);
      }
    } else if (val === 2) {
      const searchParam = url.searchParams.get('search');
      url.search = '';
      if (searchParam !== null) {
        url.searchParams.set('search', searchParam);
      }
    } else if (val === 0) {
      const locationParam = url.searchParams.get('location');
      const searchParam = url.searchParams.get('search');
      url.search = '';
      if (locationParam !== null) {
        url.searchParams.set('location', locationParam);
      }
      if (searchParam !== null) {
        url.searchParams.set('search', searchParam);
      }
    }
    window.history.replaceState(null, '', url.toString());

    setshowskill1(undefined);
    setMatchRadio('');
    setExperience('');
    setProfile('');
    setSkillOption('');
    setskill1(undefined);
    setprofile1(undefined);
    setmatch1(undefined);
    setselectedstatus('');
    setstagestatus('');
    setexperience1(undefined);
    setshowskill1(undefined);
    setBachelors(false);
    setMasters(false);
    setDoctorate(false);
    setDiploma(false);
    setOther(false);
    setAny(true);
    setqualification1(undefined);
  };

  const onSearchChange = (e: React.ChangeEvent<any>) => {
    if (e) {
      if (e.target.innerText) {
        setSearch(e.target.innerText);
      } else {
        setSearch(e.target.value);

        refetch(e.target.value.length);
      }
    }
  };

  const refetch = (val) => {
    if (back) {
      if (val === 0) {
        onClearSearch();
      }
    }
  };

  // clearing thew location when click the cross icon.
  const onClearSearch = () => {
    setSearch('');
    handlecleardata(1);
    setloadermatch(true);
    setSortFunction([]);
    dispatch(
      applicantPipeLineDataMiddleWare({
        jd_id: jdId,
        profile_match: isMatchRadio,
        candidate: '',
        work_experience: isExperience,
        profile_view: isProfile,
        education_level: qaValue,
        skill_match: optionsList,
        fav: favAdd,
        sortApplicant: isSortApplicant,
        sortSortList: isSortApplicant,
        sortInterview: isSortApplicant,
        sortSelected: isSortApplicant,
        sortRejected: isSortApplicant,
        location: formik.values.location || '',
        StageStatus:
          (isselectedstatus !== null && isselectedstatus?.label) || '',
      }),
    ).then(() => {
      currentParams.delete('search');
      history.push({
        pathname: window.location.pathname,
        search: currentParams.toString(),
      });
      setloadermatch(false);
      setback(false);
    });
  };

  const handlesearch = () => {
    setFavLoad(false);
    handlecleardata(0);
    if (formik.values.location !== '' || isSearch !== '') {
      // Set the 'search' parameter
      if (isSearch) {
        currentParams.set('search', isSearch);
      } else {
        currentParams.delete('search');
      }
      if (formik.values.location) {
        currentParams.set('location', formik.values.location);
      } else {
        currentParams.delete('location');
      }
      history.push({
        pathname: window.location.pathname,
        search: currentParams.toString(),
      });

      setloadermatch(true);
      setback(true);
      dispatch(
        applicantPipeLineDataMiddleWare({
          jd_id: jdId,
          profile_match: isMatchRadio,
          candidate: isSearch,
          work_experience: isExperience,
          profile_view: isProfile,
          education_level: qaValue,
          skill_match: optionsList,
          fav: favAdd,
          sortApplicant: isSortApplicant,
          sortSortList: isSortApplicant,
          sortInterview: isSortApplicant,
          sortSelected: isSortApplicant,
          sortRejected: isSortApplicant,
          location: formik.values.location || '',
          StageStatus:
            (isselectedstatus !== null && isselectedstatus?.label) || '',
        }),
      ).then((res) => {
        setloadermatch(false);
      });
      setSortFunction([]);
    }
  };

  const onLocationChange = (val: any) => {
    if (val.target.innerText) {
      formik.handleChange('location')(val.target.innerText);
    } else {
      const inputValue = val.target.value;
      if (inputValue?.length === 1 && inputValue[0] === ' ') {
        return;
      }
      formik.handleChange('location')(inputValue);
    }
  };

  const onClearLocation = () => {
    currentParams.delete('location');
    // Update the browser's history state with the new parameters
    history.push({
      pathname: window.location.pathname,
      search: currentParams.toString(),
    });
    handlecleardata(2);
    formik.handleChange('location')('');
    setloadermatch(true);
    dispatch(
      applicantPipeLineDataMiddleWare({
        jd_id: jdId,
        profile_match: '',
        candidate: '',
        work_experience: '',
        profile_view: '',
        education_level: '',
        skill_match: '',
        fav: favAdd,
        sortApplicant: isSortApplicant,
        sortSortList: isSortApplicant,
        sortInterview: isSortApplicant,
        sortSelected: isSortApplicant,
        sortRejected: isSortApplicant,
        location: '',
        StageStatus: '',
      }),
    ).then(() => {
      setloadermatch(false);
    });
    setSortFunction([]);
  };

  const onLocationKeyPress = (event) => {
    if (event.key === 'Enter') {
      formik.setFieldValue('location', event.target.value);
      setloadermatch(true);
      dispatch(
        applicantPipeLineDataMiddleWare({
          jd_id: jdId,
          profile_match: isMatchRadio,
          candidate: isSearch,
          work_experience: isExperience,
          profile_view: isProfile,
          education_level: qaValue,
          skill_match: optionsList,
          fav: favAdd,
          sortApplicant: isSortApplicant,
          sortSortList: isSortApplicant,
          sortInterview: isSortApplicant,
          sortSelected: isSortApplicant,
          sortRejected: isSortApplicant,
          location: event.target.value,
          StageStatus:
            (isselectedstatus !== null && isselectedstatus?.label) || '',
        }),
      ).then(() => {
        setloadermatch(false);
      });
      event.target.blur();
    }
  };

  const handleBulkDownload = () => {
    if (cardSelection.size >= 2) {
      const candidate_id = getSelectedCandidateList();
      dispatch(
        downloadApplicantsMiddleware({
          jd_id: jdId,
          download: 'download',
          candidate_id,
        }),
      );
    }
  };

  const handleCSVDownload = () => {
    dispatch(
      downloadApplicantsMiddleware({ jd_id: jdId, csvdownload: 'csvdownload' }),
    );
  };
  const handleWeightageOpen = () => {
    handlefunction();
    setmodel(true);
  };
  const handleWeightageClose = () => {
    closefunction();
    setmodel(false);
  };
  const onComparative = () => {
    localStorage.setItem('compare', 'false');
    setaimodel(true);
    setComparmodel(true);
  };
  const updatemodel = (val, id) => {
    if (val === true) {
      setComparmodel(val);
    } else {
      if (id === 1) {
        setCardSelection(new Map());
        setcheckmove('');
        setmatching([]);
      }
      setComparmodel(val);
    }
  };
  const open_subscription = (val, data) => {
    if (data === 0) {
      setsendData('Ai_matching_applicant');

      if (current_resume_count === 0 && ai_matched_count === 0) {
        setpurpose(
          "Advanced AI Matching Limit And AI Resume Parsing Limit Reached: To continue viewing applicants, you must 'Upgrade your plan' or 'Buy additional AI Matching with Descriptive Analytics Credits'and 'Buy additional AI Resume parsing Credits'.",
        );
        setfreetrial(
          'AI Resume Parsing & AI Matching Limit Exceeds: To continue viewing applicants, you must Upgrade your plan',
        );
        setpurpose_addon(
          "Advanced AI Matching Limit And AI Resume Parsing Limit Reached: To continue viewing applicants, you must 'Buy additional AI Matching with Descriptive Analytics Credits'and 'Buy additional AI Resume parsing Credits'.",
        );
        setsendAddon([
          'AI Resume Parsing Credits',
          'AI Resume Matching with Descriptive Analytics',
        ]);
      } else if (current_resume_count === 0) {
        setpurpose(
          "AI Resume Parsing Limit Exceeds: To continue viewing applicants, you must 'Upgrade your plan' or 'Buy additional AI Resume parsing Credits'.",
        );
        setfreetrial(
          "AI Resume Parsing Limit Exceeds: To continue viewing applicants, you must 'Upgrade your plan'.",
        );
        setpurpose_addon(
          "AI Resume Parsing Limit Reached: To continue viewing applicants, you must 'Buy additional AI Resume parsing Credits'.",
        );
        setsendAddon(['AI Resume Parsing Credits']);
      }
    } else {
      setsendData('Ai_comparative');
      setsendAddon(['AI Resume Comparative Analysis & Recommendation to Hire']);
      setpurpose('');
      setfreetrial('');
      setpurpose_addon('');
    }
    setopensubcription(val);
  };

  const plan_permission = () => {
    var access = permission.includes('Customizable Weightage');
    if (access) {
      return (
        <Button
          onClick={handleWeightageOpen}
          className={styles.btnStyle}
          types="secondary"
        >
          Refine Match Parameters
        </Button>
      );
    }
  };

  const column_restriction = () => {
    const selectedList = Array.from(cardSelection.values());
    if (selectedList.length > 0) {
      const move_data = selectedList.map((item) => item.columnId);

      var allSame = move_data.every(function (element) {
        return element === move_data[0];
      });

      if (allSame) {
        const [commonElement] = move_data;
        setcheckmove(commonElement);
      } else {
        setcheckmove('');
      }
    } else {
      setcheckmove('');
    }
  };

  return (
    <>
      {isApplicantView === true ? (
        <ProfileView
          open={isApplicantView}
          cancel={() => {
            localStorage.setItem('applied_view', 'false');
            localStorage.setItem('innernavigationtab', '0');
            setApplicantView(false);
            setbackfor(0);
          }}
          jobId={getAppliedJd}
          candidateId={getAppliedCanId}
          inviteIconNone
          applicantpipeline={false}
          activeState={isActive}
        />
      ) : (
        <>
          <Helmet>
            <title>Applicant pipeline</title>
          </Helmet>

          {showPipelinePopup && showStagesPopup === null && (
            <PipelinePopup
              jd_id={parseInt(jdId)}
              openPipelinePopup={showPipelinePopup}
              onClose={() => {
                handleClosePipelinePopup();
                // history.goBack();
                history.push(routesPath.MY_JOB_POSTING);
              }}
              onSuccessClose={handleClosePipelinePopup}
              onNewPipeline={handleNewPipeline}
            />
          )}
          {showPipelinePopup && showStagesPopup && (
            <PipelinePopupTwo
              jd_id={parseInt(jdId)}
              openPipelinePopup={showPipelinePopup}
              onClose={() => {
                handleClosePipelinePopup();
                // history.goBack();
                history.push(routesPath.MY_JOB_POSTING);
              }}
              onSuccessClose={handleClosePipelinePopup}
              onNewPipeline={handleNewPipeline}
            />
          )}
          {applicantDataLoader ||
          pipeLineLoader ||
          islodermatch ||
          isLoading ||
          addonloader ? (
            <Loader />
          ) : (
            <Flex row className={styles.overAll}>
              {/* applicant filter */}
              <Flex className={styles.filterFlex}></Flex>
              <Flex
                columnFlex
                className={styles.dndBoardContainer}
                // width={window.innerWidth - 220}
              >
                <Flex row className={styles.titleContainer}>
                  <Text bold size={16} color="theme">
                    Applicants Pipeline
                  </Text>
                  <JobTitleCard job_details={job_details} />
                  <div className={styles.triangle}> </div>
                </Flex>
                {/* search bar and zita button */}
                <Flex row between marginBottom={15}>
                  <Flex
                    row
                    style={{ position: 'relative', overFlowX: 'auto' }}
                    className={styles.searchbox}
                  >
                    <Flex row className={styles.searchstyle}>
                      <Text className={styles.jobstext}>
                        {/* <SvgJobTitleIcon width={20} height={20} fill={'#581845'} /> */}
                        <SvgJobselection width={18} height={18} />
                      </Text>
                      <Flex row className={styles.searchboxoverall}>
                        <InputSearch
                          labelBold
                          initialValue={isSearch}
                          options={candidate_name_mail}
                          onChange={(e) => onSearchChange(e)}
                          onkeyPress={(e) => onSearchChange(e)}
                          style={styles.boxstylename}
                          placeholder="Search by name or email"
                          setFieldValue={(e) => onSearchChange(e)}
                        />
                        {isSearch.trim() !== '' && (
                          <button
                            className={styles.crossIcon}
                            onClick={onClearSearch}
                          >
                            <SvgIntomark
                              width={14}
                              height={14}
                              fill="#888888"
                            />
                          </button>
                        )}

                        <Flex className={styles.middleline}></Flex>
                        <Flex className={styles.locationicon}>
                          <SvgLocation
                            width={18}
                            height={18}
                            fill={'#581845'}
                          />
                        </Flex>
                        <InputSearch
                          labelBold
                          initialValue={formik.values.location}
                          options={location_list}
                          onChange={onLocationChange}
                          onkeyPress={onLocationChange}
                          placeholder="Select applicant location"
                          style={styles.boxstylename}
                          setFieldValue={formik.setFieldValue}
                        />
                        {formik.values.location.trim() !== '' && (
                          <button
                            className={styles.crossIcon}
                            onClick={onClearLocation}
                          >
                            <SvgIntomark
                              width={14}
                              height={14}
                              fill="#888888"
                            />
                          </button>
                        )}

                        <Flex
                          className={styles.searchicons}
                          onClick={() => handlesearch()}
                          style={{ cursor: 'pointer' }}
                        >
                          <SvgSearch width={12} height={12} fill="#ffffff" />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex row>
                    <Flex>
                      {/* <LinkWrapper > */}
                      {plan_permission()}

                      {/* </LinkWrapper> */}
                    </Flex>
                    {zita_match_count === 0 ? (
                      <Button
                        disabled
                        className={styles.btnStyle}
                        types="primary"
                      >
                        Find More Talents
                      </Button>
                    ) : (
                      <LinkWrapper replace to={`/all_candidates/${jdId}`}>
                        <Button className={styles.btnStyle} types="primary">
                          Find More Talents
                        </Button>
                      </LinkWrapper>
                    )}
                  </Flex>
                </Flex>
                <Modal open={model}>
                  <Flex className={styles.weightagepopup}>
                    <Flex className={styles.popupheading}>
                      <Text size={14} bold>
                        Refine Match Parameters
                      </Text>
                    </Flex>
                    <Flex className={styles.parent} mt-30>
                      <Flex style={{ width: '49%' }}>
                        <Flex className={styles.progressbarstyle}>
                          {/* <Flex><Text bold style={{ paddingTop: "10px", paddingBottom: '10px' }}>Profile Compatibility Criteria</Text></Flex> */}
                          <Flex row center className={styles.techtitleblock}>
                            <Flex className={styles.techmatchtitle}>
                              <Text bold>Profile Compatibility Criteria</Text>
                            </Flex>
                            <Flex
                              title="Reset to default weightage."
                              className={styles.techresetbutton}
                            >
                              <SvgRefresh
                                width={18}
                                height={18}
                                onClick={technicalresetfunction}
                                className={styles.filtersvg}
                              />
                            </Flex>
                          </Flex>
                          <Flex
                            style={{
                              width: '100px',
                              height: '100px',
                            }}
                          >
                            <CircularProgressbar
                              value={technicalPercent}
                              text={`${technicalPercent}%`}
                              strokeWidth={10}
                              styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                //rotation: 0.25,

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                //  strokeLinecap: 'butt',

                                // Text size
                                textSize: '16px',

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                pathColor: `rgba(0,190,75, ${
                                  technicalPercent / 100
                                })`,
                                textColor: 'black',
                                trailColor: '#d6d6d6',

                                backgroundColor: '#3e98c7',
                              })}
                            />
                          </Flex>
                        </Flex>
                        <Flex>
                          <Flex className={styles.sliderstyle} marginTop={20}>
                            <Flex>
                              <Text>Technical Skills</Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  rangeValueskill === '' ? 0 : rangeValueskill
                                }
                                className={styles.customrange}
                                onChange={handleRangeChange}
                                style={{
                                  // Styling with violet color

                                  width: '200px',
                                  // Set the width as needed
                                  color: 'white', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueskill / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueskill / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueskill}
                                  onChange={handleRangeChange}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueskill < 99 ? '40px' : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Roles and Responsibilities </Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                className={styles.customrange}
                                value={
                                  rangeValuerolles === '' ? 0 : rangeValuerolles
                                }
                                onChange={handleRangeChangerole}
                                style={{
                                  // Styling with violet color

                                  width: '200px',
                                  // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing

                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValuerolles / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValuerolles / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValuerolles}
                                  onChange={handleRangeChangerole}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValuerolles < 99 ? '40px' : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Experience</Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  rangeValueexperience === ''
                                    ? 0
                                    : rangeValueexperience
                                }
                                className={styles.customrange}
                                onChange={handleRangeChangeexperience}
                                style={{
                                  width: '200px', // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueexperience / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueexperience / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueexperience}
                                  onChange={handleRangeChangeexperience}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueexperience < 99
                                        ? '40px'
                                        : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Technical Tools and Languages </Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  rangeValueTechnical === ''
                                    ? 0
                                    : rangeValueTechnical
                                }
                                onChange={handleRangeChangetechnical}
                                className={styles.customrange}
                                style={{
                                  // Styling with violet color
                                  width: '200px', // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueTechnical / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueTechnical / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueTechnical}
                                  onChange={handleRangeChangetechnical}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueTechnical < 99
                                        ? '40px'
                                        : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Soft Skills </Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                className={styles.customrange}
                                value={
                                  rangeValueSoft === '' ? 0 : rangeValueSoft
                                }
                                onChange={handleRangeChangesoft}
                                style={{
                                  // Styling with violet color
                                  width: '200px', // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueSoft / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueSoft / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueSoft}
                                  onChange={handleRangeChangesoft}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueSoft < 99 ? '40px' : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Qualifications</Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  rangeValueQualifications === ''
                                    ? 0
                                    : rangeValueQualifications
                                }
                                className={styles.customrange}
                                onChange={handleRangeChangequalifications}
                                style={{
                                  // Styling with violet color
                                  width: '200px', // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueQualifications / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueQualifications / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueQualifications}
                                  onChange={handleRangeChangequalifications}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueQualifications < 99
                                        ? '40px'
                                        : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Flex className={styles.sliderstyle}>
                            {totaltechnical !== 100 && (
                              <Text
                                style={{
                                  display: 'flex',
                                  alignSelf: 'flex-between',
                                }}
                                size={12}
                                color="error"
                              >
                                Profile compatibility criteria must equal 100
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </Flex>

                      <Flex className={styles.splitline}></Flex>

                      <Flex className={styles.split}></Flex>

                      <Flex style={{ width: '49%' }}>
                        <Flex className={styles.progressbarstyle}>
                          {/* <Flex><Text bold style={{ paddingTop: "10px", paddingBottom: '10px' }}>Enhanced Matching Criteria</Text></Flex> */}
                          <Flex row center className={styles.nontechtitleblock}>
                            <Flex className={styles.nontechmatchtitle}>
                              <Text bold>Enhanced Matching Criteria</Text>
                            </Flex>
                            <Flex
                              title="Reset to default weightage."
                              className={styles.nontechresetbutton}
                            >
                              <SvgRefresh
                                width={18}
                                height={18}
                                onClick={nontechnicalresetfunction}
                                className={styles.filtersvg}
                              />
                            </Flex>
                          </Flex>
                          <Flex
                            style={{
                              width: '100px',
                              height: '100px',
                            }}
                          >
                            <CircularProgressbar
                              value={nonTechnicalPercent}
                              text={`${nonTechnicalPercent}%`}
                              strokeWidth={10}
                              styles={buildStyles({
                                textSize: '16px',
                                pathColor: `rgba(0,190,75, ${
                                  nonTechnicalPercent / 100
                                })`,
                                textColor: 'black',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                              })}
                            />
                          </Flex>
                        </Flex>

                        <Flex>
                          <Flex className={styles.sliderstyle} marginTop={20}>
                            <Flex>
                              <Text>Industry Specific Experience </Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  rangeValueIndustry === ''
                                    ? 0
                                    : rangeValueIndustry
                                }
                                className={styles.customrange}
                                onChange={handleRangeChangeindustry}
                                style={{
                                  // Styling with violet color
                                  width: '200px', // Set the width as needed
                                  color: 'white', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueIndustry / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueIndustry / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueIndustry}
                                  onChange={handleRangeChangeindustry}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueIndustry < 99 ? '40px' : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Domain Specific Experience </Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                className={styles.customrange}
                                value={
                                  rangeValueDomain === '' ? 0 : rangeValueDomain
                                }
                                onChange={handleRangeChangedomain}
                                style={{
                                  // Styling with violet color
                                  width: '200px', // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueDomain / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueDomain / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueDomain}
                                  onChange={handleRangeChangedomain}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueDomain < 99 ? '40px' : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Certifications </Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  rangeValueCertifications === ''
                                    ? 0
                                    : rangeValueCertifications
                                }
                                className={styles.customrange}
                                onChange={handleRangeChangecertification}
                                style={{
                                  // Styling with violet color
                                  width: '200px', // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueCertifications / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueCertifications / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueCertifications}
                                  onChange={handleRangeChangecertification}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueCertifications < 99
                                        ? '40px'
                                        : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Cultural Fit</Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  rangeValueCultural === ''
                                    ? 0
                                    : rangeValueCultural
                                }
                                onChange={handleRangeChangecultural}
                                className={styles.customrange}
                                style={{
                                  // Styling with violet color
                                  width: '200px', // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueCultural / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueCultural / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueCultural}
                                  onChange={handleRangeChangecultural}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueCultural < 99 ? '40px' : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex className={styles.sliderstyle}>
                            <Flex>
                              <Text>Location Alignment </Text>
                            </Flex>
                            <Flex className={styles.innerstyle}>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  rangeValueLocation === ''
                                    ? 0
                                    : rangeValueLocation
                                }
                                className={styles.customrange}
                                onChange={handleRangeChangelocation}
                                style={{
                                  // Styling with violet color
                                  width: '200px', // Set the width as needed
                                  color: 'violet', // Violet color
                                  WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                  margin: '10px 0', // Add margin for spacing
                                  cursor: 'pointer', // Show pointer cursor
                                  background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                    (rangeValueLocation / 100) * 100
                                  }%, #d3d3d3 ${
                                    (rangeValueLocation / 100) * 100
                                  }%, #d3d3d3 100%)`,
                                  borderRadius: '5px', // Add border radius
                                }}
                              />
                              <Flex style={{ marginLeft: '20px' }}>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={rangeValueLocation}
                                  onChange={handleRangeChangelocation}
                                  maxLength={3}
                                  className={styles.scoreinputfield}
                                  onKeyDown={(evt) =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  style={{
                                    width:
                                      rangeValueLocation < 99 ? '40px' : '50px',
                                  }}
                                ></input>
                              </Flex>
                            </Flex>
                          </Flex>
                          {isstatus === true && (
                            <Flex className={styles.sliderstyle}>
                              <Flex>
                                <Text>Nice to have Skills </Text>
                              </Flex>
                              <Flex className={styles.innerstyle}>
                                <input
                                  type="range"
                                  min="0"
                                  max="100"
                                  className={styles.customrange}
                                  value={
                                    rangeValueReferences === ''
                                      ? 0
                                      : rangeValueReferences
                                  }
                                  onChange={handleRangeChangereferences}
                                  style={{
                                    // Styling with violet color
                                    width: '200px', // Set the width as needed
                                    color: 'violet', // Violet color
                                    WebkitAppearance: 'none', // Remove default styling in Webkit browsers
                                    margin: '10px 0', // Add margin for spacing
                                    cursor: 'pointer', // Show pointer cursor
                                    background: `linear-gradient(to right, #d3d3d3 0%, #996666 ${
                                      (rangeValueReferences / 100) * 100
                                    }%, #d3d3d3 ${
                                      (rangeValueReferences / 100) * 100
                                    }%, #d3d3d3 100%)`,
                                    borderRadius: '5px', // Add border radius
                                  }}
                                />
                                <Flex style={{ marginLeft: '20px' }}>
                                  <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    value={rangeValueReferences}
                                    onChange={handleRangeChangereferences}
                                    maxLength={3}
                                    className={styles.scoreinputfield}
                                    onKeyDown={(evt) =>
                                      ['e', 'E', '+', '-'].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    style={{
                                      width:
                                        rangeValueReferences < 99
                                          ? '40px'
                                          : '50px',
                                    }}
                                  ></input>
                                </Flex>
                              </Flex>
                            </Flex>
                          )}
                          <Flex className={styles.sliderstyle}>
                            {totalnontechnical !== 0 &&
                              totalnontechnical !== 100 && (
                                <Text
                                  style={{
                                    display: 'flex',
                                    alignSelf: 'flex-between',
                                  }}
                                  size={12}
                                  color="error"
                                >
                                  Enhanced matching criteria must be equal to 0
                                  or 100
                                </Text>
                              )}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex row center className={styles.popbtnContainer}>
                      <Flex></Flex>
                      <Flex row>
                        <Flex className={styles.cancelBtn}>
                          <Button onClick={handleWeightageClose} types="close">
                            Cancel
                          </Button>
                        </Flex>
                        <Flex>
                          {isnextLoader ? (
                            <Flex className={styles.updateBtnLoader}>
                              <Loader size="small" withOutOverlay />
                            </Flex>
                          ) : (
                            <Button
                              // disabled={
                              //   totaltechnical !== 100 &&
                              //   totalnontechnical !== 0 &&
                              //   totalnontechnical > 100
                              // }
                              // disabled={(totaltechnical !== 100) && (totalnontechnical !== 100 && totalnontechnical !== 0)}
                              disabled={
                                totaltechnical === 100
                                  ? totalnontechnical === 0 ||
                                    totalnontechnical === 100
                                    ? false
                                    : true
                                  : true
                              }
                              types="primary"
                              onClick={nextfunction}
                            >
                              Apply
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Modal>
                <ApplicantPipeLineFilter
                  setchange={setchange}
                  isSkillOption={isSkillOption}
                  isSkills={isSkills}
                  isSearch={isSearch}
                  setSearch={setSearch}
                  handleKeyPress={handleKeyPress}
                  isMatchRadio={isMatchRadio}
                  hanldeMatch={hanldeMatch}
                  isProfile={isProfile}
                  hanldeProfile={hanldeProfile}
                  setExperience={setExperience}
                  setSkills={setSkills}
                  setSkillOption={setSkillOption}
                  qualificationOption={qualificationOption}
                  hanldeRefresh={hanldeRefresh}
                  handleSearch={handleSearch}
                  isExperience={isExperience}
                  clear_filter={clear_filter}
                  settrigger={settrigger}
                  onClearLocation={onClearLocation}
                  setstagestatus={setstagestatus}
                  transformedData={transformedData}
                  isstagestatus={isstagestatus}
                  setselectedstatus={setselectedstatus}
                  isselectedstatus={isselectedstatus}
                  setqualification1={setqualification1}
                  qualification1={qualification1}
                  setmatch1={setmatch1}
                  match1={match1}
                  setprofile1={setprofile1}
                  profile1={profile1}
                  setexperience1={setexperience1}
                  experience1={experience1}
                  showskill1={showskill1}
                  setshowskill1={setshowskill1}
                  skill1={skill1}
                  setskill1={setskill1}
                  handleMaster={handleMaster}
                  handleBachelor={handleBachelor}
                  handleDoctorate={handleDoctorate}
                  handleDiploma={handleDiploma}
                  handleOther={handleOther}
                />

                <TotalApplicant
                  jd_id={parseInt(jdId)}
                  columns={columns}
                  Matching={Matching}
                  setemailtemplate={setemailtemplate}
                  total={total_applicants}
                  moveDisabled={getIsMultiMoveDisabled()}
                  filterTotalFav={filterTotalFav}
                  isTotalFav={isTotalFav}
                  seletedCardsLength={cardSelection.size}
                  onExport={handleBulkDownload}
                  onMove={handleMove}
                  onCSVDownload={handleCSVDownload}
                  onComparative={onComparative}
                  current_resume_count={current_resume_count}
                  permission={permission}
                  comparative={comparative}
                  plan_details={plan_details}
                  current_plan={current_plan}
                  open_subscription={open_subscription}
                  user_credits={user_credits}
                  Stageid={setStageid}
                  column_restriction={column_restriction}
                  check_move={checkmove}
                  showAutomatePopup={showAutomatePopup}
                  setShowAutomatePopup={setShowAutomatePopup}
                  job_details={job_details}
                />
                {isNotEmpty() && ColumnisEmpty() ? (
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      overflow: 'auto',
                      height: '-webkit-fill-available',
                    }}
                  >
                    {columns && (
                      <DndTitle
                        stages_name={stages_name}
                        columns={columnOrder
                          .map((key) => columns[key])
                          .sort((a, b) => a.stage_order - b.stage_order)}
                        setSortApplicant={handleSortColumn}
                        onSelectAll={handleColumnSelect}
                        onUnselectAll={handleColumnUnselect}
                        cardSelectionMap={cardSelection}
                        new_apply_count={new_apply_count}
                        active_resume={active_resume}
                      />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        flex: 1,
                        height: '-webkit-fill-available',
                      }}
                      className={styles.scrollStyle}
                    >
                      {columns && (
                        <DndBoardScreen
                          columns={Object.keys(columns)
                            .map((key) => columns[key])
                            .sort((a, b) => a.stage_order - b.stage_order)}
                          jd_id={jd_id}
                          outlook={outlook}
                          google={google}
                          job_details={job_details}
                          onClick={handleCardSelection}
                          cardSelectionMap={cardSelection}
                          isAlert={isAlert}
                          isIndex={isIndex}
                          onDragStart={onDragStart}
                          onDragEnd={onDragEnd}
                          hanldeAlertConfirm={hanldeAlertComplete}
                          hanldeCancel={hanldeCancel}
                          onRefresh={getApplicanPipelineData}
                          active_resume={active_resume}
                          new_apply_count={count_check}
                          open_subscription={open_subscription}
                          setbackfor={setbackfor}
                          Stages={isStages}
                          checkstages={checkstages}
                          Confirm_Move={Confirm_Move}
                          Truecount={truecount}
                          Falsecount={falsecount}
                          StagesCancel={stagesCancel}
                          showAutomatePopup={showAutomatePopup}
                          setShowAutomatePopup={setShowAutomatePopup}
                          Fav_loader={Fav_loader}
                          loadingFav={loadingFav}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <Flex middle center height={window.innerHeight - 236}>
                    <Flex>
                      <Flex center middle>
                        <SvgNotinterests
                          width={18}
                          height={18}
                          style={{ filter: 'opacity(0.5)' }}
                        />
                      </Flex>
                      <Text color={'gray'}>No Applicants Found</Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}

          {aimodel && (
            <ComparativeModal
              Comparmodel={Comparmodel}
              updatemodel={updatemodel}
              Matching={Matching}
              job_details={job_details}
              select_candidate={select_candidate}
            />
          )}

          {isopensubcription && (
            <SubscriptionModal
              openmodel={isopensubcription}
              setopensubcription={setopensubcription}
              addon_name={sendAddon}
              differece_key={[sendData]}
              purpose={purpose}
              purpose_freetrial={freetrial}
              purpose_addon={purpose_addon}
            />
          )}
        </>
      )}
    </>
  );

  function isNotEmpty() {
    const keys = Object.keys(applicants);
    if (keys.length !== 0) {
      return keys.map((key) => applicants[key].length === 0).includes(false);
    }
    return false;
  }

  function ColumnisEmpty() {
    const keys = Object.keys(columns);

    // Calculate the total length of all items across all columns
    const totalItems = keys.reduce((sum, key) => {
      return sum + columns[+key].items.length;
    }, 0);
    if (totalItems > 0) {
      return true;
    }
    return false;
  }
};

export default ApplicantPipeLineScreen;
