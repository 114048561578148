import React from 'react';
const defaultProps = {
  fill: '#1890ff',
  width: 92,
  height: 20,
};

const Svgdrjob = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 92 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15028_504)">
        <path
          d="M61.841 5.8125C59.8877 5.81419 57.9845 6.43085 56.4013 7.57501C54.8182 8.71917 53.6355 10.3327 53.0209 12.1869C52.4063 14.041 52.3911 16.0416 52.9775 17.9048C53.5638 19.7681 54.7219 21.3994 56.2875 22.5675C57.0055 21.8183 57.8676 21.2221 58.822 20.8149C59.7764 20.4076 60.8034 20.1976 61.841 20.1976C62.8787 20.1976 63.9057 20.4076 64.8601 20.8149C65.8145 21.2221 66.6766 21.8183 67.3946 22.5675C68.9602 21.3994 70.1183 19.7681 70.7046 17.9048C71.291 16.0416 71.2758 14.041 70.6612 12.1869C70.0466 10.3327 68.8639 8.71917 67.2807 7.57501C65.6976 6.43085 63.7944 5.81419 61.841 5.8125ZM61.841 18.8052C61.1108 18.8052 60.397 18.5887 59.7898 18.183C59.1826 17.7773 58.7094 17.2006 58.4299 16.526C58.1505 15.8513 58.0773 15.1089 58.2198 14.3927C58.3623 13.6765 58.7139 13.0186 59.2303 12.5023C59.7466 11.9859 60.4045 11.6343 61.1207 11.4918C61.837 11.3493 62.5793 11.4224 63.254 11.7019C63.9286 11.9813 64.5053 12.4546 64.911 13.0618C65.3167 13.6689 65.5332 14.3828 65.5332 15.113C65.5336 15.598 65.4384 16.0783 65.253 16.5264C65.0676 16.9746 64.7956 17.3818 64.4527 17.7247C64.1098 18.0676 63.7026 18.3396 63.2545 18.525C62.8063 18.7104 62.326 18.8056 61.841 18.8052Z"
          fill="#009EFF"
        />
        <path
          d="M31.4597 22.5766C32.2858 22.5766 32.9555 21.9069 32.9555 21.0808C32.9555 20.2548 32.2858 19.5851 31.4597 19.5851C30.6337 19.5851 29.964 20.2548 29.964 21.0808C29.964 21.9069 30.6337 22.5766 31.4597 22.5766Z"
          fill="#424242"
        />
        <path
          d="M50.6335 0.706787V14.2326C50.6335 14.3271 50.6335 14.4245 50.6335 14.519C50.5773 16.7183 49.6544 18.8063 48.0659 20.3283C46.4774 21.8504 44.3518 22.6832 42.1521 22.6454C39.9525 22.6077 37.8568 21.7024 36.3214 20.1267C34.7861 18.551 33.9354 16.4325 33.9547 14.2326V14.059C33.9547 13.9158 33.9547 13.7696 33.9761 13.6294C34.0686 13.0029 34.3934 12.4341 34.886 12.036C35.3786 11.638 36.0029 11.4398 36.6349 11.4808C37.2669 11.5219 37.8603 11.7992 38.2973 12.2576C38.7342 12.7161 38.9827 13.3222 38.9934 13.9554C38.9934 14.0316 38.9934 14.1077 38.9934 14.1839V14.2326C38.9883 15.0952 39.32 15.9257 39.918 16.5475C40.5159 17.1692 41.3329 17.5331 42.195 17.5617C43.0572 17.5903 43.8964 17.2813 44.5342 16.7005C45.172 16.1197 45.558 15.313 45.6101 14.452C45.6101 14.3788 45.6101 14.3057 45.6101 14.2326C45.6101 14.1595 45.6101 14.0864 45.6101 14.0163V5.73632H42.2896V0.706787H50.6335Z"
          fill="#424242"
        />
        <path
          d="M18.598 5.93739C17.7244 4.3247 16.3988 3.00226 14.784 2.13249C13.0446 1.22037 11.1027 0.76344 9.13907 0.804273H0V22.5735H9.13907C11.1006 22.6117 13.0396 22.1516 14.7748 21.2362C16.3929 20.3733 17.7222 19.0546 18.598 17.4435C19.5331 15.6722 19.9998 13.6913 19.9536 11.6889C19.9994 9.68753 19.5327 7.70768 18.598 5.93739ZM12.9196 16.0757C11.9143 17.1378 10.583 17.6689 8.92583 17.6689H5.48344V5.72109H8.92583C10.583 5.72109 11.9143 6.24913 12.9196 7.3052C13.9249 8.36127 14.4265 9.82352 14.4245 11.692C14.4245 13.5563 13.9229 15.0176 12.9196 16.0757Z"
          fill="#424242"
        />
        <path
          d="M82.215 5.45602C81.0752 5.45428 79.9471 5.68653 78.9006 6.1384V0.271118H73.8711V13.8C73.8711 13.8487 73.8711 13.8975 73.8711 13.9462C73.8711 13.998 73.8711 14.0467 73.8711 14.0985V14.0132C73.7614 17.9735 71.9976 19.7769 71.9976 19.7769L76.1589 22.5765C76.7286 22.4973 77.4566 20.6573 77.4566 20.6573C78.5324 21.4031 79.7686 21.8853 81.0652 22.065C82.3619 22.2447 83.6826 22.1167 84.9206 21.6915C86.1587 21.2664 87.2793 20.5559 88.192 19.6175C89.1047 18.6791 89.7837 17.5391 90.1743 16.2897C90.5649 15.0403 90.6561 13.7165 90.4404 12.4254C90.2248 11.1343 89.7084 9.91198 88.9329 8.85734C88.1575 7.80269 87.1449 6.94529 85.9768 6.35441C84.8087 5.76354 83.518 5.45579 82.209 5.45602H82.215ZM82.215 17.1114C81.5594 17.112 80.9183 16.9181 80.3728 16.5543C79.8274 16.1904 79.4021 15.673 79.1507 15.0674C78.8994 14.4619 78.8333 13.7953 78.9609 13.1522C79.0884 12.5091 79.4039 11.9182 79.8673 11.4544C80.3307 10.9905 80.9212 10.6746 81.5643 10.5464C82.2073 10.4183 82.8739 10.4837 83.4797 10.7345C84.0855 10.9853 84.6033 11.4101 84.9676 11.9552C85.332 12.5003 85.5264 13.1413 85.5264 13.7969C85.5264 14.6755 85.1777 15.518 84.5567 16.1395C83.9358 16.761 83.0936 17.1106 82.215 17.1114Z"
          fill="#424242"
        />
        <path
          d="M21.1661 8.22517C21.3948 8.50116 21.5986 8.79678 21.7753 9.10861C22.2037 9.89228 22.4592 10.7586 22.5248 11.6493C22.5369 15.2927 22.543 18.9341 22.543 22.5735H27.1704C27.1704 21.2362 27.2192 16.5265 27.274 14.5799C27.2793 14.4041 27.2977 14.229 27.3289 14.0559C27.3715 13.8152 27.7218 12.027 29.3455 11.2654C30.1864 10.895 31.1359 10.8547 32.005 11.1527L32.7331 6.55577C32.7331 6.55577 28.6114 5.03259 26.8597 10.1139C26.8597 10.1139 26.2504 6.76292 24.8522 5.71802L21.1661 8.22517Z"
          fill="#424242"
        />
      </g>
      {/* <defs>
      <clipPath id="clip0_15028_504">
        <rect width="92" height="22.8477" fill="white" />
      </clipPath>
    </defs> */}
    </svg>
  );
};
Svgdrjob.defaultProps = defaultProps;

export default Svgdrjob;
