import exp from 'constants';
import { routesPath } from './routes/routesPath';
// Auth
export const loginAuth = `/login`;
export const websitesubscriptionscreen = `/plan_and_pricing`;
export const candidateLogin = '/login_candidate';
export const newPassword = `/new_password/:userId/:confirmationToken`;
export const recruiter = `/recruiter/:planId`;
export const home = `https://www.zita.ai/`;
// Dashboadrd
export const homeRoute = `/`;
//Email
export const inbox = '/mail';
// Calendar
export const calendarRoute = '/calendar';
export const Role_value = '/rolevalue';
export const Interview_question = '/interview_question';
// My Job Posting
export const jobSelect = `${routesPath.JOBS}/select`;
export const jobCreateDs = `${routesPath.JOBS}/create_ds`;
export const jobCreateDsUpdate = `${routesPath.JOBS}/create_ds/:jdId`;
export const jobCreateDsEdit = `${routesPath.JOBS}/create_ds_edit/:editJdId`;
// export const jobCreateNonDs = `${routesPath.JOBS}/create_non_ds`;
export const jobCreateNonDs = `${routesPath.JOBS}/create_your_job`;
export const jobCreateNonDsUpdate = `${routesPath.JOBS}/create_your_job/:jdId`;
export const jobCreateNonDsEdit = `${routesPath.JOBS}/create_non_ds_edit/:editJdId`;
export const jobQuestionnaire = `${routesPath.JOBS}/questionnaire/:jd_id`;
export const weightagematching = `${routesPath.JOBS}/weightagematching/:jd_id`;
export const database = `${routesPath.MYDATABASE}/:jd_id?`;
export const jobPreview = `${routesPath.JOBS}/preview/:jdId`;
// profile view
export const applicantProfileView = '/applicant_profile_view/:jdId/:candiId';
// export const zitaMatchScreen = '/zita_match_candidate/:jdId';
export const candidateProfileView = '/candidate_profile_view/:jdId/:candiId';
export const applicantPipeLineScreen = '/applicant_pipe_line/:jdId';
export const pipelinejobsworkflow = '/jobs_workflow';
export const jdViewScreen = '/job_view/:jdId';
// account setting
export const accountSettingRoutes = `/account_setting`;
export const profile = `/account_setting/profiles`;
export const Carrire = `/account_setting/build_careers_page`;
export const Subscription = `/account_setting/subscription`;
export const Templateshub = `/account_setting/templates_hub`;
export const ManagrUser = `/account_setting/manage_user`;
export const Integration = `/account_setting/integration`;
export const Hiring = `/account_setting/hiring_workflow`;
export const Notification = `/account_setting/email_notification`;
export const careerView = '/:pageUrl/careers';
export const jobPreviewView = `/:companyName/career_job_view/:jobId/:jobTitle`;
export const candidateProfileUpload = `/candidate_profile_upload/:empId`;
export const candidateProfileEdit = `/candidate_profile_edit/:empId`;
export const candidateApplyProfileView = `/apply_candidate_profile_view/:empId`;
export const checkActivate = '/check_activate';
export const orderSummaryRoute = `/order_summary`;
export const reports = `/reports`;
export const applicantSource = `${routesPath.REPORTS}/applicant-source`;
export const passivecandidate = `${routesPath.REPORTS}/passive-candidate`;
export const jobMetrics = `${routesPath.REPORTS}/job-metrics`;
export const sourcingPerformance = `${routesPath.REPORTS}/sourcing-performance`;
// meeting scheduler
export const weightagematchingurl = `weightage_matching`;
export const weightagescoreurl = `weightage_score`;
export const meetingScheduler = `/event_scheduler`;
export const JobboardApi = 'job_board';
export const DrjobApi = 'dr_job_integration';
