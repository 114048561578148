// eslint-disable-next-line max-len
import React from 'react';
const defaultProps = {
  fill: 'none',
  width: 92,
  height: 65,
};

const Svgjora = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      width={width}
      height={height}
      fill={fill}
    >
      {/* Removed the background fill */}
      <path
        fill="#1D1E1E"
        d="M409 341h29l14 2 15 4 16 7 13 8 10 8 11 11 10 15 8 17 6 20 4 27v39l-3 21-5 19-7 16-7 12-11 14-8 8-14 10-16 8-17 5-20 3h-24l-22-3-15-4-16-7-14-9-11-10-10-14-8-16-6-15-6-23-3-19-1-10v-31l3-18 5-17 8-18 8-13 9-12 12-11 16-10 15-7 18-5zM837 341h35l16 2 16 4 16 7 10 7 9 9 8 14 5 15 3 15 2 21v40l-4 45-4 25-5 16-9 13-9 10-10 9-13 8-11 5-16 5-25 4-16 1h-12l-21-2-17-4-13-5-11-7-10-9-7-11-5-11-4-14-2-11v-30l4-15 6-11 9-10 10-7 21-10 22-7 44-11 27-6 8-5 4-6v-10l-5-7-10-6-11-3-17-1-17 2-11 4-7 6-3 5-2 1h-64l-2-1v-7l4-16 7-14 10-11 10-7 16-8 21-6 19-3z"
      />
      <path
        fill="#10A24F"
        d="M70 716h883l10 4 4 3v2h2l4 8v10l-6 9-5 4-9 3-50 1H76l-10-2-10-6-5-8-1-8 2-8 6-7 8-4z"
      />
      <path
        fill="#1D1D1E"
        d="M239 265h41l1 1v274l-4 17-8 16-11 12-10 8-16 8-18 6-20 4-9 1h-40l-19-3-16-5-14-7-9-7-9-9-8-14-4-13-2-14v-60l1-1h65l1 1 1 49 4 12 6 8 9 6 8 2h20l13-4 7-5 7-11 3-12 1-230 4-11 7-9 10-7zM716 344l2 1v71l-38 1-12 3-8 4-10 9-6 9-4 11-1 9-1 148h-71l-1-104v-32l2-22 6-25 8-19 7-11 12-15 11-9 14-9 17-8 16-5 21-4 18-2z"
      />
      <path
        fill="#FDFDFD"
        d="M413 400h16l12 3 11 6 9 8 7 11 5 13 3 11 2 18v19l-3 21-3 11-5 12-8 11-12 9-10 4-10 2h-10l-13-3-8-4-10-9-7-10-5-13-3-12-2-18v-23l2-18 4-15 8-16 6-7 10-7zM887 480h3l1 10v14l-2 16-5 13-8 11-9 8-10 5-11 3-17 1-13-2-8-3-8-9-4-12v-9l4-11 9-9 12-6 25-7 26-8z"
      />
    </svg>
  );
};
Svgjora.defaultProps = defaultProps;

export default Svgjora;
