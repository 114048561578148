import { useFormik } from 'formik';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import classNames from 'classnames/bind';
import { ErrorMessage } from '../../uikit';
import Flex from '../../uikit/Flex/Flex';
import { lowerCase } from '../../uikit/helper';
import InputText from '../../uikit/InputText/InputText';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import Toast from '../../uikit/Toast/Toast';
import InputSearch from '../../uikit/InputSearch/InputSearch';
import Button from '../../uikit/Button/Button';
import { AppDispatch } from '../../store';
import {
  ERROR_MESSAGE,
  MAX_DISPLAYED_OPTIONS,
  THIS_FIELD_REQUIRED,
} from '../constValue';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import { myDataSkillList } from '../../modules/mydatabasemodule/mock';
import SvgIntomark from '../../icons/Intomark';
import styles from './linkedinaction.module.css';

// import { distanceOptions, lastActiveOptions } from './mock';
// import { talentSourcingSearchMiddleWare } from './store/middleware/talentSoucringMiddleware';
import {
  LinkedinSourcingSearchMiddleWare,
  linkedincandidateunlock,
} from './store/middleware/candidateSoucringMiddleware';

const cx = classNames.bind(styles);

type Props = {
  location?: string[];
  setSourceLimit: (arg: number) => void;
  setCandidatesLimit: (arg: string) => void;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFind: (arg: boolean) => void;
  setInitalCheckBox: (arg: boolean) => void;
  setSubmitLoader: any;
  setvisible: any;
  setIsCheck?: any;
  val: any;
  update: any;
  setformikdata?: any;
  isformikdata?: any;
  ispagecount?: any;
  setpagecount?: any;
  talent_sourcing?: any;
  setlinkedindata?: any;
  setcandidate_ids?: any;
  formik?: any;
};

export interface SkillListEntity {
  label: string;
  value: string;
}

const TalentAction = ({
  location,
  setSourceLimit,
  setCandidatesLimit,
  setPageNumber,
  setFind,
  setInitalCheckBox,
  setSubmitLoader,
  setvisible,
  setIsCheck,
  val,
  update,
  setformikdata,
  isformikdata,
  ispagecount,
  setpagecount,
  talent_sourcing,
  setlinkedindata,
  formik,
  setcandidate_ids,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [error, seterror] = useState(0);
  const [isSkills, setSkills] = useState<any>();
  const selectInputRef = useRef<any>();

  const filteredOptions = useMemo(() => {
    if (!isSkills) {
      return myDataSkillList;
    }
    const matchByStart = [];
    const matchByInclusion = [];
    const regByInclusion = new RegExp(escapeRegExp(isSkills), 'i');
    const regByStart = new RegExp(`^${escapeRegExp(isSkills)}`, 'i');
    for (const option of myDataSkillList) {
      if (regByInclusion.test(option.label)) {
        if (regByStart.test(option.label)) {
          matchByStart.push(option);
        } else {
          matchByInclusion.push(option);
        }
      }
    }
    return [...matchByStart, ...matchByInclusion];
  }, [isSkills]);

  const handleselectskill = (option: any) => {
    formik.setFieldValue('skillValue', option);
  };

  const slicedOptions = useMemo(
    () => filteredOptions.slice(0, MAX_DISPLAYED_OPTIONS),
    [filteredOptions],
  );

  useEffect(() => {
    seterror(formik.values.keywords?.trim()?.length);
  }, [formik.values.keywords]);

  const handleInputChange = (event) => {
    formik.setFieldValue('location', event.target.value);
  };

  const onClearLocationsearch = () => {
    formik.setFieldValue("location", '');
  }

  return (
    <>
      <Flex row between bottom className={cx('rowContainer')}>
        <Flex row width={'100%'}>
          <InputText
            id={'talentaction__keywords'}
            label={'Job Title'}
            required
            inputConatinerClass={cx('salesStyle')}
            placeholder="e.g. Front-end Developer"
            value={formik.values.keywords}
            onChange={(e) => {
              formik.setFieldValue('keywords', e.target.value);
            }}
            // onChange={formik.handleChange('keywords')}
            errorMessage={formik.errors.keywords}
            error={formik.touched.keywords && Boolean(formik.errors.keywords)}
          />

          <div className={cx('cityStyle')}>
            <InputSearch
              placeholder="e.g. City or State"
              options={location}
              setFieldValue={formik.setFieldValue}
              name="location"
              label={'Location'}
              required
              errorMessage={formik.errors.location}
              error={formik.touched.location}
              initialValue={lowerCase(formik.values.location)}
              style={styles.searchStyle}
              onChange={handleInputChange}
            />
            {formik.values.location.trim() !== '' && (
              <Flex
                className={styles.crossIcon}
                onClick={onClearLocationsearch}
              >
                <SvgIntomark width={14} height={14} fill="#888888" />
              </Flex>
            )}
          </div>
          {/* <div className={cx('cityStyle')}> */}
          {/* <SelectTag
              label="Skills"
              ref={selectInputRef}
              isMulti
              linkedin
              options={slicedOptions}
              onInputChange={(value) => setSkills(value)}
              onChange={handleselectskill}
              isSearchable
              isCreate
              value={formik.values.skillValue}
            /> */}
          <InputText
            label={'Skill'}
            inputConatinerClass={cx('salesStyle')}
            placeholder="e.g. Javascript"
            value={formik.values.skillValue}
            onChange={(e) => {
              formik.setFieldValue('skillValue', e.target.value);
            }}
          />
          {/* </div> */}
          <Flex>
            <div className={styles.btnContainer}>
              <Button
                disabled={!(formik.isValid && formik.dirty)}
                className={cx('findBtn')}
                onClick={formik.handleSubmit}
              >
                Find Candidate
              </Button>
            </div>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TalentAction;
