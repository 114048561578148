import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import SvgCopy from '../../icons/SvgCopy';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import Text from '../../uikit/Text/Text';
import { RootState } from '../../store';
// import { isEmpty } from '../../uikit/helper';
// import Flex from '../../uikit/Flex/Flex';
// import MyJobsPostingData from './MyJobsPostingData';
import Flex from '../../uikit/Flex/Flex';
import { Pangination, SelectTag } from '../../uikit';
import { getDateString, isEmpty, toCamelCase } from '../../uikit/helper';
import SvgMetrics from '../../icons/SvgMetrics';
import Toast from '../../uikit/Toast/Toast'; // eslint-disable-line
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import styles from './myjobpostingscreen.module.css';
import MyJobPostingScreenStatus from './MyJobPostingScreenStatus';
import { FinalListEntity } from './myJobPostingTypes';
import { options } from './mock';


// import { myJobPostingDataReducers } from './store/reducer/myjobpostingreducer';

// import { FinalListEntity } from './myJobPostingTypes';
// import classNames from 'classnames';

export const copyToClipboard = (text: string, message?: string) => {
  if (typeof Window === 'undefined') return;
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  Toast(`${message}`, 'LONG', 'success');
};

export interface DateEntity {
  label: string;
  value: string;
}
export type MyJobFormProps = {
  jobTitle: string;
  jobId: string;
  postedOn: DateEntity;
  jobType: string;
  location: string;
};
type props = {
  currentPage?: any;
  setCurrentPage?: any;
  setpagecount?: any;
  ispagecount?: any;
  setPage?: any;
};
const MyJobPostingScreen = ({
  ispagecount,
  setpagecount,
  currentPage,
  setCurrentPage,
  setPage,
}: props) => {
  const {
    location_list,
    job_ids,
    job_title,
    final_list,
    Jobs_List,
    // is_loadingone,
    len_list,
    // is_loading,
    career_page_url,
    domain,
    // Permission,
  } = useSelector(
    ({
      myJobPosingReducers,
      myJobPostingDataReducers,
      permissionReducers,
    }: RootState) => ({
      Jobs_List: myJobPostingDataReducers.Jobs_List,
      location_list: myJobPosingReducers.location_list,
      job_ids: myJobPosingReducers.job_ids,
      job_title: myJobPosingReducers.job_title,
      final_list: myJobPostingDataReducers.final_list,
      len_list: myJobPostingDataReducers.len_list,
      is_loading: myJobPostingDataReducers.isLoading,
      is_loadingone: myJobPosingReducers.isLoading,
      career_page_url: myJobPostingDataReducers.career_page_url,
      domain: myJobPostingDataReducers.domain,
      Permission: permissionReducers.Permission,
    }),
  );

  type Props = {
    list: FinalListEntity;
  };
  const MyJobsPostingCount = ({ list }: Props) => {
    const zita_match = isEmpty(list.zita_match) ? '0' : list.zita_match;
    const invite_to_apply = isEmpty(list.invite_to_apply)
      ? '0'
      : list.invite_to_apply;
    const applicant = isEmpty(list.applicants) ? '0' : list.applicants;
  };
  const usersPerPage = ispagecount;
  const pageCount = Math.ceil(len_list / usersPerPage);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [componentHeight, setComponentHeight] = useState(
    window.innerHeight - 220,
  );
  const currentParams = new URLSearchParams(window.location.search);
  const history=useHistory()
  const option = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);

  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [currentPage, setCurrentPage]);
  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 220);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // custom styles for page selection text box and dropdown.
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#581845' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: state.isSelected ? 'none' : '#D7C7D2',
      },
      zIndex: 3, // Increase the z-index value
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #581845' : '1px solid #d7c7d2',
      fontSize: '12px',
      minHeight: '16px',
      lineHeight: '16px',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '1px solid #581845',
      },
      zIndex: 3, // Adjust the z-index value
    }),
    singleValue: (provided) => ({
      ...provided,
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      margin: '5px 0',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease',
      height: '16px',
      marginTop: '-20px',
      zIndex: 3, // Adjust the z-index value
    }),
    menu: (provided) => ({
      ...provided,
      top: 'auto',
      bottom: 22,
      zIndex: 3, // Adjust the z-index value
    }),
  };

  return (
    <div>
      {/* <div  style={{height:320}}>
     <table className="table"> */}
      <div
        className="table-responsisssve "
        style={{
          overflowY: 'scroll',
          height: len_list !== 0 && componentHeight,
        }}
        id="scrollableContainer"
        ref={scrollableContainerRef}
      >
        <table
          className="table"
          style={{ paddingLeft: 'none', marginBottom: '0rem' }}
        >
          <thead className={styles.stickyheader}>
            <tr>
              <th className={styles.padchange}>
                <Text color="theme" bold className={styles.tabeboarder}>
                  Job Title
                </Text>
              </th>
              <th className={styles.padchange} style={{ width: '130px' }}>
                <Text color="theme" bold className={styles.tabeboarder}>
                  Job ID
                </Text>
              </th>
              <th className={styles.padchange} style={{ width: '235px' }}>
                <Text color="theme" bold className={styles.tabeboarder}>
                  Location
                </Text>
              </th>
              <th className={styles.padchange} style={{ width: '88px' }}>
                <Text color="theme" bold className={styles.tabeboarder}>
                  Zita Match
                </Text>
              </th>
              <th className={styles.padchange} style={{ width: '124px' }}>
                <Text color="theme" bold className={styles.tabeboarder}>
                  Invited to Apply
                </Text>
              </th>
              <th className={styles.padchange} style={{ width: '90px' }}>
                <Text color="theme" bold className={styles.tabeboarder}>
                  Applicants
                </Text>
              </th>{' '}
              <th className={styles.padchange} style={{ width: '135px' }}>
                <Text color="theme" bold className={styles.tabeboarder}>
                  Screening Status
                </Text>
              </th>
              {/* <th className="text-center" scope="col">
                <Text color="theme" bold className={styles.tabeboarder}>
                  Metric
                </Text>
              </th> */}
              <th className={styles.padchange} style={{ width: '70px' }}>
                <Text color="theme" bold className={styles.tabeboarder}>
                  Status
                </Text>
              </th>
              <th className={styles.padchange} style={{ width: '100px' }}>
                <Text color="theme" bold className={styles.tableboarder}>
                  Posted on
                </Text>
              </th>
            </tr>
          </thead>
          <tbody style={{ paddingTop: 20 }} className={styles.tablebody}>
            {final_list &&
              Jobs_List &&
              location_list &&
              job_ids &&
              job_title &&
              final_list.map((list) => {
                const formattedJobTitle = list?.job_title?.replace(/\s+/g, '-');
                return (
                  <>
                    <tr style={{ height: 50 }}>
                      <td className={styles.padchang}>
                        <Flex row top className={styles.hellothere}>
                          {list.jd_status__label_name === 'Active' && (
                            <Flex row top>
                              <LinkWrapper
                                to={`/job_view/${list.id}`}
                                className={styles.maxwidthjobtype}
                              >
                                <Text
                                  color="link"
                                  bold
                                  title={toCamelCase(list.job_title)}
                                >
                                  {toCamelCase(list.job_title)}
                                </Text>
                              </LinkWrapper>
                              <CustomTooltip title="Copy the job posting URL from your careers page.">
                                <div
                                  tabIndex={0}
                                  role={'button'}
                                  style={{ marginLeft: 8, marginTop: 1 }}
                                  onClick={() =>
                                    copyToClipboard(
                                      `${domain}/${career_page_url}/career_job_view/${list.id}/${formattedJobTitle}`,
                                      'Link Copied',
                                    )
                                  }
                                  onKeyDown={() => {}}
                                >
                                  <SvgCopy
                                    width={11.33}
                                    height={13.33}
                                    fill={'#581845'}
                                  />
                                </div>
                              </CustomTooltip>
                            </Flex>
                          )}
                          {list.jd_status__label_name === 'Draft' && (
                            <Flex row top>
                              {list.is_ds_role !== true ? (
                                <LinkWrapper
                                  to={`/jobs/create_non_ds_edit/${list.id}`}
                                  className={styles.maxwidthjobtype}
                                >
                                  <Text
                                    color="link"
                                    bold
                                    title={toCamelCase(list.job_title)}
                                  >
                                    {toCamelCase(list.job_title)}
                                  </Text>
                                </LinkWrapper>
                              ) : (
                                <LinkWrapper
                                  to={`/jobs/create_ds_edit/${list.id}`}
                                  className={styles.maxwidthjobtype}
                                >
                                  <Text
                                    color="link"
                                    bold
                                    title={toCamelCase(list.job_title)}
                                  >
                                    {toCamelCase(list.job_title)}
                                  </Text>
                                </LinkWrapper>
                              )}
                              <div
                                tabIndex={0}
                                role={'button'}
                                style={{
                                  marginLeft: 8,
                                  marginTop: 1,
                                  cursor: 'default',
                                }}
                              >
                                <SvgCopy
                                  width={11.33}
                                  height={13.33}
                                  fill={'rgb(88 24 69/30%)'}
                                />
                              </div>
                            </Flex>
                          )}
                          {list.jd_status__label_name === 'Inactive' && (
                            <Flex row top>
                              <LinkWrapper
                                to={`/job_view/${list.id}`}
                                className={styles.maxwidthjobtype}
                              >
                                <Text
                                  color="link"
                                  bold
                                  title={toCamelCase(list.job_title)}
                                >
                                  {toCamelCase(list.job_title)}
                                </Text>
                              </LinkWrapper>{' '}
                              <div
                                tabIndex={0}
                                role={'button'}
                                style={{
                                  marginLeft: 8,
                                  marginTop: 1,
                                  cursor: 'default',
                                }}
                              >
                                <SvgCopy
                                  width={11.33}
                                  height={13.33}
                                  fill={'rgb(88 24 69/30%)'}
                                />
                              </div>
                            </Flex>
                          )}
                          {list.jd_status__label_name === 'Questionnaire' && (
                            <Flex row top>
                              <LinkWrapper
                                to={`/jobs/questionnaire/${list.id}`}
                                className={styles.maxwidthjobtype}
                              >
                                <Text
                                  color="link"
                                  bold
                                  title={toCamelCase(list.job_title)}
                                >
                                  {toCamelCase(list.job_title)}
                                </Text>
                              </LinkWrapper>{' '}
                              <div
                                tabIndex={0}
                                role={'button'}
                                style={{
                                  marginLeft: 8,
                                  marginTop: 1,
                                  cursor: 'default',
                                }}
                              >
                                <SvgCopy
                                  width={11.33}
                                  height={13.33}
                                  fill={'rgb(88 24 69/30%)'}
                                />
                              </div>
                            </Flex>
                          )}
                          {list.jd_status__label_name === 'weightage' && (
                            <Flex row top>
                              <LinkWrapper
                                to={`/jobs/weightagematching/${list.id}`}
                                className={styles.maxwidthjobtype}
                              >
                                <Text
                                  color="link"
                                  bold
                                  title={toCamelCase(list.job_title)}
                                >
                                  {toCamelCase(list.job_title)}
                                </Text>
                              </LinkWrapper>{' '}
                              <div
                                tabIndex={0}
                                role={'button'}
                                style={{
                                  marginLeft: 8,
                                  marginTop: 1,
                                  cursor: 'default',
                                }}
                              >
                                <SvgCopy
                                  width={11.33}
                                  height={13.33}
                                  fill={'rgb(88 24 69/30%)'}
                                />
                              </div>
                            </Flex>
                          )}
                          {list.jd_status__label_name === 'Preview' && (
                            <Flex row top>
                              <LinkWrapper
                                to={`/jobs/preview/${list.id}`}
                                className={styles.maxwidthjobtype}
                              >
                                <Text
                                  color="link"
                                  bold
                                  title={toCamelCase(list.job_title)}
                                >
                                  {toCamelCase(list.job_title)}
                                </Text>
                              </LinkWrapper>{' '}
                              <div
                                tabIndex={0}
                                role={'button'}
                                style={{
                                  marginLeft: 8,
                                  marginTop: 1,
                                  cursor: 'default',
                                }}
                              >
                                <SvgCopy
                                  width={11.33}
                                  height={13.33}
                                  fill={'rgb(88 24 69/30%)'}
                                />
                              </div>
                            </Flex>
                          )}
                        </Flex>
                      </td>
                      <td
                        style={{ fontSize: 13, fontWeight: 400 }}
                        className={styles.padchanges}
                      >
                        <Flex width={'fit-content'}>
                          <Flex
                            direction_tooltip={'down'}
                            title={`${list.job_id}`}
                          >
                            <Text className={styles.jdidText}>
                              {list.job_id}
                            </Text>
                          </Flex>
                        </Flex>
                      </td>
                      <td
                        style={{ fontSize: 13, width: 200, fontWeight: 400 }}
                        className={styles.location}
                      >
                        <Text className={styles.texttruncate}>
                          {list.work_space_type === '3'
                            ? list.location !== null
                              ? `Remote(${list.location})`
                              : 'Remote'
                            : list.location}
                        </Text>
                      </td>

                      {list.jd_status__label_name === 'Inactive' ? (
                        <td
                          style={{
                            fontSize: 13,
                          }}
                          className={styles.padchangesmiddle}
                        >
                          {isEmpty(list.zita_match) ? (
                            <Text className={styles.zeroorder}>0</Text>
                          ) : (
                            <Text>{list.zita_match}</Text>
                          )}
                        </td>
                      ) : (
                        <td
                          style={{
                            fontSize: 13,
                          }}
                          className={styles.hovercolormiddle}
                        >
                          {isEmpty(list.zita_match) ? (
                            <Text className={styles.zeroorder}>0</Text>
                          ) : (
                            <Text className={styles.numberorder}>
                              {' '}
                              <LinkWrapper
                                target={'_parent'}
                                to={`/all_candidates/${list.id}`}
                                className={styles.zitamatch}
                              >
                                {list.zita_match}
                              </LinkWrapper>
                            </Text>
                          )}
                        </td>
                      )}
                      {list.jd_status__label_name === 'Inactive' ? (
                        <td
                          style={{
                            fontSize: 13,
                          }}
                          className={styles.padchangesmiddle}
                        >
                          {isEmpty(list.invite_to_apply) ? (
                            <Text className={styles.zeroorder}>0</Text>
                          ) : (
                            <Text className={styles.numberorder}>
                              {list.invite_to_apply}
                            </Text>
                          )}
                        </td>
                      ) : (
                        <td
                          style={{
                            fontSize: 13,
                          }}
                          className={styles.padchangesmiddle}
                        >
                          {isEmpty(list.invite_to_apply) ? (
                            <Text className={styles.zeroorder}>0</Text>
                          ) : (
                            <Text className={styles.numberorder}>
                              <LinkWrapper
                                target={'_parent'}
                                to={`/all_candidates/${list.id}?id=success`}
                                className={styles.zitamatch}
                              >
                                {list.invite_to_apply}
                              </LinkWrapper>
                            </Text>
                          )}
                        </td>
                      )}
                      {list.jd_status__label_name === 'Inactive' ? (
                        <td
                          style={{
                            fontSize: 13,
                          }}
                          className={styles.hovercolormiddle}
                        >
                          {isEmpty(list.applicants) ? (
                            <Text className={styles.zeroorder}>0</Text>
                          ) : (
                            <Text className={styles.numberorder}>
                              {' '}
                              <LinkWrapper
                                target={'_parent'}
                                to={`/applicant_pipe_line/${list.id}`}
                                className={styles.zitamatch}
                              >
                                {list.applicants}
                              </LinkWrapper>
                            </Text>
                          )}
                        </td>
                      ) : (
                        <td
                          style={{
                            fontSize: 13,
                          }}
                          className={styles.hovercolormiddle}
                        >
                          {isEmpty(list.applicants) ? (
                            <Text className={styles.zeroorder}>0</Text>
                          ) : (
                            <Text className={styles.numberorder}>
                              {' '}
                              <LinkWrapper
                                target={'_parent'}
                                to={`/applicant_pipe_line/${list.id}`}
                                className={styles.zitamatch}
                              >
                                {list.applicants}
                              </LinkWrapper>
                            </Text>
                          )}
                        </td>
                      )}

                      <td className={styles.padchanges} style={{}}>
                        <Flex
                          style={{
                            bottom: 3,
                          }}
                        >
                          <MyJobPostingScreenStatus list={list} />
                        </Flex>
                      </td>
                      {/* <td>
                        {list.jd_status__label_name === "Active" ? (
                          <LinkWrapper to={`/job_view/${list.id}`}>
                            <SvgMetrics
                              width={21}
                              height={21}
                              fill={"#FCC203"}
                            />
                          </LinkWrapper>
                        ) : (
                          <LinkWrapper to={`/job_view/${list.id}`}>
                            <SvgMetrics
                              width={21}
                              height={21}
                              fill={"#333333"}
                            />
                          </LinkWrapper>
                        )}
                      </td> */}

                      <td className={styles.padchanges}>
                        {list.jd_status__label_name === 'Active' ? (
                          <div
                            style={{
                              color: '#00BE4B',
                              fontWeight: 600,
                              fontSize: 13,
                            }}
                          >
                            {list.jd_status__label_name}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                        {list.jd_status__label_name === 'Draft' ? (
                          <div
                            style={{
                              color: '#FCC203',
                              fontWeight: 600,
                              fontSize: 13,
                            }}
                          >
                            {list.jd_status__label_name}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                        {list.jd_status__label_name === 'Questionnaire' ? (
                          <div
                            style={{
                              color: '#FCC203',
                              fontWeight: 600,
                              fontSize: 13,
                            }}
                          >
                            {'Draft'}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                        {list.jd_status__label_name === 'Preview' ? (
                          <div
                            style={{
                              color: '#FCC203',
                              fontWeight: 600,
                              fontSize: 13,
                            }}
                          >
                            {'Draft'}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                        {list.jd_status__label_name === 'weightage' ? (
                          <div
                            style={{
                              color: '#FCC203',
                              fontWeight: 600,
                              fontSize: 13,
                            }}
                          >
                            {'Draft'}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                        {list.jd_status__label_name === 'Inactive' ? (
                          <div
                            style={{
                              color: 'red',
                              fontWeight: 600,
                              fontSize: 13,
                            }}
                          >
                            {list.jd_status__label_name}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </td>
                      <td
                        style={{ fontSize: 13 }}
                        className={styles.padchanges}
                      >
                        {getDateString(list.job_posted_on_date, 'll')}
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
          {/* </Flex> */}
        </table>
        {len_list !== 0 && (
          <Flex center row between className={styles.pagination} flex={12}>
            <Flex flex={4}></Flex>
            <Flex flex={4} middle center>
              <Pangination
                maxPages={pageCount - 1}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Flex>
            <Flex row center end flex={4}>
              <Flex style={{ marginRight: '10px', fontSize: '13px' }}>
                Jobs per page :
              </Flex>

              <Select
                options={options}
                value={options.find((e) => e.value === ispagecount)}
                onChange={(e) => {
                  setPage(0);
                  setpagecount(e.value);
                  currentParams.set('selectedpagecount', String(e.value));
                  currentParams.set('selectedpage','0');
                  // Update the URL with modified search parameters
                  history.push({
                    pathname: window.location.pathname, // Keeps the current path
                    search: currentParams.toString(),
                  });
                }}
                menuPosition="fixed"
                styles={customStyles}
              />
            </Flex>
          </Flex>
        )}
      </div>
    </div>
  );
};

export default MyJobPostingScreen;
