/* eslint-disable */
import React, { useState } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { useEffect } from 'react';
import NavBar from '../modules/navbar/empnavbar/NavBar';
import styles from './usertable.module.css';
import UserProfile from '../modules/accountsettingsmodule/userprofilemodule/userProfile';
import Sidebar from '../modules/navbar/empnavbar/sidebar';
import { display } from '@mui/system';
import { Flex } from '../uikit';
import SubscriptionModal from '../modules/subscriptionmodule/subscriptionmoduleScreen';
import Chatbot from '../modules/chatbot/Chatbotscreen';
axios.defaults.headers.common['Authorization'] =
  'Token ' + localStorage.getItem('token');

const ProtectedRoute = ({
  isside,
  notIsNav,
  component: Component,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
 
  const [sidebar, setSidebar] = useState(false);
  const [passwordupdate, setpasswordupdate] = useState(false);
  const [unsavealert, setunsavealert] = useState(false);
  const [statementalert, setstatementalert] = useState(false);
  const [isopensubcription, setopensubcription] = useState(false);
  const [isBulktab, setbulktab] = useState(null);
  const [isCandidateTab, setCandidateTab] = useState(null);

  const [tour, settour] = useState('');
  const changeurlpopup = () => {
    setunsavealert(true);
  };
  const handlefunction = () => {
    setSidebar(!sidebar);
  };
  const updatepassword = () => {
    setpasswordupdate(!passwordupdate);
  };

  useEffect(() => {
 // const value = location?.includes('/all_candidates')
 const url = window.location.href;
  const applicantpipelineUrl = url.includes('all_candidates');
 if(applicantpipelineUrl === false){
  localStorage.removeItem('jdidurl');
 }
    const toggle =
      sessionStorage.getItem('EmpToggle') === null
        ? false
        : sessionStorage.getItem('EmpToggle');
    if (toggle === '1') {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  }, []);

  useEffect(() => {
    const query = parse(location.search);

    if (query && query.redirect) {
      history.push(`${query.redirect}`);
    }
  }, [location.pathname]);

  const { status, explore } = useSelector(({ TourdataReducer }) => ({
    explore: TourdataReducer.is_first_login,
    status: TourdataReducer.is_steps,
  }));

  const url = window.location.href;
  const applicantpipelineUrl = url.includes('applicant_pipe_line');

  return (
    <>
      {status && <Flex className={styles.overlaybackground}></Flex>}
      <Route
        {...rest}
        render={(props) => {
          if (localStorage.getItem('token') !== null) {
            return (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Chatbot />

                <div
                  className={isside ? 'container-fluid' : ''}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <div class="row">
                    {notIsNav && <NavBar update={updatepassword} />}
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      flex: 1,
                      overflow: 'hidden',
                      paddingTop: isside ? 62 : '',
                      // paddingBottom: 14,
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      {isside && (
                        <div
                          className={
                            sidebar === false ? styles.model : styles.model1
                          }
                        >
                          <Sidebar
                            tour={tour}
                            settour={settour}
                            data={handlefunction}
                            changes={unsavealert}
                            setopensubcription={setopensubcription}
                            isBulktab={isBulktab}
                            isCandidateTab={isCandidateTab}
                            setCandidateTab={setCandidateTab}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          height: '-webkit-fill-available',
                          paddingLeft: isside ? 10 : 0,
                          overflow: applicantpipelineUrl ? 'auto' : 'hidden',
                        }}
                      >
                        <Component
                          {...rest}
                          {...props}
                          tour={tour}
                          settour={settour}
                          Emailsidebar={sidebar}
                          value={changeurlpopup}
                          setbulktab={setbulktab}
                          isBulktab={isBulktab}
                          isCandidateTab={isCandidateTab}
                          setCandidateTab={setCandidateTab}
                        />
                        <div>
                          <UserProfile
                            value={passwordupdate}
                            update={updatepassword}
                          />
                        </div>
                        <div>
                          {isopensubcription && (
                            <SubscriptionModal
                              addon_name={['Job Posting Credits']}
                              openmodel={isopensubcription}
                              differece_key={['job_post']}
                              purpose={
                                <div>
                                  <strong>
                                    <text
                                      style={{
                                        color: '#2E6ADD',
                                        marginRight: '3px',
                                        fontSize: '13px',
                                      }}
                                    >
                                      Heads Up!
                                    </text>
                                  </strong>
                                  <strong>Job Posting Limit Exceeds:</strong> To
                                  continue posting new jobs, you can
                                  &apos;Upgrade your plan&apos; or &apos;Buy
                                  additional Job Posting add-on&apos;.
                                </div>
                              }
                              purpose_freetrial={
                                <div>
                                  <strong>
                                    <text
                                      style={{
                                        color: '#2E6ADD',
                                        marginRight: '3px',
                                        fontSize: '13px',
                                      }}
                                    >
                                      Heads Up!
                                    </text>
                                  </strong>
                                  <strong>Job Posting Limit Exceeds:</strong> To
                                  continue posting new jobs, you must
                                  &apos;Upgrade your plan&apos;.
                                </div>
                              }
                              purpose_addon={
                                <div>
                                  <strong>
                                    <text
                                      style={{
                                        color: '#2E6ADD',
                                        marginRight: '3px',
                                        fontSize: '13px',
                                      }}
                                    >
                                      Heads Up!
                                    </text>
                                  </strong>
                                  <strong>Job Posting Limit Exceeds:</strong> To
                                  continue posting new jobs, you must &apos;Buy
                                  additional Job Posting add-on&apos;.
                                </div>
                              }
                              setopensubcription={setopensubcription}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: `/login/?next=${props.location.pathname}`,
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }}
      />
    </>
  );
};

export default ProtectedRoute;
