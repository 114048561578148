import { useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import escapeRegExp from 'lodash.escaperegexp';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import styles from '../bulkimportmodule/candidatedatabasetabfilter.module.css';
import SvgArrowUp from '../../icons/SvgArrowUp';
import SvgFilterRefresh from '../../icons/SvgFilterRefresh';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import InputText from '../../uikit/InputText/InputText';
import Button from '../../uikit/Button/Button';
import { InputSearch } from '../../uikit';
import { enterKeyPress } from '../../uikit/helper';
import SvgIntomark from '../../icons/Intomark';
import { AppDispatch } from '../../store';
import { MAX_DISPLAYED_OPTIONS } from '../constValue';
import { filterexperienceOption } from './bulkImportScreenMock';
import { bulkuploadedCandidatesMiddleWare } from './store/middleware/bulkImportMiddleware';
import { SkillListEntity } from './bulkImportTypes';
import { CandidateSkillList } from './mock';
import { qualificationFilterHelper } from './BulkimportHelper';

type Props = {
  isJdId: any;
  ispagecount: any;
  pageNumber: any;
  candidate_location?: any;
  setCandiTableLoader?: any;
  jdidchange?: any;
  setjdidchange?: (val?: any) => void;
  searchclearurl?: any;
  setsearchclearurl?: (val?: any) => void;
  handleSearchClear2?: () => void;
  searchValue?: any;
};
const ApplicantsTabFilter = ({
  isJdId,
  ispagecount,
  pageNumber,
  candidate_location,
  setCandiTableLoader,
  jdidchange,
  setjdidchange,
  setsearchclearurl,
  searchclearurl,
  handleSearchClear2,
  searchValue,
}: Props) => {
  const dropDownRef = useRef(null);
  const myRef = useRef<any>();
  const dispatch: AppDispatch = useDispatch();
  const selectInputRef = useRef<any>();
  const [showDropDown, setShowDropDown] = useState(false);
  const [change, setchange] = useState(false);
  const [isSkills, setSkills] = useState<any>();
  const [present, setpresent] = useState(true);
  const [isSearch, setSearch] = useState('');
  const [isBachelors, setBachelors] = useState(false);
  const [isDoctorate, setDoctorate] = useState(false);
  const [isDiploma, setDiploma] = useState(false);
  const [isMasters, setMasters] = useState(false);
  const [isAny, setAny] = useState(true);
  const [isOther, setOther] = useState(false);
  const [skildata, setskildata] = useState([]);
  const [experience, setexperience] = useState('');
  const [location, setlocation] = useState('');
  const [skill, setskill] = useState([]);
  const [newexperience, setnewexperience] = useState('');
  const [newqual, setnewqual] = useState([]);
  const [newlocation, setnewlocation] = useState('');
  const [newskill, setnewskill] = useState([]);
  const [hiddenskills1, sethiddenskill1] = useState([]);
  const [qual, setqual] = useState([]);
  const [isOut, setOut] = useState(false);
  const [qualificationValue, setQualificationValue] = useState<
    | {
        value: string;
        label: string;
        checked: boolean;
        onChange: () => void;
      }[]
    | undefined
  >();
  const currentParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  // useEffect(() => {
  //     if (isJdId !== undefined && isJdId !== 0) {
  //        dispatch(bulkuploadedCandidatesMiddleWare({ pagecount: ispagecount, jd_id: isJdId }))
  //     }
  // }, [ispagecount]);

  type MyDataFormProps = {
    searchValue: string;
    locationSearch: string;
    experience: SkillListEntity;
    skillValue: SkillListEntity[];
  };

  const initial: MyDataFormProps = {
    searchValue: '',
    locationSearch: '',
    experience: filterexperienceOption[0],
    skillValue: [],
  };

  const formik = useFormik({
    initialValues: initial,
    onSubmit: () => {},
  });

  const handleBachelor = () => {
    setBachelors(!isBachelors);
    setAny(false);
  };
  const handleDoctorate = () => {
    setDoctorate(!isDoctorate);
    setAny(false);
  };
  const handleDiploma = () => {
    setDiploma(!isDiploma);
    setAny(false);
  };

  const handleMaster = () => {
    setMasters(!isMasters);
    setAny(false);
  };

  const handleOther = () => {
    setOther(!isOther);
    setAny(false);
  };
  const handleAny = () => {
    setAny(!isAny);
    setBachelors(false);
    setDoctorate(false);
    setDiploma(false);
    setMasters(false);
    setOther(false);
  };

  const qualificationOption = [
    {
      value: 'Bachelors',
      label: 'Bachelors',
      checked: isBachelors,
      onChange: handleBachelor,
      width: 110,
      padding: 10,
    },
    {
      value: 'Masters',
      label: 'Masters',
      checked: isMasters,
      onChange: handleMaster,
      width: 80,
      margin: 8,
    },
    {
      value: 'Doctorate',
      label: 'Doctorate',
      checked: isDoctorate,
      onChange: handleDoctorate,
      width: 110,
    },
    {
      value: 'Diploma',
      label: 'Diploma',
      checked: isDiploma,
      onChange: handleDiploma,
      width: 80,
    },
    {
      value: 'Others',
      label: 'Others',
      checked: isOther,
      onChange: handleOther,
      width: 110,
    },
    {
      value: 'Any',
      label: 'any',
      width: 80,
      checked: isAny,
      onChange: handleAny,
    },
  ];

  useEffect(() => {
    if (
      isBachelors === false &&
      isDoctorate === false &&
      isMasters === false &&
      isOther === false &&
      isDiploma === false
    ) {
      setAny(true);
    }
  }, [isBachelors, isDoctorate, isDiploma, isMasters, isOther]);

  useEffect(() => {
    if (
      isBachelors === false &&
      isDoctorate === false &&
      isMasters === false &&
      isOther === false &&
      isDiploma === false
    ) {
      if (isAny === false) {
        setAny(true);
      }
    }
  }, [isAny]);

  const qaValue = qualificationFilterHelper(
    isAny,
    isBachelors,
    isDoctorate,
    isDiploma,
    isMasters,
    isOther,
  );

  const skillsOptionsList =
    formik.values.skillValue &&
    formik.values.skillValue.map((optionList: { value: string }) => {
      return optionList.value;
    });

  const click = () => {
    setShowDropDown(!showDropDown);
  };

  const filteredOptions = useMemo(() => {
    if (!isSkills) {
      return CandidateSkillList;
    }
    const matchByStart = [];
    const matchByInclusion = [];
    const regByInclusion = new RegExp(escapeRegExp(isSkills), 'i');
    const regByStart = new RegExp(`^${escapeRegExp(isSkills)}`, 'i');
    for (const option of CandidateSkillList) {
      if (regByInclusion.test(option.label)) {
        if (regByStart.test(option.label)) {
          matchByStart.push(option);
        } else {
          matchByInclusion.push(option);
        }
      }
    }
    return [...matchByStart, ...matchByInclusion];
  }, [isSkills]);

  const handleselectskill = (option: any) => {
    formik.setFieldValue('skillValue', option);
    // setskildata(option);
    setchange(true);
  };

  const hiddenSkills = newskill.slice(4, newskill.length);

  const slicedOptions = useMemo(
    () => filteredOptions.slice(0, MAX_DISPLAYED_OPTIONS),
    [filteredOptions],
  );

  useEffect(() => {
    if (isSearch !== '') setOut(true);
  }, [isSearch]);

  const handleLocation = () => {
    formik.setFieldValue('locationSearch', isSearch);
  };

  const handleClickOutside = (event: { target: any }) => {
    if (myRef.current && !myRef.current.contains(event.target) && isOut) {
      handleLocation();
      setOut(false);
    }
  };
  useEffect(() => {
    if (typeof Window !== 'undefined') {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      if (myRef) {
        if (typeof Window !== 'undefined') {
          document.removeEventListener('click', handleClickOutside, true);
        }
      }
    };
  });

  useEffect(() => {
    setlocation(formik.values.locationSearch);
  }, [formik.values.locationSearch]);

  useEffect(() => {
    // if (formik.values.skillValue.length !== 0) {
    setskill(
      formik.values.skillValue
        .filter((name) => name.value)
        .map((filter) => filter.value),
    );
    // }
  }, [formik.values.skillValue]);

  useEffect(() => {
    if (formik.values.experience.value === '') {
      setexperience('');
      setpresent(false);
    } else if (formik.values.experience.value === '0-1') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '1-2') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '2-5') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '5-7') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '7-10') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '>10') {
      setexperience(formik.values.experience.label);
    }
  }, [formik.values.experience.value]);

  const viewfilterrefresh = () => {
    hanldeRefresh();
    setSearch('');
    handleSearchClear2();
    formik.resetForm();
    setSkills('');
    setnewexperience('');
    setnewqual([]);
    setnewlocation('');
    setnewskill([]);
    sethiddenskill1([]);
    setskill([]);
    setexperience('');
    setlocation('');
  };

  const viewfilterrefreshurl = () => {
    hanldeRefreshurl();
    setSearch('');
    // formik.resetForm();
    setSkills('');
    setnewexperience('');
    setnewqual([]);
    setnewlocation('');
    setnewskill([]);
    sethiddenskill1([]);
    setskill([]);
    setexperience('');
    setlocation('');
  };

  const clearfilters = () => {
    viewfilterrefresh();
    clearsearch();
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        page: pageNumber + 1,
        pagecount: ispagecount,
        jd_id: isJdId,
      }),
    );
  };

  const clearfiltersurl = () => {
    viewfilterrefreshurl();
    // dispatch(bulkuploadedCandidatesMiddleWare({
    //     page: pageNumber + 1,
    //     pagecount: ispagecount,
    //     jd_id: isJdId
    // }))
  };

  const clearurlfilter = () => {
    const paramsToClear = [
      'experience1',
      'qualification1',
      'skill1',
      'location1',
      'search1',
    ];

    // Remove each parameter from the URL
    paramsToClear.forEach((param) => {
      if (currentParams.has(param)) {
        // Check if parameter exists in URL
        currentParams.delete(param); // Delete it if it does
      }
    });

    // Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  const clearsearch = () => {
    const paramsToClear = [
      'search1',
      'experience1',
      'qualification1',
      'skill1',
      'location1',
      'seletedpage1',
      "selectedpagecount1"
    ];

    // Remove each parameter from the URL
    paramsToClear.forEach((param) => {
      if (currentParams.has(param)) {
        // Check if parameter exists in URL
        currentParams.delete(param); // Delete it if it does
      }
    });

    // Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  const clearurlfilter2 = () => {
    const paramsToClear = [
      'experience1',
      'qualification1',
      'skill1',
      'location1',
    ];

    // Remove each parameter from the URL
    paramsToClear.forEach((param) => {
      if (currentParams.has(param)) {
        // Check if parameter exists in URL
        currentParams.delete(param); // Delete it if it does
      }
    });

    // Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  useEffect(() => {
    if (jdidchange === true) {
      clearfilters();
      clearurlfilter();
    }
  }, [jdidchange]);

  useEffect(() => {
    if (searchclearurl === true) {
      clearfiltersurl();
      clearurlfilter2();
    }
  }, [searchclearurl]);

  const closeexp = () => {
    setexperience('');
    formik.setFieldValue('experience', '');
    setnewexperience('');
    // setCandiTableLoader(true)
    setShowDropDown(false);
    setchange(false);
    clearurl();

    const skillsString =
      skillsOptionsList.length !== 0 ? skillsOptionsList?.join(', ') : '';
    const Location = location !== '' ? location : '';
    const EducationLevel = qaValue !== '' ? qaValue : '';

    const FilterPayload = {
      work_experience: '',
      location: Location,
      skill_match: skillsString,
      education_level: EducationLevel,
      page: ispagecount,
      jd_id: isJdId,
    };

    currentParams.delete('experience1'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading

    dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(() => {
      // setCandiTableLoader(false);
    });
  };

  const closeQualification = (doc: {
    value: string;
    label: string;
    checked: boolean;
    onChange: () => void;
  }) => {
    // doc.onChange();
    setShowDropDown(false);

    switch (doc.label) {
      case 'Masters':
        handleMaster();
        break;
      case 'Bachelors':
        handleBachelor();
        break;
      case 'Doctorate':
        handleDoctorate();
        break;
      case 'Diploma':
        handleDiploma();
        break;
      case 'Others':
        handleOther();
        break;
      default:
        console.warn(`No handler found for ${doc.label}`);
        return;
    }

    // Parse the `qualification` parameter directly without encoding
    const qualificationParam = currentParams.get('qualification1');
    if (!qualificationParam) return; // Exit if no qualification param found

    let qualificationArray = JSON.parse(qualificationParam);

    // Remove the item with the specified label
    qualificationArray = qualificationArray.filter(
      (item) => item.label !== doc.label,
    );

    // Update the `qualification` parameter in the URL with the modified array
    currentParams.set('qualification1', JSON.stringify(qualificationArray));

    // Update the URL without reloading the page
    history.push({ search: currentParams.toString() });

    const updatedQualifications = newqual.filter(
      (obj) => obj.label !== doc.label,
    );
    setnewqual(updatedQualifications);

    const qualificationsString = updatedQualifications
      .map((qualification) => qualification.value)
      .join(', ');
    const Location = location !== '' ? location : '';
    const WorkExperience = experience !== '' ? experience : '';

    const FilterPayload = {
      work_experience: WorkExperience,
      location: Location,
      skill_match:
        skillsOptionsList.length !== 0 ? skillsOptionsList?.join(', ') : '',
      education_level: qualificationsString,
      page: ispagecount,
      jd_id: isJdId,
    };

    dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(() => {
      // setCandiTableLoader(false);
    });
    clearurl();
  };

  const handlefunction = (doc) => {
    var gremove = newqual.filter((obj) => obj.label !== doc.label);
    setnewqual(gremove);
    closeQualification(doc);
    setchange(false);
  };

  const skillval = (id, val) => {
    const updatedSkildata = skildata.filter((obj) => obj.label !== val.label);
    // setCandiTableLoader(true);
    setShowDropDown(false);
    setskildata(updatedSkildata);
    setnewskill(updatedSkildata);
    formik.setFieldValue('skillValue', updatedSkildata);
    setchange(false);

    if (updatedSkildata.length < 5) {
      sethiddenskill1(undefined);
    }

    const url = new URL(window.location.href);
    const skillString = updatedSkildata
      .map((skillvalue) => skillvalue.label)
      .join(',');
    url.searchParams.set('skill1', skillString);


  [ 'selectedpagecount1',
  'selectedpage1',
  'total1',
  'completed1',
  'incompleted1'].forEach(param => {
    if (url.searchParams.has(param)) {
        url.searchParams.delete(param);
    }
});

    window.history.replaceState(null, '', url);

    const skillsString = updatedSkildata
      .map((skillssss) => skillssss.value)
      .join(', ');

    const Location = location !== '' ? location : '';
    const EducationLevel = qaValue !== '' ? qaValue : '';
    const WorkExperience = experience !== '' ? experience : '';

    const FilterPayload = {
      work_experience: WorkExperience,
      location: Location,
      skill_match: skillsString,
      education_level: EducationLevel,
      page: ispagecount,
      jd_id: isJdId,
    };

    dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(() => {
      // setCandiTableLoader(false)
    });
//clearurl();
  };

  const locationsrh = () => {
    // setCandiTableLoader(true);
    setShowDropDown(false);
    setlocation('');
    setnewlocation('');
    setSearch('');
    formik.setFieldValue('locationSearch', '');
    setchange(false);

    const skillsString =
      skillsOptionsList.length !== 0 ? skillsOptionsList?.join(', ') : '';
    const EducationLevel = qaValue !== '' ? qaValue : '';
    const WorkExpereince = experience !== '' ? experience : '';

    const FilterPayload = {
      work_experience: WorkExpereince,
      location: '',
      skill_match: skillsString,
      education_level: EducationLevel,
      page: ispagecount,
      jd_id: isJdId,
    };

    currentParams.delete('location1'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading

    dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(() => {
      // setCandiTableLoader(false);
    });
    clearurl();
  };

  useEffect(() => {
    setQualificationValue(
      qualificationOption.filter((option) => option.checked),
    );
  }, [qaValue]);

  useEffect(() => {
    const ans = qualificationOption
      .filter((chek) => chek.checked !== false)
      .map((name) => name.value);
    if (qualificationOption.length !== 0 && ans.length !== 0) {
      setqual(ans);
    }
  }, [qaValue]);

  const RenderQuickFilter = (props: {
    doc?: { label: string; value: any };
    onClose: () => void;
  }) => {
    const { doc, onClose } = props;
    if (doc === undefined) {
      return null;
    }
    if (doc && (doc.value === '' || doc.value === 'any')) {
      return null;
    }

    return (
      <>
        {doc.label !== 'any' ? (
          <Text className={styles.quickfil}>
            {doc.label}{' '}
            <SvgIntomark className={styles.stylesvg} onClick={onClose} />
          </Text>
        ) : (
          setnewqual(null)
        )}
      </>
    );
  };
  const clearurl=()=>{
    const paramsToClear = [
     
      'selectedpagecount1',
      'selectedpage1',
      'total1',
      'completed1',
      'incompleted1'
    ];
    // Remove each parameter from the URL
    paramsToClear.forEach(param => {
      if (currentParams.has(param)) { // Check if parameter exists in URL
        currentParams.delete(param);  // Delete it if it does
      }
    });
    // Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }

  const handleFilterApply = () => {
    currentParams.set('experience1', experience);
    currentParams.set('qualification1', JSON.stringify(qualificationValue));
    currentParams.set('skill1', String(skill));
    currentParams.set('location1', location);
    currentParams.set('page', ispagecount);

    // Update the URL with modified search parameters
    history.push({
      pathname: window.location.pathname, // Keeps the current path
      search: currentParams.toString(),
    });

    setCandiTableLoader(true);
    setShowDropDown(false);
    setnewexperience(experience);
    setnewqual(qualificationValue);
    setnewlocation(location);
    setnewskill(skill);
    sethiddenskill1(hiddenSkills);
    setchange(false);

    const skillsString = skillsOptionsList?.join(', ');

    const FilterPayload = {
      work_experience: experience,
      location: location,
      skill_match: skillsString,
      education_level: qaValue,
      page: ispagecount,
      jd_id: isJdId,
      search: searchValue !== '' ? searchValue : '',
    };

    // dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(() => {
    //   setCandiTableLoader(false);
    // });

    clearurl();
  };

  const hanldeRefresh = () => {
    setAny(true);
    setBachelors(false);
    setDoctorate(false);
    setDiploma(false);
    setMasters(false);
    setOther(false);
    formik.resetForm();
    setjdidchange(false);
  };

  const hanldeRefreshurl = () => {
    setAny(true);
    setBachelors(false);
    setDoctorate(false);
    setDiploma(false);
    setMasters(false);
    setOther(false);
    // formik.resetForm();
    formik.setValues((prevValues) => ({
      ...prevValues,
      searchValue: prevValues.searchValue,
      parser: '',
      locationSearch: '',
      experience: filterexperienceOption[0],
      skillValue: [],
    }));
    setjdidchange(false);
    setsearchclearurl(false);
  };

  const isDefaultFilter = () => {
    const skills = newskill || [];
    const qualification = newqual || [];
    if (
      qualification?.length === 0 &&
      skills?.length === 0 &&
      newexperience === '' &&
      newlocation === ''
    ) {
      return true;
    }
    return false;
  };

  // function foe clearing the location filed.
  const handleSearchClose = () => {
    formik.setFieldValue('locationSearch', '');
    setlocation('');
    setSearch('');
    clearurl();
  };

  const getQualificationWithFunctions = () => {
    const qualificationParam = currentParams.get('qualification1');
    let parsedData = [];

    if (qualificationParam) {
      try {
        const parsed = JSON.parse(qualificationParam);

        // Re-add the onChange functions based on value
        parsedData = parsed.map((item) => {
          let onChange;
          switch (item.value) {
            case 'Bachelors':
              onChange = handleBachelor;
              break;
            case 'Masters':
              onChange = handleMaster;
              break;
            case 'Doctorate':
              onChange = handleDoctorate;
              break;
            case 'Diploma':
              onChange = handleDiploma;
              break;
            case 'Others':
              onChange = handleOther;
              break;
            default:
              onChange = () => {};
          }
          return { ...item, onChange };
        });
      } catch (error) {}
    }

    return parsedData;
  };

  const urlsearch = useLocation();
  useEffect(() => {
    const experienceurl = currentParams.get('experience1');
    if (experienceurl) {
      setnewexperience(experienceurl);
      setAny(false);
      if (experienceurl === 'Less than a year') {
        formik.setFieldValue('experience', { value: '0-1', label: 'Less than a year' });
      } else if (experienceurl === '1-2 years') {
        formik.setFieldValue('experience', {
          value: '1-2',
          label: '1-2 years',
        });
      } else if (experienceurl === '2-5 years') {
        formik.setFieldValue('experience', {
          value: '2-5',
          label: '2-5 years',
        });
      } else if (experienceurl === '5-7 years') {
        formik.setFieldValue('experience', {
          value: '5-7',
          label: '5-7 years',
        });
      }else if (experienceurl === '7-10 years') {
        formik.setFieldValue('experience', {
          value: '7-10',
          label: '7-10 years',
        });
      } else if (experienceurl === 'Above 10 years') {
        formik.setFieldValue('experience', {
          value: '>10',
          label: 'Above 10 years',
        });
      }
    }

    let resultpass = '';
    const qualificationurl = currentParams.get('qualification1');
    if (qualificationurl) {
      const result = getQualificationWithFunctions();
      const qualificationObject = JSON.parse(qualificationurl);

      // Update the newqual state
      setnewqual(result);

      setAny(false);

      // Filter the checked labels from the qualification object
      const checkedLabels = qualificationObject
        .filter((item) => item.checked)
        .map((item) => item.label);

      resultpass = checkedLabels.join(', ');

      // Set states based on the checked labels
      setBachelors(checkedLabels.includes('Bachelors'));
      setMasters(checkedLabels.includes('Masters'));
      setDoctorate(checkedLabels.includes('Doctorate'));
      setDiploma(checkedLabels.includes('Diploma'));
      setOther(checkedLabels.includes('Others'));
    }

    const locationValue1 = currentParams.get('location1');
    if (locationValue1) {
      // Only set state if locationValue is non-empty
      formik.setFieldValue('locationSearch', locationValue1);
      setnewlocation(locationValue1);
      setlocation(locationValue1);
      setSearch(locationValue1);
    }

    const skillsurl = currentParams.get('skill1');
    if (skillsurl) {
      const makeitarray = skillsurl.split(',');

      // Transform the array of strings to array of objects
      const transformedArray = makeitarray.map((skillurl) => ({
        label: skillurl,
        value: skillurl,
      }));

      formik.setFieldValue('skillValue', transformedArray);
      setskildata(transformedArray);

      const hiddenSkillsurl = makeitarray.slice(4, makeitarray.length);
      setnewskill(makeitarray);
      sethiddenskill1(hiddenSkillsurl);
    }
    const jdurl = currentParams.get('jdid');
    const pageurl = currentParams.get('page');
    const searchvalueurl = currentParams.get('search1');
    const pageurl1= Number(currentParams.get('selectedpage1'))
   // const totalurl= Number(currentParams.get('total1'))
     const pagecounturl=Number(currentParams.get('selectedpagecount1'))
     const totalurl= Number(currentParams.get('total1'))
     const completedurl= Number(currentParams.get('completed1'))
     const incompletedurl= Number(currentParams.get('incompleted1'))
     let dynamicTotalKey = {};
if (completedurl) {
    dynamicTotalKey = { completed: completedurl };
} else if (incompletedurl) {
    dynamicTotalKey = { incompleted: incompletedurl };
} else if(totalurl){
    dynamicTotalKey = { total: totalurl };
}
    const FilterPayload = {
      work_experience: experienceurl,
      location: locationValue1,
      skill_match: skillsurl,
      education_level: resultpass === 'any' ? '' : resultpass,
      jd_id: jdurl,
    
      pagecount:pagecounturl||10,
      page: Number(pageurl1+1),
      search: searchvalueurl,
      ...dynamicTotalKey
    };
   
    const paramsToClear = [
      'experience1',
      'qualification1',
      'skill1',
      'location1',
      'search1',
      'selectedpage1',
      'selectedpagecount1',
      'completed',
      'incompleted',
      'completed1',
      'incompleted1'
    ];

    // Check if any of the parameters exist in the URL
    const hasTargetParams = paramsToClear.some((param) =>
      currentParams.has(param),
    );
    if (hasTargetParams) {
      
      dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(() => {
        setCandiTableLoader(false);
      });
    }
  }, [urlsearch.search]);

  useEffect(() => {
    const paramsToClear = [
      'experience1',
      'qualification1',
      'skill1',
      'location1',
      'search1',
      'selectedpage1',
      'total1'
    ];

    // Check if any of the parameters exist in the URL
    const hasTargetParams = paramsToClear.some((param) =>
      currentParams.has(param),
    );
    if (!hasTargetParams) {
      if (localStorage.getItem('tabkeybulkupload') === '1') {
        dispatch(bulkuploadedCandidatesMiddleWare({ pagecount: 10 }));
      }
    }
  }, []);

  return (
    <>
      <Flex row between marginTop={10} marginRight={1}>
        <div className={styles.quickfilters}>
          <Text size={13} className={''} style={{ marginTop: '3px' }}>
            Applied Filters :
          </Text>

          {newexperience === '' ? null : (
            <Text className={styles.quickfil}>
              {newexperience}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={() => closeexp()}
              />
            </Text>
          )}
          {isDefaultFilter() ? (
            <Text className={styles.quickfil}>{'All'}</Text>
          ) : (
            <Flex row wrap style={{ display: 'contents' }}>
              {newqual &&
                newqual.map((doc, index) => (
                  <RenderQuickFilter
                    key={index}
                    doc={{ label: doc.label, value: doc.value }}
                    onClose={() => handlefunction(doc)}
                  />
                ))}

              {newskill.length === 0
                ? null
                : skildata.slice(0, 4).map((skills, index) => (
                    <Text className={styles.quickfil} key={skills}>
                      {skills.label}{' '}
                      <SvgIntomark
                        className={styles.stylesvg}
                        onClick={() => skillval(index, skills)}
                      />
                    </Text>
                  ))}

              {hiddenskills1 &&
                hiddenskills1.length > 0 &&
                hiddenSkills.length > 0 && (
                  <Text className={styles.quickfil}>
                    {`Skills : + ${hiddenSkills.length}`}
                  </Text>
                )}
            </Flex>
          )}

          {newlocation === '' ? null : (
            <Text className={styles.quickfil}>
              {newlocation}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={() => locationsrh()}
              />
            </Text>
          )}
        </div>
        <Flex>
          <div
            ref={dropDownRef}
            className={styles.drop_down}
            style={{ zIndex: 1 }}
          >
            <Flex
              row
              className={styles.drop_down_header}
              style={{ cursor: 'pointer' }}
            >
              <Flex style={{ width: '90%', cursor: 'pointer' }} onClick={click}>
                <Flex row center style={{ cursor: 'pointer' }}>
                  <Text
                    bold
                    color="theme"
                    size={13}
                    style={{ cursor: 'pointer' }}
                  >
                    {showDropDown ? 'Hide Filters' : 'View Filters'}
                  </Text>

                  <Flex
                    center
                    style={{ cursor: 'pointer', margin: '3px 0  0 5px ' }}
                  >
                    <SvgArrowUp
                      width={10}
                      height={10}
                      strokeWidth={25}
                      viewBox="0 0 380 380"
                      style={{
                        transform: showDropDown ? 'scaleY(-1)' : 'none',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex title={'Clear Filters'} style={{ cursor: 'pointer' }}>
                <SvgFilterRefresh
                  width={16}
                  height={16}
                  onClick={clearfilters}
                  className={styles.filtersvg}
                />
              </Flex>
            </Flex>
            <div
              className={`${styles.drop_down_menus} ${
                showDropDown ? styles.show : ''
              }`}
            >
              <Flex className={styles.mtstyle}>
                <Flex>
                  <Text
                    color="primary"
                    bold
                    size={13}
                    style={{ marginBottom: '5px' }}
                  >
                    Experience
                  </Text>
                  <SelectTag
                    labelBold
                    value={formik.values.experience}
                    options={filterexperienceOption}
                    onChange={(option) => {
                      formik.setFieldValue('experience', option);
                      setchange(true);
                    }}
                  />
                </Flex>
              </Flex>

              <Flex className={styles.mtstyle}>
                <Text
                  color="primary"
                  size={13}
                  bold
                  style={{ marginBottom: '5px' }}
                >
                  Qualification
                </Text>
                <Flex row center wrap>
                  {qualificationOption.map((qualificationList) => {
                    return (
                      <Flex
                        row
                        key={qualificationList.value}
                        className={styles.qualificationRadioStyle}
                        width={qualificationList.width}
                      >
                        <InputCheckBox
                          label={qualificationList.value}
                          checked={qualificationList.checked}
                          onChange={qualificationList.onChange}
                          onClick={() => setchange(true)}
                        />
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
              <Flex className={styles.mtstyle}>
                <div className={styles.skillContainer}>
                  <Text
                    color="primary"
                    size={13}
                    bold
                    style={{ marginBottom: '5px' }}
                  >
                    Skills
                  </Text>
                  <SelectTag
                    labelBold
                    ref={selectInputRef}
                    isMulti
                    filter
                    onInputChange={(value) => setSkills(value)}
                    onChange={handleselectskill}
                    isSearchable
                    isCreate
                    options={slicedOptions}
                    value={formik.values.skillValue}
                  />
                </div>
              </Flex>

              <Flex className={styles.mtstyle}>
                <div>
                  <Text
                    color="primary"
                    size={13}
                    bold
                    style={{ marginBottom: '5px' }}
                  >
                    Location
                  </Text>
                  <InputSearch
                    initialValue={isSearch}
                    placeholder="Enter candidate location"
                    options={candidate_location}
                    CrossiconSpace
                    truncatewidth
                    setFieldValue={formik.setFieldValue}
                    style={styles.boxstyle}
                    onkeyPress={(e) => {
                      if (e.target.innerText) {
                        const inputValue = e.target.innerText;
                        formik.setFieldValue('locationSearch', inputValue);
                        if (inputValue.length === 1 && inputValue[0] === ' ') {
                          return;
                        }
                        setSearch(inputValue);
                      } else {
                        const inputValue = e.target.value;
                        formik.setFieldValue('locationSearch', inputValue);
                        if (inputValue.length === 1 && inputValue[0] === ' ') {
                          return;
                        }
                        setSearch(inputValue);
                      }
                      setchange(true);
                    }}
                    onChange={(e) => {
                      if (e.target.innerText) {
                        const inputValue = e.target.innerText;
                        formik.setFieldValue('locationSearch', inputValue);
                        if (inputValue.length === 1 && inputValue[0] === ' ') {
                          return;
                        }
                        setSearch(inputValue);
                      } else {
                        const inputValue = e.target.value;
                        formik.setFieldValue('locationSearch', inputValue);
                        if (inputValue.length === 1 && inputValue[0] === ' ') {
                          return;
                        }
                        setSearch(inputValue);
                      }
                      setchange(true);
                    }}
                  />
                  {formik.values.locationSearch !== '' && (
                    <button
                      className={styles.crossIcon}
                      onClick={handleSearchClose}
                    >
                      <Flex style={{ cursor: 'pointer' }}>
                        <SvgIntomark width={14} height={14} fill="#888888" />
                      </Flex>
                    </button>
                  )}
                </div>
              </Flex>
              <Flex row end className={styles.appFilterContainer}>
                <Flex marginRight={10}>
                  <Button types="secondary" onClick={clearfilters}>
                    Reset
                  </Button>
                </Flex>
                <Flex>
                  <Button className={styles.buyBtn} onClick={handleFilterApply}>
                    Apply
                  </Button>
                </Flex>
              </Flex>
              <div className={styles.filterContainer}></div>
            </div>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default ApplicantsTabFilter;
