import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames/bind';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import Flex from '../../uikit/Flex/Flex';
import Loader from '../../uikit/Loader/Loader';
import Pangination from '../../uikit/Pagination/Pangination';
import SingleButton from '../common/SingleButton';
import Toast from '../../uikit/Toast/Toast';
import { AppDispatch, RootState } from '../../store';
import { ERROR_MESSAGE, THIS_FIELD_REQUIRED } from '../constValue';
import SvgJobapplication from '../../icons/SvgJobapplication';
import SvgNotinterests from '../../icons/SvgNotinterests';
import SvgUserSearch from '../../icons/SvgUserSearch';
import SvgSearch from '../../icons/SvgSearch';
import { Text } from '../../uikit';
import Title from '../common/Title';
import Empty from '../common/Empty';
import CancelAndDeletePopup from '../common/CancelAndDeletePopup';
import { isEmpty, lowerCase } from '../../uikit/helper';

import { permissionMiddleWare } from '../Login/store/middleware/loginMiddleWare';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { DataEntity } from '../mydatabasemodule/myDataBaseTypes';

import {
  filterCondition,
  talentFilterHelper,
} from '../../modules/talentsourcingmodule/talentFilterHelper'; // eslint-disable-line
import { talentSourcingMiddleWare } from '../../modules/talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import {
  LinkedinSourcingSearchMiddleWare,
  linkedincandidateunlock,
  unlockedcandidateget,
} from '../../modules/linkedinsourcingmodule/store/middleware/candidateSoucringMiddleware';
import TalentCardList from './TalentCardList';
import TalentFilter from './TalentFilter';
import Linkedinaction from './LinkedinAction';
import CandiViewModal from './LinkedinCardViewModal';
import UnlockLoaderModal from './UnlockLoaderModal';
import CandidateSuccessModal from './CandidateSuccessModal';
import styles from './linkedinsourcingscreen.module.css';

const cx = classNames.bind(styles);

export type experienceOptionsType = { value: string; label: string };

type FormProps = {
  keywords: string;
  location: string;
  // skillValue: SkillListEntity[];
  skillValue: string;
};
const initials: FormProps = {
  keywords: '',
  location: '',
  skillValue: '',
};
type errorType = {
  location: string;
  keywords: string;
};

const LinkedinSourcingScreen = () => {
  //subcription setstate
  const [isopensubcription, setopensubcription] = useState(false);

  const [isBachelors, setBachelors] = useState(false);
  const [isDoctorate, setDoctorate] = useState(false);
  const [isMasters, setMasters] = useState(false);
  const [isAny, setAny] = useState(true);
  const [isRelocate, setRelocate] = useState(false);
  const [isExperience, setExperience] = useState<experienceOptionsType>({
    label: 'All',
    value: 'all',
  });
  const [isSearchData, setSearchData] = useState<DataEntity[]>();
  const [linkedindata, setlinkedindata] = useState([]);
  const [candidate_ids, setcandidate_ids] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isNoLimit, setNoLimit] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isNoPermission, setNoPermission] = useState(false);
  const [isContact, setContact] = useState(false);
  const [isSourceLimit, setSourceLimit] = useState(0);
  const [isCandidatesLimit, setCandidatesLimit] = useState('0');
  const [isCredit, setCredit] = useState(false);
  const [isShowPdf, setShowPdf] = useState(false);
  const [isCheckOutLoader, setCheckOutLoader] = useState(false);
  const [isNoCount, setNoCount] = useState(false);
  const [isCandiList, setCandiList] = useState<string[]>();
  const [isFind, setFind] = useState(true);
  const [isOther, setOther] = useState(false);
  const [isInitalCheckBox, setInitalCheckBox] = useState(false);
  const [isFree, setFree] = useState(false);
  const [isPdfLoader, setPdfLoader] = useState(false);
  const [isSubmitLoader, setSubmitLoader] = useState(false);
  const [isInitialLoader, setInitialLoader] = useState(true);
  const [visible, setvisible] = useState(false);
  const [show, setshow] = useState(false);
  const uselocation = useLocation();
  const history = useHistory();
  const [apply, setapply] = useState(false);
  const [change, setchange] = useState(false);
  const [cardloader, setcardloader] = useState(false);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [unlockcan, setunlockcan] = useState<any>([]);
  const [isformikdata, setformikdata] = useState<any>(false);
  const [ispagecount, setpagecount] = useState<any>(null);
  const [componentHeight, setComponentHeight] = useState(
    window.innerHeight - 178,
  );
  const [unlockmodal, setunlockmodal] = useState(false);
  const [lockcandidate, setlockcandidate] = useState(0);
  const [profiledownload, setprofiledownload] = useState(false);
  const [viewfile, setviewfile] = useState('');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [myquafilter, setmyqualfilter] = useState([]);
  const [expgra, setexpgra] = useState<any>();
  const [exples, setexples] = useState<any>();
  const [initalSearch, setinitalSearch] = useState(false);
  const [profilepdf, setprofilepdf] = useState(false);
  const [newexperience, setnewexperience] = useState(null);
  const [NewBachelors1, setnewBachelors1] = useState(false);
  const [Newmaster1, setnewmaster1] = useState(false);
  const [NewDoctorate1, setnewDoctorate1] = useState(false);
  const [Newothers1, setnewothers1] = useState(false);
  const [newrelocate, setnewrelocate] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 178);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const isValidURL = (string) => {
    const regex = /^(https?:\/\/)?([^\s$.?#].[^\s]*)$/i;
    return regex.test(string);
  };
  const expirence_filter = (data) => {
    if (data.value === 'all') {
      setexpgra(0);
      setexples(100);
    } else if (data.value === '0-1') {
      setexpgra(0);
      setexples(1);
    } else if (data.value === '1-2') {
      setexpgra(1);
      setexples(2);
    } else if (data.value === '2-5') {
      setexpgra(2);
      setexples(5);
    } else if (data.value === '5-7') {
      setexpgra(5);
      setexples(7);
    } else if (data.value === '7-10') {
      setexpgra(7);
      setexples(10);
    } else {
      setexpgra(10);
      setexples(100);
    }
  };
  const qulification_filter = (bool, str) => {
    var a = [...myquafilter];
    if (bool) {
      if (str === 'Any') {
        a = [];
      } else {
        a.push(str);
      }
    } else {
      var index = a.indexOf(str);

      if (index !== -1) {
        a.splice(index, 1);
      }
    }
    setmyqualfilter([...a]);
  };
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const val_location = params.get('location');
    const val_jobTitle = JSON.parse(params.get('job_title'));
    const val_skill = JSON.parse(params.get('skill'));
    const val_isExperience = JSON.parse(params.get('isExperience'));
    const val_isBachelors = JSON.parse(params.get('isBachelors'));
    const val_isMasters = JSON.parse(params.get('isMasters'));
    const val_isDoctorate = JSON.parse(params.get('isDoctorate'));
     
    if (val_location) {
      formik.setFieldValue('location', val_location);
    }
    if (val_jobTitle) {
      formik.setFieldValue('keywords', val_jobTitle);
    }
    if (val_skill) {
      formik.setFieldValue('skillValue', val_skill);
    }
    if (val_isExperience) {
      setnewexperience(val_isExperience.value);
      expirence_filter(val_isExperience);
      setExperience(val_isExperience);
    }
    if (val_isBachelors) {
      setnewBachelors1(val_isBachelors);
      setAny(false);
      setBachelors(val_isBachelors);
      qulification_filter(val_isBachelors,'Bachelors')
    }
    if (val_isMasters) {
      setMasters(val_isMasters);
      setAny(false);
      setnewmaster1(val_isMasters);
      qulification_filter(val_isMasters,'Masters')
    }
    if (val_isDoctorate) {
      setDoctorate(val_isDoctorate);
      setAny(false);
      setnewDoctorate1(val_isDoctorate);
      qulification_filter(val_isDoctorate,'Doctorate')
    }
  }, []);

  //count  update
  useEffect(() => {
    if (isCheck.length > 0) {
      const can_select: number[] = candi_list.map(Number);
      const checkarr: number[] = isCheck.map(Number);
      const uniqueAnotherArray: number[] = checkarr.filter(
        (item) => !can_select.includes(item),
      );
      setlockcandidate(uniqueAnotherArray.length);
    }
  }, [isCheck]);

  const handleValidation = (values: FormProps) => {
    const errors: Partial<errorType> = {};
    if (values.location === '') {
      errors.location = THIS_FIELD_REQUIRED;
    }

    if (values.keywords === '') {
      errors.keywords = THIS_FIELD_REQUIRED;
    }

    return errors;
  };

  const formikRef = useRef();

  const SignupSchema = Yup.object().shape({
    keywords: Yup.string()
      .trim('Space is not a character')
      .min(1, 'Space is not a character')
      .max(512, 'The contact name cannot exceed 512 char')
      .required(THIS_FIELD_REQUIRED),
    location: Yup.string()
      .trim('Space is not a character')
      .min(1, 'Space is not a character')
      .max(512, 'The contact name cannot exceed 512 char')
      .required(THIS_FIELD_REQUIRED),
  });
  const handleSubmit = () => {
    const currentParams = new URLSearchParams(window.location.search);

    if (!isEmpty(formik.values.location)) {
      currentParams.set('location', lowerCase(formik.values.location));
    } else {
      currentParams.delete('location');
    }
    if (!isEmpty(formik.values.keywords)) {
      currentParams.set('job_title', JSON.stringify(formik.values.keywords));
    } else {
      currentParams.delete('job_title');
    }
    if (!isEmpty(formik.values.skillValue)) {
      currentParams.set('skill', JSON.stringify(formik.values.skillValue));
    } else {
      currentParams.delete('skill');
    }
    history.push({
      pathname: window.location.pathname,
      search: currentParams.toString(),
    });
    setvisible(true);
    setinitalSearch(true);
    dispatch(
      LinkedinSourcingSearchMiddleWare({
        location: lowerCase(formik.values.location),
        job_title: formik.values.keywords,
        skill: formik.values.skillValue,
        pagecount: 15,
        education: JSON.stringify(myquafilter),
        expirence_gte: expgra,
        expirence_lte: exples,
      }),
    )
      .then((res) => {
        setlinkedindata(res.payload.candidates);

        setcandidate_ids(res.payload.candidate_ids);
        setinitalSearch(false);
        setFind(false);
      })
      .catch((err) => {
        setinitalSearch(false);
        setFind(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };
  const formik = useFormik({
    innerRef: formikRef,
    initialValues: initials,
    validate: handleValidation,
    validationSchema: SignupSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;
  const length: any = isSearchData?.length;
  const pageCount = Math.ceil(length / usersPerPage);
  document.title = 'Source Candidates';
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem('freeCheck', 'true');
    dispatch(SubsriptionMiddleWare());

    dispatch(talentSourcingMiddleWare()).then(() => {
      //setInitalCheckBox(true);
      setInitialLoader(false);
    });
  }, []);

  const update = (val) => {
    setshow(val);
  };

  // Separate effect for dispatch
  useEffect(() => {
    const currentParams = new URLSearchParams(window.location.search);
    if (isValidURL(currentParams)) {
      if (formik.values.keywords) {
        setvisible(true);
        setinitalSearch(true);
        dispatch(
          LinkedinSourcingSearchMiddleWare({
            location: lowerCase(formik.values.location),
            job_title: formik.values.keywords,
            skill: formik.values.skillValue,
            pagecount: 15,
            education: JSON.stringify(myquafilter),
            expirence_gte: expgra,
            expirence_lte: exples,
          }),
        )
          .then((res) => {
            setlinkedindata(res.payload.candidates);
            setcandidate_ids(res.payload.candidate_ids);
            setinitalSearch(false);
            setFind(false);
          })
          .catch((err) => {
            setinitalSearch(false);
            setFind(false);
            Toast(ERROR_MESSAGE, 'LONG', 'error');
          });
      }
    }
  }, [formik.values.keywords]);

  // Function to handle the logic after setting candidate IDs
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const val_pagination = JSON.parse(params.get('pagination'));
    if (val_pagination && candidate_ids.length > 0) {
      handleSetPage(val_pagination);
    }
  }, [candidate_ids]);

  // dispatching the API's when the user buy the add-on
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const session: any = query.get('session');
  useEffect(() => {
    if (!isEmpty(session)) {
      dispatch(SubsriptionMiddleWare());
      dispatch(permissionMiddleWare()).then(() =>
        dispatch(SubsriptionMiddleWare()).then((res) => {
          setCredit(true);
          setSourceLimit(res.payload.user_credits.resume_unlock_credits);
        }),
      );
    }
  }, [session]);

  const {
    location,
    sourceLoader,
    searchData,
    searchLoader,
    unLockLoader,
    bulkUnlockLoader,
    bulkDownloadLoader,
    // candiViewLoader,
    stripeLoader,
    publicKey,
    candi_list,
    candiViewFile,
    plan,
    is_plan,
    add_on_plans,
    resume_unlock_credits,
    client_url,
    talent_sourcing,
    selected_id,
  } = useSelector(
    ({
      talentSourcingReducers,
      talentSourcingSearchReducers,
      talentUnlockCandidateReducers,
      bulkActionReducers,
      bulkDownloadActionReducers,
      candidateViewReducers,
      stripeReducers,
      permissionReducers,
      SubscriptionReducers,
      UnlockcandidatesReducers,
    }: RootState) => {
      return {
        location: talentSourcingReducers.location,
        sourceLoader: talentSourcingReducers.isLoading,
        searchData: talentSourcingSearchReducers.data,
        searchLoader: talentSourcingSearchReducers.isLoading,
        unLockLoader: talentUnlockCandidateReducers.isLoading,
        bulkUnlockLoader: bulkActionReducers.isLoading,
        bulkDownloadLoader: bulkDownloadActionReducers.isLoading,
        candiViewLoader: candidateViewReducers.isLoading,
        publicKey: stripeReducers.publicKey,
        stripeLoader: stripeReducers.isLoading,
        candi_list: UnlockcandidatesReducers.candidates_ids,
        candiViewFile: candidateViewReducers.file,
        plan: talentSourcingSearchReducers.plan,
        is_plan: permissionReducers.is_plan,
        add_on_plans: SubscriptionReducers.add_on_plans,
        resume_unlock_credits:
          SubscriptionReducers.user_credits?.resume_unlock_credits,
        client_url: permissionReducers.client_url,
        talent_sourcing: SubscriptionReducers.talent_sourcing,
        selected_id: UnlockcandidatesReducers.candidates_ids,
      };
    },
  );
  useEffect(() => {
    if (!is_plan) {
      sessionStorage.setItem('superUserTab', '2');
      history.push('/account_setting/settings');
    }
  });

  useEffect(() => {
    dispatch(unlockedcandidateget({})).then((res) => {});
  }, []);

  useEffect(() => {
    if (isContact) {
      // dispatch(stripeMiddleWare());
    }
  }, [isContact]);

  useEffect(() => {
    setCandiList(candi_list);
  }, [candi_list]);

  // store search data in local
  sessionStorage.setItem('storeSearchData', JSON.stringify(searchData));

  const getStoreSearchData = JSON.parse(
    sessionStorage.getItem('storeSearchData') || 'null',
  );

  const planId = plan && plan[0] && plan[0].plan_id_id;

  // single unloack submit function
  const handleUnlockSubmit = (values: string) => {
    if (resume_unlock_credits !== 0) {
      var data = [values];
      setunlockmodal(true);
      const formData = new FormData();
      formData.append('linkedin_id', JSON.stringify(data));
      formData.append('unlock', 'True');
      dispatch(
        linkedincandidateunlock({
          formData,
        }),
      )
        .then((resp) => {
          dispatch(unlockedcandidateget({}));
          dispatch(SubsriptionMiddleWare()).then((res) => {
            setSourceLimit(res.payload.user_credits.linkedin_credits);
          });
          setIsCheck([]);
          setIsCheckAll(false);
          setunlockmodal(false);
          setSuccess(true);
        })
        .catch((error) => {});
    } else {
      setopensubcription(true);
    }
  };

  // open resume function
  const handleCandidateView = (id) => {
    setPdfLoader(true);
    // setcardloader(true);
    setprofilepdf(true);

    dispatch(unlockedcandidateget({ candidates_id: id }))
      .then((response) => {
        if (response.payload.file_path) {
          setShowPdf(true);
          setviewfile(response.payload.file_path);
        }
        setPdfLoader(false);
        // setcardloader(false);
        setprofilepdf(false);
      })
      .catch((error) => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
        setPdfLoader(false);
        // setcardloader(false);
        setprofilepdf(false);
      });
  };

  const hanldeContactModal = () => {
    setNoLimit(false);
    sessionStorage.setItem('superUserTab', '2');
    history.push('/account_setting/settings?planFocus=focus');
  };

  const getFocus = () => {
    document.getElementById('selectAll')?.focus();
  };

  const handleSetPage = (page: number) => {
    var initial = 15;

    if (page === 0) {
      var filter1 = initial * page;
      var multiplepage = initial * page - 1;
      pagination(multiplepage + 1, initial + filter1);
    } else {
      var next = initial * page;
      var next_sub = initial * page - 1;
      if (initial + next > candidate_ids.length) {
        pagination(next_sub + 1, candidate_ids.length);
      } else {
        pagination(next_sub + 1, initial + next);
      }
    }
    const currentParams = new URLSearchParams(window.location.search);

    if (!isEmpty(formik.values.location)) {
      currentParams.set('pagination', JSON.stringify(page));
    }

    history.push({
      pathname: window.location.pathname,
      search: currentParams.toString(),
    });
    setshow(false);
    setIsCheck([]);
    setPageNumber(page);
    getFocus();
  };

  const pagination = (from, to) => {
    var resultArray = [];
    var result = candidate_ids.slice(from, to);
    resultArray.push(result);

    setvisible(true);
    setinitalSearch(true);
    dispatch(
      LinkedinSourcingSearchMiddleWare({
        linkedin_id: JSON.stringify(result),
      }),
    )
      .then((res) => {
        setlinkedindata(res.payload.candidates);
        setinitalSearch(false);
        setFind(false);
        setIsCheck([]);
        setIsCheckAll(false);
      })
      .catch((err) => {
        setinitalSearch(false);
        setFind(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  useEffect(() => {
    setSearchData([]);
  }, []);

  // filter refresh function
  const handleRefresh = () => {
    setBachelors(false);
    setAny(true);
    setDoctorate(false);
    setExperience({
      label: 'All',
      value: 'all',
    });
    setOther(false);
    setMasters(false);
    setRelocate(false);
    setmyqualfilter([]);
    setexpgra('');
    setexples('');
  };

  if (isInitialLoader) {
    return <Loader />;
  }
  function countLockedCandidates(candidates) {
    let count = 0;
    for (let i = 0; i < candidates?.length; i++) {
      if (candidates[i].unlock_status === 'locked') {
        count++;
      }
    }
    return count;
  }

  const filter_api = () => {
    setvisible(true);
    setinitalSearch(true);
    var quali = myquafilter;
    dispatch(
      LinkedinSourcingSearchMiddleWare({
        location: lowerCase(formik.values.location),
        job_title: formik.values.keywords,
        skill: formik.values.skillValue,
        pagecount: 15,
        education: JSON.stringify(quali),
        expirence_gte: expgra,
        expirence_lte: exples,
      }),
    )
      .then((res) => {
        setlinkedindata(res.payload.candidates);
        setcandidate_ids(res.payload.candidate_ids);
        // setSubmitLoader(false);
        setinitalSearch(false);
        setFind(false);
      })
      .catch((err) => {
        setinitalSearch(false);
        setFind(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  

  const filterclose = (id, val) => {
    var a = [...myquafilter];

    var less = exples;

    var gra = expgra;

    if (id === 1) {
      less = '';
      gra = '';
    } else {
      var index = a.indexOf(val);
      if (index !== -1) {
        a.splice(index, 1);
      }
    }

    setmyqualfilter([...a]);
    setexpgra(gra);
    setexples(less);
    setvisible(true);
    setinitalSearch(true);

    dispatch(
      LinkedinSourcingSearchMiddleWare({
        location: lowerCase(formik.values.location),
        job_title: formik.values.keywords,
        skill: formik.values.skillValue,
        pagecount: 15,
        education: JSON.stringify(a),
        expirence_gte: gra,
        expirence_lte: less,
      }),
    )
      .then((res) => {
        setlinkedindata(res.payload.candidates);
        setcandidate_ids(res.payload.candidate_ids);
        setinitalSearch(false);
        setFind(false);
      })
      .catch((err) => {
        setinitalSearch(false);
        setFind(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  const referesh_filter = () => {
    setmyqualfilter([]);
    setexpgra('');
    setexples('');
    setvisible(true);
    //setSubmitLoader(true);
    setinitalSearch(true);

    dispatch(
      LinkedinSourcingSearchMiddleWare({
        location: lowerCase(formik.values.location),
        job_title: formik.values.keywords,
        skill: formik.values.skillValue,
        pagecount: 15,
        education: JSON.stringify([]),
        expirence_gte: '',
        expirence_lte: '',
      }),
    )
      .then((res) => {
        setlinkedindata(res.payload.candidates);
        setcandidate_ids(res.payload.candidate_ids);
        setinitalSearch(false);
        setFind(false);
      })
      .catch((err) => {
        setinitalSearch(false);
        setFind(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  const lockedCandidatesCount = countLockedCandidates(isSearchData);

  return (
    <>
      {isSubmitLoader && <Loader />}
      {isopensubcription && (
        <SubscriptionModal
          addon_name={['Resume Unlock']}
          openmodel={isopensubcription}
          differece_key={['talentsourcing']}
          setopensubcription={setopensubcription}
        />
      )}
      <Flex style={{ overflow: 'hidden !important' }}>
        {/* <Flex row className={styles.ribbon} between>
          <Flex marginTop={9} marginLeft={8}>
            <Text size={16} bold color="theme">
              LinkedIn Sourcing
            </Text>
          </Flex>
          <Flex>
            <div className={styles.triangle}></div>
          </Flex>
        </Flex> */}
        <Flex row className={styles.overAll}>
          {(sourceLoader ||
            isPdfLoader ||
            searchLoader ||
            stripeLoader ||
            isCheckOutLoader) &&
            isSubmitLoader}

          <CandiViewModal
            open={isShowPdf}
            filePath={viewfile}
            cancel={() => setShowPdf(false)}
          />
          <UnlockLoaderModal
            title={
              'Exporting resumes... This might take a few minutes to process the files.'
            }
            open={profiledownload}
          />
          <UnlockLoaderModal
            title={
              'Unlocking the profiles. Please wait until the process gets completed.'
            }
            open={unlockmodal}
          />
          <UnlockLoaderModal
            title={
              'Scouring LinkedIn for potential candidates based on your search criteria. We appreciate your patience while we find the best matches.'
            }
            open={initalSearch}
            linkedin={true}
          />
          <UnlockLoaderModal
            title={
              'Analyzing the professional profile of the candidate. This may take a moment as we gather comprehensive details to provide you with a insight.'
            }
            open={profilepdf}
            linkedin={true}
          />
          {/* <ContactCreditsModal
            setCheckOutLoader={setCheckOutLoader}
            open={isContact}
            cancelBtnOnClick={() => setContact(false)}
            publicKey={publicKey}
          /> */}

          {/* <CancelAndDeletePopup
            title={
              'You do not have enough contact credits. Do you wish to buy credits to unlock contacts?'
            }
            btnRight={'Buy'}
            open={isNoLimit}
            btnCancel={() => {
              setNoLimit(false);
            }}
            btnDelete={() => {
              hanldeContactModal();
            }}
          /> */}
          <CandidateSuccessModal
            open={isSuccess}
            btnOnclick={() => setSuccess(false)}
            credits={resume_unlock_credits}
          />
          {/* <SingleButton
            btnTitle="OK"
            title={'Please contact your company admin to unlock contacts.'}
            open={isNoPermission}
            btnOnclick={() => setNoPermission(false)}
          /> */}
          {/* <SingleButton
            btnTitle="OK"
            title={`Contact credits purchased successfully. Your Available Contact Credits: ${isSourceLimit}`}
            open={isCredit}
            btnOnclick={() => setCredit(false)}
          /> */}

          <Flex className={styles.titleContainer}>
            <div>
              <Flex className={styles.talentActionContainer}>
                <Linkedinaction
                  update={update}
                  val={show}
                  setIsCheck={setIsCheck}
                  setInitalCheckBox={setInitalCheckBox}
                  setFind={setFind}
                  setPageNumber={setPageNumber}
                  setCandidatesLimit={setCandidatesLimit}
                  setSourceLimit={setSourceLimit}
                  location={location}
                  setSubmitLoader={setSubmitLoader}
                  setvisible={setvisible}
                  isformikdata={isformikdata}
                  setformikdata={setformikdata}
                  ispagecount={ispagecount}
                  setpagecount={setpagecount}
                  talent_sourcing={talent_sourcing}
                  setlinkedindata={setlinkedindata}
                  setcandidate_ids={setcandidate_ids}
                  formik={formik}
                />
              </Flex>

              {visible && linkedindata.length !== 0 && (
                <div className={cx('filterOverAll')}>
                  <TalentFilter
                    setchange={setchange}
                    apply={apply}
                    setapply={setapply}
                    isInitalCheckBox={isInitalCheckBox}
                    setOther={setOther}
                    isOther={isOther}
                    isBachelors={isBachelors}
                    isDoctorate={isDoctorate}
                    isMasters={isMasters}
                    isAny={isAny}
                    setBachelors={setBachelors}
                    setDoctorate={setDoctorate}
                    setMasters={setMasters}
                    setAny={setAny}
                    isRelocate={isRelocate}
                    setRelocate={setRelocate}
                    isExperience={isExperience}
                    setExperience={setExperience}
                    setInitialPage={setPageNumber}
                    handleRefresh={handleRefresh}
                    filter_api={filter_api}
                    qulification_filter={qulification_filter}
                    expirence_filter={expirence_filter}
                    filterclose={filterclose}
                    filter_reset={referesh_filter}
                    newexperience={newexperience}
                    setnewexperience={setnewexperience}
                    NewBachelors1={NewBachelors1}
                    setnewBachelors1={setnewBachelors1}
                    Newmaster1={Newmaster1}
                    setnewmaster1={setnewmaster1}
                    NewDoctorate1={NewDoctorate1}
                    setnewDoctorate1={setnewDoctorate1}
                    Newothers1={Newothers1}
                    setnewothers1={setnewothers1}
                    newrelocate={newrelocate}
                    setnewrelocate={setnewrelocate}
                  />
                </div>
              )}

              {linkedindata?.length === 0 && isFind && !initalSearch && (
                <Flex
                  center
                  middle
                  height={componentHeight}
                  className={styles.emptyStyle}
                >
                  <SvgSearch width={16} height={16} fill={'#888888'} />
                  <Text style={{ color: '#888888', marginTop: '2px' }}>
                    Please enter your search keywords in the required search
                    field to find the candidates
                  </Text>
                </Flex>
              )}

              {linkedindata?.length === 0 && !isFind && !isSubmitLoader && (
                <Flex
                  className={styles.emptyStyle}
                  center
                  middle
                  height={window.innerHeight - 238}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <SvgNotinterests
                    width={18}
                    height={18}
                    style={{ filter: 'opacity(0.5)' }}
                  />
                  <div>
                    <Empty title="No Candidate Found" />
                  </div>
                </Flex>
              )}
              {linkedindata?.length !== 0 && initalSearch !== true && (
                <TalentCardList
                  handleSetPage={handleSetPage}
                  client_url={client_url}
                  pageNumber={pageNumber}
                  isSubmitLoader={isSubmitLoader}
                  add_on_plans={add_on_plans}
                  resume_unlock_credits={resume_unlock_credits}
                  setopensubcription={setopensubcription}
                  update={update}
                  val={show}
                  isCheck={isCheck}
                  setIsCheck={setIsCheck}
                  setCandiList={setCandiList}
                  setNoCount={setNoCount}
                  candi_list={isCandiList}
                  setNoLimit={setNoLimit}
                  setNoPermission={setNoPermission}
                  setSuccess={setSuccess}
                  setCandidatesLimit={setCandidatesLimit}
                  isCandidatesLimit={isCandidatesLimit}
                  handleCandidateView={handleCandidateView}
                  isRelocate={isRelocate}
                  isExperience={isExperience}
                  isBachelors={isBachelors}
                  isDoctorate={isDoctorate}
                  isMasters={isMasters}
                  isAny={isAny}
                  searchLoader={searchLoader}
                  searchData={isSearchData}
                  handleUnlockSubmit={handleUnlockSubmit}
                  source_limit={isSourceLimit}
                  setSourceLimit={setSourceLimit}
                  planID={planId}
                  setFree={setFree}
                  lockedCandidatesCount={lockcandidate}
                  setunlockcan={setunlockcan}
                  unlockcan={unlockcan}
                  setSearchData={setSearchData}
                  setpagecount={setpagecount}
                  setformikdata={setformikdata}
                  talent_sourcing={talent_sourcing}
                  linkedindata={linkedindata}
                  candidate_ids={candidate_ids}
                  setunlockmodal={setunlockmodal}
                  setprofiledownload={setprofiledownload}
                  isCheckAll={isCheckAll}
                  setIsCheckAll={setIsCheckAll}
                />
              )}
            </div>

            {cardloader && <Loader />}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default LinkedinSourcingScreen;
