import { useFormik } from 'formik';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createCheckoutMiddleWare,
  stripeMiddleWare,
} from '../talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import SvgCloseSmall from '../../icons/SvgCloseSmall';
import { AppDispatch, RootState } from '../../store';
import Button from '../../uikit/Button/Button';
import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import { isEmpty } from '../../uikit/helper';
import InputText from '../../uikit/InputText/InputText';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import Loader from '../../uikit/Loader/Loader';
import Text from '../../uikit/Text/Text';
import { billingPortalMiddleWare } from '../accountsettingsmodule/managesubscription/store/managesubscriptionmiddleware';
import { Toast } from '../../uikit';
import styles from './ordersummaryscreen.module.css';
import {
  backendProcessMiddleWare,
  orderSummaryMiddleWare,
  orderSummaryPostMiddleWare,
} from './store/ordersummarymiddleware';

const OrderSummaryScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isPageLoader, setPageLoader] = useState(true);
  const [isPromoError, setPromoError] = useState(false);
  const [isDisCount, setDisCount] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [discounts, setDiscounts] = useState<any>();

  const history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const planId: any = query.get('key');
  const count: any = query.get('count');
  const current = query.get('current');
  const add_on_count = localStorage.getItem('add_on_count');
  const add_on_id = localStorage.getItem('add_on_id');
  const stipids = localStorage.getItem('stipids');
  // initial api call based on url params
  useEffect(() => {
    setTimeout(() => {
      dispatch(
        orderSummaryMiddleWare({
          key: planId.toString(),
          count: count.toString(),
          add_on_id: add_on_id,
          add_on_count: add_on_count,
          state: current,
        }),
      ).then(() => {
        setPageLoader(false);
      });
    }, 1000);
  }, []);

  const {
    palnDeatils,
    local_sub,
    un_used,
    subtotal,
    final,
    available_balance,
    discount_added,
    isLoading,
    stripe_balance,
    new_price,
    is_plan,
    total_discount_amounts,
    addon_respons,
    add_on_total,
  } = useSelector(
    ({ orderSummaryReducers, permissionReducers }: RootState) => ({
      local_sub: orderSummaryReducers.local_sub,
      palnDeatils: orderSummaryReducers.plan,
      un_used: orderSummaryReducers.un_used,
      subtotal: orderSummaryReducers.subtotal,
      final: orderSummaryReducers.final,
      available_balance: orderSummaryReducers.available_balance,
      discount_added: orderSummaryReducers.discount_added,
      isLoading: orderSummaryReducers.isLoading,
      stripe_balance: orderSummaryReducers.stripe_balance,
      new_price: orderSummaryReducers.new_price,
      is_plan: permissionReducers.is_plan,
      total_discount_amounts: orderSummaryReducers.total_discount_amounts,
      addon_respons: orderSummaryReducers.addon_respons,
      add_on_total: orderSummaryReducers.add_on_total,
    }),
  );

  // useEffect(() => {
  //   if (!is_plan) {
  //     sessionStorage.setItem('superUserTab', '2');
  //     history.push('/account_setting/settings');
  //   }
  // });
  const addonValuesid =
    addon_respons && addon_respons?.map((item) => item.addon);
  // form submit
  const handleFormikSubmit = (values: any) => {
    setLoader(true);
    if (addon_respons && addon_respons.length !== 0) {
      const addonStatus =
        current === 'true' &&
        add_on_count.length !== 0 &&
        add_on_count.length !== 0 &&
        true;
      dispatch(
        orderSummaryPostMiddleWare({
          discounts: values.value,
          key: palnDeatils.plan_id.toString(),
          count,
          addon: JSON.stringify(addonValuesid),
          addon_status: addonStatus,
        }),
      ).then((res) => {
        if (res.payload.msg) {
          setDisCount(false);
          setPromoError(true);
          setErrorMessage(res?.payload?.message);
          setLoader(false);
        } else {
          setPromoError(false);
          setLoader(false);
        }
        if (res.payload.discounts) {
          setDisCount(true);
          sessionStorage.setItem('addonid', res.payload.discounts);
          setDiscounts(sessionStorage.getItem('addonid'));
          dispatch(
            orderSummaryMiddleWare({
              key: palnDeatils.plan_id.toString(),
              count,
              discounts: res.payload.discounts,
              add_on_id: add_on_id,
              add_on_count: add_on_count,
              state: current,
            }),
          ).then((response) => {
            if (response?.payload?.success === false) {
              setDisCount(false);
              Toast(
                'Minimum purchase required for this coupon.',
                'MEDIUM',
                'error',
              );
              formik.setFieldValue('value', '');
            }
            setLoader(false);
          });
        Toast('Coupon applied successfully');
        }
        // Toast('Coupon applied successfully');
      });
    } else {
      dispatch(
        orderSummaryPostMiddleWare({
          discounts: values.value,
          key: palnDeatils.plan_id.toString(),
          count,
        }),
      ).then((res) => {
        if (res.payload.msg) {
          setDisCount(false);
          setPromoError(true);
          setErrorMessage(res?.payload?.message);
          setLoader(false);
        } else {
          setPromoError(false);
          setLoader(false);
        }
        if (res.payload.discounts) {
          setDisCount(true);
          dispatch(
            orderSummaryMiddleWare({
              key: palnDeatils.plan_id.toString(),
              count,
              discounts: res.payload.discounts,
              add_on_id: add_on_id,
              add_on_count: add_on_count,
              state: current,
            }),
          ).then(()=>{
            setLoader(false);
            Toast('Coupon applied successfully');
          })
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: { value: '' },
    onSubmit: handleFormikSubmit,
  });

  // const chekcUpdateUser = palnDeatils.plan_id === local_sub.plan_id_id;
  const checkPlanChange = palnDeatils.plan_id !== local_sub.plan_id_id;

  // pay now submit fuction
  const handlePayNow = () => {
    setLoader(true);
    if (planId !== '0') {
      dispatch(
        orderSummaryPostMiddleWare({
          update: 'update',
          key: palnDeatils.plan_id.toString(),
          count,
          discounts:
            discount_added && discount_added.discount_code
              ? discount_added.discount_code
              : '',
          finalvalue: Math.round(Number(final) * 100) / 100 + add_on_total,
        }),
      ).then((res) => {
        if (res.payload.update) {
          dispatch(backendProcessMiddleWare({})).then((endRes) => {
            if (endRes.payload.success && checkPlanChange) {
              sessionStorage.setItem('MyAccountDropdown', '1');
              history.push(
                '/account_setting/subscription?modal_popup=plan_changed',
              );
            }
            if (endRes.payload.user_count === 'user_ase') {
              sessionStorage.setItem('MyAccountDropdown', '1');
              history.push(
                '/account_setting/subscription?modal_popup=user_ase',
              );
            }
            if (endRes.payload.user_count === 'user_dec') {
              sessionStorage.setItem('MyAccountDropdown', '1');
              history.push(
                '/account_setting/subscription?modal_popup=user_dec',
              );
            }
            setLoader(false);
          });
        } else {
          setLoader(false);
          Toast(
            'Your payment has failed. Please check your invoice.',
            'LONG',
            'error',
          );
        }
      });
    } else {
      dispatch(
        createCheckoutMiddleWare({
          can_count: add_on_count,
          plan: stipids,
          add_on: add_on_id,
          manage_sub: 'yes',
          discounts: discounts,
        }),
      ).then((response) => {
        dispatch(stripeMiddleWare()).then(async (stripRes) => {
          const stripe = await loadStripe(stripRes.payload.publicKey);
          if (response.payload.sessionId) {
            stripe
              ?.redirectToCheckout({
                sessionId: response.payload.sessionId,
              })
              .then((resp) => {
                setLoader(false);
              });
          }
        });
      });
    }
  };
  // paymentinfo form submit
  const handleUpdatePaymentInfo = () => {
    setLoader(true);
    dispatch(
      billingPortalMiddleWare({ order_summary: window.location.href }),
    ).then((res) => {
      setLoader(false);
      window.location.replace(res.payload.url);
    });
  };
  // promo close function
  const handleClosePromo = () => {
    setLoader(true);
    dispatch(
      orderSummaryMiddleWare({
        key: palnDeatils.plan_id.toString(),
        count,
        discounts: '',
        add_on_id: add_on_id,
        add_on_count: add_on_count,
        state: current,
      }),
    ).then(() => {
      setLoader(false);
      setDisCount(false);
    });
  };

  if (isPageLoader) {
    return <Loader />;
  }

  const promochange = (e) => {
    formik.setFieldValue('value', e.target.value);
    setPromoError(false);
  };

  return (
    <Flex center className={styles.mainStyle} height={window.innerHeight - 72}>
      <Flex className={styles.overAll} marginTop={30}>
        {(isLoader || isLoading) && <Loader />}
        <Text size={20} bold>
          Order Summary
        </Text>
        <Text style={{ marginBottom: 20 }}>
          Review your order before proceeding with your payment
        </Text>
        <Card className={styles.cardOverAll}>
          <Flex row center between className={styles.borderBottom}>
            <LinkWrapper
              target={'_parent'}
              to={`/account_setting/subscription?count=${count}&planFocus=true&key=${planId}`}
            >
              <Button types="link">Edit</Button>
            </LinkWrapper>
          </Flex>
          <Flex className={styles.planFlex}>
            {planId !== '0' && (
              <Flex>
                <Flex row center between marginBottom={8}>
                  <Text size={16} bold>
                    {palnDeatils.plan_name}
                  </Text>
                  <Text size={16} bold>
                    ${palnDeatils.price}
                  </Text>
                </Flex>
                <Flex row center between marginBottom={8}>
                  {palnDeatils.subscription_value_days === 30 && (
                    <Text>Monthly Subscription</Text>
                  )}
                  {palnDeatils.subscription_value_days === 365 && (
                    <Text>Annual Subscription</Text>
                  )}
                </Flex>
              </Flex>
            )}
            {addon_respons.length !== 0 && (
              <Flex>
                <Text bold size={14} style={{ marginBottom: 8 }}>
                  Add-on(s)
                </Text>
                {addon_respons.map((data, i) => (
                  <Flex row between key={i} style={{ padding: '0 5px 5px 0' }}>
                    <Text>{data.addon__name}</Text>
                    <Text bold>${data.addon_price}</Text>
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>

          {/* plan change */}

          {checkPlanChange && (
            <Flex className={styles.detailsFlex}>
              <Text bold size={16}>
                Details
              </Text>
              <Flex row between marginTop={8} marginBottom={8}>
                <Flex>
                  <Text style={{ marginBottom: 8 }}>
                    Subscription Charges for{' '}
                    <b>
                      <Text>{palnDeatils.plan_name}</Text>
                      Plan (
                      {palnDeatils.subscription_value_days === 365
                        ? 'Annual'
                        : 'Monthly'}
                      )
                    </b>
                  </Text>
                </Flex>
                <Text>${palnDeatils.price * Number(count)}.00</Text>
              </Flex>
              <Flex row between marginTop={8} marginBottom={8}>
                <Flex>
                  <Text style={{ marginBottom: 8 }}>
                    Unused Credits from Previous{' '}
                    <b>
                      <Text>{local_sub.plan_name}</Text>
                      Plan ({local_sub.days === 365 ? 'Annual' : 'Monthly'})
                    </b>
                  </Text>
                </Flex>
                <Text>-${un_used.toString().replace('-', '')}</Text>
              </Flex>
              <Flex
                row
                between
                center
                className={styles.totalBorder}
                marginTop={8}
              >
                <Text bold>Subtotal</Text>
                <Text bold>${subtotal}</Text>
              </Flex>
              {!isEmpty(available_balance) &&
                (available_balance !== 0 || stripe_balance !== 0) &&
                checkPlanChange && (
                  <Flex row center between marginTop={16}>
                    {available_balance !== 0 ? (
                      <Text>Available Balance Credits</Text>
                    ) : (
                      <Text>Applied Balance Credits </Text>
                    )}
                    {available_balance !== 0 ? (
                      <Text>
                        ${available_balance.toString().replace('-', '')}
                      </Text>
                    ) : (
                      <Text>${stripe_balance.toString().replace('-', '')}</Text>
                    )}
                  </Flex>
                )}

              {discount_added && (
                <Flex row center between marginTop={16}>
                  {discount_added?.discount_type === 'Fixed' ? (
                    <Text>{discount_added?.discount_name}</Text>
                  ) : (
                    <Text>{discount_added?.discount_name}</Text>
                  )}
                  <Text>-${total_discount_amounts}</Text>
                </Flex>
              )}
              <Flex row center between marginTop={16}>
                <Text bold>Grand Total</Text>
                <Text bold>
                  ${Math.round(Number(final) * 100) / 100 + add_on_total}
                </Text>
              </Flex>
            </Flex>
          )}

          {/* update user */}
          {planId === '0' && (
            <Flex className={styles.detailsFlex}>
              {discount_added && (
                <Flex row center between marginBottom={16}>
                  {discount_added?.discount_type === 'Fixed' ? (
                    <Text>{discount_added?.discount_name}</Text>
                  ) : (
                    <Text>{discount_added?.discount_name}</Text>
                  )}
                  <Text>-${total_discount_amounts}</Text>
                </Flex>
              )}
              <Flex row center between>
                <Text bold>Grand Total</Text>
                {final ? (
                  <Text bold>
                    ${(Math.round(Number(final) * 100) + add_on_total) / 100}
                  </Text>
                ) : (
                  <Text bold>${add_on_total}</Text>
                )}
              </Flex>
            </Flex>
          )}

          <Flex
            row
            top
            marginTop={20}
            marginBottom={30}
            style={{
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
              padding: '10px 0',
            }}
          >
            <div style={{ width: '250px' }}>
              <InputText
                value={formik.values.value}
                onChange={(e) => promochange(e)}
                placeholder="Enter Promo Code"
                disabled={isDisCount}
                actionRight={() =>
                  isDisCount && (
                    <>
                      <div
                        tabIndex={-1}
                        role="button"
                        onKeyDown={() => {}}
                        onClick={() => {
                          formik.setFieldValue('value', '');
                          handleClosePromo();
                        }}
                      >
                        <SvgCloseSmall />
                      </div>
                    </>
                  )
                }
              />
              {isPromoError && (
                <Text size={12} color="error">
                  {errorMessage}
                </Text>
              )}
            </div>
            {!isDisCount && (
              <Button
                style={{ marginLeft: 16 }}
                disabled={isEmpty(formik.values.value)}
                onClick={formik.handleSubmit}
              >
                Apply
              </Button>
            )}
          </Flex>
          <Flex
            columnFlex
            center
            marginBottom={10}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <Flex row center>
              <Button
                types="secondary"
                onClick={handleUpdatePaymentInfo}
                style={{ marginRight: 16 }}
              >
                Update Payment Info
              </Button>
              <Button disabled={isPromoError} onClick={handlePayNow}>
                Pay Now
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default OrderSummaryScreen;
