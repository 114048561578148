import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import SvgClose from '../../icons/SvgClose';
import SvgNewTab from '../../icons/SvgNewTab';
import SvgLeft from '../../icons/SvgLeft';
import SvgArrowLeft from '../../icons/SvgArrowLeft';
import SvgArrowRight from '../../icons/SvgArrowRight';
import SvgshareIcon from '../../icons/SvgShareIconview';
import SvgJobselection from '../../icons/SvgJobselection';
import { GARY_3, LINK } from '../../uikit/Colors/colors';
import { SelectTag, Toast } from '../../uikit';
import { toCamelCase } from '../../uikit/helper';
import Drawer from '../../uikit/Drawer/Drawer';
import Flex from '../../uikit/Flex/Flex';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import Text from '../../uikit/Text/Text';
// import { Loader } from '../../uikit';
import {
  PipelineStagesDeleteMiddleWare,
  PipelineStagesGetMiddleWare,
  PipelineStagesPostMiddleWare,
  applicantMatchMiddleWare,
  applicantStatusMiddleWare,
} from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import ApplicantProfileModal from './ApplicantProfileModal';
import styles from './profileview.module.css';

type Props = {
  open: boolean;
  cancel: () => void;
  jobId: string;
  // jobtitle: string;
  candidateId: any;
  inviteIconNone?: boolean;
  activeState?: number;
  setdatachange?: (a?: any) => void;
  datakey?: () => void;
  buttonchange?: (a?: any) => void;
  setcurrent_canid?: (a?: any) => void;
  togglebutton?: (a?: any) => void;
  column_detail?: any;
  isendofbutton?: any;
  applicantpipeline?: boolean;
};

const ProfileView = ({
  cancel,
  open,
  jobId,
  // jobtitle,
  candidateId,
  inviteIconNone,
  activeState,
  setdatachange,
  datakey,
  buttonchange,
  setcurrent_canid,
  togglebutton,
  isendofbutton,
  column_detail,
  applicantpipeline,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [jobtitle, setjobtitle] = useState<string>();
  const [columndata, setcolumndata] = useState<any>();
  const [filteredcolumn, setfilteredcolumn] = useState<any>();
  const [filteredcolumnindex, setfilteredcolumnindex] = useState<any>();
  const [checkingstatus, setcheckingstatus] = useState(null);
  const [stages_cand, setstages_cand] = useState(null);
  const [ischaractercheck, setcharactercheck] = useState<any>();
  const [isdublicatecheck, setdublicatecheck] = useState<any>();
  const [isdublicateerror, setdublicateerror] = useState<any>(false);
  const [ispreviousdata, setpriviousdata] = useState<any>();
  const [isnextdata, setnextdata] = useState<any>();
  const [isapplicantpipeline, setapplicantpipeline] = useState<any>();
  const [show, setshow] = useState(true);
  const [droupvalue, setdroupvalue] = useState<any>();

  const {
    jd,
    pipelinestages,
    current_stage,
    stages,
    can_id,
    isLoading,
    matchLoader,
    active_resume,
  } = useSelector(
    ({
      applicantProfileInitalReducers,
      applicantStausReducers,
      PipelineStagesReducers,
      candidatejdmatchReducers,
      applicantPipeLineDataReducers,
    }: RootState) => {
      return {
        jd: applicantProfileInitalReducers.jd,
        can_id: applicantProfileInitalReducers.can_id,
        active_resume: applicantPipeLineDataReducers.active_resume,
        stages: applicantStausReducers?.stages,
        pipelinestages: PipelineStagesReducers?.stages,
        current_stage: PipelineStagesReducers?.current_stage,
        isLoading: applicantProfileInitalReducers.isLoading,
        matchLoader: candidatejdmatchReducers.isLoading,
      };
    },
  );
  // passing the pipeline stages to array format to pass as a option in select tag
  const transformedData = pipelinestages?.map((item) => ({
    value: item?.stage_id,
    label: item?.stage_name,
    id: item?.stage_userbased,
    stageused: item?.stage_used,
  }));

  // default calling the API except the new applicant.
  useEffect(() => {
    if (can_id && show) {
      setdroupvalue(stages[stages?.length - 1]?.current_stage);
    }
  }, [show, can_id, stages]);

  // condition for updating the stages after calling the post call then call the get call for getting the data from response.
  const handlepipelinestages = (event) => {
    if (event.__isNew__ === true) {
      if (event?.label?.replace(/\s+/g, '')?.trim()?.length > 25) {
        setcharactercheck(true);
      } else if (
        transformedData.filter((data) => data.label === isdublicatecheck)
          .length !== 0
      ) {
        setdublicateerror(true);
      } else {
        setcharactercheck(false);
        setdublicateerror(false);
        const formData = new FormData();
        formData.append(
          'jd_id',
          JSON.stringify(stages[stages?.length - 1]?.jd_id),
        );
        formData.append(
          'can_id',
          JSON.stringify(stages[stages?.length - 1]?.candidate_id),
        );
        formData.append(
          'pipeline_id',
          JSON.stringify(stages[stages?.length - 1]?.stage_id),
        );
        formData.append('stage_name', toCamelCase(event.value));
        formData.append('customization', 'customization');
        dispatch(
          PipelineStagesPostMiddleWare({
            formData,
          }),
        ).then(() => {
          setdroupvalue(toCamelCase(event.value));
          Toast('Stage status created successfully.', 'SHORT', 'success');
          dispatch(
            PipelineStagesGetMiddleWare({
              jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
              can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
              pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id),
            }),
          );
          dispatch(
            applicantStatusMiddleWare({
              jd_id: stages[stages?.length - 1]?.jd_id.toString(),
              can_id: stages[stages?.length - 1]?.candidate_id.toString(),
            }),
          );
        });
      }
    } else {
      const data = pipelinestages?.find(
        (e) => Number(e?.stage_id) === Number(event?.value),
      );

      const formData = new FormData();
      formData.append(
        'jd_id',
        JSON.stringify(stages[stages?.length - 1]?.jd_id),
      );
      formData.append(
        'can_id',
        JSON.stringify(stages[stages?.length - 1]?.candidate_id),
      );
      formData.append(
        'pipeline_id',
        JSON.stringify(stages[stages?.length - 1]?.stage_id),
      );
      formData.append('update', JSON.stringify(data?.stage_id));
      if (Number(data?.stage_id !== 1)) {
        dispatch(
          PipelineStagesPostMiddleWare({
            formData,
          }),
        ).then(() => {
          setdroupvalue(toCamelCase(event.value));
          if (Number(data?.stage_id) === 4) {
            Toast('Stage status is set to completed.', 'SHORT', 'success');
          } else {
            Toast('Stage status updated successfully.', 'SHORT', 'success');
          }
          dispatch(
            PipelineStagesGetMiddleWare({
              jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
              can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
              pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id),
            }),
          );
          dispatch(
            applicantStatusMiddleWare({
              jd_id: stages[stages?.length - 1]?.jd_id.toString(),
              can_id: stages[stages?.length - 1]?.candidate_id.toString(),
            }),
          );
        });
      }
    }
  };

  // getting the applicant current stage in this job
  useEffect(() => {
    if (stages.length >= 1) {
      const stage_name = stages[stages.length - 1].stage_id__stage_name;
      const stage_cand = stages[stages.length - 1].candidate_id;
      setcheckingstatus(stage_name);
      setstages_cand(stage_cand);
    }
    if (stages.length === 0) {
      setcheckingstatus('');
    }
  }, [stages]);

  // useEffect for getting applicantpipelinestatus on the pipeline
  useEffect(() => {
    if (column_detail !== undefined) {
      localStorage.setItem('column_detail', JSON.stringify(column_detail));
    }
    if (applicantpipeline !== undefined) {
      if (applicantpipeline === true) {
        localStorage.setItem('applicantpipelinestatus', 'true');
        setapplicantpipeline('true');
      }
      if (applicantpipeline === false) {
        localStorage.setItem('applicantpipelinestatus', 'false');
        setapplicantpipeline('false');
      }
    }
  }, [column_detail, applicantpipeline]);

  // useEffect for  getting column_detail based on the pipeline
  useEffect(() => {
    const columnDetail = localStorage.getItem('column_detail');
    if (columnDetail && columnDetail !== 'undefined') {
      try {
        setcolumndata(JSON.parse(columnDetail));
      } catch (error) {
        console.error('Error parsing column_detail:', error);
      }
    }
  }, [localStorage.getItem('column_detail'), stages]);

  // useEffect for getting applicantpipelinestatus on the pipeline
  useEffect(() => {
    if (localStorage.getItem('applicantpipelinestatus')) {
      setapplicantpipeline(localStorage.getItem('applicantpipelinestatus'));
    }
  }, [localStorage.getItem('applicantpipelinestatus')]);

  // useEffect for updating the privious and next profile based on the pipeline
  useEffect(() => {
    if (stages.length !== 0) {
      if (stages[stages.length - 1]?.stage_id === null) {
        const value = columndata?.filter((data) => data?.id === 0);

        const updatedStages =
          value &&
          value?.map((stage) => ({
            ...stage,
            items: stage.items.filter((item) =>
              // item.block_descriptive === false,
              active_resume.includes(item.candidate_id_id),
            ),
          }));


        setfilteredcolumn(updatedStages && updatedStages[0]?.items.length);
        const currentIndex =
          updatedStages &&
          updatedStages[0]?.items?.findIndex(
            (item) =>
              item?.candidate_id_id ===
              stages[stages?.length - 1]?.candidate_id,
          );
        setfilteredcolumnindex(currentIndex);
        setpriviousdata(
          updatedStages && updatedStages[0]?.items[currentIndex - 1],
        );
        setnextdata(updatedStages && updatedStages[0]?.items[currentIndex + 1]);
      } else {
        const value = columndata?.filter(
          (data) => data?.id === stages[stages?.length - 1]?.stage_id,
        );
        setfilteredcolumn(value && value[0]?.items.length);
        const currentIndex =
          value &&
          value[0]?.items?.findIndex(
            (item) =>
              item?.candidate_id_id ===
              stages[stages?.length - 1]?.candidate_id,
          );
        setfilteredcolumnindex(currentIndex);
        setpriviousdata(value && value[0]?.items[currentIndex - 1]);
        setnextdata(value && value[0]?.items[currentIndex + 1]);
      }
    }
  }, [columndata, stages, ispreviousdata, isnextdata]);


  // ondelete function in select option when don't want the stage status.
  const onDeleteoption = (e) => {
    dispatch(
      PipelineStagesDeleteMiddleWare({
        id: e,
      }),
    ).then(() => {
      Toast('Stage status removed permanently.', 'SHORT', 'success');
      dispatch(
        PipelineStagesGetMiddleWare({
          jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
          can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
          pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id),
        }),
      ).then(() => {
        // setloader(false);
      });
    });
  };

  // this is using validate the input value while typing it self in the dropdown field.
  useEffect(() => {
    if (isdublicatecheck?.replace(/\s+/g, '')?.trim()?.length > 25) {
      setcharactercheck(true);
    }
  }, [isdublicatecheck]);

  // on inputchange function for creating stage in select tag.
  const onCreatestage = (e) => {
    setdublicatecheck(e);
    setcharactercheck(false);
    setdublicateerror(false);
  };
  return (
    <Drawer open={open}>
      <div className={styles.overAll}>
        <Flex row center between flex={12} className={styles.border}>
          <Flex
            className={'pointer'}
            style={{ cursor: 'pointer' }}
            onClick={cancel}
            row
            flex={4}
            center
          >
            <SvgLeft fill={'#581845'} height={16} width={16} />
            {isapplicantpipeline === 'true' && (
              <Text bold color="theme" style={{ marginLeft: '5px' }}>
                Back to pipeline
              </Text>
            )}
          </Flex>

          {jd && jd.job_id !== '' && jd.job_title !== '' ? (
            <Flex row center middle flex={4}>
              {isapplicantpipeline === 'true' ? (
                <>
                  {checkingstatus !== 'Applied' && (
                    <>
                      {isdublicateerror || ischaractercheck ? (
                        <Flex>
                          {stages.length !== 0 && (
                            <Flex
                              row
                              style={{
                                border: '1px solid #581845',
                                borderRadius: '4px',
                              }}
                              center
                              marginTop={18}
                            >
                              <Flex
                                center
                                style={{
                                  color: '#581845',
                                  padding: '0px 10px',
                                }}
                              >
                                Applicant Status
                              </Flex>
                              <Flex
                                width={215}
                                disabled={droupvalue === 'Completed'}
                              >
                                <SelectTag
                                  menuIsOpen={false}
                                  createstage
                                  Deletecomponent
                                  onDeleteoption={onDeleteoption}
                                  onInputChange={onCreatestage}
                                  value={transformedData?.find(
                                    (e) => e?.label === droupvalue,
                                  )}
                                  isCreate
                                  isSearchable={true}
                                  isDisabled={droupvalue === 'Completed'}
                                  options={transformedData}
                                  placeholder="Please select the stages"
                                  onChange={(e) => handlepipelinestages(e)}
                                />
                              </Flex>
                            </Flex>
                          )}
                          <Flex>
                            {!isdublicateerror && ischaractercheck && (
                              <Text color="error">
                                Stage status should not exceed 25 characters
                              </Text>
                            )}
                            {isdublicateerror && (
                              <Text color="error">
                                Stage name already exists.
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      ) : (
                        <Flex>
                          {!matchLoader &&
                            !isLoading &&
                            isapplicantpipeline !== undefined && (
                              <>
                                {stages.length !== 0 && (
                                  <Flex
                                    row
                                    style={{
                                      border: '1px solid #581845',
                                      borderRadius: '4px',
                                    }}
                                    center
                                  >
                                    <Flex
                                      center
                                      style={{
                                        color: '#581845',
                                        padding: '0px 10px',
                                      }}
                                    >
                                      Applicant Status
                                    </Flex>
                                    <Flex
                                      width={215}
                                      disabled={droupvalue === 'Completed'}
                                    >
                                      <SelectTag
                                        createstage
                                        Deletecomponent
                                        onDeleteoption={onDeleteoption}
                                        onInputChange={onCreatestage}
                                        value={transformedData?.find(
                                          (e) => e?.label === droupvalue,
                                        )}
                                        isCreate
                                        isSearchable={true}
                                        isDisabled={droupvalue === 'Completed'}
                                        options={transformedData}
                                        placeholder="Please select the stages"
                                        onChange={(e) =>
                                          handlepipelinestages(e)
                                        }
                                      />
                                    </Flex>
                                  </Flex>
                                )}
                              </>
                            )}
                        </Flex>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Flex row center>
                  <Flex marginTop={2}>
                    <SvgJobselection width={16} height={14} />
                  </Flex>
                  <Flex marginLeft={4}>
                    {toCamelCase(jd?.job_title)} - {jd?.job_id}
                  </Flex>
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex flex={4}></Flex>
          )}
          <Flex flex={4}>
            {!matchLoader && !isLoading && isapplicantpipeline !== undefined && (
              <>
                {isapplicantpipeline === 'true' ? (
                  <Flex row end>
                    {ispreviousdata !== undefined && (
                      <Flex
                        onClick={() => {
                          if (filteredcolumnindex !== 0) {
                            setshow(true);
                            cancel();
                            togglebutton(0);
                            datakey();
                            buttonchange(0);
                          }
                        }}
                        disabled={filteredcolumnindex === 0}
                        style={{ cursor: 'pointer' }}
                      >
                        <Flex row>
                          <Flex
                            marginRight={5}
                            marginTop={4}
                            style={{ cursor: 'pointer' }}
                          >
                            <SvgArrowLeft />
                          </Flex>
                          <Flex>
                            <Flex row style={{ cursor: 'pointer' }}>
                              <Text
                                bold
                                color="theme"
                                style={{ cursor: 'pointer', display: 'flex' }}
                              >
                                Prev:
                                <Flex
                                  title={`${
                                    ispreviousdata && ispreviousdata?.first_name
                                  } ${
                                    ispreviousdata &&
                                    ispreviousdata?.last_name !== null
                                      ? ispreviousdata?.last_name
                                      : ''
                                  }`}
                                >
                                  <Text
                                    className={styles.trucatetextmane}
                                    color="theme"
                                    style={{
                                      cursor: 'pointer',
                                      marginLeft: 2.5,
                                    }}
                                  >
                                    {`${
                                      ispreviousdata &&
                                      ispreviousdata?.first_name
                                    } ${
                                      ispreviousdata &&
                                      ispreviousdata?.last_name !== null
                                        ? ispreviousdata?.last_name
                                        : ''
                                    }`}
                                  </Text>
                                </Flex>
                              </Text>
                            </Flex>
                            <Flex row center>
                              <Flex
                                title={
                                  stages &&
                                  stages[stages?.length - 1]
                                    ?.stage_id__stage_name
                                }
                              >
                                <Text className={styles.trucatetext}>
                                  {stages &&
                                    stages[stages?.length - 1]
                                      ?.stage_id__stage_name}
                                </Text>
                              </Flex>
                              {ispreviousdata &&
                                ispreviousdata?.current_stage !== null && (
                                  <>
                                    <Flex className={styles.dotstyle}></Flex>
                                    <Flex
                                      title={
                                        ispreviousdata &&
                                        ispreviousdata?.current_stage
                                      }
                                    >
                                      <Text className={styles.trucatetext}>
                                        {ispreviousdata &&
                                          ispreviousdata?.current_stage}
                                      </Text>
                                    </Flex>
                                  </>
                                )}
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                    {ispreviousdata !== undefined && isnextdata !== undefined && (
                      <Flex
                        style={{
                          border: '1px solid #581845',
                          width: '.7px',
                          height: '35px',
                        }}
                        marginLeft={10}
                        marginRight={10}
                      ></Flex>
                    )}
                    {isnextdata !== undefined && (
                      <Flex
                        onClick={() => {
                          if (filteredcolumnindex + 1 !== filteredcolumn) {
                            setshow(true);
                            cancel();
                            togglebutton(1);
                            datakey();
                            buttonchange(1);
                          }
                        }}
                        disabled={filteredcolumnindex + 1 === filteredcolumn}
                        style={{ cursor: 'pointer' }}
                      >
                        <Flex row>
                          <Flex>
                            <Flex row style={{ cursor: 'pointer' }}>
                              <Text
                                bold
                                color="theme"
                                style={{ cursor: 'pointer', display: 'flex' }}
                              >
                                Next:
                                <Flex
                                  title={`${
                                    isnextdata && isnextdata?.first_name
                                  } ${
                                    isnextdata && isnextdata?.last_name !== null
                                      ? isnextdata?.last_name
                                      : ''
                                  }`}
                                >
                                  <Text
                                    className={styles.trucatetextmane}
                                    color="theme"
                                    style={{
                                      cursor: 'pointer',
                                      marginLeft: 2.5,
                                    }}
                                  >
                                    {`${isnextdata && isnextdata?.first_name} ${
                                      isnextdata &&
                                      isnextdata?.last_name !== null
                                        ? isnextdata?.last_name
                                        : ''
                                    }`}
                                  </Text>
                                </Flex>
                              </Text>
                            </Flex>
                            <Flex row center>
                              <Flex
                                title={
                                  stages &&
                                  stages[stages?.length - 1]
                                    ?.stage_id__stage_name
                                }
                              >
                                <Text className={styles.trucatetext}>
                                  {stages &&
                                    stages[stages?.length - 1]
                                      ?.stage_id__stage_name}
                                </Text>
                              </Flex>
                              {isnextdata &&
                                isnextdata?.current_stage !== null && (
                                  <>
                                    <Flex className={styles.dotstyle}></Flex>
                                    <Flex
                                      title={
                                        isnextdata && isnextdata?.current_stage
                                      }
                                    >
                                      <Text className={styles.trucatetext}>
                                        {isnextdata &&
                                          isnextdata?.current_stage}
                                      </Text>
                                    </Flex>
                                  </>
                                )}
                            </Flex>
                          </Flex>
                          <Flex
                            marginLeft={5}
                            marginTop={4}
                            style={{ cursor: 'pointer' }}
                          >
                            <SvgArrowRight />
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                ) : (
                  <LinkWrapper
                    target={'_blank'}
                    to={`/applicant_profile_view/${jobId}/${candidateId}`}
                  >
                    <Flex
                      row
                      end
                      center
                      className={'pointer'}
                      style={{ cursor: 'pointer' }}
                      marginTop={1}
                    >
                      {/* <Text color="link" className={styles.openStyle}>
                  Open profile in a new window
                </Text> */}
                      <SvgshareIcon width={18} height={18} />
                    </Flex>
                  </LinkWrapper>
                )}
              </>
            )}
          </Flex>
        </Flex>
        {/* <div className={styles.middle}></div> */}
        <div
          style={{
            paddingBottom: 16,
          }}
        >
          <ApplicantProfileModal
            setjobtitle={setjobtitle}
            jobId={jobId}
            candidateId={candidateId}
            inviteIconNone={inviteIconNone}
            activeState={activeState}
            isapplicantpipeline={isapplicantpipeline}
          />
        </div>
      </div>
    </Drawer>
  );
};
export default ProfileView;
