import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Card from '../../uikit/Card/Card';
import { Button } from '../../uikit';
import { isEmpty } from '../../uikit/helper';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import InputSwitch from '../../uikit/Switch/InputSwitch';
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import SvgRefresh from '../../icons/SvgRefresh';
import SvgIntomark from '../../icons/Intomark';
import SvgFilterRefresh from '../../icons/SvgFilterRefresh';
import SvgArrowUp from '../../icons/SvgArrowUp';
import { experienceOptionslinkedin } from '../talentsourcingmodule/mock';
import styles from './talentfilter.module.css';

//import styles1 from './../mydatabasemodule/switch.module.css';

type experienceOptionsType = {
  value: string;
  label: string;
};

const cx = classNames.bind(styles);

type Props = {
  isBachelors: boolean;
  isDoctorate: boolean;
  isMasters: boolean;
  isAny: boolean;
  apply: boolean;
  setapply: any;
  setBachelors: (arg: boolean) => void;
  setDoctorate: (arg: boolean) => void;
  setMasters: (arg: boolean) => void;
  setAny: (arg: boolean) => void;
  isRelocate: boolean;
  setRelocate: (arg: boolean) => void;
  isExperience: experienceOptionsType;
  setExperience: Dispatch<SetStateAction<experienceOptionsType>>;
  setInitialPage: (arg: number) => void;
  isOther: boolean;
  setOther: (arg: boolean) => void;
  isInitalCheckBox: boolean;
  handleRefresh: () => void;
  setchange?: any;
  filter_api?: () => void;
  qulification_filter?: (arg: boolean, arg1: string) => void;
  expirence_filter?: (arg: any) => void;
  filterclose?: (arg: any, arg1: any) => void;
  filter_reset?: () => void;
  newexperience?: any;
  setnewexperience?: any;
  NewBachelors1: boolean;
  setnewBachelors1: (arg: boolean) => void;
  Newmaster1: boolean;
  setnewmaster1: (arg: boolean) => void;
  NewDoctorate1: boolean;
  setnewDoctorate1: (arg: boolean) => void;
  Newothers1: boolean;
  setnewothers1: (arg: boolean) => void;
  newrelocate: boolean;
  setnewrelocate: (arg: boolean) => void;
};
const LinkedFilter = ({
  isBachelors,
  isDoctorate,
  isMasters,
  isAny,
  setBachelors,
  setDoctorate,
  setMasters,
  setAny,
  isRelocate,
  setRelocate,
  isExperience,
  setExperience,
  setInitialPage,
  isOther,
  setOther,
  apply,
  setapply,
  isInitalCheckBox,
  setchange,
  handleRefresh,
  filter_api,
  qulification_filter,
  expirence_filter,
  filterclose,
  filter_reset,
  newexperience,
  setnewexperience,
  NewBachelors1,
  setnewBachelors1,
  Newmaster1,
  setnewmaster1,
  NewDoctorate1,
  setnewDoctorate1,
  Newothers1,
  setnewothers1,
  newrelocate,
  setnewrelocate,
}: Props) => {
  const handleBachelor = (e) => {
    if (e.target.checked) {
      qulification_filter(true, 'Bachelor');
    } else {
      qulification_filter(false, 'Bachelor');
    }
    setBachelors(!isBachelors);
    setAny(false);
    setchange(true);
    setInitialPage(0);
  };
  const handleDoctorate = (e) => {
    if (e.target.checked) {
      qulification_filter(true, 'Doctorate');
    } else {
      qulification_filter(false, 'Doctorate');
    }
    setDoctorate(!isDoctorate);
    setAny(false);
    setchange(true);
    setInitialPage(0);
  };
  const handleMaster = (e) => {
    if (e.target.checked) {
      qulification_filter(true, 'Master');
    } else {
      qulification_filter(false, 'Master');
    }
    setMasters(!isMasters);
    setAny(false);
    setchange(true);
    setInitialPage(0);
  };
  const handleOther = (e) => {
    if (e.target.checked) {
      qulification_filter(true, 'Others');
    } else {
      qulification_filter(false, 'Others');
    }
    setAny(false);
    setOther(!isOther);
    setchange(true);
    setInitialPage(0);
  };
  const handleAny = (e) => {
    if (e.target.checked) {
      qulification_filter(true, 'Any');
    }
    setAny(!isAny);
    setBachelors(false);
    setDoctorate(false);
    setMasters(false);
    setOther(false);
    setchange(true);
    setInitialPage(0);
  };
  const filterRefresh = () => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.delete('isExperience');
    currentParams.delete('isBachelors');
    currentParams.delete('isDoctorate');
    currentParams.delete('isMasters');

    history.push({
      pathname: window.location.pathname,
      search: currentParams.toString(),
    });
    handleRefresh();
    setRelocate(false);
    setnewexperience(null);
    setnewBachelors1(false);
    setnewmaster1(false);
    setnewDoctorate1(false);
    setnewothers1(false);
    setnewrelocate(false);
    filter_reset();
  };
  const history = useHistory();
  const selectInputRef = useRef<any>();
  const myRef = useRef<any>();
  const dropDownRef = useRef(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [apply1, setapply1] = useState(false);

  useEffect(() => {
    if (!isBachelors && !isDoctorate && !isMasters && !isOther) {
      setAny(true);
    }
  }, [isBachelors, isDoctorate, isMasters, isOther]);

  // delete the params from the url
  const deleteparams = (val: string) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.delete(val);
    history.push({
      pathname: window.location.pathname,
      search: currentParams.toString(),
    });
  };

  const closebachelor = () => {
    deleteparams('isBachelors');
    filterclose(0, 'Bachelor');
    setBachelors(false);
    setnewBachelors1(false);
    setchange(false);
  };
  const closedoctorate = () => {
    deleteparams('isDoctorate');
    filterclose(0, 'Doctorate');
    setDoctorate(false);
    setnewDoctorate1(false);
    setchange(false);
  };
  const closemasters = () => {
    deleteparams('isMasters');
    filterclose(0, 'Masters');
    setMasters(false);
    setnewmaster1(false);
    setchange(false);
  };
  const closeother = () => {
    filterclose(0, 'Others');
    setOther(false);
    setnewothers1(false);
    setchange(false);
  };
  const closerelocate = () => {
    setRelocate(false);
    setnewrelocate(false);
    setchange(false);
  };
  const close = (val) => {
    deleteparams('isExperience');
    filterclose(1, val);
    setExperience(experienceOptionslinkedin[0]);
    setnewexperience(null);
    setchange(false);
  };
  const handlechange = () => {
    const currentParams = new URLSearchParams(window.location.search);
    deleteparams('pagination');
    if (!isEmpty(isExperience)) {
      currentParams.set('isExperience', JSON.stringify(isExperience));
    } else {
      currentParams.delete('isExperience');
    }
    if (isBachelors === true) {
      currentParams.set('isBachelors', JSON.stringify(isBachelors));
    } else {
      currentParams.delete('isBachelors');
    }
    if (isDoctorate === true) {
      currentParams.set('isDoctorate', JSON.stringify(isDoctorate));
    } else {
      currentParams.delete('isDoctorate');
    }
    if (isMasters === true) {
      currentParams.set('isMasters', JSON.stringify(isMasters));
    } else {
      currentParams.delete('isMasters');
    }
    currentParams.delete('pagination');
    history.push({
      pathname: window.location.pathname,
      search: currentParams.toString(),
    });
    deleteparams('pagination');
    setapply(true);
    setShowDropDown(false);
    setapply1(true);
    setnewBachelors1(isBachelors);
    setnewDoctorate1(isDoctorate);
    setnewmaster1(isMasters);
    setnewothers1(isOther);
    setnewexperience(isExperience);
    setnewrelocate(isRelocate);
    setchange(false);
    filter_api();
  };
  const changeexperience = () => {
    setchange(true);
  };
  const handlerelocate = () => {
    setRelocate(!isRelocate);
    setchange(true);
  };

  const selectexpirence = (value) => {
    expirence_filter(value);
    setExperience(value);
    setchange(true);
  };
  const experience1 =
    (newexperience && newexperience.value === 'all') || newexperience === null;

  return (
    <Flex row between>
      <Flex row>
        <Text className={''} style={{ marginTop: '3px' }}>
          Applied Filters :
        </Text>
        {!NewBachelors1 &&
        !NewDoctorate1 &&
        !Newmaster1 &&
        !Newothers1 &&
        !newrelocate &&
        experience1 ? (
          <Text className={styles.quickfil} style={{ cursor: 'default' }}>
            {'All'}
          </Text>
        ) : null}
        {newexperience !== null ? (
          newexperience.value !== 'all' ? (
            <Text className={styles.quickfil}>
              {isExperience.label}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={() => close(newexperience.value)}
                style={{ cursor: 'pointer' }}
              />
            </Text>
          ) : null
        ) : null}

        {NewBachelors1 ? (
          <Text className={styles.quickfil}>
            {'Bachelors'}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closebachelor()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : null}
        {NewDoctorate1 ? (
          <Text className={styles.quickfil}>
            {'Doctorate'}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closedoctorate()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : null}

        {Newmaster1 ? (
          <Text className={styles.quickfil}>
            {'Masters'}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closemasters()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : null}
        {Newothers1 ? (
          <Text className={styles.quickfil}>
            {'Other'}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closeother()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : null}
        {newrelocate ? (
          <Text className={styles.quickfil}>
            {'Willing to Relocate'}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closerelocate()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : null}
      </Flex>

      <div ref={dropDownRef} className={styles.drop_down} style={{ zIndex: 1 }}>
        <Flex
          row
          className={styles.drop_down_header}
          style={{ cursor: 'pointer' }}
        >
          <Flex
            style={{ width: '90%', cursor: 'pointer' }}
            onClick={() => {
              setShowDropDown((value) => !value);
            }}
          >
            <Flex row center style={{ cursor: 'pointer' }}>
              <Text
                bold
                className={styles.filtername}
                style={{ cursor: 'Pointer', paddingTop: 6, fontSize: 13 }}
              >
                {showDropDown ? 'Hide Filter' : 'View Filter'}
              </Text>

              <Flex
                center
                style={{ cursor: 'pointer', margin: '3px 0  0 5px ' }}
              >
                <SvgArrowUp
                  width={10}
                  height={10}
                  strokeWidth={25}
                  viewBox="0 0 380 380"
                  style={{
                    transform: showDropDown ? 'scaleY(-1)' : 'none',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </Flex>
            </Flex>
          </Flex>

          <Flex title={'Clear Filters'} style={{ cursor: 'pointer' }}>
            <SvgFilterRefresh
              width={16}
              height={16}
              onClick={filterRefresh}
              className={styles.filtersvg}
            />
          </Flex>
        </Flex>
        <div
          className={`${styles.drop_down_menus} ${
            showDropDown ? styles.show : ''
          }`}
        >
          <Flex className={styles.mtstyle}>
            <Text
              type="titleSmall"
              bold
              style={{ marginBottom: '2px', fontSize: 13 }}
            >
              Experience
            </Text>
            <SelectTag
              id={'talentfilter__experienceId'}
              defaultValue={{
                value: isExperience.value,
                label: isExperience.label,
              }}
              value={
                experienceOptionslinkedin
                  ? experienceOptionslinkedin.find(
                      (option) => option.value === isExperience.value,
                    )
                  : ''
              }
              labelBold
              options={experienceOptionslinkedin}
              onChange={(value) => selectexpirence(value)}
              isDisabled={isInitalCheckBox}
            />
          </Flex>

          <Flex className={styles.mtstyle}>
            <Flex className={styles.skillContainer}>
              <Text
                type="titleSmall"
                bold
                style={{ marginBottom: '2px', fontSize: 13 }}
              >
                Qualification
              </Text>
              <Flex row top className={cx('checkBoxContainer')}>
                <Flex className={cx('checkBoxContainerOne')}>
                  <div className={cx('checkBoxOne')}>
                    <InputCheckBox
                      checked={isBachelors}
                      label={'Bachelors'}
                      onChange={(e) => handleBachelor(e)}
                      disabled={isInitalCheckBox}
                    />
                  </div>
                  <InputCheckBox
                    onChange={(e) => handleDoctorate(e)}
                    checked={isDoctorate}
                    label="Doctorate"
                    disabled={isInitalCheckBox}
                  />
                  <CustomTooltip title="Not Applicable" tooltip_distance={2}>
                    <div style={{ paddingTop: 3, opacity: '0.5' }}>
                      <InputCheckBox
                        label={'Others'}
                        // checked={isOther}
                        // onChange={(e) => handleOther(e)}
                        disabled={true}
                      />
                    </div>
                  </CustomTooltip>
                </Flex>
                <Flex style={{ marginLeft: '14px' }}>
                  <div className={cx('checkBoxOne')}>
                    <InputCheckBox
                      onChange={(e) => handleMaster(e)}
                      checked={isMasters}
                      label="Masters"
                      disabled={isInitalCheckBox}
                    />
                  </div>
                  <InputCheckBox
                    onChange={(e) => handleAny(e)}
                    checked={isAny}
                    label="Any"
                    disabled={isAny}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <div className={styles.linkedin_filter}>
            {/* <Flex row>
              <Flex>
                <InputSwitch
                  checked={isRelocate}
                  onClick={handlerelocate}
                  disabled={isInitalCheckBox}
                />
              </Flex>
              <Text style={{ marginLeft: '5px' }}>Willing to Relocate</Text>
            </Flex> */}
          </div>
          <Flex
            row
            style={{
              padding: '6px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Flex marginRight={10}>
              <Button types="secondary" onClick={filterRefresh}>
                Reset
              </Button>
            </Flex>
            <Flex>
              <Button className={styles.buyBtn} onClick={handlechange}>
                Apply
              </Button>
            </Flex>
          </Flex>
        </div>
      </div>
    </Flex>
  );
};

export default LinkedFilter;
