import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { AppDispatch, RootState } from '../../store';
import { Flex, Text, Modal, Button, Card, LinkWrapper } from '../../uikit';
import Loader from '../../uikit/Loader/Loader';
import SvgClose from '../../icons/SvgClose';
import SvgTick from '../../icons/SvgTick';
import { SUCCESS } from '../../uikit/Colors/colors';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import styles from '../subscriptionmodule/subscriptionscreen.module.css';
import {
  backendProcessMiddleWare,
  creditsPurchaseMiddleWare,
  orderSummaryMiddleWare,
  orderSummaryPostMiddleWare,
} from '../ordersummarymodule/store/ordersummarymiddleware';
import {
  createCheckoutMiddleWare,
  stripeMiddleWare,
} from '../talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import {
  createCheckoutSubscriptionMiddleWare,
  manageSubscriptionMiddleWare,
} from '../accountsettingsmodule/managesubscription/store/managesubscriptionmiddleware';
import { isEmpty } from '../../uikit/helper';
import { permissionMiddleWare } from '../Login/store/middleware/loginMiddleWare';
import Svgsubcriptiondoller from '../../icons/Svgsubscriptiondoller';
import Svgsubcriptionrefresh from '../../icons/Svgsubscriptionrefresh';
import Svgsubcriptionduration from '../../icons/Svgsubscriptionduration';
type Props = {
  currentplan?: any;
  nextplan?: any;
  openmodel: boolean;
  setopensubcription: (a: any) => void;
  addon_name?: any;
  differece_key?: any;
  purpose?: any;
  purpose_freetrial?: any;
  purpose_addon?: any;
  handlefunction?: any;
};
const SubscriptionModal = ({
  currentplan,
  nextplan,
  openmodel,
  setopensubcription,
  addon_name,
  differece_key,
  purpose,
  purpose_freetrial,
  purpose_addon,
  handlefunction,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [oldplan, setoldplan] = useState(null);
  const [Nextplan, setnextplan] = useState(null);
  const [filteredifference, setfilteredifference] = useState<any>();
  const [selectedValue, setSelectedValue] = useState(1);
  const history = useHistory();

  useEffect(() => {
    dispatch(SubsriptionMiddleWare());
  }, []);

  const {
    current_plan,
    current_jd_count,
    total_plan,
    isLoading,
    add_on_plans,
    Last_plan,
    differences,
    super_user,
    client_url,
  } = useSelector(
    ({ SubscriptionReducers, permissionReducers }: RootState) => ({
      current_plan: SubscriptionReducers.current_plan,
      current_jd_count: SubscriptionReducers.current_jd_count,
      total_plan: SubscriptionReducers.total_plan,
      isLoading: SubscriptionReducers.isLoading,
      add_on_plans: SubscriptionReducers.add_on_plans,
      Last_plan: SubscriptionReducers.Last_plan,
      differences: SubscriptionReducers.differences,
      super_user: permissionReducers.super_user,
      client_url: permissionReducers.client_url,
    }),
  );

  // Getting current plan and next plan
  useEffect(() => {
    const found = total_plan?.find(
      (plan) =>
        plan?.plan_id ===
        (current_plan === 1 ? total_plan[1]?.plan_id : current_plan),
    );
    setoldplan(found);
    const founds = total_plan?.find(
      (plan) =>
        plan?.plan_id ===
        (current_plan === 1 ? total_plan[1]?.plan_id + 1 : current_plan + 1),
    );
    setnextplan(founds);
  }, []);

  // Filtering data based on perticular future
  function filterAndMapByKey(key) {
    const items = JSON.parse(differences?.toString())[0][key] || [];

    return { items };
  }

  // getting props for plan difference based on perticular future
  const key = differece_key;

  // seperating the data based on standerd and primium.
  const { items } = filterAndMapByKey(key);

  // createCheckoutMiddleWare  middleware for add=on purchasing
  const url = window.location.href;
  const handleSubmit = (val) => {
    window.onbeforeunload = null;
    if (url?.toLowerCase()?.includes('calendar')) {
      localStorage?.setItem('return', 'true');
    }
    setLoader(true);
    dispatch(
      createCheckoutMiddleWare({
        can_count: JSON.stringify([selectedValue.toString()]),
        amount: [val.price],
        back_url: [
          url.startsWith(client_url) ? url.substring(client_url?.length) : url,
        ],
        plan: JSON.stringify([val?.stripe_id]),
        add_on: JSON.stringify([val?.addon_id__id]),
      }),
    ).then((response) => {
      dispatch(stripeMiddleWare()).then(async (stripRes) => {
        const stripe = await loadStripe(stripRes?.payload?.publicKey);
        if (response.payload.sessionId) {
          stripe
            ?.redirectToCheckout({
              sessionId: response?.payload?.sessionId,
            })
            .then((resp) => {
              setLoader(false);
            });
        }
      });
    });
  };

  // handleclick for plan purchasing direct while in free trial
  const handlepurchase = (val) => {
    dispatch(
      createCheckoutSubscriptionMiddleWare({
        plan: JSON.stringify([val?.stripe_id]),
        count: JSON.stringify(['1']),
        plan_name: JSON.stringify([val?.plan_id?.toString()]),
        back_url: [
          url.startsWith(client_url) ? url?.substring(client_url?.length) : url,
        ],
      }),
    ).then((response) => {
      dispatch(stripeMiddleWare()).then(async (stripRes) => {
        const stripe = await loadStripe(stripRes?.payload?.publicKey);
        if (response?.payload?.sessionId) {
          stripe
            ?.redirectToCheckout({
              sessionId: response?.payload?.sessionId,
            })
            .then(() => {
              setLoader(false);
            });
        }
      });
    });
  };

  //this will option for count of purchansing
  const generateOptions = (step) => {
    const val = step * 12;
    const options = [];

    for (let j = step; j <= val; j += step) {
      options?.push(j);
    }
    return options?.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  };

  // Handlechange for while choosing the add-on count
  const handleChange = (e, step) => {
    const selectedOption = parseInt(e.target.value, 10);
    const count = selectedOption / step;
    setSelectedValue(count);
  };
  const planid =
    add_on_plans &&
    add_on_plans
      ?.filter((item) => addon_name?.includes(item?.addon_id__name))
      .map((item) => item.addon_id__id);

  const stipids2 =
    add_on_plans &&
    add_on_plans
      ?.filter((item) => addon_name?.includes(item?.addon_id__name))
      .map((item) => item.stripe_id);
  //this is for storing localstoreage
  const setlocal = () => {
    localStorage.setItem('add_on_id', JSON.stringify(planid));
    localStorage.setItem('add_on_count', JSON.stringify([selectedValue]));
    localStorage.setItem('stipids', JSON.stringify(stipids2));
  };
  //this function is for navigattion
  const navigatetoordersummery = () => {
    const queryParams = `?key=${0}&count=1&current=${false}`;
    // history.push(`/order_summary${queryParams}`);
    window.location.assign(
      window.location.origin + `/order_summary${queryParams}`,
    );
  };

  const handleNavigate = () => {
    const addOnIdExists = localStorage.getItem('add_on_id') !== null || undefined;
  const addOnCountExists = localStorage.getItem('add_on_count') !== null||undefined;
  const stipidsExists = localStorage.getItem('stipids') !== null||undefined;
  if (addOnIdExists && addOnCountExists && stipidsExists) {
    localStorage.setItem('add_on_id', JSON.stringify([]));
    localStorage.setItem('add_on_count', JSON.stringify([]));
    localStorage.setItem('stipids', JSON.stringify([]));
  }
    const queryParams = `?key=${Nextplan && Nextplan.plan_id}&count=1&current=true`
    window.location.assign(
      window.location.origin + `/order_summary${queryParams}`,
    );
  };
 

  // Loading whilte triggering the add-on purchasing stripe API's
  if (isLoader) {
    return <Loader />;
  }
  const validfunction = () => {
    setopensubcription(false);
    if (url?.toLowerCase()?.includes('calendar')) {
      handlefunction();
    }
  };

  return (
    <>
      <Modal open={openmodel}>
        {super_user === true ? (
          <Flex className={styles.subscriptionmodule}>
            <Flex row between className={styles.bottomborder}>
              <Flex marginBottom={5}>
                {current_plan === 1 ? (
                  <Text bold size={14}>
                    Upgrade your plan
                  </Text>
                ) : (
                  <Text bold size={14}>
                    {!Last_plan.toString().includes(current_plan.toString())
                      ? 'Upgrade your plan'
                      : 'Avail Additional Add-on Feature'}
                  </Text>
                )}
              </Flex>
              <Flex
                onClick={() => validfunction()}
                style={{ cursor: 'pointer' }}
              >
                <SvgClose
                  width={10}
                  height={10}
                  fill={'#888888'}
                  cursor={'pointer'}
                />
              </Flex>
            </Flex>

            {/* showing the text for add-on purchasing.they show only which add-on is count. */}
            {!Last_plan.includes(current_plan) ? (
              <>
                {current_plan !== 1 && purpose && (
                  <Flex
                    row
                    center
                    className={styles.warningFlex}
                    marginTop={13}
                  >
                    <Text
                      style={{ color: '#333333', textAlign: 'justify' }}
                      className={styles.warningText}
                    >
                      {purpose}
                    </Text>
                  </Flex>
                )}
                {current_plan === 1 && purpose_freetrial && (
                  <Flex marginTop={13}>
                    <Text style={{ textAlign: 'justify' }}>
                      <td>{purpose_freetrial}</td>
                    </Text>
                  </Flex>
                )}
              </>
            ) : (
              <Flex marginTop={13}>
                <Text style={{ textAlign: 'justify' }}>
                  <td>{purpose_addon}</td>
                </Text>
              </Flex>
            )}

            {/* current plan is a free trial this comparition table will show for upgrade a plan. */}
            {current_plan === 1 && oldplan !== null && Nextplan !== null && (
              <Flex className={styles.container} flex={12}>
                <Flex
                  style={{
                    border: '1px solid #c3c3c3',
                    borderRadius: '5px 5px 5px 5px',
                  }}
                >
                  <Flex
                    row
                    style={{
                      backgroundColor: '#D7C7D2',
                      padding: '5px 0px',
                      borderRadius: '0px 4px 0px 0px',
                    }}
                  >
                    <Flex flex={7}></Flex>
                    <Flex flex={2.5} middle center>
                      <Flex middle center style={{ display: 'flex' }}>
                        <Text color="theme">{oldplan.plan_name}</Text>
                        <Text>
                          (
                          <span style={{ fontWeight: 'bold' }}>
                            ${oldplan.price}
                          </span>
                          /month)
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex flex={2.5} middle center>
                      <Flex middle center style={{ display: 'flex' }}>
                        <Text color="theme">{Nextplan.plan_name}</Text>
                        <Text>
                          (
                          <span style={{ fontWeight: 'bold' }}>
                            ${Nextplan.price}
                          </span>
                          /month)
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex>
                    {items.map((data, ind) => (
                      <Flex
                        key={ind}
                        style={{
                          borderBottom:
                            ind === items.length - 1 ? '' : '1px solid #ccc',
                          padding: '5px 0px',
                        }}
                        row
                        flex={12}
                      >
                        <Flex flex={7} marginLeft={6}>
                          <Text size={13}>{data.title}</Text>
                        </Flex>
                        <Flex flex={2.5} middle center>
                          {data.standard === true ? (
                            <Flex
                              marginTop={1.7}
                              middle
                              center
                              style={{ display: 'flex' }}
                            >
                              <SvgTick fill={SUCCESS} />
                            </Flex>
                          ) : (
                            <Flex middle center style={{ display: 'flex' }}>
                              <Text size={13}>{data.standard}</Text>
                            </Flex>
                          )}
                        </Flex>
                        <Flex flex={2.5} middle center>
                          {data.premium === true ? (
                            <Flex
                              marginTop={1.7}
                              middle
                              center
                              style={{ display: 'flex' }}
                            >
                              <SvgTick fill={SUCCESS} />
                            </Flex>
                          ) : (
                            <Flex middle center style={{ display: 'flex' }}>
                              <Text size={13}>{data.premium}</Text>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
                <Flex row>
                  <Flex flex={7}></Flex>
                  <Flex
                    onClick={() => handlepurchase(oldplan)}
                    flex={2.5}
                    middle
                    center
                    marginTop={10}
                  >
                    <Button>Upgrade</Button>
                  </Flex>
                  <Flex
                    onClick={() => handlepurchase(Nextplan)}
                    flex={2.5}
                    middle
                    center
                    marginTop={10}
                  >
                    <Button>Upgrade</Button>
                  </Flex>
                </Flex>
              </Flex>
            )}

            {/* current plan is not a free trial this comparition table will show for upgrade a plan. */}
            {current_plan !== 1 &&
              !Last_plan.toString().includes(current_plan.toString()) &&
              oldplan !== null &&
              Nextplan !== null && (
                <Flex className={styles.container} flex={12}>
                  <Flex
                    style={{
                      border: '1px solid #c3c3c3',
                      borderRadius: '5px 5px 5px 5px',
                    }}
                  >
                    <Flex
                      row
                      style={{
                        backgroundColor: '#D7C7D2',
                        padding: '5px 0px',
                        borderRadius: '0px 4px 0px 0px',
                      }}
                    >
                      <Flex flex={7}></Flex>
                      <Flex flex={2.5} middle center>
                        <Flex middle center style={{ display: 'flex' }}>
                          <Text color="theme">{oldplan.plan_name}</Text>
                          <Text>
                            (
                            <span style={{ fontWeight: 'bold' }}>
                              ${oldplan.price}
                            </span>
                            /month)
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex flex={2.5} middle center>
                        <Flex middle center style={{ display: 'flex' }}>
                          <Text color="theme">{Nextplan.plan_name}</Text>
                          <Text>
                            (
                            <span style={{ fontWeight: 'bold' }}>
                              ${Nextplan.price}
                            </span>
                            /month)
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex>
                      {items.map((data, ind) => (
                        <Flex
                          key={ind}
                          style={{
                            borderBottom:
                              ind === items.length - 1 ? '' : '1px solid #ccc',
                            padding: '5px 0px',
                          }}
                          row
                          flex={12}
                        >
                          <Flex flex={7} marginLeft={6}>
                            <Text size={13}>{data.title}</Text>
                          </Flex>
                          <Flex flex={2.5} middle center>
                            {data.standard === true ? (
                              <Flex
                                marginTop={1.7}
                                middle
                                center
                                style={{ display: 'flex' }}
                              >
                                <SvgTick fill={SUCCESS} />
                              </Flex>
                            ) : (
                              <Flex middle center style={{ display: 'flex' }}>
                                <Text size={13}>{data.standard}</Text>
                              </Flex>
                            )}
                          </Flex>
                          <Flex flex={2.5} middle center>
                            {data.premium === true ? (
                              <Flex
                                marginTop={1.7}
                                middle
                                center
                                style={{ display: 'flex' }}
                              >
                                <SvgTick fill={SUCCESS} />
                              </Flex>
                            ) : (
                              <Flex middle center style={{ display: 'flex' }}>
                                <Text size={13}>{data.premium}</Text>
                              </Flex>
                            )}
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                  <Flex row>
                    <Flex flex={7}></Flex>
                    <Flex disabled flex={2.5} middle center marginTop={10}>
                      <Button disabled>Current Plan</Button>
                    </Flex>
                    <Flex flex={2.5} middle center marginTop={10}>
                      {/* <LinkWrapper
                        to={`/order_summary?key=${Nextplan && Nextplan.plan_id
                          }&count=${'1'}&current=${true}`}
                      > */}
                      <Button onClick={handleNavigate}>Upgrade</Button>
                      {/* </LinkWrapper> */}
                    </Flex>
                  </Flex>
                </Flex>
              )}

            {/* current subscription have an add-on means shown the add-on */}
            {current_plan !== 1 &&
              !Last_plan.toString().includes(current_plan.toString()) &&
              add_on_plans &&
              add_on_plans?.filter((item) =>
                addon_name?.includes(item?.addon_id__name),
              ).length !== 0 && (
                <>
                  <Flex marginTop={15} height={20} row center>
                    <Flex
                      className={styles.line}
                      style={{ color: '#C3C3C3', backgroundColor: '#C3C3C3' }}
                    >
                      {' '}
                    </Flex>{' '}
                    <Text className={styles.line_OR}>
                      <Text style={{ color: '#C3C3C3', cursor: 'default' }}>
                        OR
                      </Text>
                    </Text>{' '}
                    <Flex
                      className={styles.line}
                      style={{ color: '#C3C3C3', backgroundColor: '#C3C3C3' }}
                    ></Flex>
                  </Flex>
                  <Text bold style={{ marginTop: '10px' }} size={14}>
                    Avail Additional Add-on Feature
                  </Text>
                </>
              )}
            {current_plan !== 1 &&
              add_on_plans &&
              add_on_plans
                ?.filter((item) => addon_name?.includes(item?.addon_id__name))
                .map((val, index) => (
                  <Flex marginTop={20} key={index}>
                    <Flex>
                      <Text color="theme" className={styles.addon}>
                        {val?.addon_id__name === 'Resume Unlock'
                          ? 'Sourcing Contact Unlock'
                          : val?.addon_id__name}
                      </Text>
                    </Flex>
                    <Card className={styles.cards}>
                      <Flex
                        className={styles.creditcontainer}
                        flex={12}
                        center
                        row
                      >
                        <Flex flex={7.5}>
                          <Text>
                            {val !== undefined
                              ? JSON.parse(val?.addon_id__display_name)
                              : ''}
                          </Text>
                        </Flex>
                        <Flex flex={2.5} marginLeft={20}>
                          <Flex
                            flex={3}
                            marginLeft={15}
                            row
                            center
                            marginBottom={10}
                          >
                            <Svgsubcriptiondoller width={14} height={14} />
                            <Flex marginLeft={4}>
                              {' '}
                              <Text bold>{val?.price}</Text>{' '}
                            </Flex>
                          </Flex>
                          {val?.usage.toString() !== 'unlimited' ? (
                            <Flex flex={3} marginLeft={15} row center>
                              <Flex>
                                <Svgsubcriptionrefresh width={12} height={12} />
                              </Flex>
                              <Flex marginLeft={6}>
                                {' '}
                                <Text bold>Rolling</Text>
                              </Flex>
                            </Flex>
                          ) : (
                            <Flex flex={3} marginLeft={15} row center>
                              <Flex>
                                <Svgsubcriptionduration
                                  width={12}
                                  height={12}
                                />
                              </Flex>
                              <Flex marginLeft={6}>
                                {' '}
                                <Text bold>30 Days Validity</Text>
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                        {val?.usage.toString() !== 'unlimited' && (
                          <Flex flex={2.5}>
                            <Flex marginBottom={10}>
                              <Text color="theme">{val?.addon_id__name}</Text>
                            </Flex>
                            <Flex width={40} row>
                              <Text style={{ marginRight: 10 }}>Quantity</Text>
                              <select
                                name="numberSelect"
                                id="numberSelect"
                                onChange={(e) =>
                                  handleChange(e, Number(val?.usage))
                                }
                              >
                                {generateOptions(Number(val?.usage))}
                              </select>
                            </Flex>
                          </Flex>
                        )}
                        <Flex
                          flex={2}
                          end
                          marginRight={10}
                          onClick={() => {
                            setlocal();
                            navigatetoordersummery();
                          }}
                        >
                          <Button>Buy</Button>
                        </Flex>
                      </Flex>
                    </Card>
                  </Flex>
                ))}
          </Flex>
        ) : (
          <Flex className={styles.overAll}>
            <Flex row center className={styles.title}>
              <Text className={styles.titleText}>
                Please contact your company admin to upgrade.
              </Text>
            </Flex>
            <Flex row center middle className={styles.btnContainer}>
              <Button onClick={() => setopensubcription(false)}>OK</Button>
            </Flex>
          </Flex>
        )}
      </Modal>
    </>
  );
};

export default SubscriptionModal;
