import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
// import { ref } from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import SvgIntomark from '../../icons/Intomark';

// import { Button, DropdownButton } from 'react-bootstrap';
import { Button, InputCheckBox, InputRadio, InputSwitch } from '../../uikit';
// import Dropdown from 'react-bootstrap';
import SvgRefresh from '../../icons/SvgRefresh';
import Flex from '../../uikit/Flex/Flex';
import { toCamelCase } from '../../uikit/helper';
import InputSearch from '../../uikit/InputSearch/InputSearch';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import Text from '../../uikit/Text/Text';
import SvgFilterRefresh from '../../icons/SvgFilterRefresh';
import SvgArrowUp from '../../icons/SvgArrowUp';
import { postedOn, jobTypeData } from './mock';
import styles from './myjobpostingfilter.module.css';


// import classNames from "classnames";
// import { Pointer } from "highcharts";
// import Placeholder from "react-select/dist/declarations/src/components/Placeholder";

// import { any } from 'prop-types';
export interface DateEntity {
  label: string;
  value: string;
  // placeholder:string;
}
export type MyJobFormProps = {
  jobId: string;
  postedOn: DateEntity;
  jobType: string;
  location: string;
  jobTitle: string;
};

type Props = {
  formik: FormikProps<MyJobFormProps>;
  location_list: string[];
  job_title: string[];
  job_ids: string[];
  setchange?: any;
  handleRelocate: () => void;
  remote: boolean;
  setremote: (item: any) => void;
  setPage?: any;
  issettingstate?: any;
  setsettingstate?: any;
  settriggerremote?: (a?: any) => void;
  istriggerremote?: boolean;
};

const MyJobsPostingFilter = ({
  formik,
  location_list,
  job_ids,
  job_title,
  setchange,
  remote,
  handleRelocate,
  setremote,
  setPage,
  issettingstate,
  setsettingstate,
  settriggerremote,
  istriggerremote,
}: Props) => {
  const [data, setdata] = useState('');
  const [done, setdone] = useState('');
  const [date, setdate] = useState('');
  const [Title, setTitle] = useState('');
  const [locationdata, setlocationdata] = useState('');

  const [jobid1, setjobid1] = useState('');
  const [jobstatus1, setjobstatus1] = useState('');
  const [jobposted1, setjobposted1] = useState('');
  const [jobtitle, setjobtitle] = useState('');
  const [location, setlocation] = useState('');
  const [isremotetoggle, setremotetoggle] = useState<any>();
  const inputRef = useRef<any>();
  const currentParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  const [remotetemp, setremotetemp] = useState(false);
  
  useEffect(() => {
    if (istriggerremote === false) {
      setremotetoggle(remote);
    }
  }, [remote, isremotetoggle]);

  const handletoggle = () => {
    setremotetoggle(!isremotetoggle);
  };

  const clearurlfilter=()=>{
    const paramsToClear = [
      'jobtitle',
      'jobid',
      'jobstatus',
      'location',
      'remort',
      'jobpostedon',
   
    ];
  
    // Remove each parameter from the URL
    paramsToClear.forEach(param => {
      if (currentParams.has(param)) { // Check if parameter exists in URL
        currentParams.delete(param);  // Delete it if it does
      }
    });
  
  
    // Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
    
    
  }

  const pageReload = () => {
    // location.reload( );
    setjobid1('');
    setlocation('');
    setjobstatus1('');
    setjobtitle('');
    setjobposted1('');
    setdata('');
    setdate('');
    setTitle('');
    setlocationdata('');
    formik.setFieldValue('jobTitle', '');
    formik.setFieldValue('jobId', '');
    formik.setFieldValue('postedOn', {
      value: '',
    });
    formik.setFieldValue('jobType', '');
    formik.setFieldValue('location', '');
    setremote(false);
    setremotetemp(false);
    formik.resetForm();
    setchange(false);
    clearurlfilter();
    inputRef.current.autowhatever.input.value = null;
  };
  useEffect(() => {
    if (formik.values.jobType === 'All') {
      setdata('');
      setdate('');
      setTitle('');
      setlocationdata('');
    }
  }, [formik.values.jobType]);
  useEffect(() => {
    if (formik.values.jobType === '1') {
      setdone('Active');
    } else if (formik.values.jobType === '4') {
      setdone('Inactive');
    } else if (formik.values.jobType === '2,5,6') {
      setdone('Draft');
    } else if (formik.values.jobType === '') {
      setdone('All');
      setdata('');
      setdate('');
      setTitle('');
      setlocationdata('');
    }

    if (formik.values.jobId !== '' && formik.values.jobType === '') {
      setdata(formik.values.jobId);
      // setdone("");
    } else {
      setdata(formik.values.jobId);
    }

    if (formik.values.postedOn.value === '1') {
      setdate('Last 24 hours');
    } else if (formik.values.postedOn.value === '3') {
      setdate('Last 3 days');
    } else if (formik.values.postedOn.value === '7') {
      setdate('Last 7 days');
    } else if (formik.values.postedOn.value === '14') {
      setdate('Last 14 days');
    } else if (formik.values.postedOn.value === '30') {
      setdate('Last 30 days');
    } else if (formik.values.postedOn.value === '90') {
      setdate('Last 90 days');
    } else if (formik.values.postedOn.value === '365') {
      setdate('Last year');
    } else if (formik.values.postedOn.value === '') {
      setdate('');
    }
    setTitle(formik.values.jobTitle);
    setlocationdata(formik.values.location);
  }, [
    formik.values.jobId,
    formik.values.jobType,
    formik.values.postedOn.value,
    formik.values.jobTitle,
    formik.values.location,
  ]);

  const [showDropDown, setShowDropDown] = useState(false);
  const dropDownRef = useRef(null);

  // const closeDropDown = (e: any) => {
  //   if (
  //     dropDownRef.current &&
  //     showDropDown &&
  //     !dropDownRef.current.contains(e.target)
  //   ) {
  //     setShowDropDown(false);

  //   }

  // };

  //    useEffect(() =>{
  //     if (typeof Window !== 'undefined') {
  //       document.addEventListener('click',closeDropDown);
  //     }
  //   })
  //   const onchek = localStorage.getItem('oncheck')
  //   useEffect(() => {
  //     if(onchek === 'false'){
  //     if (typeof Window !== 'undefined') {
  //       document.addEventListener('click', closeDropDown);
  //     }}
  //     else{

  //   }
  //     return () => {
  //       if (dropDownRef) {
  //         if (typeof Window === 'undefined') {
  //           document.removeEventListener('click',closeDropDown,false);
  //         }
  //       }
  //     };}
  //   );

  //  useEffect(()=>{
  // setShowDropDown(true)
  //  },[formik.values])

  const closestatus = () => {
    setdone('');
    setjobstatus1('');
    formik.setFieldValue('jobType', '');
    setchange(false);
    currentParams.delete('jobstatus'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading
  };
  const close = () => {
    setchange(false);
    setdata('');
    setjobid1('');
    formik.setFieldValue('jobId', '');
    currentParams.delete('jobid'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading
  };

  const closedate = () => {
    setchange(false);
    setdate('');
    setjobposted1('');
    formik.setFieldValue('postedOn', { value: '', label: 'All' });
    currentParams.delete('jobpostedon'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading
  };

  const closetitle = () => {
    setchange(false);
    setTitle('');
    setjobtitle('');
    formik.setFieldValue('jobTitle', '');
    currentParams.delete('jobtitle'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading
  };
  const closelocationdata = () => {
    setchange(false);
    setlocationdata('');
    setlocation('');
    formik.setFieldValue('location', '');
    currentParams.delete('location'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading
  };

  const closeremote = () => {
    setchange(false);
    setremote(false);
    setremotetemp(false);
    currentParams.delete('remort'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading
  };
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // };

  const [offset, setOffset] = useState(0);

  const urlsearch = useLocation();
  useEffect(() => {
    const jobtitleurl = currentParams.get('jobtitle');
    if (jobtitleurl) {
      setjobtitle(jobtitleurl);
      setTitle(jobtitleurl)
      formik.setFieldValue('jobTitle', jobtitleurl);
    }

    const jobidurl = currentParams.get('jobid');
    if (jobidurl) {
      setdata(jobidurl);
      setjobid1(jobidurl);
      formik.setFieldValue('jobId', jobidurl);
    }

    const jobstatusurl = currentParams.get('jobstatus');
    if (jobstatusurl) {
      setjobstatus1(jobstatusurl);
      setdone(jobstatusurl);
      if (jobstatusurl === 'Active') {
       formik.setFieldValue('jobType', '1')
      } else if (jobstatusurl === 'Inactive') {
        formik.setFieldValue('jobType', '4')
      } else if (jobstatusurl === 'Draft') {
        formik.setFieldValue('jobType', '2,5,6')
      } 
    }

    const dateurl = currentParams.get('jobpostedon');
    if(dateurl){
    const dateMapping = {
      'Last 24 hours': { value: '1', label: 'Last 24 hours' },
      'Last 3 days': { value: '3', label: 'Last 3 days' },
      'Last 7 days': { value: '7', label: 'Last 7 days' },
      'Last 14 days': { value: '14', label: 'Last 14 days' },
      'Last 30 days': { value: '30', label: 'Last 30 days' },
      'Last 90 days': { value: '90', label: 'Last 90 days' },
      'Last Year': { value: '365', label: 'Last Year' }
    };

    if (dateurl && dateMapping[dateurl] && formik.values.postedOn.label !== dateMapping[dateurl].label) {
      formik.setFieldValue('postedOn', dateMapping[dateurl]);
    }}

    const locationdataurl = currentParams.get('location');
    if (locationdataurl) {
      setlocation(locationdataurl);
      formik.setFieldValue('location',locationdataurl)
    }

    const remoteurl = currentParams.get('remort');
    if (remoteurl) {

      if(localStorage.getItem('remotetemp')==='true'){
      if(remoteurl==='false'){
      setremote(true);
      setremotetemp(true);}
      else{
        setremote(false);
       setremotetemp(false);
              }}
     
    }


  }, [urlsearch.search]);



  const handlechange = () => {
     // Set new parameters or update existing ones
     currentParams.set('jobtitle', Title);
     currentParams.set('jobid', data);
     currentParams.set('jobstatus', done);
     currentParams.set('jobpostedon', date);
     currentParams.set('location', locationdata);

    localStorage.setItem('remotetemp',String(remotetemp))
     currentParams.set('remort', String(remote));

   
     // Update the URL with modified search parameters
     history.push({
       pathname: window.location.pathname, // Keeps the current path
       search: currentParams.toString(),
     });
 
    setchange(false);
    setShowDropDown(false);
    setjobid1(data);
    setjobstatus1(done);
    setjobposted1(date);
    setlocation(locationdata);
    setjobtitle(Title);
    setremotetemp(remote);
    if (istriggerremote) {
      handleRelocate();
      settriggerremote(false);
    }
    setPage(0);
  };
  useEffect(() => {
    setchange(false);
    setShowDropDown(false);
    setjobid1(data);
    setjobstatus1(done);
    setjobposted1(date);
    setlocation(locationdata);
    setjobtitle(Title);
     setremotetemp(remote);
    setsettingstate(false);
  }, [issettingstate]);
  const handlechange1 = (event) => {
    formik.setFieldValue('jobId', event.target.value);
    setchange(true);
  };
  const handlechange2 = (event) => {
    formik.setFieldValue('jobTitle', event.target.value);
    setchange(true);
  };
  const handlechange3 = (event) => {
    const inputValue = event.target.value;
    if (inputValue?.length === 1 && inputValue[0] === ' ') {
      return;
    }
    formik.setFieldValue('location', inputValue);
    setchange(true);
  };

  // const MyDataBaseSearchAction = ({ jobTitle, formik,isSearchValue,setSearchValue }: Props) => {
  const isDefaultFilter = () => {
    if (
      location === '' &&
      jobid1 === '' &&
      jobtitle === '' &&
      jobposted1 === '' &&
      remotetemp === false &&
      (jobstatus1 === 'All' || jobstatus1 === '')
    ) {
      return true;
    }
    return false;
  };

  // onclick function for when click the cross icon they clearing the data in input field .
  const handleSearchClose = () => {
    formik.setFieldValue('location', '');
    setchange(true);
  };

  const handletitleClear = () => {
    formik.setFieldValue('jobTitle', '');
    setchange(true);
  };

  const handleIDClear = () => {
    formik.setFieldValue('jobId', '');
    setchange(true);
  };

  useEffect(()=>{
    
    localStorage.setItem('remotetemp',String(remotetemp))
  },[remotetemp])
  
  return (
    <Flex row style={{ justifyContent: 'space-between' }}>
      <Flex row className={styles.quickFilters}>
        <Text size={13} style={{ whiteSpace: 'nowrap', marginTop: '3px' }}>
          Applied Filters :
        </Text>
        {jobtitle !== '' ? (
          <Text className={styles.quickfil}>
            {toCamelCase(jobtitle)}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closetitle()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : (
          ''
        )}

        {jobid1 !== '' ? (
          <Text className={styles.quickfil}>
            {jobid1}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => close()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : (
          ' '
        )}
        {isDefaultFilter() ? (
          <Text className={styles.quickfil}>All</Text>
        ) : jobstatus1 !== 'All' && jobstatus1 !== '' ? (
          <Text className={styles.quickfil}>
            {' '}
            {jobstatus1}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closestatus()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : (
          ''
        )}

        {jobposted1 !== '' ? (
          <Text className={styles.quickfil}>
            {jobposted1}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closedate()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : (
          ' '
          // " "
        )}

        {location !== '' ? (
          <Text className={styles.quickfil}>
            {location}{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closelocationdata()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : (
          ''
        )}

        {remotetemp === true ? (
          <Text className={styles.quickfil}>
            Remote{' '}
            <SvgIntomark
              className={styles.stylesvg}
              onClick={() => closeremote()}
              style={{ cursor: 'pointer' }}
            />
          </Text>
        ) : (
          ''
        )}
      </Flex>

      <Flex>
        <div ref={dropDownRef} className={styles.drop_down}>
          <Flex
            row
            className={styles.drop_down_header}
            style={{ cursor: 'pointer' }}
          >
            <Flex
              style={{ width: '90%', cursor: 'pointer' }}
              onClick={() => {
                setShowDropDown((value) => !value);
              }}
              height={30}
              center
            >
              <Flex row center style={{ cursor: 'pointer' }}>
                <Text
                  bold
                  className={styles.filtername}
                  style={{
                    cursor: 'Pointer',
                    paddingTop: 5,
                    paddingBottom: 5,
                    fontSize: 13,
                  }}
                >
                  {showDropDown ? 'Hide Filters' : 'View Filters'}
                </Text>
                <Flex
                  center
                  style={{ cursor: 'pointer', margin: '2px 0 0 5px' }}
                >
                  <SvgArrowUp
                    width={10}
                    height={10}
                    strokeWidth={25}
                    viewBox="0 0 380 380"
                    style={{
                      transform: showDropDown ? 'scaleY(-1)' : 'none',
                      transition: 'transform 0.3s ease',
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex title={'Clear Filters'} style={{ cursor: 'pointer' }}>
              <SvgFilterRefresh
                width={16}
                height={16}
                onClick={pageReload}
                className={styles.filtersvg}
              />
            </Flex>
          </Flex>
          <div
            className={`${styles.drop_down_menus} ${
              showDropDown ? styles.show : ''
            }`}
          >
            <Flex className={styles.mtstyle}>
              <div>
                <Text className={styles.jobTextStyle}>Job Title</Text>

                <Flex
                  style={{ position: 'relative' }}
                  className={styles.hoverbox}
                >
                  <InputSearch
                    initialValue={toCamelCase(formik.values.jobTitle)}
                    setFieldValue={formik.setFieldValue}
                    options={job_title}
                    placeholder="Enter a job title"
                    style={styles.jobTitleBoxstyle}
                    name="jobTitle"
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (inputValue?.length === 1 && inputValue[0] === ' ') {
                        return;
                      }
                      formik.setFieldValue('jobTitle', event.target.value);
                      setchange(true);
                    }}
                  />
                  {formik.values.jobTitle !== '' && (
                    <>
                      <Flex
                        className={styles.jobTitleCloseIcon}
                        onClick={handletitleClear}
                      >
                        <SvgIntomark width={14} height={14} fill="#888888" />
                      </Flex>
                    </>
                  )}
                </Flex>
              </div>
            </Flex>
            <Flex className={styles.mtstyle}>
              <Text className={styles.jobTextStyle}>Job ID</Text>

              <Flex style={{ position: 'relative' }}>
                <InputSearch
                  style={styles.boxstyle}
                  initialValue={formik.values.jobId}
                  options={job_ids}
                  placeholder="Enter a job id"
                  // labelBold
                  setFieldValue={formik.setFieldValue}
                  inputRef={inputRef}
                  name="jobId"
                  // // eslint-disable-next-line jsx-a11y/no-autofocus
                  // autoFocus
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (inputValue?.length === 1 && inputValue[0] === ' ') {
                      return;
                    }
                    formik.setFieldValue('jobId', inputValue);
                    setchange(true);
                  }}
                />
                {formik.values.jobId !== '' && (
                  <>
                    <Flex
                      className={styles.jobTitleCloseIcon}
                      onClick={handleIDClear}
                    >
                      <SvgIntomark width={14} height={14} fill="#888888" />
                    </Flex>
                  </>
                )}
              </Flex>
            </Flex>

            <Flex className={styles.mtstyle}>
              <div className={styles.skillContainer}>
                <Text className={styles.jobTextStyle}>Job Status</Text>
                <Flex marginTop={5} className={styles.matchRadioStyling}>
                  {jobTypeData.map((jobList) => {
                    return (
                      <Flex
                        key={jobList.value}
                        width={jobList.width}
                        className={styles.matchRadioStyle}
                      >
                        <InputRadio
                          // className={styles.checkbox}
                          label={jobList.value}
                          checked={jobList.label === formik.values.jobType}
                          onClick={() => (
                            formik.setFieldValue('jobType', jobList.label),
                            setchange(true)
                          )}
                        />
                      </Flex>
                    );
                  })}
                </Flex>
              </div>
            </Flex>
            <Flex className={styles.mtstyle}>
              <div className={styles.inputmargin}>
                <Text className={styles.jobTextStyle}>Job posted on</Text>
                <div className={styles.selectoptions}>
                  <SelectTag
                    // linechange
                    value={
                      postedOn
                        ? postedOn.find(
                            (option) =>
                              option.value === formik.values.postedOn.value,
                          )
                        : ' '
                    }
                    options={postedOn}
                    onChange={(options) => {
                      formik.setFieldValue('postedOn', options);
                      setchange(true);
                    }}
                  />
                </div>
              </div>
            </Flex>

            <Flex className={styles.mtstyle}>
              <div>
                <Text className={styles.jobTextStyle}>Location</Text>
                <Flex>
                  <InputSearch
                    initialValue={formik.values.location}
                    placeholder="Enter job location"
                    options={location_list}
                    CrossiconSpace
                    truncatewidth
                    onkeyPress={(event) => {
                      handlechange3(event);
                    }}
                    setFieldValue={formik.setFieldValue}
                    name="location"
                    style={styles.boxstyle}
                    onChange={(event) => {
                      handlechange3(event);
                    }}
                  />
                  {formik.values.location !== '' && (
                    <button
                      className={styles.crossIcon}
                      onClick={handleSearchClose}
                    >
                      <Flex style={{ cursor: 'pointer' }}>
                        <SvgIntomark width={14} height={14} fill="#888888" />
                      </Flex>
                    </button>
                  )}
                </Flex>
              </div>
            </Flex>

            <Flex row className={styles.mtstyle}>
              <Flex className={styles.switch}>
                <InputSwitch
                  onClick={() => {
                    settriggerremote(true);
                    handletoggle();
                  }}
                  checked={isremotetoggle}
                />
              </Flex>
              <Flex className={styles.toggletext}>Remote</Flex>
            </Flex>

            <Flex row className={styles.filterContainers}>
              <Flex marginRight={10}>
                <Button types="secondary" onClick={pageReload}>
                  Reset
                </Button>
              </Flex>
              <Flex>
                <Button className={styles.buyBtn} onClick={handlechange}>
                  Apply
                </Button>
              </Flex>
            </Flex>
          </div>
        </div>
      </Flex>
    </Flex>
  );
};

export default MyJobsPostingFilter;
