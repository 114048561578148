export const qualificationOption = [
  { value: 'Bachelors', label: 'Bachelors' },
  { value: 'Masters', label: 'Masters' },
  { value: 'Doctorate', label: 'Doctorate' },
  { value: 'Diploma', label: 'Diploma' },
  { value: 'Others', label: 'Others' },
];

export const experienceOption = [ 
  { value: '0-1', label: 'Less than a year' },
  { value: '1-2', label: '1-2 years' },
  { value: '2-5', label: '2-5 years' },
  { value: '5-7', label: '5-7 years' },
  { value: '7-10', label: '7-10 years' },
  { value: '>10', label: 'Above 10 years' }
];

export const filterexperienceOption = [
  { value: '', label: 'All' },
  { value: '0-1', label: 'Less than a year' },
  { value: '1-2', label: '1-2 years' },
  { value: '2-5', label: '2-5 years' },
  { value: '5-7', label: '5-7 years' },
  { value: '7-10', label: '7-10 years' },
  { value: '>10', label: 'Above 10 years' }
];
