import { SetStateAction, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import StarsRating from 'react-star-rate';
import { Card, Modal, Button, LinkWrapper, Toast } from '../../uikit';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Loader from '../../uikit/Loader/Loader';
import SvgClose from '../../icons/SvgClose';
import SvgshareIcon from '../../icons/SvgShareIconview';
import SvgCSV from '../../icons/SvgCSV';
import SvgRight from '../../icons/SvgRight';
import SvgLocationicon from '../../icons/SvgLocationicon';
import SvgLeft from '../../icons/SvgLeft';
import Svgeditingnotes from '../../icons/editingnotes';
import SvgJobselection from '../../icons/SvgJobselection';
import SvgAngle from '../../icons/SvgAngle';
import SvgAdd from '../../icons/SvgAdd';
import Avatar from '../../uikit/Avatar';
import { isEmpty, toCamelCase } from '../../uikit/helper';
import { AppDispatch } from '../../store';
import MyLottieComponent from '../../loader/Loader';
import comloader from '../../actions/ComparitiveAnalysis.json';
import styles from './comparativeanalysis.module.css';
import Addcandidatesmodal from './addcandidatesmodel';
import Prosandcons from './prosandcons';
import Editcriteriamodal from './editmatchingcriteriaModal';
import {
  comparativeanalysismiddleware,
  comparativecsvdownloadmiddleware,
  comparativesearchingdatamiddleware,
} from './store/middleware/comparativemiddleware';

const cx = classNames.bind(styles);
type ParamsType = {
  jdId: string;
};
type Props = {
  Comparative?: any;
  update_alysismodal?: (val: any) => void;
  updatemodel?: (val: any, id: any) => void;
  resetdata: any;
  Matching: any;
  job_details: any;
  isData: any;
  edit?: any;
  edit_function?: (val: any) => void;
  select_candidate?: (val: any, id: any) => void;
  selectedcriteria: any;
  update_riteria: (val: any) => void;
  newedit?: any;
  check?: boolean;
  ids?: any;
};
const ComparativeanalysisModal = ({
  Comparative,
  update_alysismodal,
  updatemodel,
  resetdata,
  Matching,
  job_details,
  isData,
  select_candidate,
  edit,
  edit_function,
  selectedcriteria,
  update_riteria,
  newedit,
  check,
  ids,
}: Props) => {
  const [addmodel, setaddmodel] = useState(false);
  const { jdId } = useParams<ParamsType>();
  const [iskey, setkey] = useState('0');
  const [isDatastore, setDatastore] = useState<any>();
  const [isPros, setPros] = useState(true);
  const [isLoader, setLoader] = useState(true);
  const [verify, setverify] = useState(false);
  const [olddata, setolddata] = useState([]);
  const [errormsg, seterrormsg] = useState(false);
  const [addcandidate, setaddcandidate] = useState(false);
  const [isclosemodelwindow, setclosemodelwindow] = useState(false);
  const [isoveralldata, setoveralldata] = useState<any>();
  const [value, setval] = useState(false);
  const [role, setrole] = useState('add');
  const [change, setchange] = useState(false);
  const [editmodal, seteditmodal] = useState(false);

  const [editdata, seteditdata] = useState<any>([]);

  const dispatch: AppDispatch = useDispatch();

  const openaddmodel = (val) => {
    setaddmodel(val);
  };
  const Edit = () => {
    // edit_function(true);
    // update_alysismodal(false);
    seteditmodal(true);
  };
  const Edit_data = (val, id) => {
    if (id === 0) {
      seteditdata([...editdata, val]);
    } else {
      seteditdata(val);
    }
  };

  const edit_close = (val) => {
    seteditmodal(val);
    seteditdata(isData);
  };

  const closemodel = () => {
    setclosemodelwindow(true);
  };

  const closemodelwindow = () => {
    localStorage.setItem('compare', 'true');
    resetdata();
    updatemodel(false, 1);
    update_alysismodal(false);
  };

  useEffect(() => {
    setolddata(Matching);
    seteditdata(isData);
    dispatchcomparativeApi(Matching, isData, value);
    setkey('0');
  }, [change]);

  useEffect(() => {
    setoveralldata(
      selectedcriteria &&
        selectedcriteria.payload.analysis
          .slice() // Create a shallow copy of the array to avoid mutating the original
          .sort((data1, data2) => {
            if (
              data1.Total_matching_percentage < data2.Total_matching_percentage
            ) {
              return 1; // Sort in descending order
            }
            if (
              data1.Total_matching_percentage > data2.Total_matching_percentage
            ) {
              return -1;
            }
            return 0;
          }),
    );
  }, [selectedcriteria]);
  const dispatchcomparativeApi = (match, Data, values) => {
    setLoader(true);

    let candidateids = match.map((item) => item.candidate_id).join(',');
    let selectcriteria = Data.map((item) => item.label).join(',');
    dispatch(
      comparativeanalysismiddleware({
        candidate_ids: candidateids,
        job_id: check === true ? ids : jdId,
        categories: selectcriteria,
      }),
    ).then((response) => {
      const conditionInLocalStorage = localStorage.getItem('compare');
      if (response.payload.success === true) {
        setolddata(Matching);
        update_riteria(response);
        setLoader(false);
        dispatch(
          comparativesearchingdatamiddleware({
            jd_id: check === true ? ids : jdId,
          }),
        );
        if (conditionInLocalStorage === 'false') {
          if (values === 'edit') {
            edit_function(Data);
            seteditdata(Data);
            Toast(
              'Criteria for the comparison updated successfully.',
              'LONG',
              'success',
            );
          }
          if (values === 'add') {
            Toast(
              'Applicants updated successfully for comparison.',
              'LONG',
              'success',
            );
          }
        }

        setaddcandidate(false);
        // edit_function(false);
      } else {
        setLoader(false);
        setolddata(Matching);
        closemodelwindow();
        if (conditionInLocalStorage === 'false') {
          if (response?.payload?.standalone === true) {
            Toast(response?.payload?.message, 'LONG', 'error');
          } else {
            Toast(
              'Oops! Something went wrong. Please try again shortly.',
              'LONG',
              'error',
            );
          }
        }
        // edit_function(false);
      }
    });
  };
  const DownloadCsv = () => {
    dispatch(
      comparativecsvdownloadmiddleware({
        response_json: JSON.stringify(selectedcriteria.payload),
        jd_id: check === true ? ids : jdId,
      }),
    );
  };
  const remove_user = (val) => {
    if (Matching.length > 2) {
      var data = {
        candidate_id: val.candidate_id,
      };
      select_candidate(data, 5);
      setverify(true);
      seterrormsg(false);
    } else {
      setverify(true);
      seterrormsg(true);
    }
  };

  const removeprofile = () => {
    setverify(false);
    seterrormsg(false);
    select_candidate(Matching, 6);
    setolddata(Matching);
    dispatchcomparativeApi(Matching, isData, role);
  };
  const add_candidates = (val) => {
    setaddcandidate(val);
  };

  const cancelverify = () => {
    setverify(false);
    select_candidate(olddata, 6);
    seterrormsg(false);
  };

  const clearstate = () => {
    setverify(false);
    seterrormsg(false);
  };

  return (
    <>
      <Flex middle>
        <>
          {isLoader && !newedit ? (
            <Flex
              className={styles.fixingcontent}
              height={window.innerHeight - 100}
              width={window.innerWidth - 100}
            >
              <MyLottieComponent
                data={comloader}
                width={400}
                height={350}
                check={true}
                closemodelwindow={closemodelwindow}
              />
            </Flex>
          ) : (
            <Modal open={Comparative}>
              <Flex style={{ backgroundColor: 'white', borderRadius: '4px' }}>
                <Flex
                  center
                  row
                  between
                  style={{
                    backgroundColor: '#EEE8EC',
                    padding: '10px',
                    borderRadius: '4px 4px 0px 0px',
                  }}
                  flex={1}
                >
                  <Flex></Flex>
                  <Flex>
                    {' '}
                    <Text size={14} color="theme">
                      Comparative Analysis & AI Recommendation
                    </Text>
                  </Flex>
                  <Flex end onClick={closemodel} marginRight={15}>
                    <SvgClose
                      width={10}
                      height={10}
                      fill={'#888888'}
                      cursor={'pointer'}
                    />
                  </Flex>
                </Flex>

                <Flex
                  className={styles.fixingcontent}
                  height={window.innerHeight - 100}
                  width={window.innerWidth - 100}
                >
                  <Flex row between marginTop={5}>
                    <Flex row center>
                      <Flex>
                        <Text bold size={14}>
                          Comparative Analysis
                        </Text>
                      </Flex>
                      <Flex
                        marginLeft={8}
                        onClick={DownloadCsv}
                        title="Download CSV"
                        style={{ cursor: 'pointer' }}
                      >
                        <SvgCSV height={16} width={16} />
                      </Flex>
                    </Flex>
                    <Button
                      onClick={() => openaddmodel(true)}
                      types="secondary"
                    >
                      <Flex row center style={{ cursor: 'pointer' }}>
                        <Flex marginRight={7} style={{ cursor: 'pointer' }}>
                          <SvgAdd height={10} width={10} fill="#581845" />
                        </Flex>
                        <Flex>
                          <Text
                            color="theme"
                            size={13}
                            style={{ cursor: 'pointer' }}
                            bold
                          >
                            Add Applicant
                          </Text>
                        </Flex>
                      </Flex>
                    </Button>
                  </Flex>
                  <Flex row>
                    <Flex>
                      <Flex row marginTop={32.5} flex={4}>
                        <Flex>
                          {' '}
                          <SvgJobselection width={15} height={15} />
                        </Flex>
                        <Flex marginLeft={7}>
                          <Text
                            size={13}
                            color="theme"
                            className={styles.textelipssis}
                            title={` ${toCamelCase(job_details.job_title)} - ${
                              job_details.job_id
                            }`}
                          >
                            {toCamelCase(job_details.job_title)} -{' '}
                            {job_details.job_id}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex row marginTop={5} center>
                        <Flex marginLeft={-1.5}>
                          {' '}
                          <SvgLocationicon
                            height={18}
                            width={18}
                            fill={'#581845'}
                          />
                        </Flex>
                        <Flex marginTop={5}>
                          {job_details.city === null &&
                          job_details.state === null &&
                          job_details.country === null &&
                          job_details.work_space_type === '3' ? (
                            <Text
                              size={13}
                              color="theme"
                              className={styles.textelipssis}
                              title={'Remote'}
                              style={{ paddingLeft: '5px' }}
                            >
                              Remote
                            </Text>
                          ) : (
                            <>
                              {job_details.city !== null &&
                              job_details.state !== null &&
                              job_details.country !== null &&
                              job_details.work_space_type === '3' ? (
                                <Text
                                  size={13}
                                  color="theme"
                                  className={styles.textelipssis}
                                  title={`${job_details.city}, ${job_details.state}, ${job_details.country} (Remote)`}
                                  style={{ paddingLeft: '5px' }}
                                >
                                  {`${job_details.city}, ${job_details.state}, ${job_details.country} (Remote)`}
                                </Text>
                              ) : (
                                <Text
                                  size={13}
                                  color="theme"
                                  className={styles.textelipssis}
                                  title={`${job_details.city}, ${job_details.state}, ${job_details.country}`}
                                  style={{ paddingLeft: '5px' }}
                                >
                                  {`${job_details.city}, ${job_details.state}, ${job_details.country}`}
                                </Text>
                              )}
                            </>
                          )}
                        </Flex>
                      </Flex>
                      <Flex
                        row
                        marginTop={62}
                        center
                        style={{
                          borderBottom: '1px solid rgb(195, 195, 195)',
                          paddingBottom: '6px',
                        }}
                      >
                        <Flex> Criteria </Flex>
                        <Flex
                          marginLeft={8}
                          style={{ cursor: 'pointer' }}
                          onClick={Edit}
                        >
                          <Svgeditingnotes
                            height={14}
                            width={14}
                            fill={'#581845'}
                          />
                        </Flex>
                      </Flex>
                      {selectedcriteria ? (
                        <>
                          {selectedcriteria &&
                            selectedcriteria.payload.analysis.length > 0 &&
                            Object.keys(
                              selectedcriteria.payload.analysis[0].categories,
                            ).map((key, index) => (
                              <Flex
                                marginTop={6}
                                style={{
                                  borderBottom: '1px solid rgb(195, 195, 195)',
                                  paddingBottom: '6px',
                                }}
                                key={index}
                              >
                                {`${key}`}
                              </Flex>
                            ))}
                        </>
                      ) : (
                        ''
                      )}
                    </Flex>
                    <Flex
                      flex={8}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        overflowX: 'scroll',
                        margin: '5px 5px 5px 0px',
                      }}
                    >
                      {isoveralldata &&
                        isoveralldata.map((e, indexnum) => {
                          return (
                            <Flex
                              key={indexnum}
                              row
                              marginBottom={7}
                              marginTop={2}
                              marginLeft={8}
                              marginRight={2}
                            >
                              <Flex
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Flex center middle>
                                  {indexnum === 0 && (
                                    <Card className={styles.Recommendedchanges}>
                                      <Flex middle center>
                                        AI Recommendation
                                      </Flex>
                                    </Card>
                                  )}
                                </Flex>
                                <Flex marginTop={indexnum !== 0 && 26.5}>
                                  <Card
                                    className={
                                      indexnum === 0
                                        ? styles.cardstructureforprofile
                                        : styles.cardstructureforprofileother
                                    }
                                  >
                                    <Flex row between>
                                      <Flex
                                        style={{
                                          backgroundColor: '#581845',
                                        }}
                                        width={20}
                                        height={37}
                                        marginLeft={10}
                                      >
                                        <Flex center middle>
                                          <text style={{ color: 'white' }}>
                                            {indexnum + 1}
                                          </text>
                                        </Flex>
                                        <Flex className={styles.triangle}>
                                          {' '}
                                        </Flex>
                                      </Flex>
                                      <Flex
                                        marginTop={20}
                                        marginLeft={-10}
                                        marginBottom={-20}
                                      >
                                        <Avatar
                                          className={styles.profile}
                                          style={{
                                            fontSize: '24px',
                                            textTransform: 'uppercase',
                                            color: 'white',
                                          }}
                                          avatar={
                                            e.image && e.image !== 'default.jpg'
                                              ? `${process.env.REACT_APP_HOME_URL}media/${e.image}`
                                              : undefined
                                          }
                                          initials={`${
                                            isEmpty(e.last_name)
                                              ? e?.first_name?.slice(0, 2)
                                              : e?.first_name?.charAt(0)
                                          }${
                                            !isEmpty(e.last_name)
                                              ? e.last_name?.charAt(0)
                                              : ''
                                          }`}
                                        />
                                        <Flex
                                          className={cx({
                                            countStyle1:
                                              e.Total_matching_percentage < 40,
                                            countStyle2:
                                              e.Total_matching_percentage >=
                                                40 &&
                                              e.Total_matching_percentage < 69,
                                            countStyle3:
                                              e.Total_matching_percentage > 69,
                                          })}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 10,
                                              marginTop: ' 2px',
                                              color: 'white',
                                            }}
                                            bold
                                          >
                                            {Math.round(
                                              e.Total_matching_percentage,
                                            )}
                                          </Text>
                                        </Flex>
                                      </Flex>
                                      <Flex
                                        marginRight={10}
                                        marginTop={10}
                                        onClick={() => remove_user(e)}
                                        direction_tooltip="up"
                                        tooltip_distance={10}
                                        title="Remove Candidate"
                                      >
                                        <SvgClose
                                          width={10}
                                          height={10}
                                          fill={'#888888'}
                                          cursor={'pointer'}
                                        />
                                      </Flex>
                                    </Flex>
                                    <Flex row middle center>
                                      <Flex
                                        width={12}
                                        title={e.stage_name}
                                        style={{
                                          backgroundColor: e.stage_color,
                                          borderRadius: '4px',
                                        }}
                                        height={12}
                                        marginRight={5}
                                        marginBottom={1}
                                      ></Flex>
                                      <Flex
                                        title={`${toCamelCase(e?.first_name)} ${
                                          e?.last_name
                                            ? toCamelCase(e?.last_name)
                                            : ''
                                        }`}
                                      >
                                        <Text
                                          className={styles.changingtexts}
                                          style={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {toCamelCase(e?.first_name)}{' '}
                                          {e?.last_name
                                            ? toCamelCase(e?.last_name)
                                            : ''}
                                        </Text>
                                      </Flex>
                                      <LinkWrapper
                                        target={'_blank'}
                                        to={
                                          check === true
                                            ? `/applicant_profile_view/${ids}/${
                                                e?.candidateid ||
                                                e?.candidate_id
                                              }`
                                            : `/applicant_profile_view/${jdId}/${
                                                e?.candidateid ||
                                                e?.candidate_id
                                              }`
                                        }
                                      >
                                        {' '}
                                        <Flex
                                          marginLeft={5}
                                          style={{
                                            cursor: 'pointer',
                                            position: 'relative',
                                          }}
                                          title="View Profile"
                                        >
                                          {' '}
                                          <SvgshareIcon
                                            width={18}
                                            height={18}
                                          />
                                        </Flex>
                                      </LinkWrapper>
                                    </Flex>
                                    <Flex
                                      middle
                                      center
                                      style={{ cursor: 'default' }}
                                      height={25}
                                      marginTop={5}
                                      className={styles.starratingoverall}
                                    >
                                      {' '}
                                      <StarsRating
                                        value={e.overall_scorecard}
                                        disabled
                                        count={5}
                                      />
                                    </Flex>
                                    <Flex>
                                      <Flex key={indexnum}>
                                        {Object.keys(e.categories).map(
                                          (key, subIndex) => (
                                            <Flex
                                              center
                                              middle
                                              height={34}
                                              style={{
                                                borderTop:
                                                  '1px solid rgb(195, 195, 195)',
                                                padding: '4px',
                                              }}
                                              key={subIndex}
                                            >
                                              {' '}
                                              {Math.round(e.categories[key]) <=
                                                3 && (
                                                <Text size={12}>{`${Math.round(
                                                  e.categories[key],
                                                )}/10 (Low)`}</Text>
                                              )}
                                              {Math.round(e.categories[key]) >
                                                7 && (
                                                <Text size={12}>{`${Math.round(
                                                  e.categories[key],
                                                )}/10 (High)`}</Text>
                                              )}
                                              {Math.round(e.categories[key]) >
                                                3 &&
                                                Math.round(e.categories[key]) <=
                                                  7 && (
                                                  <Text
                                                    size={12}
                                                  >{`${Math.round(
                                                    e.categories[key],
                                                  )}/10 (Medium)`}</Text>
                                                )}
                                            </Flex>
                                          ),
                                        )}
                                      </Flex>
                                    </Flex>
                                  </Card>
                                </Flex>
                              </Flex>
                            </Flex>
                          );
                        })}
                    </Flex>
                  </Flex>
                  <Flex marginBottom={15}>
                    <Flex row between center>
                      <Flex row center>
                        <Flex
                          style={{
                            fontsize: '14px',
                            color: '#581845',
                          }}
                        >
                          <Text size={14} bold color="theme">
                            {' '}
                            Detailed Information
                          </Text>
                        </Flex>
                        <Flex
                          onClick={() => setPros(!isPros)}
                          marginLeft={8}
                          style={{ cursor: 'pointer' }}
                        >
                          <SvgAngle
                            width={12}
                            height={12}
                            fill="#581845"
                            up={isPros}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  {isPros && (
                    <Prosandcons
                      selectedcriteria={selectedcriteria}
                      isoveralldata={isoveralldata}
                      setkey={setkey}
                      iskey={iskey}
                    />
                  )}
                </Flex>
              </Flex>
            </Modal>
          )}

          <Flex>
            <Modal open={verify}>
              <Flex
                column
                style={{
                  backgroundColor: 'white',
                  padding: '25px',
                  borderRadius: '4px',
                }}
              >
                {errormsg ? (
                  <>
                    <Flex row>
                      {
                        " Eliminating a applicant isn't feasible as a comparison mandates the inclusion of at least two applicants"
                      }
                    </Flex>
                    <Flex style={{ justifyContent: 'center' }}>
                      <Button
                        style={{ marginTop: '15px' }}
                        onClick={clearstate}
                      >
                        OK
                      </Button>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex row center style={{ justifyContent: 'center' }}>
                      This action will remove the applicant from the comparison.
                    </Flex>
                    <Flex row end marginTop={20}>
                      <Button
                        onClick={cancelverify}
                        types="close"
                        style={{ marginRight: '8px' }}
                      >
                        Cancel
                      </Button>
                      <Button onClick={removeprofile}>Remove</Button>
                    </Flex>
                  </>
                )}
              </Flex>
            </Modal>
            <Modal open={isclosemodelwindow}>
              <Flex
                column
                style={{
                  backgroundColor: 'white',
                  padding: '25px',
                  borderRadius: '4px',
                }}
              >
                <>
                  <Flex row center style={{ justifyContent: 'center' }}>
                    This action will reset the comparative analysis for the
                    applicants.
                  </Flex>
                  <Flex>Are you sure to proceed?</Flex>
                  <Flex row end marginTop={20}>
                    <Button
                      onClick={() => setclosemodelwindow(false)}
                      types="close"
                      style={{ marginRight: '8px' }}
                      width="51"
                    >
                      NO
                    </Button>
                    <Button onClick={closemodelwindow} width="51">
                      YES
                    </Button>
                  </Flex>
                </>
              </Flex>
            </Modal>
          </Flex>
        </>
        {/* )} */}
        <Addcandidatesmodal
          model={addmodel}
          openfunction={openaddmodel}
          Matching={Matching}
          select_candidate={select_candidate}
          dispatchcomparativeApi={dispatchcomparativeApi}
          add_candidates={add_candidates}
          update_alysismodal={update_alysismodal}
          isData={isData}
          check={check}
          ids={ids}
        />
        {editmodal && (
          <Editcriteriamodal
            editmodal={editmodal}
            editdata={editdata}
            Edit_data={Edit_data}
            edit_close={edit_close}
            dispatchcomparativeApi={dispatchcomparativeApi}
            Matching={Matching}
          />
        )}
      </Flex>
    </>
  );
};

export default ComparativeanalysisModal;
