import { SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tab from '../../uikit/Tab/Tab';
import Tabs from '../../uikit/Tab/Tabs';
import { EmpPoolEntity } from './bulkImportTypes';
import CandidateDatabaseTab from './CandidateDatabaseTab';
import Applicants from './applicants';
import LogFileTab from './LogFileTab';
type Props = {
  emp_pool?: EmpPoolEntity[];
  params: any;
  total_count: number;
  completed: number;
  incompleted: number;
  handleTotal: () => void;
  handleSubmit: () => void;

  handleCompleted: () => void;

  handleInCompeleted: () => void;
  // handleApplicantView: () => void;
  searchValue: any;
  jd_id: any;
  searchHandleChange: any;
  features_balance: number;
  setFeaturesBalance: (a: number | null) => void;
  isSearch: number;
  formik: any;
  setPageNumber: (a: number) => void;
  pageNumber: number;
  upDateloader: boolean
  setbulktab?: any;
  isBulkTab?:any;
  istableloadertrue?: boolean;
};


const BulkImportTabs = ({
  emp_pool,
  total_count,
  completed,
  incompleted,
  handleTotal,
  handleSubmit,
  jd_id,
  handleCompleted,
  handleInCompeleted,
  searchValue,
  searchHandleChange,
  features_balance,
  setFeaturesBalance,
  isSearch,
  formik,
  setPageNumber,
  pageNumber,
  upDateloader,
  setbulktab,
  params,
  isBulkTab,
  istableloadertrue
}: Props) => {
  const [tabKey, setKey] = useState('0');
  const [isjd_iddata, setjd_iddata] = useState<any>(null);
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const history = useHistory();
  const tab: any = query.get('tab');
  const jd_ids: any = query.get('jd_ids');
  const currentParams = new URLSearchParams(window.location.search);
  if (tab) {
    setKey(tab)
    if (query.has('tab')) {
      query.delete('tab');
      history.replace({
        search: query.toString(),
      });
    }
  }
  if (jd_ids) {
    setjd_iddata(jd_ids)
    if (query.has('jd_ids')) {
      query.delete('jd_ids');
      history.replace({
        search: query.toString(),
      });
    }
  }
  useEffect(() => {
    if (tabKey !== '1') {
     // formik.setFieldValue('searchValue','')
      setjd_iddata(null)
    }else{
     // formik.setFieldValue('searchValue','')
    }
    setbulktab(tabKey)
  }, [tabKey])





  const clearurlfilter=()=>{
    const paramsToClear = [
      'experience',
      'qualification',
      'skill',
      'location',
      'search',
      'page1',
      'selectedpagecount',
      'selectedpage',
      'total',
      'completed',
      'incompleted'
    ];
  
    // Remove each parameter from the URL
    paramsToClear.forEach(param => {
      if (currentParams.has(param)) { // Check if parameter exists in URL
        currentParams.delete(param);  // Delete it if it does
      }
    });
  
  
    // Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }

  const clearurlfiltercandidate=()=>{
    const paramsToClear = [
      'experience1',
      'qualification1',
      'skill1',
      'location1',
      'search1',
      'jdid',
      'page',
      'selectedpagecount1',
      'selectedpage1',
      'total1'
    ];
  
    // Remove each parameter from the URL
    paramsToClear.forEach(param => {
      if (currentParams.has(param)) { // Check if parameter exists in URL
        currentParams.delete(param);  // Delete it if it does
      }
    });
  
  
    // Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }


  useEffect(()=>{  
   if(localStorage.getItem('tabkeybulkupload')==='1')
   {
    clearurlfilter();
   }else if(localStorage.getItem('tabkeybulkupload')==='0'){
    clearurlfiltercandidate();
   }
  },[tabKey])

  useEffect(()=>{
   if(localStorage.getItem('tabkeybulkupload')){
     if(localStorage.getItem('tabkeybulkupload')==='1'){
         setKey('1')
     }else  if(localStorage.getItem('tabkeybulkupload')==='1'){
      setKey('0')
  }
    }
     
  },[])

  const handleTabSelect = (key: SetStateAction<string>) => {
    setKey(key);
    localStorage.setItem('tabkeybulkupload', String(key)); // Store the selected tab key in localStorage
  };


  const urlsearch = useLocation();
  useEffect(() => {
    if(localStorage.getItem('tabkeybulkupload')==='0'){
    const searchurl = currentParams.get('search');
    if(searchurl){
      formik.setFieldValue('searchValue',searchurl)
      // handleSubmit();
    }else{
      formik.setFieldValue('searchValue','')
    }}

    if(localStorage.getItem('tabkeybulkupload')==='1'){
    const searchurl1 = currentParams.get('search1');
    if(searchurl1){
      formik.setFieldValue('searchValue',searchurl1)
      // handleSubmit();
    }else{
      formik.setFieldValue('searchValue','')
      // alert('ssss')
    }}

  }, [localStorage.getItem('tabkeybulkupload')]);




  return (
    <Tabs
      activeKey={tabKey}
      onSelect={handleTabSelect}
    >
      <Tab title={'Candidate Database'} eventKey={'0'} >
        {tabKey === '0' &&
          <CandidateDatabaseTab
          // emp_pool={emp_pool}
          // total_count={total_count}
          // completed={completed}
          // incompleted={incompleted}
          handleTotal={handleTotal}
          handleSubmit={handleSubmit}
          handleCompleted={handleCompleted}
          handleInCompeleted={handleInCompeleted}
          searchValue={searchValue}
          searchHandleChange={searchHandleChange}
          setKey={setKey}
          // features_balance={features_balance}
          setFeaturesBalance={setFeaturesBalance}
          isSearch={isSearch}
          formik={formik}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber} 
          isBulkTab={isBulkTab}
          // upDateloader={upDateloader}
          />
        }
      </Tab>
      <Tab title={'Job Applicants'} eventKey={'1'} >
        {tabKey === '1' &&
          <Applicants
          emp_pool={emp_pool}
          total_count={total_count}
          jd_id={jd_id}
          completed={completed}
          incompleted={incompleted}
          // handleTotal={handleTotal}
          // handleSubmit={handleSubmitWithJd}
          // handleInCompeleted={handleInCompeleted}
          searchValue={searchValue}
          isjd_iddata={isjd_iddata}
          setjd_iddata={setjd_iddata}
          searchHandleChange={searchHandleChange}
          setKey={setKey}
          features_balance={features_balance}
          setFeaturesBalance={setFeaturesBalance}
          istableloadertrue={istableloadertrue}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          upDateloader={upDateloader}
          isBulkTab={tabKey} 
          params={params}          
          />
        }
      </Tab>
      <Tab title={'Upload Log'} eventKey={'2'}>
        <LogFileTab getKey={tabKey} />
      </Tab>
    </Tabs>
  );
};

export default BulkImportTabs;
