import { loadStripe } from '@stripe/stripe-js';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { Modal } from '../../../uikit';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import { SUCCESS } from '../../../uikit/Colors/colors';
import ErrorMessage from '../../../uikit/ErrorMessage/ErrorMessage';
import Flex from '../../../uikit/Flex/Flex';
import SvgTick from '../../../icons/SvgTick';
import SvgTickmanage from '../../../icons/SvgTickmanage';
import InputText from '../../../uikit/InputText/InputText';
import Loader from '../../../uikit/Loader/Loader';
import Text from '../../../uikit/Text/Text';
import Toast from '../../../uikit/Toast/Toast';
import { ERROR_MESSAGE, onlyNumber } from '../../constValue';
import {
  createCheckoutMiddleWare,
  stripeMiddleWare,
} from '../../talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import Totalcount from '../../../globulization/TotalCount';
import SvgSubcriptioncrown from '../../../icons/Subscriptioncrown';
import SvgInfinity from '../../../icons/SvgInfinity';
import SvgRolling from '../../../icons/SvgRolling';
import styles from './addoncard.module.css';

type Props = {
  formik1: any;
  focustoRef: any;
  managesub: any;
  redirect_ordersummary2?:()=>void;
  valuetolocal2?:()=>void;
};
const AddOnCard = ({ formik1, focustoRef, managesub,redirect_ordersummary2,valuetolocal2 }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const addonRefs = useRef({});
  const [isLoader, setLoader] = useState(false);
  const [myVariable, setMyVariable] = useState(null);
  const [openmodel, setopenmodel] = useState(false);
  const [index, setindex] = useState([]);
  const focusedAddOnId = 11;

  const { subscription, available, content_text, available_addons, unlimited, expire_details, is_plan } =
    useSelector(({ manageSubscriptionReducers, SubscriptionReducers, permissionReducers }: RootState) => ({
      subscription: manageSubscriptionReducers.subscription,
      available: manageSubscriptionReducers.available,
      content_text: manageSubscriptionReducers.content_text,
      available_addons: manageSubscriptionReducers.available_addons,
      unlimited: manageSubscriptionReducers.unlimited,
      expire_details: SubscriptionReducers.expire_details,
      is_plan: permissionReducers.is_plan,

    }));

  useEffect(() => {
    if (focusedAddOnId && addonRefs.current[focusedAddOnId]) {
      addonRefs.current[focusedAddOnId].focus();
    }
  }, [focusedAddOnId]);

  // formik validation
  const handleValidation = (values: any) => {
    const errors: Partial<any> = {};
    if (Number(values.value) < 5) {
      errors.value = 'Minimum Candidate 5';
    }
    return errors;
  };

  // formik submit function
  const handleSubmit = async (values: any) => {
    setLoader(true);
    dispatch(
      createCheckoutMiddleWare({
        can_count: values.value,
        amount: totalAmount,
        manage_sub: '/account_setting/subscription',
      }),
    )
      .then((response) => {
        dispatch(stripeMiddleWare()).then(async (stripRes) => {
          const stripe = await loadStripe(stripRes.payload.publicKey);
          if (response.payload.sessionId) {
            stripe
              ?.redirectToCheckout({
                sessionId: response.payload.sessionId,
              })
              .then(() => {
                setLoader(false);
              });
          }
        });
      })
      .catch(() => {
        setLoader(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  const formik = useFormik({
    initialValues: { value: '5' },
    onSubmit: handleSubmit,
    validate: handleValidation,
  });

  const disPlayValue = Number(formik.values.value) * 2;

  const totalAmount: string = disPlayValue.toString();

  function preprocessAndParseData(data) {
    let dataString;

    if (typeof data === 'object' && data !== null) {
      // Convert the object to a JSON string
      dataString = JSON.stringify(data);
    } else if (typeof data === 'string') {
      dataString = data;
    } else {
      return null;
    }

    try {
      // Correcting potential JSON format issues (if it was originally a string)
      if (typeof data === 'string') {
        dataString = dataString
          .replace(/value/g, '"value"')
          .replace(/,\s*\]/, ']');
      }
      return JSON.parse(dataString);
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    if (content_text && content_text.length > 0) {
      const processedData = preprocessAndParseData(content_text[0].content);
      setMyVariable(processedData);
    }
  }, [content_text]);
  const handleValid = (data) => {
    setindex(data);
    setopenmodel(true);
  };
  useEffect(() => {
    // Check if available_addons is an array before proceeding
    if (Array.isArray(available_addons)) {
      const filteredAddons = available_addons
        .filter((item) => managesub === item.name) // Filter based on the condition
        .flatMap((item) =>
          item.content.map((items) => ({
            // Use flatMap for nested arrays
            id: items.stripe_id,
            checked: false,
            quantity: items.value,
            addonid: items.addon_id,
          })),
        );

      formik1.setFieldValue('addtocart', filteredAddons);
      formik1.setFieldValue('addtocart2', filteredAddons);
    
    }
  }, [available_addons, managesub]); // Include all dependencies in the dependency array


  const variable = myVariable && myVariable.credits_cards;
  const generateOptions = (i) => {
    const options = [];
    let step;
    let val;
    if (i === 0) {
      step = 5;
      val = 60;
    } else if (i === 1 || i === 3) {
      step = 100;
      val = 1200;
    } else if (i === 2) {
      step = 20;
      val = 240;
    }

    for (let value = step; value <= val; value += step) {
      options.push(
        <option key={value} value={value}>
          {value}
        </option>,
      );
    }

    return options;
  };
  const handleChange = (event, data) => {
    formik1.setFieldValue(`addtocart[${data}].quantity`, event.target.value);
    formik1.setFieldValue(`addtocart2[${data}].quantity`, event.target.value);
  };
  const reffunction = () => {
    if (focustoRef.current) {
      focustoRef.current.focus();
    }
  };

  const purchacefunction = (data, i) => {
    setLoader(true);

    const plan = [data.stripe_id];
    const id = [data.id];
    let quantityValue = formik1.values?.addtocart[i]?.quantity;

    // Check for quantity and perform calculations based on addonid
    if (quantityValue !== null && quantityValue !== undefined) {
      switch (formik1.values?.addtocart[i]?.addonid) {
        case 1:
          quantityValue /= 5;
          break;
        case 2:
        case 11: // New case for addonid 11
          quantityValue /= 100;
          break;
        case 4:
          quantityValue /= 20;
          break;
        default:
          break;
      }
    } else {
      quantityValue = 1; // Default value if quantity is null or undefined
    }

    const count = [quantityValue];

    dispatch(
      createCheckoutMiddleWare({
        can_count: JSON.stringify(count),
        plan: JSON.stringify(plan),
        add_on: JSON.stringify(id),
        manage_sub: 'yes',
      }),
    ).then((response) => {
      dispatch(stripeMiddleWare()).then(async (stripRes) => {
        const stripe = await loadStripe(stripRes.payload.publicKey);
        if (response.payload.sessionId) {
          stripe
            ?.redirectToCheckout({
              sessionId: response.payload.sessionId,
            })
            .then((resp) => {
              setLoader(false);
            });
        }
      });
    });
  };


  return (
    <>
      <Modal open={openmodel}>
        <Flex
          style={{
            height: '400px',
            width: '600px',
            backgroundColor: 'white',
            padding: '25px',
          }}
        >
          <Button
            onClick={() => {
              setopenmodel(false);
            }}
          >
            Buy Now
          </Button>
        </Flex>
      </Modal>
      {isLoader && <Loader />}
      <Card className={styles.overAll}>
        <Flex row center between>
          <Text size={14} bold>
            Available Credits and Add-On Details
          </Text>
        </Flex>
        <Text style={{ marginBottom: 20 }}>
          * You must have any of the paid plans to avail these add-ons
        </Text>
        <Flex row between>
          <Flex flex={1}>
            {available_addons?.map((value, int) => (
              managesub === value.name && (
                value.content.map((data, i) => (
                  <Flex key={i} style={{ marginBottom: '10px' }}>
                    <Card className={styles.card}>
                      <Flex style={{ padding: '10px' }}>
                        <Text size={13} color="theme" style={{ marginBottom: "2px" }}>
                          {data.display_name}
                        </Text>
                        <Text>
                          Available Credits: {data.usage === 'unlimited' ? (
                            data.addon_exist ? (
                              <Text bold>
                                Unlimited
                              </Text>
                            ) : (
                              is_plan && expire_details?.plan_id === 1 ? (
                                <Text bold>
                                  Expires in {expire_details?.plan_exp?.days} days
                                </Text>
                              ) : (
                                <Text bold>
                                  Not yet purchased
                                </Text>
                              )
                            )
                          ) : (data.usage !== null ? <Text bold>{data.usage}</Text> : 0)}
                        </Text>
                      </Flex>
                    </Card>
                  </Flex>
                )))
            ))}
          </Flex>
          {/* <Flex flex={3}>
            {content_text.map(
              (datas, idx) =>
              
                managesub === datas.name &&
                datas.content.credits_cards.map((data, i) => (
                  
                  <Flex
                    key={i}
                    style={{ marginBottom: '10px' }}
                    ref={(el) => (addonRefs.current[data.id] = el)}
                  >
                    <Flex>
                      <Text color="theme" className={styles.addon}>
                        {data.title}
                      </Text>
                    </Flex>
            
                      <Flex style={{ border: "1px solid #D7C7D2", padding: '20px', borderRadius: '0px 5px 5px 5px' }} row between>
                        <Flex>
                          <Text style={{ maxWidth: '330px', textAlign: 'justify' }}>{data.text}</Text> 
                        </Flex>
                        <Flex between>
                          <Text bold>${data.price}</Text>
                          <Text bold style={{ marginTop: "5px"}}>{data.type}</Text>
                        </Flex>
                        {
                          data.credit !== "" && formik1.values.addtocart?.length > i && (
                            <Flex>
                              <Text>{data.credit}</Text>
                              <Flex row style={{ alignItems: 'center', display: 'flex', marginTop: "5px" }}>
                                <Text>Quantity:</Text>
                                <select
                                  name="numberSelect"
                                  id="numberSelect"
                                  value={formik1.values.addtocart[i]?.quantity || '0'}
                                  onChange={(e) => handleChange(e, i)}
                                    style={{
                                      textAlign: "center",
                                      boxShadow: "1px 2px 2px 2px rgb(238, 232, 236)",
                                      border: "1px solid #A5889C !important",
                                      borderRadius: "5px",
                                      height: "20px",
                                      marginLeft: "10px"
                                    }}
                                >
                                  {generateOptions(i)}
                                </select>
                              </Flex>
                            </Flex>
                          )
                        }
                        {
                          managesub==="Free Trial" ? (
                            <Button onClick={() => { reffunction() }}>
                              <Flex row style={{cursor:'pointer'}}>
                                <Text bold color='white' style={{cursor:'pointer'}}>Upgrade</Text>
                                <Flex                             
                                  marginLeft={5}
                                  marginTop={1}
                                  style={{ cursor: 'pointer' }}
                                  >
                                <SvgSubcriptioncrown
                                  height={14}
                                  width={14}
                                  fill=""
                                  />
                                </Flex>
                                </Flex>
                            </Button>
                          ) : (
                            <Flex row>
                              <Button types='secondary' style={{ margin: '0 8px 0 0' }} onClick={() => formik1.setFieldValue(`addtocart[${i}].checked`, !formik1.values.addtocart[i]?.checked)}>
                                {formik1.values.addtocart?.[i]?.checked ? (
                                  <Flex row>
                                    <SvgTick fill={SUCCESS} />
                                    <Text bold style={{margin:'0 0 0 5px'}}>Added to cart</Text>
                                  </Flex>
                                ) : (
                                  <Text bold>Add to cart</Text>
                                )}
                              </Button>
                              <Button onClick={() => { purchacefunction(data,i) }}>Buy Now</Button>
                            </Flex>
                          )
                        }
                      </Flex>
                    </Flex>
                  
                )),
            )}


          </Flex> */}
          <Flex flex={3}>
            {content_text.map((datas, idx) =>
              managesub === datas.name &&
              datas.content.credits_cards.map((data, i) => {
                const usageIndex = available_addons.findIndex((value) => value.name === managesub);
                return (
                  <Flex
                    key={i}
                    style={{ marginBottom: '10px' }}
                    ref={(el) => (addonRefs.current[data.id] = el)}
                  >
                    <Flex>
                      <Text color="theme" className={styles.addon}>
                        {data.title}
                      </Text>
                    </Flex>

                    <Flex style={{ border: '1px solid #D7C7D2', padding: '20px', borderRadius: '0px 5px 5px 5px' }} row between>
                      <Flex width={'40%'}>
                        <Text style={{ maxWidth: '330px', textAlign: 'justify' }}>{data.text}</Text>
                      </Flex>
                      {/* <Flex between>
                        <Text bold>${data.price}</Text>
                        <Flex row center middle>
                        <Flex marginTop={5} marginRight={3}>
                          {data.type === 'Rolling' && (
                            <SvgRolling width={12} height={12}/>
                          )}
                          </Flex>
                          <Text bold style={{ marginTop: '5px' }}>{data.type}</Text>
                        </Flex>
                      </Flex> */}

                      <Flex row between width={'30%'}>

                        <Flex>
                          <Flex>
                            <Text bold>${data.price}</Text>
                          </Flex>

                          <Flex row center middle>
                            <Flex marginTop={5} marginRight={3}>
                              {data.type === 'Rolling' && (
                                <SvgRolling width={12} height={12} />
                              )}
                            </Flex>
                            <Text bold style={{ marginTop: '5px' }}>{data.type}</Text>
                          </Flex>
                        </Flex>

                        {data.credit !== '' && formik1.values.addtocart?.length > i && (
                          <Flex width={managesub === 'Free Trial' ? '60%' : '70%'}>
                            <Text>{data.credit}</Text>
                            <Flex row style={{ alignItems: 'center', display: 'flex', marginTop: '5px' }}>
                              <Text>Quantity:</Text>
                              <select
                                name="numberSelect"
                                id="numberSelect"
                                value={formik1.values.addtocart[i]?.quantity || '0'}
                                onChange={(e) => handleChange(e, i)}
                                style={{
                                  textAlign: 'center',
                                  boxShadow: '1px 2px 2px 2px rgb(238, 232, 236)',
                                  border: '1px solid #A5889C !important',
                                  borderRadius: '5px',
                                  height: '20px',
                                  marginLeft: '10px',
                                  width: '60px',
                                }}
                              >
                                {generateOptions(i)}
                              </select>
                            </Flex>
                          </Flex>
                        )}

                      </Flex>
                      {managesub === 'Free Trial' ? (
                        <Flex>
                          <Button onClick={() => { reffunction() }}>
                            <Flex row style={{ cursor: 'pointer' }}>
                              <Text bold color='white' style={{ cursor: 'pointer' }}>Upgrade</Text>
                              <Flex
                                marginLeft={5}
                                marginTop={1}
                                style={{ cursor: 'pointer' }}
                              >
                                <SvgSubcriptioncrown
                                  height={14}
                                  width={14}
                                  fill=""
                                />
                              </Flex>
                            </Flex>
                          </Button>

                        </Flex>
                      ) : (
                        <>
                          {available_addons[usageIndex].content[i].addon_exist === true && available_addons[usageIndex].content[i].usage === 'unlimited' ? (
                            <>
                              {available_addons[usageIndex].content[i].next_month_created_at !== null && (
                                <Flex width={182}>
                                  <Text>
                                    Expires On: {new Date(available_addons[usageIndex].content[i].next_month_created_at).toLocaleDateString('en-GB', {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric',
                                    })}
                                  </Text>
                                </Flex>
                              )}
                            </>
                          ) : (
                            <Flex row >

                              <Button disabled={available_addons[usageIndex].content[i].addon_exist === true && available_addons[usageIndex].content[i].usage === 'unlimited'} types='secondary' style={{ margin: '0 8px 0 0' }} onClick={() => formik1.setFieldValue(`addtocart[${i}].checked`, !formik1.values.addtocart[i]?.checked)}>
                                {formik1.values.addtocart?.[i]?.checked ? (
                                  <Flex row>
                                    <SvgTick fill={SUCCESS} />
                                    <Text bold style={{ margin: '0 0 0 5px' }}>Added to cart</Text>
                                  </Flex>
                                ) : (
                                  <Text bold>Add to cart</Text>
                                )}
                              </Button>
                              <Button disabled={available_addons[usageIndex].content[i].addon_exist === true && available_addons[usageIndex].content[i].usage === 'unlimited'|| formik1.values.plan.length===1} 
                             onClick={() => {
                              formik1.setFieldValue(`addtocart2[${i}].checked`, true);
                               valuetolocal2();
                               redirect_ordersummary2();
                            }}
                            >Buy Now</Button>
                            </Flex>
                          )}</>
                      )}
                    </Flex>
                  </Flex>
                );
              })
            )}
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default AddOnCard;
