import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import Totalcount from '../../globulization/TotalCount';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Toast from '../../uikit/Toast/Toast';
import {
  getBlur,
  getFocus,
  isEmpty,
  unlimitedHelper,
} from '../../uikit/helper';
import Loader from '../../uikit/Loader/Loader';
import { AppDispatch, RootState } from '../../store';
import SingleButton from '../common/SingleButton';
import { ERROR_MESSAGE } from '../constValue';
import {
  backendProcessMiddleWare,
  creditsPurchaseMiddleWare,
} from '../ordersummarymodule/store/ordersummarymiddleware';
import { manageSubscriptionMiddleWare } from '../accountsettingsmodule/managesubscription/store/managesubscriptionmiddleware';
import { permissionMiddleWare } from '../Login/store/middleware/loginMiddleWare';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import SvgParsing from '../../icons/SvgParsing';
import {
  bulkImportMiddleWare,
  bulkuploadedCandidatesMiddleWare,
} from './store/middleware/bulkImportMiddleware';
import BulkImportTabs from './BulkImportTabs';
import styles from './bulkimport.module.css';
import { SkillListEntity } from './bulkImportTypes';
import { filterexperienceOption } from './bulkImportScreenMock';

export type FormProps = {
  searchValue: string;
  parser: string;
  locationSearch: string;
  experience: SkillListEntity;
  skillValue: SkillListEntity[];
};
const initial: FormProps = {
  searchValue: '',
  parser: '',
  locationSearch: '',
  experience: filterexperienceOption[0],
  skillValue: [],
};

type Props = {
  setbulktab?: any;
  isBulktab?: any;
};

const BulkImportScreen = ({ setbulktab, isBulktab }: Props) => {
  const [isFeaturesBalance, setFeaturesBalance] = useState<any>(null);
  const [isSearch, setSearch] = useState(1);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isSourceLimit, setSourceLimit] = useState(0);
  const [istableloadertrue, settableloadertrue] = useState<any>(false);
  const [isCredit, setCredit] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  // dispatching the API's when the user buy the add-on
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const history = useHistory();
  const session_id: any = query.get('session_id');
  const session: any = query.get('session');
  const jd_ids: any = query.get('jd_ids');
  useEffect(() => {
    dispatch(permissionMiddleWare());
    dispatch(bulkImportMiddleWare());
    dispatch(SubsriptionMiddleWare());

    const paramsToClear = ['experience', 'qualification', 'skill', 'location','experience1', 'qualification1', 'skill1', 'location1','search','search1','jdid','selectedpage','selectedpagecount','selectedpagecount1','selectedpage1','incompleted','completed'];
      const currentParams = new URLSearchParams(window.location.search);
  
      // Check if any of the parameters exist in the URL
      const hasTargetParams = paramsToClear.some(param => currentParams.has(param));
      if (!hasTargetParams){
        settableloadertrue(true);
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          pagecount: import_candidates,
          jd_id: isjd_id !== undefined ? isjd_id : '',
        }),
      ).then(() => {
        settableloadertrue(false);
      });
      if (!isEmpty(session)) {
        dispatch(SubsriptionMiddleWare()).then((res) => {
          setCredit(true);
          setSourceLimit(res.payload.user_credits.resume_credits);
        });
      }
    }

  }, [session]);

  useEffect(() => {
    localStorage.setItem('freeCheck', 'true');
    dispatch(bulkImportMiddleWare());
  }, []);
  useEffect(() => {
    sessionStorage.setItem('jd_id_in', isBulktab);
  }, [isBulktab]);

  const {
    emp_pool,
    total_count,
    completed,
    incompleted,
    bulkInitalLoader,
    features_balance,
    upDateloader,
    is_plan,
    jdId,
    import_candidates,
    params,
    isLoading,
  } = useSelector(
    ({
      bulkUploadedCandidatesReducers,
      bulkImportReducers,
      permissionReducers,
      SubscriptionReducers,
    }: RootState) => {
      return {
        emp_pool: bulkUploadedCandidatesReducers.emp_pool,
        isLoading: bulkUploadedCandidatesReducers.isLoading,
        params: bulkUploadedCandidatesReducers.params,
        total_count: bulkUploadedCandidatesReducers.total_count,
        completed: bulkUploadedCandidatesReducers.completed,
        incompleted: bulkUploadedCandidatesReducers.incompleted,
        bulkInitalLoader: bulkImportReducers.isLoading,
        features_balance: bulkImportReducers.features_balance,
        jdId: bulkImportReducers.jd_id,
        upDateloader: bulkUploadedCandidatesReducers.isLoading,
        is_plan: permissionReducers.is_plan,
        import_candidates: SubscriptionReducers.import_candidates,
      };
    },
  );
  const [isbulktabhandle, setbulktabhandle] = useState(null);
  const [isjd_id, setjd_id] = useState(jd_ids);
  const currentParams = new URLSearchParams(window.location.search);

  useEffect(() => {
   
    const paramsToClear = ['experience', 'qualification', 'skill', 'location','experience1', 'qualification1', 'skill1', 'location1','search','search1','jdid','selectedpage','selectedpagecount','selectedpagecount1','selectedpage1','incompleted','completed'];
   // const currentParams = new URLSearchParams(window.location.search);
   
    // Check if any of the parameters exist in the URL
    const hasTargetParams = paramsToClear.some(param => currentParams.has(param));
    if (!hasTargetParams){
      settableloadertrue(true);
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          pagecount: import_candidates,
          jd_id: isjd_id !== undefined ? isjd_id : '',
        }),
      ).then(() => {
        settableloadertrue(false);
      });
    }
  }, []);
 
  useEffect(() => {
    setbulktabhandle((prevIsBulktab) => {
      if (isBulktab === '2') {
        return prevIsBulktab;
      } else {
        return isBulktab;
      }
    });
  }, [isBulktab]);
  useEffect(() => {
    if (Number(isbulktabhandle) === 0) {
      document.title = 'Passive Candidates Database';
    }
    if (Number(isbulktabhandle) === 1) {
      document.title = 'Passive Candidates Database';
    }
  }, [isbulktabhandle]);

  useEffect(() => {
    if (!is_plan) {
      history.push('/account_setting/subscription');
    }
  });

  useEffect(() => {
    setFeaturesBalance(features_balance);
  }, [features_balance]);

  // Search Submit Function
  const handleSubmit = (values: FormProps) => {
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        search: values.searchValue,
        pagecount: import_candidates,
        jd_id: isjd_id !== undefined ? isjd_id : '',
      }),
    )
      .then((response) => {
        setSearch(response.payload.search);
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  const formik = useFormik({
    initialValues: initial,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  // Filter Total Candidates
  const handleTotal = () => {
    currentParams.delete('incompleted');
    currentParams.delete('completed');
    currentParams.delete('selectedpagecount');
    currentParams.delete('selectedpage');
    history.push({
      pathname: window.location.pathname, // Keeps the current path
      search: currentParams.toString(),
    });
    const location=currentParams.get('location')
const skill=currentParams.get('skill')
const experience=currentParams.get('experience')
const qualification=currentParams.get('qualification')
let resultpass=''
if(qualification){
const qualificationObject = JSON.parse(qualification);
const checkedLabels = qualificationObject
.filter((item) => item.checked)
.map((item) => item.label);
resultpass = checkedLabels.join(", ");}
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        total: total_count,
        search: formik.values.searchValue,
        page: pageNumber + 1,
        pagecount: import_candidates,
        jd_id: isjd_id !== undefined ? isjd_id : '',
        work_experience: experience,
        location: location,
        skill_match: skill,
        education_level: resultpass==='any'?"":resultpass,
      }),
    )
      .then(() => {
        getFocus('candidates__input');
        setPageNumber(0);
        getBlur('candidates__input');
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  // Filter Completed Candidates
  const handleCompleted = () => {
    currentParams.set('completed', String(completed));
    currentParams.delete('total');
    currentParams.delete('incompleted');
     currentParams.delete('selectedpagecount');
    currentParams.delete('selectedpage');
    history.push({
      pathname: window.location.pathname, // Keeps the current path
      search: currentParams.toString(),
    });
    const location=currentParams.get('location')
    const skill=currentParams.get('skill')
    const experience=currentParams.get('experience')
    const qualification=currentParams.get('qualification')
    let resultpass=''
    if(qualification){
    const qualificationObject = JSON.parse(qualification);
    const checkedLabels = qualificationObject
    .filter((item) => item.checked)
    .map((item) => item.label);
    resultpass = checkedLabels.join(", ");}
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        completed,
        search: formik.values.searchValue,
        page: pageNumber + 1,
        pagecount: import_candidates,
        jd_id: isjd_id !== undefined ? isjd_id : '',
        work_experience: experience,
        location: location,
        skill_match: skill,
        education_level: resultpass==='any'?"":resultpass,
      }),
    )
      .then(() => {
        getFocus('candidates__input');
        setPageNumber(0);
        getBlur('candidates__input');
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  // Filter InCompeleted Candidates
  const handleInCompeleted = () => {
    currentParams.set('incompleted', String(incompleted));
    currentParams.delete('total');
    currentParams.delete('completed');
     currentParams.delete('selectedpagecount');
     currentParams.delete('selectedpage');
    history.push({
      pathname: window.location.pathname, // Keeps the current path
      search: currentParams.toString(),
    });
    const location=currentParams.get('location')
    const skill=currentParams.get('skill')
    const experience=currentParams.get('experience')
    const qualification=currentParams.get('qualification')
    let resultpass=''
    if(qualification){
    const qualificationObject = JSON.parse(qualification);
    const checkedLabels = qualificationObject
    .filter((item) => item.checked)
    .map((item) => item.label);
    resultpass = checkedLabels.join(", ");}
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        incompleted,
        search: formik.values.searchValue,
        page: pageNumber + 1,
        pagecount: import_candidates,
        jd_id: isjd_id !== undefined ? isjd_id : '',
        work_experience: experience,
        location: location,
        skill_match: skill,
        education_level: resultpass==='any'?"":resultpass,
      }),
    )
      .then(() => {
        getFocus('candidates__input');
        setPageNumber(0);
        getBlur('candidates__input');
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };
  let value = unlimitedHelper(isFeaturesBalance);
  let value1 = value as number;

  return (
    <>
      <SingleButton
        btnTitle="OK"
        title={`Contact credits purchased successfully. Your Available Contact Credits: ${isSourceLimit}`}
        open={isCredit}
        btnOnclick={() => setCredit(false)}
      />
      <div className={styles.overAllContainer}>
        <Flex className={styles.overAlll}>
          {bulkInitalLoader && !istableloadertrue && !upDateloader && (
            <Loader />
          )}

          <Flex row className={styles.ribbon} between>
            <Flex marginTop={9} marginLeft={8}>
              <Text size={16} bold color="theme">
                {/* Import Candidates */}
                Passive Candidates Database
              </Text>
            </Flex>
            <Flex>
              <div className={styles.triangle}></div>
            </Flex>
          </Flex>

          <p
            style={{
              color: '#333333',
              marginBottom: '0px',
              padding: '10px 0px',
              fontSize: '13px',
            }}
          >
            Import the resumes and create your own database to match candidates
            with the posted jobs. You can Import up to 150 resumes at a time.
          </p>

          <Flex className={styles.tabFlex}>
            <Flex className={styles.candidatesText} row center>
              {/* <Totalcount
                name="AI Resume Parsing Credit"
                numbers={value1}
              /> */}
              <Flex title="AI Resume Parsing Credits">
                <SvgParsing width={15} height={15} />
              </Flex>
              <Flex marginLeft={2}>
                <Text>: {value1}</Text>
              </Flex>
            </Flex>
            <BulkImportTabs
              emp_pool={emp_pool}
              jd_id={jdId}
              total_count={total_count}
              completed={completed}
              incompleted={incompleted}
              handleTotal={handleTotal}
              handleSubmit={formik.handleSubmit}
              setbulktab={setbulktab}
              handleCompleted={handleCompleted}
              handleInCompeleted={handleInCompeleted}
              searchValue={formik.values.searchValue}
              searchHandleChange={formik.handleChange('searchValue')}
              features_balance={isFeaturesBalance}
              setFeaturesBalance={setFeaturesBalance}
              isSearch={isSearch}
              formik={formik}
              istableloadertrue={istableloadertrue}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              upDateloader={upDateloader}
              params={params}
              isBulkTab={isBulktab}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default BulkImportScreen;
