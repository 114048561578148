import { useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import Totalcount from '../../globulization/TotalCount';
import SvgSearch from '../../icons/SvgSearch';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import {
  enterKeyPress,
  getBlur,
  getFocus,
  isEmpty,
  unlimitedHelper,
} from '../../uikit/helper';
import { Card, InputCheckBox, Modal, SelectTag } from '../../uikit';
import Loader from '../../uikit/Loader/Loader';
import Button from '../../uikit/Button/Button';
import Flex from '../../uikit/Flex/Flex';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import InputText from '../../uikit/InputText/InputText';
import Tabel from '../../uikit/Table/Table';
import Text from '../../uikit/Text/Text';
import Toast from '../../uikit/Toast/Toast';
import SvgRefresh from '../../icons/SvgRefresh';
// import ProfileView from '../applicantpipelinemodule/ProfileView';
import ZitaMatchCandidateDrawer from '../zitamatchcandidatemodule/ZitaMatchCandidateDrawer';
import { CANCEL, ERROR_MESSAGE } from '../constValue';
import YesOrNoModal from '../common/YesOrNoModal';
import { dashBoardMiddleWare } from '../dashboardmodule/empdashboard/store/dashboardmiddleware';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import SvgFilterRefresh from '../../icons/SvgFilterRefresh';
import SvgArrowUp from '../../icons/SvgArrowUp';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import SvgClose from '../../icons/SvgClose';
import Tour from '../tour/tour';
import Highlighter from '../tour/highlighter';
import { tourdataget } from '../tour/store/middleware/middleware';

// import {

//   uploadedProfileViewMiddleWare,
// } from '../bulkimportmodule/store/middleware/bulkImportMiddleware';
import CandidateDatabase from '../bulkimportmodule/CandidateDatabaseTab';

import ParsingLoadingModal from '../bulkimportmodule/ParsingLoadingModal';
import ProfileViewModal from '../bulkimportmodule/ProfileViewModal';
// import { experienceOption, qualificationOption } from '../bulkimportmodule/bul';
import CandidateDatabaseTabFilter from '../bulkimportmodule/CandidateDatabaseTabFilter';
import { qualificationFilterHelper } from '../bulkimportmodule/BulkimportHelper';
import styles from '../bulkimportmodule/candidatedatabasetab.module.css';
import SvgIntomark from '../../icons/Intomark';
import {
  unlockedCandidatesmiddleware,
  SourcingCandidatesMiddleWare,
  uploadedProfileViewMiddleWare,
} from './store/middleware/UnlockedCandidatesMiddleware';
import UnlockedCandidatesFilter from './UnlockedCandidatesFilter';
import { title, Sampledata, columnsCandi } from './tablestructure';
import { EmpPoolEntity } from './types';

type Tabs = 'total' | 'completed' | 'inCompleted';

type Props = {
  handleTotal: () => void;
  handleSubmit: any;
  handleCompleted: () => void;
  handleInCompeleted: () => void;
  searchValue: string;
  searchHandleChange: (a: any) => void;
  setKey: (arg: string) => void;
  // features_balance: number;
  setFeaturesBalance: (a: number | null) => void;
  isSearch: number;
  formik: any;
  setPageNumber: (a: number) => void;
  pageNumber: number;
  isBulkTab: any;
  isCandiTableLoader?: any;
  setCandiTableLoader?: any;
  settabchange?: (val?: any) => void;
  tabchange?: any;
  setmainexperiencevalue?: (val?: any) => void;
  setmainlocation?: (val?: any) => void;
  setmainskillsOptionsList?: (val?: any) => void;
  setmainqaValue?: (val?: any) => void;
};

const CandidateDatabaseTab = ({
  handleTotal,
  handleSubmit,
  handleCompleted,
  handleInCompeleted,
  searchValue,
  searchHandleChange,
  setKey,
  setFeaturesBalance,
  isSearch,
  formik,
  setPageNumber,
  pageNumber,
  isBulkTab,
  isCandiTableLoader,
  setCandiTableLoader,
  settabchange,
  tabchange,
  setmainexperiencevalue,
  setmainlocation,
  setmainskillsOptionsList,
  setmainqaValue,
}: Props) => {
  //subcription setstate
  const [isopensubcription, setopensubcription] = useState(false);
  const history = useHistory();
  const dropDownRef = useRef(null);
  const myRef = useRef<any>();
  const selectInputRef = useRef<any>();
  const [isParse, setParse] = useState(false);
  const [isTableLoader, setTableLoader] = useState(false);
  const [isImport, setImport] = useState<any>('');
  const [isFile, setFile] = useState<string>('');
  // const [isPersonal, setPersonal] = useState<any>([]);
  // const [isAddDetail, setAddDetail] = useState<any>([]);
  const [isProfileView, setProfileView] = useState(false);
  const [isOpenProfile, setOpenProfile] = useState(false);
  const [isUpgrade, setUpgrade] = useState(false);
  const [isTab, setTab] = useState<Tabs>('total');
  // const [isCandiTableLoader, setCandiTableLoader] = useState(false);
  const [isCanId, setCanId] = useState<any>([]);
  const [ispagecount, setpagecount] = useState<any>(null);
  const dispatch: AppDispatch = useDispatch();
  const [model, setmodel] = useState(false);
  const [Relocate, setRelocate] = useState(false);
  const [verify, setverify] = useState(false);

  const [showDropDown, setShowDropDown] = useState(false);
  const [change, setchange] = useState(false);
  const [location, setlocation] = useState('');
  const [experience, setexperience] = useState('');
  const [experiencevalue, setexperiencevalue] = useState('');
  const [isBachelors, setBachelors] = useState(false);
  const [isDoctorate, setDoctorate] = useState(false);
  const [isDiploma, setDiploma] = useState(false);
  const [isMasters, setMasters] = useState(false);
  const [isAny, setAny] = useState(true);
  const [isOther, setOther] = useState(false);
  const [isSampleData, setSampleData] = useState(false);
  const [componentHeight, setComponentHeight] = useState(
    window.innerHeight - 355,
  );
  const [searchclearurl, setsearchclearurl] = useState(false);

  const currentParams = new URLSearchParams(window.location.search);
  // const history=useHistory()
  // Profile View Function

  //  const hanldeEditProfileView = (id: number) => {
  //   setCanId(id);
  //   setOpenProfile(false);
  //   setProfileView(true);
  //    dispatch(uploadedProfileViewMiddleWare({ id })).then((res) => {
  //     setFile(res.payload.resume_file_path);
  //   });
  // };
  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 355);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hanldeEditProfileView = (id: number) => {
    setCanId(id);
    setProfileView(false);
    setOpenProfile(true);
    dispatch(uploadedProfileViewMiddleWare({ id })).then((res) => {
      setFile(res.payload.resume_file_path);
    });
  };

  useEffect(() => {
    if (searchValue === '') {
      const paramsToClear = [
        'experience',
        'qualification',
        'skill',
        'location',
        'relocate',
        'search',
      ];

      // Check if any of the parameters exist in the URL
      const hasTargetParams = paramsToClear.some((param) =>
        currentParams.has(param),
      );
      if (!hasTargetParams) {
        dispatch(
          SourcingCandidatesMiddleWare({ pagecount: import_candidates }),
        );
      }
    }
  }, [searchValue]);

  const handlechange = () => {
    setmodel(true);
    dispatch(dashBoardMiddleWare());
  };

  const {
    datas,
    emp_pool,
    total_count,
    completed,
    incompleted,
    features_balance,
    upDateloader,
    current_plan,
    current_resume_count,
    data,
    import_candidates,
    params,
    Permission1,
    super_user,
    status,
    explore,
    candidate_location,
  } = useSelector(
    ({
      unlockedCandidatesReducers,
      bulkImportReducers,
      dashboardEmpReducers,
      SubscriptionReducers,
      zitaMatchprogressReducers,
      permissionReducers,
      TourdataReducer,
    }: RootState) => {
      return {
        datas: unlockedCandidatesReducers,
        emp_pool: unlockedCandidatesReducers.emp_pool,
        total_count: unlockedCandidatesReducers.total_count,
        completed: unlockedCandidatesReducers.completed,
        incompleted: unlockedCandidatesReducers.incompleted,
        features_balance: bulkImportReducers.features_balance,
        upDateloader: unlockedCandidatesReducers.isLoading,
        current_plan: SubscriptionReducers.current_plan,
        current_resume_count: SubscriptionReducers.current_resume_count,
        data: zitaMatchprogressReducers.data,
        import_candidates: SubscriptionReducers.import_candidates,
        params: unlockedCandidatesReducers.params,
        Permission1: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        explore: TourdataReducer.is_first_login,
        status: TourdataReducer.is_steps,
        candidate_location: unlockedCandidatesReducers.candidate_location,
      };
    },
  );

  useEffect(() => {
    const paramsToClear = [
      'experience',
      'qualification',
      'skill',
      'location',
      'search',
    ];
    // const currentParams = new URLSearchParams(window.location.search);

    // Check if any of the parameters exist in the URL
    const hasTargetParams = paramsToClear.some((param) =>
      currentParams.has(param),
    );
    if (!hasTargetParams) {
      dispatch(unlockedCandidatesmiddleware());
    }
  }, []);

  useEffect(() => {
    setpagecount(import_candidates);
  }, [import_candidates]);

  const [isPageTab, setPageTab] = useState(total_count);

  const columns = useMemo(
    () =>
      title(
        hanldeEditProfileView,
        setFeaturesBalance,
        searchValue,
        isTab,
        total_count,
        completed,
        incompleted,
        pageNumber,
        emp_pool,
      ),
    [emp_pool],
  );

  useEffect(() => {
    if (isTab === 'total') {
      setPageTab(total_count);
    }
    if (isTab === 'completed') {
      setPageTab(completed);
    }
    if (isTab === 'inCompleted') {
      setPageTab(incompleted);
    }
  }, [isTab, upDateloader]);
  // Pagination
  const usersPerPage = import_candidates;
  const pageCount = Math.ceil(isPageTab / usersPerPage);

  const handleCloseParse = () => {
    setParse(false);
    localStorage.setItem('bulk_loader', 'true');
  };

  const handleOpenParse = () => setParse(true);

  useEffect(() => {
    if (data === true) {
      localStorage.setItem('bulk_loader', 'true');
    }
    if (data === false) {
      localStorage.setItem('bulk_loader', 'false');
    }
  }, [data]);

  const qaValue = qualificationFilterHelper(
    isAny,
    isBachelors,
    isDoctorate,
    isDiploma,
    isMasters,
    isOther,
  );

  const skillsOptionsList =
    formik.values.skillValue &&
    formik.values.skillValue.map((optionList: { value: string }) => {
      return optionList.value;
    });

  // Pagination Function
  const handleSetPagination = (a: number, ispagecounts?: number) => {
    currentParams.set('pagination', String(a));

    // Update the URL with modified search parameters
    history.push({
      pathname: window.location.pathname,
      search: currentParams.toString(),
    });
    setPageNumber(a);
    setCandiTableLoader(true);
    setTableLoader(true);
    if (isTab === 'total') {
      dispatch(
        SourcingCandidatesMiddleWare({
          page: a + 1,
          total: total_count,
          search: searchValue,
          pagecount: ispagecounts,
          work_experience: experiencevalue,
          location: location,
          skill_match: skillsOptionsList?.join(', '),
          education_level: qaValue,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setCandiTableLoader(false);
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          setCandiTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'total') {
      dispatch(
        SourcingCandidatesMiddleWare({
          page: a + 1,
          total: total_count,
          search: searchValue,
          pagecount: ispagecounts,
          work_experience: experiencevalue,
          location: location,
          skill_match: skillsOptionsList?.join(', '),
          education_level: qaValue,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setCandiTableLoader(false);
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          setCandiTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'inCompleted') {
      dispatch(
        SourcingCandidatesMiddleWare({
          page: a + 1,
          incompleted,
          search: searchValue,
          pagecount: ispagecounts,
          work_experience: experiencevalue,
          location: location,
          skill_match: skillsOptionsList?.join(', '),
          education_level: qaValue,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
          setCandiTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'completed') {
      dispatch(
        SourcingCandidatesMiddleWare({
          page: a + 1,
          completed,
          search: searchValue,
          pagecount: ispagecounts,
          work_experience: experiencevalue,
          location: location,
          skill_match: skillsOptionsList?.join(', '),
          education_level: qaValue,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
          setCandiTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
  };

  // Table Refresh Function
  const hanldeSvgRefresh = (e: any) => {
    const search = currentParams.get('search');
    const location1 = currentParams.get('location');
    const skill = currentParams.get('skill');
    const experience1 = currentParams.get('experience');
    const qualification = currentParams.get('qualification');
    let resultpass = '';
    if (qualification) {
      const qualificationObject = JSON.parse(qualification);
      const checkedLabels = qualificationObject
        .filter((item) => item.checked)
        .map((item) => item.label);
      resultpass = checkedLabels.join(', ');
    }
    const page = currentParams.get('pagination');
    setCandiTableLoader(true);
    formik.setFieldValue('searchValue', '');
    setTab('total');
    e.preventDefault();
    dispatch(
      SourcingCandidatesMiddleWare({
        page: Number(page) +1,
        pagecount: import_candidates,
        work_experience: experience1,
        location: location1,
        skill_match: skill,
        education_level: resultpass === 'any' ? '' : resultpass,
        search: search,
      }),
    ).then(() => {
      getFocus('candidates__input');
      setPageNumber(Number(page));
      getBlur('candidates__input');
      setCandiTableLoader(false);
    });
  };

  const hanldeSvgRefreshOnUpdate = (e: any) => {
    const search = currentParams.get('search');
    const location1 = currentParams.get('location');
    const skill = currentParams.get('skill');
    const experience1 = currentParams.get('experience');
    const qualification = currentParams.get('qualification');
    let resultpass = '';
    if (qualification) {
      const qualificationObject = JSON.parse(qualification);
      const checkedLabels = qualificationObject
        .filter((item) => item.checked)
        .map((item) => item.label);
      resultpass = checkedLabels.join(', ');
    }
    const page = currentParams.get('selectedpage') || 1;
    const numberpage = Number(page);
    setOpenProfile(false);
    setCandiTableLoader(true);
    // setCanId([]);
    formik.setFieldValue('searchValue', '');
    setTab('total');
    e.preventDefault();
    dispatch(
      SourcingCandidatesMiddleWare({
        page: pageNumber + 1,
        pagecount: import_candidates,
        work_experience: experience1,
        location: location1,
        skill_match: skill,
        education_level: resultpass === 'any' ? '' : resultpass,
        search: search,
      }),
    ).then(() => {
      getFocus('candidates__input');
      setPageNumber(0);
      getBlur('candidates__input');
      setCandiTableLoader(false);
    });
  };

  // updating the state to dispatch in  main
  useEffect(() => {
    setmainexperiencevalue(experiencevalue);
    setmainlocation(location);
    setmainskillsOptionsList(skillsOptionsList?.join(', '));
    setmainqaValue(qaValue);
  }, [experiencevalue, location, formik.values.skillValue, emp_pool]);

  const manageUser = () => {
    // window.location.replace()
    window.location.replace(
      window.location.origin + '/account_setting/subscription?planFocus=focus',
    );
    // history.push('/account_setting/settings?planFocus=focus');
  };

  const handleCloseImportModal = () => {
    setImport(localStorage.setItem('isImport', 'false'));
  };

  useEffect(() => {
    setImport(localStorage.getItem('isImport'));
  });

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleSubmit();
      setsearchclearurl(true);
    }
  };
  const update = () => {
    setverify(false);
  };
  const closemodel = () => {
    setverify(false);
    setmodel(false);
  };
  const handlefunction = () => {
    setverify(true);
    formik.setFieldValue('parser', '0');
  };
  const handlefunction1 = () => {
    setverify(true);
    formik.setFieldValue('parser', '1');
  };

  const onClearsearch = () => {
    formik.setFieldValue('searchValue', '');

    currentParams.delete('search'); // Remove the 'location' parameter
    history.push({ search: currentParams.toString() }); // Update the URL without reloading
    setsearchclearurl(true);
  };

  const value = emp_pool?.length;
  const value1 = value > 4;
  const isBulkLoaderprocess = localStorage.getItem('bulk_loader');
  const [step5, setstep5] = useState(false);
  const [currentindex, setcurrentindex] = useState(0);
  useEffect(() => {
    dispatch(tourdataget({}));
    if (status) {
      setstep5(true);
    }
  }, [status]);

  const searchfunction = (e: any) => {
    searchHandleChange(e);
    const url = new URL(window.location.href);
    url.searchParams.set('search', e.target.value);
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    if (localStorage.getItem('tabkeymanagecandidate') === '2') {
      const searchurl = currentParams.get('search');
      if (searchurl) formik.setFieldValue('searchValue', searchurl);
    }
  }, []);

  return (
    <Flex className={styles.candidatedatabase}>
      {isOpenProfile && (
        <ProfileViewModal
          filePath={isFile}
          open={isOpenProfile}
          canId={isCanId}
          // cancel={() => setOpenProfile(false)}
          refreshOnUpdate={(e: any) => hanldeSvgRefreshOnUpdate(e)}
        />
      )}
      <ParsingLoadingModal
        loader
        title={'Parsing and loading resumes in background.....'}
        open={isParse}
        close={handleCloseParse}
        des={
          <Text className={styles.des}>
            {`This might take a few minutes to process the files. We'll notify you
            by email when completed. Moving away from this page will not stop
            the process.`}
          </Text>
        }
      />

      <ParsingLoadingModal
        info
        css
        title={'Resumes imported successfully....'}
        open={isImport === 'true' ? true : false}
        close={handleCloseImportModal}
        des={
          <Text>
            Your available candidates limits are{' '}
            {unlimitedHelper(features_balance)}. Please check on the{' '}
            <Text
              onClick={() => {
                setKey('2');
                handleCloseImportModal();
              }}
              color="link"
            >
              log file
            </Text>{' '}
            to get the complete import details.
          </Text>
        }
      />

      <Flex>
        <UnlockedCandidatesFilter
          isBachelors={isBachelors}
          setBachelors={setBachelors}
          isDoctorate={isDoctorate}
          setDoctorate={setDoctorate}
          isDiploma={isDiploma}
          setDiploma={setDiploma}
          isMasters={isMasters}
          setMasters={setMasters}
          isAny={isAny}
          setAny={setAny}
          isOther={isOther}
          setOther={setOther}
          experience={experience}
          setexperience={setexperience}
          location={location}
          setlocation={setlocation}
          skillsOptionsList={skillsOptionsList}
          qaValue={qaValue}
          import_candidates={import_candidates}
          formik={formik}
          ispagecount={ispagecount}
          isBulkTab={isBulkTab}
          candidate_location={candidate_location}
          setCandiTableLoader={setCandiTableLoader}
          setsearchclearurl={setsearchclearurl}
          searchclearurl={searchclearurl}
          settabchange={settabchange}
          tabchange={tabchange}
          experiencevalue={experiencevalue}
          setexperiencevalue={setexperiencevalue}
          setPageNumber={setPageNumber}
        />
      </Flex>
      <Flex center row between>
        <Flex row center className={styles.filterStyle}>
          <Flex
            row
            center
            className={styles.marginLeft}
            title="Filter Total Candidates"
          >
            {total_count === 0 ? (
              <Text
                bold={isTab === 'total'}
                className={styles.linkSpaceDefault}
              >
                <Totalcount
                  name="Total Unlocked Candidates"
                  numbers={total_count}
                  click={total_count === 0 ? false : true}
                />
              </Text>
            ) : (
              <Text
                onClick={() => {
                  handleTotal();
                  setTab('total');
                }}
                bold={isTab === 'total'}
                className={styles.linkSpace}
                color={'link'}
                style={{ cursor: 'pointer' }}
              >
                <Totalcount
                  name="Total Unlocked Candidates"
                  numbers={total_count}
                  click={total_count === 0 ? false : true}
                />
              </Text>
            )}
          </Flex>
          {total_count !== 0 && (
            <>
              <Flex
                row
                center
                className={styles.marginLeft}
                title="Filter Completed Profiles"
              >
                {completed === 0 ? (
                  <Text
                    bold={isTab === 'completed'}
                    className={styles.linkSpaceDefault}
                    style={{ cursor: 'pointer' }}
                  >
                    <Totalcount
                      name="Completed Profiles"
                      numbers={completed}
                      click
                    />
                  </Text>
                ) : (
                  <Text
                    onClick={() => {
                      handleCompleted();
                      setTab('completed');
                    }}
                    bold={isTab === 'completed'}
                    color={'link'}
                    className={styles.linkSpace}
                    style={{ cursor: 'pointer' }}
                  >
                    <Totalcount
                      name="Completed Profiles"
                      numbers={completed}
                      click
                    />
                  </Text>
                )}
              </Flex>

              <Flex
                row
                center
                className={styles.inComplete}
                title="Filter Incomplete Profiles"
              >
                {incompleted === 0 ? (
                  <Text
                    bold={isTab === 'inCompleted'}
                    className={styles.linkSpaceDefault}
                    style={{ cursor: 'pointer' }}
                  >
                    <Totalcount
                      name="Incomplete Profiles"
                      numbers={incompleted}
                      click={incompleted === 0 ? false : true}
                    />
                  </Text>
                ) : (
                  <Text
                    color={'link'}
                    onClick={() => {
                      handleInCompeleted();
                      setTab('inCompleted');
                    }}
                    bold={isTab === 'inCompleted'}
                    className={styles.linkSpace}
                    style={{ cursor: 'pointer' }}
                  >
                    <Totalcount
                      name="Incomplete Profiles"
                      numbers={incompleted}
                      click
                    />
                  </Text>
                )}
              </Flex>
            </>
          )}
          {total_count > 0 && (
            <CustomTooltip title={'Refresh table'}>
              <div
                tabIndex={-1}
                role={'button'}
                onKeyPress={() => {}}
                className={styles.svgRefresh}
                onClick={(e) => {
                  hanldeSvgRefresh(e);
                }}
              >
                <SvgRefresh />
              </div>
            </CustomTooltip>
          )}
        </Flex>
        <Flex row center className={styles.inputConatiner}>
          <Flex style={{ position: 'relative' }}>
            <InputText
              className={styles.inputWidth}
              inputConatinerClass={styles.inputStyle}
              placeholder={'Search candidates by name or email'}
              value={searchValue}
              onChange={searchfunction}
              id={'candidates__input'}
              actionRight={() => (
                <label style={{ margin: 0, marginTop: '3px' }}>
                  <SvgSearch width={14} height={14} />
                </label>
              )}
              onKeyPress={handleKeyPress}
            />
            {searchValue.trim() !== '' && (
              <Flex className={styles.crossIcon} onClick={onClearsearch}>
                <SvgIntomark width={14} height={14} fill="#888888" />
              </Flex>
            )}
          </Flex>
          <Button
            disabled={searchValue === '' ? true : false}
            onClick={() => {
              handleSubmit();
              setsearchclearurl(true);
            }}
          >
            {/* Find Candidates */}
            Search
          </Button>
        </Flex>
      </Flex>
      {isCandiTableLoader ? (
        <Flex center middle>
          <Loader size={'medium'} />
        </Flex>
      ) : emp_pool?.length === 0 &&
        !params.includes('search') &&
        !params.includes(
          'education_level',
          'work_experience',
          'location',
          'skill_match',
        ) ? (
        <>
          <Flex
            style={{ display: 'flex' }}
            // height={window.innerHeight - 355}
            // style={{ opacity: 0.5, pointerEvents: 'none' }}
          >
            {/* <Flex>
                <Text color='placeholder' size={13} align='center'>
                  Your Talent pool is Empty
                </Text>
                <Text color='placeholder' size={13} align='center'>
                  Import candidates to build your database
                </Text>
              </Flex> */}

            {!isSampleData ? (
              <>
                <Flex style={{ opacity: 0.5, pointerEvents: 'none' }}>
                  <Tabel columns={columnsCandi} />
                </Flex>
                <Flex middle center height={componentHeight}>
                  <Flex center>
                    {/* <Flex center>
                        <Text className={styles.postyet1} size={13}>
                          No Candidates Imported - Yet !
                        </Text>
                      </Flex> */}
                    <Flex center>
                      <Text
                        className={styles.postyet2}
                        size={13}
                        color="placeholder"
                      >
                        Your Talent pool is Empty
                      </Text>
                    </Flex>
                    <Flex center>
                      <Text
                        className={styles.postyet3}
                        size={13}
                        color="placeholder"
                      >
                        Import candidates to build your database.
                      </Text>
                    </Flex>
                    <Flex row middle>
                      <Text style={{ marginRight: '4px' }} color="placeholder">
                        To refer some sample Candidate details
                      </Text>
                      <Text
                        color="link"
                        bold
                        onClick={() => setSampleData(true)}
                      >
                        Click here
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            ) : (
              <>
                <Flex style={{ opacity: 0.5, pointerEvents: 'none' }}>
                  <Tabel dataSource={Sampledata} columns={columnsCandi} />
                </Flex>
                <Flex style={{ position: 'absolute', left: '46%', top: '67%' }}>
                  <Text color="link" bold onClick={() => setSampleData(false)}>
                    Go back
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </>
      ) : (
        <Flex flex={1}>
          <Tabel
            empty={
              (params?.includes('search') && 'No candidate found') ||
              (params?.includes('education_level') &&
                params?.includes('work_experience') &&
                params?.includes('location') &&
                params?.includes('skill_match') &&
                'No candidate found')
            }
            dataSource={emp_pool}
            columns={columns}
            // isLoader={isTableLoader}
            pageCount={pageCount}
            pageNumber={Number(pageNumber)}
            handleSetPagination={handleSetPagination}
            isCandiTableLoader={isCandiTableLoader}
            isPageTab={isPageTab}
            setpagecount={setpagecount}
            only_pagination={true}
            pagination_count={ispagecount}
          />
        </Flex>
      )}

      {isopensubcription && (
        <>
          <SubscriptionModal
            openmodel={isopensubcription}
            setopensubcription={setopensubcription}
            addon_name={['AI Resume Parsing Credits']}
            differece_key={['AI_matching']}
            purpose={
              <div>
                <strong>
                  <text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                  >
                    Heads Up!
                  </text>
                </strong>
                <strong>AI Resume Parsing Limit Reached:</strong> To continue
                viewing applicants, you must &apos;Upgrade your plan&apos; or
                &apos;Buy additional AI Resume Parsing Credits add-on&apos;.
              </div>
            }
            purpose_freetrial={
              <div>
                <strong>
                  <text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                  >
                    Heads Up!
                  </text>
                </strong>
                <strong>AI Resume Parsing Limit Reached:</strong> To continue
                viewing applicants, you must &apos;Upgrade your plan&apos;.
              </div>
            }
            purpose_addon={
              <div>
                <strong>
                  <text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                  >
                    Heads Up!
                  </text>
                </strong>
                <strong>AI Resume Parsing Limit Reached:</strong> To continue
                viewing applicants, you must &apos;Buy additional AI Resume
                Parsing Credits add-on&apos;.
              </div>
            }
          />
        </>
      )}
    </Flex>
  );
};

export default CandidateDatabaseTab;
