import { SetStateAction, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import StarsRating from 'react-star-rate';
import { Card, Modal, Button, LinkWrapper, Toast } from '../../uikit';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Loader from '../../uikit/Loader/Loader';
import SvgClose from '../../icons/SvgClose';
import SvgshareIcon from '../../icons/SvgShareIconview';
import SvgCSV from '../../icons/SvgCSV';
import SvgRight from '../../icons/SvgRight';
import SvgLocationicon from '../../icons/SvgLocationicon';
import SvgLeft from '../../icons/SvgLeft';
import Svgeditingnotes from '../../icons/editingnotes';
import SvgJobselection from '../../icons/SvgJobselection';
import SvgAngle from '../../icons/SvgAngle';
import SvgAdd from '../../icons/SvgAdd';
import Avatar from '../../uikit/Avatar';
import { isEmpty, toCamelCase } from '../../uikit/helper';
import { AppDispatch } from '../../store';
import MyLottieComponent from '../../loader/Loader';
import comloader from '../../actions/ComparitiveAnalysis.json';
import styles from './comparativeanalysis.module.css';
import Addcandidatesmodal from './addcandidatesmodel';
import Editcriteriamodal from './editmatchingcriteriaModal';
import {
  comparativeanalysismiddleware,
  comparativecsvdownloadmiddleware,
  comparativesearchingdatamiddleware,
} from './store/middleware/comparativemiddleware';

const cx = classNames.bind(styles);
type ParamsType = {
  jdId: string;
};
type Props = {
  selectedcriteria: any;
  isoveralldata: any;
  setkey: (val: string) => void;
  iskey: string;
};
const ComparativeanalysisModal = ({
  selectedcriteria,
  isoveralldata,
  iskey,
  setkey,
}: Props) => {
  const [addmodel, setaddmodel] = useState(false);
  const { jdId } = useParams<ParamsType>();
  const [ids, setid] = useState(0);
  const [datas, setsdatas] = useState([]);
  const [firstCandidate, setFirstCandidate] = useState([isoveralldata[0]]);
  useEffect(() => {}, [isoveralldata]);

  const select_id = (val, candidate) => {
    setFirstCandidate([candidate]);
    setid(val);
  };

  return (
    <>
      {firstCandidate.map((data, index) => (
        <>
          <Flex key={index} marginBottom={15}>
            <Flex>
              <Flex
                row
                between
                flex={12}
                marginBottom={10}
                marginTop={4}
                center
              >
                <Flex flex={3} row center>
                  <Flex>Overall Score based on the criteria : </Flex>
                  <Flex marginLeft={6}>
                    {Math.round(data.Average_match_percentage) <= 3 && (
                      <Text color="error" bold>
                        {Math.round(data.Average_match_percentage)}
                        /10
                      </Text>
                    )}
                    {Math.round(data.Average_match_percentage) > 7 && (
                      <Text color="success" bold>
                        {Math.round(data.Average_match_percentage)}
                        /10
                      </Text>
                    )}
                    {Math.round(data.Average_match_percentage) > 3 &&
                      Math.round(data.Average_match_percentage) <= 7 && (
                        <Text style={{ color: '#F29111' }} bold>
                          {Math.round(data.Average_match_percentage)}
                          /10
                        </Text>
                      )}
                  </Flex>
                </Flex>
                <Flex flex={3} row center marginLeft={20}>
                  <Flex>Recommendation to Hire : </Flex>
                  <Flex marginLeft={6}>
                    {Math.round(data.Average_match_percentage) <= 3 && (
                      <Text color="error" bold>
                        NO
                      </Text>
                    )}
                    {Math.round(data.Average_match_percentage) > 7 && (
                      <Text color="success" bold>
                        YES
                      </Text>
                    )}
                    {Math.round(data.Average_match_percentage) > 3 &&
                      Math.round(data.Average_match_percentage) <= 7 && (
                        <Text style={{ color: '#F29111' }} bold>
                          Neutral
                        </Text>
                      )}
                  </Flex>
                </Flex>

                <Flex flex={6}>
                  <Flex row end center>
                    <Flex className={styles.button_group}>
                      {isoveralldata &&
                        isoveralldata.map((val, ind) => (
                          <Flex
                            onClick={() => select_id(ind, val)}
                            key={ind}
                            style={{
                              backgroundColor: ids === ind ? '#581845' : '',
                              cursor: 'pointer',
                              borderRadius: '5px',
                            }}
                            width={110}
                            center
                            middle
                            title={`${toCamelCase(val.first_name)} ${
                              val.last_name ? toCamelCase(val.last_name) : ''
                            }`}
                          >
                            <Text
                              color="white"
                              className={styles.textelipssisforname}
                              style={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {toCamelCase(val.first_name)}
                            </Text>
                          </Flex>
                        ))}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <table className="parallel-columns-table">
                <colgroup>
                  <col style={{ width: '50%' }} />
                  <col style={{ width: '50%' }} />
                </colgroup>
                <thead style={{ border: '1px solid #A5889C' }}>
                  <tr style={{ border: '1px solid #A5889C' }}>
                    <th
                      style={{
                        border: '1px solid #A5889C',
                      }}
                    >
                      <Flex middle center style={{ padding: '3px' }}>
                        Skills Evaluation
                      </Flex>
                    </th>
                    <th
                      style={{
                        border: '1px solid #A5889C',
                        verticalAlign: 'top',
                      }}
                    >
                      <Flex middle center style={{ padding: '3px' }}>
                        Enhancement Analysis
                      </Flex>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ border: '1px solid #A5889C' }}>
                  <tr>
                    {data.Pros.length !== 0 ? (
                      <td className={styles.prosdata}>
                        <Flex flex={1}>
                          {data.Pros.map((Pros, indexno) => (
                            <ul key={indexno}>
                              <li className="indented-list">{Pros}</li>
                            </ul>
                          ))}
                        </Flex>
                      </td>
                    ) : (
                      <td
                        style={{ textAlign: 'center' }}
                        className={styles.prosdatano}
                      >
                        <Text size={12} color="gray">
                          No data found
                        </Text>
                      </td>
                    )}
                    {data.Cons.length !== 0 ? (
                      <td className={styles.Consdata}>
                        <Flex flex={1}>
                          {data.Cons.map((Cons, indexval) => (
                            <ul key={indexval}>
                              <li className="indented-list">{Cons}</li>
                            </ul>
                          ))}
                        </Flex>
                      </td>
                    ) : (
                      <td
                        style={{ textAlign: 'center' }}
                        className={styles.Consdatano}
                      >
                        <Text size={12} color="gray">
                          No data found
                        </Text>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </Flex>

            <Flex></Flex>
          </Flex>
        </>
      ))}
    </>
  );
};

export default ComparativeanalysisModal;
