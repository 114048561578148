import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import SvgCheckBox from '../../icons/SvgCheckBox';
import Svgwhatjobs from '../../icons/Svgwhatjobs';
import Svgjobhelper from '../../icons/Svgjobhelper';
import Svgdrjob from '../../icons/Svgdrjob';
import Svgjora from '../../icons/Svgjora';
import Svgcareerjet from '../../icons/SvgCareerjet';
import SingleButton from '../common/SingleButton';
import { routesPath } from '../../routes/routesPath';
import Button from '../../uikit/Button/Button';
import Card from '../../uikit/Card/Card';
import ExternalSwitch from '../../uikit/externalswitch/Externalswitch';
import { PRIMARY } from '../../uikit/Colors/colors';
import Flex from '../../uikit/Flex/Flex';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import Text from '../../uikit/Text/Text';
import { BACK } from '../constValue';
import { Loader } from '../../uikit';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import Blur from '../../uikit/Blur/blur';
import styles from './standardjobposting.module.css';
import {
  DrjobMiddleWare,
  JobboardGetMiddleWare,
  JobboardPostMiddleWare,
} from './store/middleware/createjdmiddleware';

type Props = {
  jdId: string;
  hanldePulish: () => void;
  ds_role: boolean;
  feature: number;
  whatjob: any;
  super_user: boolean;
  postLoader: any;
  iswhatjobs?: any;
  current_plan?: any;
  location?: any;
  jbdata?: any;
};

const StandardJobPosting = ({
  jdId,
  super_user,
  hanldePulish,
  ds_role,
  feature,
  whatjob,
  postLoader,
  iswhatjobs,
  current_plan,
  location,
  jbdata,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isopensubcription, setopensubcription] = useState(false);
  const [extarajobpost, setextarajobpost] = useState('1');
  const [drjobs, setdrjobs] = useState('1');
  const [myjobhelper, setmyjobhelper] = useState('1');
  const [careerjet, setcareerjet] = useState('1');
  const [jora, setjora] = useState('1');
  const [isOpenPlanDetails, setOpenPlanDetails] = useState(false);
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [isEditLoader, setEditLoader] = useState(false);
  const [isUpgradeLoader, setUpgradeLoader] = useState(false);
  useEffect(() => {
    if (iswhatjobs === true) {
      setextarajobpost('0');
    } else {
      setextarajobpost('1');
    }
  }, [iswhatjobs]);
  useEffect(() => {
    if (jbdata?.drjob === true) {
      setdrjobs('0');
    } else {
      setdrjobs('1');
    }
    if (jbdata?.myjobhelper === true) {
      setmyjobhelper('0');
    } else {
      setmyjobhelper('1');
    }
    if (jbdata?.career_jet === true) {
      setcareerjet('0');
    } else {
      setcareerjet('1');
    }
    // if (jbdata.jora === true) {
    //   setjora('0');
    // } else {
    //   setjora('1');
    // }
  }, []);
  const extarajob = () => {
    if (extarajobpost === '0') {
      setextarajobpost('1');
      whatjob(1);
    } else {
      setextarajobpost('0');
      whatjob(0);
    }
  };
  const drjobsfunction = () => {
    const formdata = new FormData();
    formdata.append('action', 'False');
    formdata.append('pk', jdId);
    if (drjobs === '0') {
      setdrjobs('1');
      const job_boards = {
        drjob: 'False',
      };

      formdata.append('job_boards', JSON.stringify(job_boards));
      dispatch(DrjobMiddleWare({ formData: formdata }))
        .then((res) => {
          if (res.payload) {
            // console.log(res)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setdrjobs('0');
      const job_boards = {
        drjob: 'True',
      };
      formdata.append('job_boards', JSON.stringify(job_boards));
      dispatch(DrjobMiddleWare({ formData: formdata }))
        .then((res) => {
          if (res.payload) {
            // console.log(res)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleJobBoard = (
    boardName?: string,
    currentBoardState?: string,
    setBoardState?: (value: string) => void,
  ) => {
    const formdata = new FormData();
    formdata.append('action', 'False');
    formdata.append('pk', jdId);
    const isBoardActive = currentBoardState === '0';
    setBoardState(isBoardActive ? '1' : '0');

    const job_boards = {
      jora: jora === '1' ? 'False' : 'True',
      career_jet: careerjet === '1' ? 'False' : 'True',
      myjobhelper: myjobhelper === '1' ? 'False' : 'True',
      [boardName]: isBoardActive === false ? 'True' : 'False',
    };

    formdata.append('job_boards', JSON.stringify(job_boards));

    dispatch(JobboardPostMiddleWare({ formData: formdata }))
      .then((res) => {
        if (res.payload) {
          dispatch(JobboardGetMiddleWare({ pk: jdId }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const finaltoggleJobBoard = () => {
    const formdata = new FormData();
    formdata.append('action', 'True');
    formdata.append('pk', jdId);
    const job_boards = {
      jora: jora === '1' ? 'False' : 'True',
      career_jet: careerjet === '1' ? 'False' : 'True',
      myjobhelper: myjobhelper === '1' ? 'False' : 'True',
    };
    formdata.append('job_boards', JSON.stringify(job_boards));
    dispatch(JobboardPostMiddleWare({ formData: formdata }))
      .then((res) => {
        if (res.payload) {
          dispatch(JobboardGetMiddleWare({ pk: jdId }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const manageLocation = () => {
    sessionStorage.setItem('superUserTab', '2');
  };

  const drjobpublish = () => {
    const formdata = new FormData();
    const job_boards = {
      drjob: drjobs === '1' ? 'False' : 'True',
    };
    formdata.append('action', 'True');
    formdata.append('job_boards', JSON.stringify(job_boards));
    formdata.append('pk', jdId);
    dispatch(DrjobMiddleWare({ formData: formdata }))
      .then((res) => {
        if (res.payload) {
          // console.log(res)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Flex>
      <Flex columnFlex>
        <Card className={styles.cardOverAll}>
          <Text size={14} color="primary" bold>
            Standard Job Posting
          </Text>
          <Text className={styles.defaultText}>
            Your job will be posted in the following site by default
          </Text>
          <Flex>
            <div className={styles.checkBox}>
              <Flex row center>
                <div style={{ opacity: 0.5, marginRight: 8 }}>
                  <SvgCheckBox fill={PRIMARY} />
                </div>
                <Text bold className={styles.textstyleinjobpost}>
                  Company Website Career Page
                </Text>
              </Flex>
            </div>
            <Text size={14} color="primary" bold>
              Other Job Boards
            </Text>
            {current_plan === 1 ? (
              <Flex row center>
                <Text
                  color="theme"
                  bold
                  size={13}
                  onClick={() => setopensubcription(true)}
                  style={{
                    marginRight: '2px',
                    marginTop: '1px',
                    cursor: 'pointer',
                  }}
                >
                  Upgrade
                </Text>
                to access Other Job Boards.
              </Flex>
            ) : (
              <Flex row center style={{ flexWrap: 'wrap' }}>
                <Flex row center>
                  <ExternalSwitch
                    checked={extarajobpost === '1'}
                    onClick={() => extarajob()}
                  />
                  <div className={styles.checkBoxs}>
                    <div style={{ opacity: 0.5, marginRight: 8 }}></div>
                    <Svgwhatjobs />
                  </div>
                </Flex>
                <Flex row center>
                  <ExternalSwitch
                    checked={drjobs === '1'}
                    onClick={() => drjobsfunction()}
                  />
                  <div className={styles.checkBoxs}>
                    <div style={{ opacity: 0.5, marginRight: 8 }}></div>
                    <Svgdrjob />
                  </div>
                </Flex>

                <Flex row center>
                  <ExternalSwitch
                    checked={careerjet === '1'}
                    onClick={() =>
                      toggleJobBoard('career_jet', careerjet, setcareerjet)
                    }
                  />
                  <div className={styles.checkBoxs}>
                    <div style={{ opacity: 0.5, marginRight: 8 }}></div>
                    <Svgcareerjet />
                  </div>
                </Flex>
                <Flex row center>
                  <ExternalSwitch
                    checked={jora === '1'}
                    onClick={() => toggleJobBoard('jora', jora, setjora)}
                  />
                  <div className={styles.checkBoxs}>
                    <div style={{ opacity: 0.5, marginRight: 8 }}></div>
                    <Svgjora />
                  </div>
                </Flex>

                {location.country__name === 'USA' && (
                  <Flex row center>
                    <ExternalSwitch
                      checked={myjobhelper === '1'}
                      onClick={() =>
                        toggleJobBoard(
                          'myjobhelper',
                          myjobhelper,
                          setmyjobhelper,
                        )
                      }
                    />
                    <div className={styles.checkBoxs}>
                      <div style={{ opacity: 0.5, marginRight: 8 }}></div>
                      <Svgjobhelper />
                    </div>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        </Card>
        <Flex style={{ height: 15 }}></Flex>
        <Flex row center between className={styles.btnContainer}>
          <LinkWrapper target={'_parent'} to={`/jobs/questionnaire/${jdId}`}>
            <Button types="secondary">{BACK}</Button>
          </LinkWrapper>

          <Flex row center>
            {isBtnLoader || isEditLoader || isUpgradeLoader || postLoader ? (
              <Flex className={styles.updateBtnLoader}>
                <Loader size="small" withOutOverlay />
              </Flex>
            ) : (
              <>
                <LinkWrapper
                  target={'_parent'}
                  to={routesPath.MY_JOB_POSTING}
                  onClick={() => {
                    setBtnLoader(true);
                  }}
                >
                  <Button types="secondary">Save as draft</Button>
                </LinkWrapper>

                {ds_role === true && (
                  <LinkWrapper
                    target={'_parent'}
                    to={`/jobs/create_ds_edit/${jdId}`}
                    onClick={() => setEditLoader(true)}
                  >
                    {isEditLoader ? (
                      ''
                    ) : (
                      <Button types="secondary" className={styles.editBtn}>
                        Edit
                      </Button>
                    )}
                  </LinkWrapper>
                )}
                {ds_role !== true && (
                  <LinkWrapper
                    target={'_parent'}
                    to={`/jobs/create_non_ds_edit/${jdId}`}
                    onClick={() => setEditLoader(true)}
                  >
                    {isEditLoader ? (
                      ''
                    ) : (
                      <Button types="secondary" className={styles.editBtn}>
                        Edit
                      </Button>
                    )}
                  </LinkWrapper>
                )}

                <Button
                  onClick={() => {
                    hanldePulish();
                    drjobpublish();
                    finaltoggleJobBoard();
                  }}
                  id={extarajobpost}
                >
                  Publish
                </Button>
                <SingleButton
                  btnTitle="OK"
                  title={
                    'Please contact your company admin to upgrade you plan'
                  }
                  open={isOpenPlanDetails}
                  btnOnclick={() => setOpenPlanDetails(false)}
                />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          differece_key={['external_posting']}
        />
      )}
    </Flex>
  );
};
export default StandardJobPosting;
