import { memo, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import ErrorMessage from '../../uikit/ErrorMessage/ErrorMessage';
import Flex from '../../uikit/Flex/Flex';
import Required from '../../uikit/Required/required';
import { isEmpty } from '../../uikit/helper';
import LabelWrapper from '../../uikit/Label/LabelWrapper';
import Text from '../../uikit/Text/Text';
import RichText from '../common/RichText';
import { JdOutput } from './createJdTypes';
import type { dsFormProps } from './formikTypes';
import JobTitle from './JobTitle';
import styles from './jobtitledescription.module.css';

type ParamsType = {
  jdId: string;
  editJdId: string;
};
type Props = {
  values: dsFormProps;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  job_title: string;
  job_description: string;
  errors: any;
  touched: any;
  handleTempOpen: () => void;
  jd_output: JdOutput;
  isNonDs?: boolean;
  jd_id: string;
  is_taken: boolean;
  updateJd: string;
  onDirty: () => void;
  onPristine: () => void;
  formik?: any;
  view?: boolean;
};

const JobTitleDescription = ({
  values,
  setFieldValue,
  job_title,
  job_description,
  errors,
  touched,
  handleTempOpen,
  jd_output,
  isNonDs,
  jd_id,
  is_taken,
  updateJd,
  onDirty,
  onPristine,
  formik,
  view,
}: Props) => {
  const { jdId, editJdId } = useParams<ParamsType>();
  const editorRef = useRef<any>(null);
  const [isRich, setRich] = useState(false);

  const parser = new DOMParser();

  const doc = parser.parseFromString(values?.jobDescription, 'text/html');

  const textNodes = doc.querySelectorAll('body')[0]?.textContent?.trim();

  // free fill initial value
  useEffect(() => {
    const editorContainer = editorRef?.current?.getEditor()?.container;

    if (editorContainer) {
      // Set the desired height and other styles
      editorContainer.style.height = '450px';

      const editorContent = editorContainer.querySelector('.ql-editor');
      if (editorContent) {
        editorContent.style.setProperty('max-height', '450px', 'important');
        editorContent.style.padding = '0px 16px';
      }
    }
  }, []);
  useEffect(() => {
    setFieldValue('jobDescription', editorRef?.current?.value?.toString());
    if (jd_output?.richtext_job_description === values?.jobDescription) {
      onPristine();
    } else {
      onDirty();
    }
  }, [isRich]);

  const handleOpenInput = () => {
    setRich(true);
  };

  const handleCloseInput = () => {
    setRich(false);
  };

  useEffect(() => {
    let datasettingspace = job_description;
    setFieldValue('jobDescription', datasettingspace);
  }, [job_description]);

  useEffect(() => {
    let datasettingspace = jd_output.richtext_job_description.replace(
      /(?![^<>]*>)(\s+)/g,
      (match) => {
        if (match.includes(' ')) {
          return '&nbsp;'.repeat(match.length);
        } else if (match.includes('\t')) {
          return '&nbsp;&nbsp;&nbsp;&nbsp;'.repeat(match.length);
        }
        return match;
      },
    );

    setFieldValue('jobDescription', datasettingspace);
  }, [jd_output]);

  useEffect(() => {
    if (jdId === undefined && editJdId === undefined) {
      setFieldValue('jobDescription', '');
    }
  }, []);

  // toolbar setting  for reactquill editor
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ header: [1, 2, 3, 4, 5, 6] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  // toolbar farmating  for reactquill editor
  const formats = [
    'bold',
    'italic',
    'underline',
    'header',
    'list',
    'bullet',
    'link',
  ];

  return (
    <Flex columnFlex>
      {view === true ? (
        <Flex center className={styles.jobTitleFlex}>
          <Text size={14} bold className={styles.jobTitle}>
            Job Details
          </Text>
          <Required />
        </Flex>
      ) : (
        ''
      )}

      {view === true ? (
        <>
          <JobTitle
            values={values}
            setFieldValue={setFieldValue}
            job_title={job_title}
            jd_output={jd_output}
            isNonDs={isNonDs}
            jd_id={jd_id}
            is_taken={is_taken}
            errors={errors}
            touched={touched}
            updateJd={updateJd}
            onDirty={onDirty}
          />
        </>
      ) : (
        <>
          <div className={styles.richTextContainer}>
            <LabelWrapper label="Job Description" required>
              <ReactQuill
                onFocus={handleOpenInput}
                onBlur={handleCloseInput}
                modules={modules}
                formats={formats}
                placeholder={`
Enter the job description here, including the candidate job duties and requirements.
 
Eg: The perfect candidate is self-driven, capable of handling multiple tasks simultaneously, and a proven collaborator.This role is for a lead developer tasked with both creating new software solutions and improving existing ones. The ideal applicant thrives in managing comprehensive applications and systems, showcasing exceptional leadership and communication skills.

Responsibilities:
    • Crafting and maintaining code that is both high in quality and performance, ensuring it is also sustainable.
    • Engaging in the development and enhancement of software, including app development, integrating databases, creating interfaces, and adding new features.
    • Working collaboratively across different teams to ensure projects align with business goals and adhere to compliance standards.
    • Assisting in the testing and deployment phases of new products and functionalities.
    • Contributing to code reviews, fostering a culture of quality and continuous improvement.

Qualifications:
    • A Bachelor's degree in Computer Science or a related discipline.
    • At least three years of experience in a relevant position.
    • Proficiency in Object-Oriented Design, Database Design, and XML Schema.
    • Familiarity with Agile or Scrum methodologies for software development.
    • Excellent skills in time management, organization, and prioritization of tasks.
`}
                ref={editorRef}
                value={textNodes.length === 0 ? '' : values.jobDescription}
                onChange={(event) => {
                  const editor = editorRef?.current?.getEditor();
                  const editorContent = editor?.root.innerHTML;
                  setFieldValue('jobDescription', editorContent);
                  onDirty();
                }}
              />
            </LabelWrapper>

            <input
              name="jobDescription"
              id="jobtitledescription___richtext"
              className={styles.displayNone}
            />
            {!isEmpty(textNodes) && textNodes.length < 201 && (
              <Text size={12} color="error">
                {'Text length should contain at least 200 characters.'}
              </Text>
            )}
            <ErrorMessage
              name="jobDescription"
              touched={touched}
              errors={errors}
            />
          </div>
        </>
      )}
    </Flex>
  );
};

export default memo(JobTitleDescription);
