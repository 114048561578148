import { useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import escapeRegExp from 'lodash.escaperegexp';
import Flex from '../../uikit/Flex/Flex'
import Text from '../../uikit/Text/Text'
import styles from '../bulkimportmodule/candidatedatabasetabfilter.module.css'
import SvgArrowUp from '../../icons/SvgArrowUp'
import SvgFilterRefresh from '../../icons/SvgFilterRefresh'
import SelectTag from '../../uikit/SelectTag/SelectTag'
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox'
import InputText from '../../uikit/InputText/InputText'
import Button from '../../uikit/Button/Button'
import { InputSearch } from '../../uikit';
import { enterKeyPress } from '../../uikit/helper'
import SvgIntomark from '../../icons/Intomark';
import { AppDispatch } from '../../store';
import { MAX_DISPLAYED_OPTIONS } from '../constValue';
import { filterexperienceOption } from './bulkImportScreenMock'
import { bulkuploadedCandidatesMiddleWare } from './store/middleware/bulkImportMiddleware';
import { SkillListEntity } from './bulkImportTypes';
import { CandidateSkillList } from './mock';
import { qualificationFilterHelper } from './BulkimportHelper';



type Props = {
    isBachelors: boolean,
    setBachelors: any,
    isDoctorate: boolean,
    setDoctorate: any,
    isDiploma: boolean,
    setDiploma: any,
    isMasters: boolean,
    setMasters: any,
    isAny: boolean,
    setAny: any,
    isOther: boolean,
    setOther: any,
    experience: string,
    setexperience: any,
    location: string,
    setlocation: any,
    skillsOptionsList: any,
    qaValue: any,
    import_candidates: any,
    formik: any,
    ispagecount: any,
    isBulkTab: any,
    candidate_location?: any
    setCandiTableLoader?: any
    setsearchclearurl?:(val?:any)=> void;
    searchclearurl?:any;
    searchValue?:any
}
const CandidateDatabaseTabFilter = ({
    isBachelors,
    setBachelors,
    isDoctorate,
    setDoctorate,
    isDiploma,
    setDiploma,
    isMasters,
    setMasters,
    isAny,
    setAny,
    isOther,
    setOther,
    experience,
    setexperience,
    location,
    setlocation,
    skillsOptionsList,
    qaValue,
    import_candidates,
    formik,
    ispagecount,
    isBulkTab,
    candidate_location,
    setCandiTableLoader,
    setsearchclearurl,
    searchclearurl,
    searchValue
}: Props) => {

    const dropDownRef = useRef(null);
    const myRef = useRef<any>();
    const dispatch: AppDispatch = useDispatch();
    const selectInputRef = useRef<any>();
    const [showDropDown, setShowDropDown] = useState(false);
    const [change, setchange] = useState(false);
    const [isSkills, setSkills] = useState<any>();
    const [present, setpresent] = useState(true);
    const [isSearch, setSearch] = useState('');
    const [skildata, setskildata] = useState([]);
    const [skill, setskill] = useState([]);
    const [newexperience, setnewexperience] = useState('');
    const [newqual, setnewqual] = useState([]);
    const [newlocation, setnewlocation] = useState('');
    const [newskill, setnewskill] = useState([]);
    const [hiddenskills1, sethiddenskill1] = useState([]);
    const [qual, setqual] = useState([]);
    const [isOut, setOut] = useState(false);
    const currentParams = new URLSearchParams(window.location.search);
    const history = useHistory();

    const [qualificationValue, setQualificationValue] = useState<
        | {
            value: string;
            label: string;
            checked: boolean;
            onChange: () => void;
        }[]
        | undefined
    >();

    const handleBachelor = () => {
        setBachelors(!isBachelors);
        setAny(false);
    };
    const handleDoctorate = () => {
        setDoctorate(!isDoctorate);
        setAny(false);
    };
    const handleDiploma = () => {
        setDiploma(!isDiploma);
        setAny(false);
    };

    const handleMaster = () => {
        setMasters(!isMasters);
        setAny(false);
    };

    const handleOther = () => {
        setOther(!isOther);
        setAny(false);
    };
    const handleAny = () => {
        setAny(!isAny);
        setBachelors(false);
        setDoctorate(false);
        setDiploma(false);
        setMasters(false);
        setOther(false);
    };

    const qualificationOption = [
        { value: 'Bachelors', label: 'Bachelors', checked: isBachelors, onChange: handleBachelor, width: 110, padding: 10 },
        { value: 'Masters', label: 'Masters', checked: isMasters, onChange: handleMaster, width: 80, margin: 8 },
        { value: 'Doctorate', label: 'Doctorate', checked: isDoctorate, onChange: handleDoctorate, width: 110 },
        { value: 'Diploma', label: 'Diploma', checked: isDiploma, onChange: handleDiploma, width: 80 },
        { value: 'Others', label: 'Others', checked: isOther, onChange: handleOther, width: 110 },
        { value: 'Any', label: 'any', width: 80, checked: isAny, onChange: handleAny },
    ];

    useEffect(() => {
        if (isBachelors === false && isDoctorate === false && isMasters === false && isOther === false && isDiploma === false) {
            setAny(true);
        }
    }, [isBachelors, isDoctorate, isDiploma, isMasters, isOther]);

    useEffect(()=>{
        if (isBachelors === false && isDoctorate === false && isMasters === false && isOther === false && isDiploma === false) {
            if(isAny===false){
            setAny(true)}
          }
    },[isAny])

    const getQualificationWithFunctions = () => {
        const qualificationParam = currentParams.get('qualification');
        let parsedData = [];
    
        if (qualificationParam) {
            try {
                const parsed = JSON.parse(qualificationParam);

                // Re-add the onChange functions based on value
                parsedData = parsed.map((item) => {
                    let onChange;
                    switch (item.value) {
                        case 'Bachelors':
                            onChange = handleBachelor;
                            break;
                        case 'Masters':
                            onChange = handleMaster;
                            break;
                        case 'Doctorate':
                            onChange = handleDoctorate;
                            break;
                        case 'Diploma':
                            onChange = handleDiploma;
                            break;
                        case 'Others':
                            onChange = handleOther;
                            break;
                        default:
                            onChange = () => { };
                    }
                    return { ...item, onChange };
                });
            } catch (error) {
                console.error('Error parsing qualification parameter:', error);
            }
        }
    
        return parsedData;
      };

  


  const urlsearch = useLocation();
  useEffect(() => {
    const experienceurl = currentParams.get('experience');
    if (experienceurl) {
      setnewexperience(experienceurl);
      setAny(false);
      if (experienceurl === 'Less than a year') {
        formik.setFieldValue('experience', { value: '0-1', label: 'Less than a year' });
      } else if (experienceurl === '1-2 years') {
        formik.setFieldValue('experience', {
          value: '1-2',
          label: '1-2 years',
        });
      } else if (experienceurl === '2-5 years') {
        formik.setFieldValue('experience', {
          value: '2-5',
          label: '2-5 years',
        });
      } else if (experienceurl === '5-7 years') {
        formik.setFieldValue('experience', {
          value: '5-7',
          label: '5-7 years',
        });
      }else if (experienceurl === '7-10 years') {
        formik.setFieldValue('experience', {
          value: '7-10',
          label: '7-10 years',
        });
      } else if (experienceurl === '7-10 years') {
        formik.setFieldValue('experience', {
          value: '>10',
          label: '7-10 years',
        });
      }
    }

        let resultpass = "";
        const qualificationurl = currentParams.get('qualification');
        if (qualificationurl) {
            const result = getQualificationWithFunctions();
            const qualificationObject = JSON.parse(qualificationurl);

      // Update the newqual state
      setnewqual(result);

      setAny(false);

      // Filter the checked labels from the qualification object
      const checkedLabels = qualificationObject
        .filter((item) => item.checked)
        .map((item) => item.label);
        
        resultpass = checkedLabels.join(", ");


      // Set states based on the checked labels
      setBachelors(checkedLabels.includes('Bachelors'));
      setMasters(checkedLabels.includes('Masters'));
      setDoctorate(checkedLabels.includes('Doctorate'));
      setDiploma(checkedLabels.includes('Diploma'));
      setOther(checkedLabels.includes('Others'));
    }

    const locationValue1 = currentParams.get('location');
    if (locationValue1) {
      // Only set state if locationValue is non-empty
      formik.setFieldValue('locationSearch', locationValue1);
      setnewlocation(locationValue1);
      setlocation(locationValue1);
      setSearch(locationValue1);
    }


  const skillsurl = currentParams.get('skill');
    if(skillsurl){
    const makeitarray=skillsurl.split(",");

            // Transform the array of strings to array of objects
            const transformedArray = makeitarray.map((skillurl) => ({
                label: skillurl,
                value: skillurl
            }));

            formik.setFieldValue('skillValue', transformedArray);
            setskildata(transformedArray);


            const hiddenSkillsurl = makeitarray.slice(4, makeitarray.length);
            setnewskill(makeitarray);
            sethiddenskill1(hiddenSkillsurl);

        }
        const pageurl2 = currentParams.get('page1')
        const searchvalueurl = currentParams.get('search')

      const pageurl= Number(currentParams.get('selectedpage'))
     
      const totalurl= Number(currentParams.get('total'))
      const completedurl= Number(currentParams.get('completed'))
      const incompletedurl= Number(currentParams.get('incompleted'))
    
       const pagecounturl=Number(currentParams.get('selectedpagecount'))

       // Determine the total/completed/incompleted logic
let dynamicTotalKey = {};
if (completedurl) {
    dynamicTotalKey = { completed: completedurl };
} else if (incompletedurl) {
    dynamicTotalKey = { incompleted: incompletedurl };
} else if(totalurl){
    dynamicTotalKey = { total: totalurl };
}
        const FilterPayload = {
            work_experience: experienceurl,
            page:pageurl+1,
            //total:totalurl,
            location: locationValue1,
            skill_match: skillsurl,
            education_level: resultpass==='any'?"":resultpass,
            pagecount: pagecounturl||10 ,
            search : searchvalueurl,
            ...dynamicTotalKey,
        }

        const paramsToClear = ['experience', 'qualification', 'skill', 'location','selectedpage','selectedpagecount'];
      
        // Check if any of the parameters exist in the URL
        const hasTargetParams = paramsToClear.some(param => currentParams.has(param));
      if(localStorage.getItem('tabkeybulkupload')==='0'){
                       dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(() => {
            setCandiTableLoader(false)
        });
    }
   
  }, [urlsearch.search]);

    const click = () => {
        setShowDropDown(!showDropDown);
    };

    const filteredOptions = useMemo(() => {
        if (!isSkills) {
            return CandidateSkillList;
        }
        const matchByStart = [];
        const matchByInclusion = [];
        const regByInclusion = new RegExp(escapeRegExp(isSkills), 'i');
        const regByStart = new RegExp(`^${escapeRegExp(isSkills)}`, 'i');
        for (const option of CandidateSkillList) {
            if (regByInclusion.test(option.label)) {
                if (regByStart.test(option.label)) {
                    matchByStart.push(option);
                } else {
                    matchByInclusion.push(option);
                }
            }
        }
        return [...matchByStart, ...matchByInclusion];
    }, [isSkills]);

    const handleselectskill = (option: any) => {
        formik.setFieldValue('skillValue', option);
        // setskildata(option);
        setchange(true);
    }

    const hiddenSkills = newskill.slice(4, newskill.length);

    const slicedOptions = useMemo(() => filteredOptions.slice(0, MAX_DISPLAYED_OPTIONS), [filteredOptions],);

    useEffect(() => {
        if (isSearch !== '') setOut(true);
    }, [isSearch]);

    const handleLocation = () => {
        formik.setFieldValue('locationSearch', isSearch);
    }

    const handleClickOutside = (event: { target: any }) => {
        if (myRef.current && !myRef.current.contains(event.target) && isOut) {
            handleLocation();
            setOut(false);
        }
    };
    useEffect(() => {
        if (typeof Window !== 'undefined') {
            document.addEventListener('click', handleClickOutside, true);
        }
        return () => {
            if (myRef) {
                if (typeof Window !== 'undefined') {
                    document.removeEventListener('click', handleClickOutside, true);
                }
            }
        };
    });

    useEffect(() => { 
            setlocation(formik.values.locationSearch); 
    }, [formik.values.locationSearch]);

    useEffect(() => {
        // if (formik.values.skillValue.length !== 0) {
            setskill(
                formik.values.skillValue
                    .filter((name) => name.value)
                    .map((filter) => filter.value),
            )
       // }
    }, [formik.values.skillValue]);

    useEffect(() => {
        if (formik.values.experience.value === '') {
            setexperience('');
            setpresent(false);
        } else if (formik.values.experience.value === '0-1') {
            setexperience(formik.values.experience.label);
        } else if (formik.values.experience.value === '1-2') {
            setexperience(formik.values.experience.label);
        } else if (formik.values.experience.value === '2-5') {
            setexperience(formik.values.experience.label);
        } else if (formik.values.experience.value === '5-7') {
            setexperience(formik.values.experience.label);
        } else if (formik.values.experience.value === '7-10') {
            setexperience(formik.values.experience.label);
        }else if (formik.values.experience.value === '>10') {
            setexperience(formik.values.experience.label);
        }
    }, [formik.values.experience.value]);

    const clearurlfilter=()=>{
        const paramsToClear = [
          'experience',
          'qualification',
          'skill',
          'location',
                  ];
      
        // Remove each parameter from the URL
        paramsToClear.forEach(param => {
          if (currentParams.has(param)) { // Check if parameter exists in URL
            currentParams.delete(param);  // Delete it if it does
          }
        });
      
      
        // Update the URL without reloading the page
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }

      const clearsearch=()=>{
        const paramsToClear = [
          'search',
  
        ];
        // Remove each parameter from the URL
        paramsToClear.forEach(param => {
          if (currentParams.has(param)) { // Check if parameter exists in URL
            currentParams.delete(param);  // Delete it if it does
          }
        });
        // Update the URL without reloading the page
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }

    const viewfilterrefresh = () => {
        hanldeRefresh();
        setSearch('');
        formik.resetForm();
        setSkills('');
        setnewexperience('');
        setnewqual([]);
        setnewlocation('');
        setnewskill([]);
        sethiddenskill1([]);
        setskill([]);
        setexperience('');
        setlocation('');
    };

    const viewfilterrefreshurl = () => {
        hanldeRefreshurl();
        setSearch('');
        // formik.resetForm();
        setSkills('');
        setnewexperience('');
        setnewqual([]);
        setnewlocation('');
        setnewskill([]);
        sethiddenskill1([]);
        setskill([]);
        setexperience('');
        setlocation('');
    };

    const clearfilters = () => {
        // setCandiTableLoader(true)
        viewfilterrefresh();
        clearurlfilter();
        clearsearch();
        setShowDropDown(false);
        setsearchclearurl(false);
        dispatch(bulkuploadedCandidatesMiddleWare({ page: 1, pagecount: import_candidates })).then(()=>{
            // setCandiTableLoader(false)
        })
    };

    const clearfiltersurl = () => {
        // setCandiTableLoader(true)
        viewfilterrefreshurl();
        clearurlfilter();
        setShowDropDown(false);
        setsearchclearurl(false);
        // dispatch(bulkuploadedCandidatesMiddleWare({ page: 1, pagecount: import_candidates })).then(()=>{
        //     setCandiTableLoader(false)
        // })
    };

    useEffect(()=>{
        if(searchclearurl===true)
        {
            clearfiltersurl()
        }
    },[searchclearurl])

    const closeexp = () => {
        setexperience('');
        formik.setFieldValue('experience', '');
        setnewexperience('');
        setchange(false);
        // setCandiTableLoader(true)
        setShowDropDown(false);

        const skillsString = skillsOptionsList.length !== 0 ? skillsOptionsList?.join(', ') : '';
        const Location = location !== '' ? location : '';
        const EducationLevel = qaValue !== '' ? qaValue : '';

        const FilterPayload = {
            work_experience: '',
            location: Location,
            skill_match: skillsString,
            education_level: EducationLevel,
            pagecount: ispagecount,
        }

        currentParams.delete('experience'); // Remove the 'location' parameter
        history.push({ search: currentParams.toString() }); // Update the URL without reloading

        dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(()=>{
            // setCandiTableLoader(false)
        });
        clearurl();
    }

    const closeQualification = (doc: {
        value: string;
        label: string;
        checked: boolean;
        onChange: () => void;
    }) => {
        // doc.onChange();
        setShowDropDown(false);

        switch (doc.label) {
            case 'Masters':
              handleMaster();
              break;
            case 'Bachelors':
              handleBachelor();
              break;
            case 'Doctorate':
              handleDoctorate();
              break;
            case 'Diploma':
              handleDiploma();
              break;
            case 'Others':
              handleOther();
              break;
            default:
              return;
          }
       
          // Parse the `qualification` parameter directly without encoding
          const qualificationParam = currentParams.get("qualification");
          if (!qualificationParam) return; // Exit if no qualification param found
        
          let qualificationArray = JSON.parse(qualificationParam);
        
          // Remove the item with the specified label
          qualificationArray = qualificationArray.filter(
            (item) => item.label !== doc.label
        );

        // Update the `qualification` parameter in the URL with the modified array
        currentParams.set("qualification", JSON.stringify(qualificationArray));

        // Update the URL without reloading the page
        history.push({ search: currentParams.toString() });


        const updatedQualifications = newqual.filter((obj) => obj.label !== doc.label);
        setnewqual(updatedQualifications);

        const qualificationsString = updatedQualifications.map(qualification => qualification.value).join(', ');
        const Location = location !== '' ? location : '';
        const WorkExperience = experience !== '' ? experience : '';

        const FilterPayload = {
            work_experience: WorkExperience,
            location: Location,
            skill_match: skillsOptionsList.length !== 0 ? skillsOptionsList?.join(', ') : '',
            education_level: qualificationsString,
            pagecount: ispagecount,
        }
        // setCandiTableLoader(true)

        dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(()=>{
            // setCandiTableLoader(false)
        });
        clearurl();
    };

    const handlefunction = (doc) => {
        var gremove = newqual.filter((obj) => obj.label !== doc.label);
        setnewqual(gremove);
        closeQualification(doc);
        setchange(false);
    };

    const skillval = (id, val) => {
        const updatedSkildata = skildata.filter((obj) => obj.label !== val.label);
        setShowDropDown(false);
        setskildata(updatedSkildata);
        setnewskill(updatedSkildata);
        formik.setFieldValue('skillValue', updatedSkildata);
        setchange(false);
        // setCandiTableLoader(true)
        if (updatedSkildata.length < 5) {
            sethiddenskill1(undefined);
        }

        const url = new URL(window.location.href);


        const skillString = updatedSkildata.map(skillvalue => skillvalue.label).join(',');

        url.searchParams.set('skill', skillString);

        ['selectedpagecount', 'selectedpage', 'total', 'completed', 'incompleted'].forEach(param => {
            if (url.searchParams.has(param)) {
                url.searchParams.delete(param);
            }
        });

        window.history.replaceState(null, '', url);

        const skillsString = updatedSkildata.map(skillssss => skillssss.value).join(', ');

        const Location = location !== '' ? location : '';
        const EducationLevel = qaValue !== '' ? qaValue : '';
        const WorkExperience = experience !== '' ? experience : '';


        const FilterPayload = {
            work_experience: WorkExperience,
            location: Location,
            skill_match: skillsString,
            education_level: EducationLevel,
            pagecount: ispagecount,
        }

        dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(()=>{
            // setCandiTableLoader(false)
        });
       // clearurl();
    };

    const locationsrh = () => {
        setlocation('');
        setnewlocation('');
        setSearch('');
        formik.setFieldValue('locationSearch', '');
        setShowDropDown(false);
        setchange(false);
        // setCandiTableLoader(true)
        currentParams.delete('location'); // Remove the 'location' parameter
        history.push({ search: currentParams.toString() }); // Update the URL without reloading
        const skillsString = skillsOptionsList.length !== 0 ? skillsOptionsList?.join(', ') : '';
        const EducationLevel = qaValue !== '' ? qaValue : '';
        const WorkExpereince = experience !== '' ? experience : '';


        const FilterPayload = {
            work_experience: WorkExpereince,
            location: '',
            skill_match: skillsString,
            education_level: EducationLevel,
            pagecount: ispagecount
        }

        dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(()=>{
            // setCandiTableLoader(false)
        });
        clearurl();
    };

    useEffect(() => {
        setQualificationValue(
            qualificationOption.filter((option) => option.checked),
        );
    }, [qaValue]);

    useEffect(() => {
        const ans = qualificationOption
            .filter((chek) => chek.checked !== false)
            .map((name) => name.value);
        if (qualificationOption.length !== 0 && ans.length !== 0) {
            setqual(ans);
        }
    }, [qaValue]);

    const RenderQuickFilter = (props: {
        doc?: { label: string; value: any };
        onClose: () => void;
    }) => {
        const { doc, onClose } = props;
        if (doc === undefined) {
            return null;
        }
        if (doc && (doc.value === '' || doc.value === 'any')) {
            return null;
        }

        return (
            <>
                {doc.label !== 'any' ? (
                    <Text className={styles.quickfil}>
                        {doc.label}{' '}
                        <SvgIntomark className={styles.stylesvg} onClick={onClose} />
                    </Text>
                ) : (
                    setnewqual(null)
                )}
            </>
        );
    };

    
    const clearurl=()=>{
        const paramsToClear = [
         
          'selectedpagecount',
          'selectedpage',
          'total',
          'completed',
          'incompleted'
        ];
        // Remove each parameter from the URL
        paramsToClear.forEach(param => {
          if (currentParams.has(param)) { // Check if parameter exists in URL
            currentParams.delete(param);  // Delete it if it does
          }
        });
        // Update the URL without reloading the page
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }

    const handleFilterApply = () => {

        currentParams.set('experience', experience);
        currentParams.set('qualification',  JSON.stringify(qualificationValue));
        currentParams.set('skill', String(skill));
        currentParams.set('location', location);
        currentParams.set('page1', ispagecount);
      


    // Update the URL with modified search parameters
    history.push({
        pathname: window.location.pathname, // Keeps the current path
        search: currentParams.toString(),
      });

        setCandiTableLoader(true)
        setShowDropDown(false);
        setnewexperience(experience);
        setnewqual(qualificationValue);
        setnewlocation(location);
        setnewskill(skill);
        sethiddenskill1(hiddenSkills);
        setchange(false);

        const skillsString = skillsOptionsList?.join(', ');

                const FilterPayload = {
                        work_experience: experience,
            location: location,
            skill_match: skillsString,
            education_level: qaValue,
            pagecount: ispagecount,
            search : searchValue !== '' ? searchValue : '',
        }
  
        dispatch(bulkuploadedCandidatesMiddleWare(FilterPayload)).then(() => {
            setCandiTableLoader(false)
        });

        clearurl();

    };

    const hanldeRefreshurl = () => {
        setAny(true);
        setBachelors(false);
        setDoctorate(false);
        setDiploma(false);
        setMasters(false);
        setOther(false);
        // formik.resetForm();
        formik.setValues(prevValues => ({
            ...prevValues,
            searchValue: prevValues.searchValue,
            parser: '',
            locationSearch: '',
            experience: filterexperienceOption[0],
            skillValue: [],
          }));
    };

    const hanldeRefresh = () => {
        setAny(true);
        setBachelors(false);
        setDoctorate(false);
        setDiploma(false);
        setMasters(false);
        setOther(false);
        formik.resetForm();
    };

    const isDefaultFilter = () => {
        const skills = newskill || [];
        const qualification = newqual || [];
        if (
            qualification?.length === 0 &&
            skills?.length === 0 &&
            newexperience === '' &&
            newlocation === ''
        ) {
            return true;
        }
        return false;
    };
    
    // function foe clearing the location filed.
    const handleSearchClose = () => {
        formik.setFieldValue('locationSearch', '');
        setlocation('');
        setSearch('');
    }
    return (
        <>
            <Flex row between marginTop={10} marginRight={1}>
                <div className={styles.quickfilters}>
                    <Text size={13} className={''} style={{ marginTop: '3px' }}>
                        Applied Filters :
                    </Text>

                    {newexperience === '' ? null : (
                        <Text className={styles.quickfil}>
                            {newexperience}{' '}
                            <SvgIntomark
                                className={styles.stylesvg}
                                onClick={() => closeexp()}
                            />
                        </Text>
                    )}
                    {isDefaultFilter() ? (
                        <Text className={styles.quickfil}>{'All'}</Text>
                    ) : (
                        <Flex row wrap style={{ display: 'contents' }}>
                            {newqual && newqual.map((doc, index) => (
                                <RenderQuickFilter
                                    key={index}
                                    doc={{ label: doc.label, value: doc.value }}
                                    onClose={() => handlefunction(doc)}
                                />
                            ))}

                            {newskill.length === 0 ? null : skildata.slice(0, 4).map((skills, index) => (
                                <Text className={styles.quickfil} key={skills}>
                                    {skills.label}{' '}
                                    <SvgIntomark
                                        className={styles.stylesvg}
                                        onClick={() => skillval(index, skills)}
                                    />
                                </Text>
                            ))}

                            {hiddenskills1 && hiddenskills1.length > 0 && hiddenSkills.length > 0 && (
                                <Text
                                    className={styles.quickfil}
                                >{`Skills : + ${hiddenSkills.length}`}</Text>
                            )}
                        </Flex>
                    )}

                    {newlocation === '' ? null : (
                        <Text className={styles.quickfil}>
                            {newlocation}{' '}
                            <SvgIntomark
                                className={styles.stylesvg}
                                onClick={() => locationsrh()}
                            />
                        </Text>
                    )}
                </div>
                <Flex>
                    <div
                        ref={dropDownRef}
                        className={styles.drop_down}
                        style={{ zIndex: 1 }}
                    >
                        <Flex row className={styles.drop_down_header} style={{ cursor: 'pointer' }}>
                            <Flex style={{ width: '90%', cursor: 'pointer' }} onClick={click} >
                                <Flex row center style={{ cursor: 'pointer' }}>
                                    <Text bold color="theme" size={13} style={{ cursor: 'pointer' }}>
                                        {showDropDown ? "Hide Filters" : "View Filters"}
                                    </Text>

                                    <Flex center style={{ cursor: 'pointer', margin: '3px 0  0 5px ' }}>
                                        <SvgArrowUp
                                            width={10}
                                            height={10}
                                            strokeWidth={25}
                                            viewBox='0 0 380 380'
                                            style={{ transform: showDropDown ? 'scaleY(-1)' : 'none', transition: 'transform 0.3s ease' }}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex title={'Clear Filters'} style={{ cursor: 'pointer' }}>
                                <SvgFilterRefresh
                                    width={16}
                                    height={16}
                                    onClick={clearfilters}
                                    className={styles.filtersvg}
                                />
                            </Flex>
                        </Flex>
                        <div className={`${styles.drop_down_menus} ${showDropDown ? styles.show : ''}`}>
                            <Flex className={styles.mtstyle}>
                                <Flex>
                                    <Text
                                        color="primary"
                                        bold
                                        size={13}
                                        style={{ marginBottom: '5px' }}
                                    >
                                        Experience
                                    </Text>
                                    <SelectTag
                                        labelBold
                                        value={formik.values.experience}
                                        options={filterexperienceOption}
                                        onChange={(option) => {
                                            formik.setFieldValue('experience', option);

                                            setchange(true);
                                        }}
                                    />
                                </Flex>
                            </Flex>

                            <Flex className={styles.mtstyle}>
                                <Text
                                    color="primary"
                                    size={13}
                                    bold
                                    style={{ marginBottom: '5px' }}
                                >
                                    Qualification
                                </Text>
                                <Flex row center wrap>
                                    {qualificationOption.map((qualificationList) => {
                                        return (
                                            <Flex
                                                row
                                                key={qualificationList.value}
                                                className={styles.qualificationRadioStyle}
                                                width={qualificationList.width}
                                            >
                                                <InputCheckBox
                                                    label={qualificationList.value}
                                                    checked={qualificationList.checked}
                                                    onChange={qualificationList.onChange}
                                                    onClick={() => setchange(true)}
                                                />
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            </Flex>
                            <Flex className={styles.mtstyle}>
                                <div className={styles.skillContainer}>
                                    <Text
                                        color="primary"
                                        size={13}
                                        bold
                                        style={{ marginBottom: '5px' }}
                                    >
                                        Skills
                                    </Text>
                                    <SelectTag
                                        labelBold
                                        ref={selectInputRef}
                                        isMulti
                                        filter
                                        onInputChange={(value) => setSkills(value)}
                                        onChange={handleselectskill}
                                        isSearchable
                                        isCreate
                                        options={slicedOptions}
                                        value={formik.values.skillValue}
                                    />
                                </div>
                            </Flex>

                            <Flex className={styles.mtstyle}>
                                <div>
                                    <Text
                                        color="primary"
                                        size={13}
                                        bold
                                        style={{ marginBottom: '5px' }}
                                    >
                                        Location
                                    </Text>
                                    <InputSearch
                                        initialValue={isSearch}
                                        placeholder="Enter candidate location"
                                        options={candidate_location}
                                        CrossiconSpace
                                        truncatewidth
                                        labelBold
                                        setFieldValue={formik.setFieldValue}
                                        style={styles.boxstyle}
                                        onkeyPress={(e) => {
                                            if (e.target.innerText) {
                                                const inputValue = e.target.innerText;
                                                formik.setFieldValue('locationSearch', inputValue);
                                                if (inputValue.length === 1 && inputValue[0] === ' ') {
                                                    return;
                                                }
                                                setSearch(inputValue);
                                            }
                                            else {
                                                const inputValue = e.target.value;
                                                formik.setFieldValue('locationSearch', inputValue);
                                                if (inputValue.length === 1 && inputValue[0] === ' ') {
                                                    return;
                                                }
                                                setSearch(inputValue);
                                            }
                                            setchange(true);
                                        }}
                                        onChange={(e) => {
                                            if (e.target.innerText) {
                                                const inputValue = e.target.innerText;
                                                formik.setFieldValue('locationSearch', inputValue);
                                                if (inputValue.length === 1 && inputValue[0] === ' ') {
                                                    return;
                                                }
                                                setSearch(inputValue);
                                            }
                                            else {
                                                const inputValue = e.target.value;
                                                formik.setFieldValue('locationSearch', inputValue);
                                                if (inputValue.length === 1 && inputValue[0] === ' ') {
                                                    return;
                                                }
                                                setSearch(inputValue);
                                            }
                                            setchange(true);
                                        }}
                                    />
                                    {formik.values.locationSearch !== '' &&

                                        <button
                                            className={styles.crossIcon}
                                            onClick={handleSearchClose}
                                        >
                                            <Flex style={{ cursor: 'pointer' }}>
                                                <SvgIntomark width={14} height={14} fill="#888888" />
                                            </Flex>
                                        </button>
                                    }
                                </div>
                            </Flex>
                            <Flex
                                row
                                end
                                className={styles.appFilterContainer}
                            >
                                <Flex marginRight={10}>
                                    <Button
                                        types='secondary'
                                        onClick={clearfilters}>
                                        Reset
                                    </Button>
                                </Flex>
                                <Flex>
                                    <Button className={styles.buyBtn} onClick={handleFilterApply}>
                                        Apply
                                    </Button>
                                </Flex>
                            </Flex> 
                        </div>
                    </div>
                </Flex>
            </Flex>
        </>
    )
}

export default CandidateDatabaseTabFilter;
