// eslint-disable-next-line

import escapeRegExp from 'lodash/escapeRegExp';
import { isEmpty, notSpecified } from '../../uikit/helper';
import { Expirenceregex, MAX_DISPLAYED_OPTIONS } from '../constValue';

const filteredOptions = (isSkills: any, skillList: any) => {
  if (!isSkills) {
    return skillList;
  }

  const matchByStart = [];
  const matchByInclusion = [];

  const regByInclusion = new RegExp(escapeRegExp(isSkills), 'i');
  const regByStart = new RegExp(`^${escapeRegExp(isSkills)}`, 'i');

  for (const option of skillList) {
    if (regByInclusion.test(option.label)) {
      if (regByStart.test(option.label)) {
        matchByStart.push(option);
      } else {
        matchByInclusion.push(option);
      }
    }
  }
  return [...matchByStart, ...matchByInclusion];
};

export const slicedOptions = (isSkills: any, skillList: any) =>
  filteredOptions(isSkills, skillList).slice(0, MAX_DISPLAYED_OPTIONS);

export const qualificationFilterHelper = (
  isAny: boolean,
  isBachelors: boolean,
  isDoctorate: boolean,
  isdiploma: boolean,
  isMasters: boolean,
  isOther: boolean,
) => {
  let qualificationFilter: string = '';
  if (isBachelors) {
    qualificationFilter += 'Bachelor,';
  }
  if (isDoctorate) {
    qualificationFilter += 'Doctorate,';
  }
  if (isMasters) {
    qualificationFilter += 'Master,';
  }
  if (isdiploma) {
    qualificationFilter += 'Diploma,';
  }
  if (isOther) {
    qualificationFilter += 'Other,';
  }
  // Remove the trailing comma, if any
  qualificationFilter = qualificationFilter.replace(/,$/, '');

  // Check if qualificationFilter is empty
  if (!qualificationFilter) {
    qualificationFilter = '';
  }
  return qualificationFilter;
};

export const workYear = (value?: string): string => {
  // Early exit if no value is provided
  if (!value) {
    return 'Not Specified';
  } 

  // Normalize input for consistent comparison
  value = value.trim().toLowerCase();

  // Handle exact match cases first for performance
  const exactMatches: Record<string, string> = {
    '0 months': '0 Month',
    '1 months': '1 Month',
    '1 years': '1 Year',
    'not specified': 'Not Specified',
    '0': 'Fresher',
    '0-1': '0-1 Year',
    'less than 1 year': '0-1 Year',
    '>10': '10+ Years',
    'more than 10 years': '10+ Years'
  };

  if (exactMatches[value]) {
    return exactMatches[value];
  }

  // Handle ranges like 'X-Y' and numeric years directly
  const rangeMatches = [
    { range: '0-1', label: '0-1 Year' },
    { range: '0-1 year', label: '0-1 Year' },
    { range: '1-2', label: '1-2 Years' },
    { range: '1-2 years', label: '1-2 Years' },
    { range: '2-5', label: '2-5 Years' },
    { range: '2-5 years', label: '2-5 Years' },
    { range: '5-7', label: '5-7 Years' },
    { range: '5-7 years', label: '5-7 Years' },
    { range: '7-10', label: '7-10 Years' },
    { range: '7-10 years', label: '7-10 Years' },
    { range: '6-10 years', label: '6-10 years' }
    
  ];

  for (const { range, label } of rangeMatches) {
    if (value === range) {
      return label;
    }
  }

  // Handle year ranges with a "+" (e.g., '5+')
  const matchPlus = value.match(/(\d+)\s*\+/);
  if (matchPlus) {
    return `${matchPlus[1]}+ Years`;
  }

  // Regular expression to extract years and months
  const regex = /(?:(\d+)\s*years?)?\s*(?:(\d+)\s*months?)?/i;
  const match = value.match(regex);
  if (match) {
    const years = parseInt(match[1] || '0', 10);
    const months = parseInt(match[2] || '0', 10);

    const yearPart = years === 1 ? `${years} Year` : years > 0 ? `${years} Years` : '';
    const monthPart = months === 1 ? `${months} Month` : months > 0 ? `${months} Months` : '';

    return months > 0 ? `${yearPart} ${monthPart}`.trim() : yearPart || monthPart;
  }

  // Handle specific numeric years directly (e.g., '1', '2', '3')
  const numericValue = parseInt(value, 10);
  if (!isNaN(numericValue)) {
    if (numericValue === 1) {
      return '1 Year';
    } else if (numericValue === 2) {
      return '2 Years';
    } else if (numericValue > 2) {
      return `${numericValue}+ Years`;
    }
  }

  // Handle ranges with '-' (e.g., '3-5')
  const matchRange = value.match(/\d+\s*-\s*\d+/);
  if (matchRange) {
    const range = matchRange[0].split('-');
    return `${range[0]}-${range[1]} Years`;
  }

  // Default case if no condition matches
  return '';
};



// export const workYear = (value?: string) =>
//   value === '0'
//     ? 'Fresher'
//     : !isEmpty(value)
//       ? value !== '0-1'
//         ? value === '1'
//           ? `${value.replace('year', '')} Year`
//           : value === 'Not Specified'
//             ? `${value?.replace('years', '').replace('Years', '')} `
//             : value === '0-1 Year'
//               ? '0-1 Year'
//               : value === 'Less than 1 year'
//                 ? `0-1 Year`
//                 : `${value?.replace('years', '').replace('Years', '')} Years`
//         : `0-1 Year`
//       : '';

export const pieYValue = (value?: string | any) => {
  const output = value === '0.0' ? null : Number(value);
  return output;
};
