/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { FormikProps } from 'formik';
import Loader from '../../components/Loader';
import classNames from 'classnames/bind';
import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import SvgView from '../../icons/SvgView';
import SvgHeart from '../../icons/SvgHeart';
import SvgDoublestar from '../../icons/Svgdoublestar';
import { getDateString, isEmpty, toCamelCase } from '../../uikit/helper';
import ProfileWithPercentage from '../common/ProfileWithPercentage';
import CancelAndDeletePopup from '../common/CancelAndDeletePopup';
import { YES } from '../constValue';
import ZitaMatchCandidateDrawer from '../zitamatchcandidatemodule/ZitaMatchCandidateDrawer';
import ProfileView from '../applicantpipelinemodule/ProfileView';
import styles from './mydatabasecard.module.css';
import { DataEntity } from './myDataBaseTypes';
import SkillContainer from './SkillContainer';
import LocationContainer from './LocationContainer';
import InviteContainer from './InviteContainer';
import QualificationContainer from './QualificationContainer';
import { MyDataFormProps } from './MyDataBaseScreen';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { myDataBaseDataMiddleWare } from './store/middleware/mydatabasemiddleware';
import { MyDataBaseFavoriteMiddleWare } from './store/middleware/mydatabasemiddleware';
import { color } from 'highcharts';
import { red } from '@mui/material/colors';
import CustomTooltip from '../../uikit/Tooltip/tooltip';

const cx = classNames.bind(styles);

type Props = {
  dataList: DataEntity;
  index: number;
  filterFormik: FormikProps<MyDataFormProps>;
  qaValue: string;
  skillsOptionsList: any;
  tabKey: string;
  jobId: string | boolean;
  hanldeInvite: (arg: number) => void;
  isFav: boolean;
  isCheck: string[];
  handleCheckBoxClick: (e: {
    target: { id: string; checked: boolean };
  }) => void;
  isSortOptions: {
    value: string;
    label: string;
  };
  isPage: number;
  addFavFilter: string;
  isZitamatchfilter?: any;
  setInviteLoader?: (val: any) => void;
  ispagecount?: any;
  isMatchai?: any;
  Fav_loader?: (id: number, val: number) => void;
  loadingFav?: any;
  setnewdata?: (val?: any) => void;
};

const MyDataBaseCard = ({
  dataList,
  index,
  filterFormik,
  qaValue,
  skillsOptionsList,
  tabKey,
  jobId,
  hanldeInvite,
  isCheck,
  handleCheckBoxClick,
  isSortOptions,
  isPage,
  addFavFilter,
  isZitamatchfilter,
  setInviteLoader,
  ispagecount,
  isMatchai,
  Fav_loader,
  loadingFav,
  setnewdata,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isInvite, setInvite] = useState(false);
  const [isProfileView, setProfileView] = useState(false);
  const [isNotes, setNotes] = useState(false);
  const [isShowMatch, setShowMatch] = useState(false);
  const [isFavLoader, setFavLoader] = useState(false);
  const [isColor, setColor] = useState(false);
  const [isFav, setFavourite] = useState(false);
  const [jd, setjd] = useState('0');
  const history = useHistory();
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const handleFavAction = async (can_id: number, jd_id: any) => {
    try {
      // Optimistic UI Update
      setFavourite(!isFav);
      Fav_loader(can_id, 0);

      // Wait for 300 milliseconds
      await delay(300);

      // Dispatch API call
      const res = await dispatch(
        MyDataBaseFavoriteMiddleWare({ can_id, jd_id }),
      );

      // Handle response
      if (res.payload.success) {
        Fav_loader(res.payload.can_id, 1);
      } else {
        console.error('API call failed:', res.payload);
      }
    } catch (error) {
      // Optionally handle errors (e.g., show an error toast)
    }
  };

  useEffect(() => {
    localStorage.setItem('innernavigationtab', '0');
  }, []);

  // inivite popup open
  const handleInviteView = () => {
    setInvite(true);
  };
  // inivite popup submit function
  const inviteSubmit = () => {
    setInvite(false);
    hanldeInvite(dataList.id);
  };

  const handleApplicantView = () => {
    localStorage.setItem('applied_view', JSON.stringify(true));
    localStorage.setItem('applied_can_id', dataList.id.toString());
    localStorage.setItem('applied_jd_id', jobId.toString());
  };

  const handleProfileView = () => {
    if (isEmpty(dataList.applicant)) {
      setProfileView(true);
      localStorage.setItem('innernavigationtab', '0');
    } else {
      handleApplicantView();
      window.open(`/applicant_pipe_line/${jobId}?applied_view=true`, '_blank'); 
      localStorage.setItem('innernavigationtab', '0');
    }
  };

  const notesfunction = () => {
    if (!isEmpty(dataList.applicant)) {
      handleApplicantView();
      // setNotes(true)
      window.open(`/applicant_pipe_line/${jobId}?active=5`, '_blank');
      localStorage.setItem('innernavigationtab', '0');
    } else {
      setNotes(true);
      localStorage.setItem('innernavigationtab', '0');
    }
  };

  const matchfunction = () => {
    if (!isEmpty(dataList.applicant)) {
      handleApplicantView();
      // setShowMatch(true)
      localStorage.setItem('innernavigationtab', '1');
      window.open(`/applicant_pipe_line/${jobId}?active=3`, '_blank');
    } else {
      setShowMatch(true);
      localStorage.setItem('innernavigationtab', '1');
    }
  };

  // setting the jdid after selecting the job
  useEffect(() => {
    setjd(jobId === false ? '0' : jobId.toString());
  });
  const checkWithJd = jd;
  // const checkWithJd: any = useMemo(() => (jobId === '' ? '0' : jobId), []);

  const isTablet = useMediaQuery({ query: '(max-width: 1000px)' });
  const normal = useMediaQuery({
    query: '(min-width: 1000px) and (max-width: 1411px)',
  });

  // close applicant and candidate view function
  const handleClose = () => {
    setnewdata(undefined);
    dispatch(
      myDataBaseDataMiddleWare({
        jobTitle: filterFormik.values.jobTitle,
        fav: addFavFilter,
        experience: filterFormik.values.experience.value,
        educationLevel: qaValue,
        typeofJob: filterFormik.values.jobType,
        location: filterFormik.values.locationSearch,
        skill_match: skillsOptionsList,
        relocate: filterFormik.values.reLocateValue,
        candidate: filterFormik.values.searchValue,
        userType: tabKey,
        sort: isSortOptions.value,
        page: isPage + 1,
        pagecount: ispagecount,
        applicant_only: filterFormik.values.applicantOnly,
        matching: isMatchai,
        zitamatchfilter: isZitamatchfilter ? '1' : '0',
      }),
    ).then((res) => {
      setnewdata(res.payload.data);
    });
    setNotes(false);
    setShowMatch(false);
    setProfileView(false);
    localStorage.setItem('innernavigationtab', '0');
  };

  const sidebar = sessionStorage.getItem('EmpToggle');
  const size = sidebar === '1';
  return (
    <>
      <Flex
        className={styles.cardwrap}
        style={{
          width: isTablet ? '100%' : normal ? '48%' : '32%',
        }}
      >
        {isEmpty(dataList.candidate_id_id) && (
          <>
            <ZitaMatchCandidateDrawer
              activeState={jobId === false ? 1 : 5}
              open={isNotes}
              cancel={handleClose}
              jobId={checkWithJd}
              candidateId={dataList.id.toString()}
            />
            <ZitaMatchCandidateDrawer
              activeState={jobId === false ? 2 : 2}
              open={isShowMatch}
              cancel={handleClose}
              jobId={checkWithJd}
              candidateId={dataList.id.toString()}
            />
            <ZitaMatchCandidateDrawer
              activeState={0}
              open={isProfileView}
              cancel={handleClose}
              jobId={checkWithJd}
              candidateId={dataList.id.toString()}
            />
          </>
        )}
        {!isEmpty(dataList.candidate_id_id) && (
          <>
            <ProfileView
              open={isProfileView}
              cancel={handleClose}
              jobId={checkWithJd}
              candidateId={dataList.id.toString()}
              inviteIconNone={jobId === false ? true : false}
              applicantpipeline={false}
            />
            <ProfileView
              activeState={jobId === false ? 1 : 5}
              open={isNotes}
              cancel={handleClose}
              jobId={checkWithJd}
              candidateId={dataList.id.toString()}
              inviteIconNone={jobId === false ? true : false}
              applicantpipeline={false}
            />
            <ProfileView
              activeState={jobId === false ? 3 : 3}
              open={isShowMatch}
              cancel={handleClose}
              jobId={checkWithJd}
              candidateId={dataList.id.toString()}
              inviteIconNone={jobId === false ? true : false}
              applicantpipeline={false}
            />
          </>
        )}

        {isEmpty(dataList.invite) && (
          <CancelAndDeletePopup
            open={isInvite}
            title={
              isEmpty(dataList.last_name) ? (
                <Flex>
                  <Text>{`Invite will be sent as an email to ${toCamelCase(
                    dataList.first_name,
                  )}.`}</Text>
                  <Text> Are you sure to proceed?</Text>
                </Flex>
              ) : (
                <Flex>
                  <Text>{`Invite will be sent as an email to ${toCamelCase(
                    dataList.first_name,
                  )} ${toCamelCase(dataList.last_name)}.`}</Text>
                  <Text> Are you sure to proceed?</Text>
                </Flex>
              )
            }
            btnDelete={inviteSubmit}
            btnCancel={() => setInvite(false)}
            btnRight={YES}
          />
        )}

        {!isEmpty(dataList.invite) && (
          <CancelAndDeletePopup
            open={isInvite}
            title={
              <Flex className={styles.popTitle}>
                <Text>{`The candidate ${
                  !isEmpty(dataList.last_name)
                    ? `${toCamelCase(dataList.first_name)} ${toCamelCase(
                        dataList.last_name,
                      )} `
                    : `${toCamelCase(dataList.first_name)}`
                } has already been invited for this job on ${getDateString(
                  dataList.invite,
                  'll',
                )}.`}</Text>
                <Text>Do you wish to invite again?</Text>
              </Flex>
            }
            btnDelete={inviteSubmit}
            btnCancel={() => setInvite(false)}
            btnRight={YES}
          />
        )}

        <Card className={styles.cardOverAll}>
          <Flex row columnFlex className={styles.cardHeight}>
            <Flex columnFlex top>
              <InputCheckBox
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(dataList.id.toString())}
                key={dataList.id.toString()}
                name={dataList.first_name}
                id={dataList.id.toString()}
              />
            </Flex>
            <Flex flex={1} width={'100%'} row>
              <Flex row className={styles.profileFlex} width={'70%'}>
                <Flex row marginLeft={5}>
                  <Flex>
                    <ProfileWithPercentage
                      isPercentage={!isEmpty(filterFormik.values.jobTitle)}
                      index={index}
                      dataList={dataList}
                    />
                  </Flex>
                  <Flex className={styles.nameFlex}>
                    <Flex
                      row
                      center
                      style={{ overflow: 'hidden', width: '80%' }}
                    >
                      <Flex
                        title={
                          !isEmpty(dataList.last_name)
                            ? `${toCamelCase(
                                dataList.first_name,
                              )} ${toCamelCase(dataList.last_name)}`
                            : `${toCamelCase(dataList.first_name)}`
                        }
                      >
                        <Text
                          bold
                          textStyle="ellipsis"
                          onClick={handleProfileView}
                          color={isEmpty(dataList.applicant) ? 'link' : 'link'}
                          className={cx({
                            pointerEvet: false,
                          })}
                        >
                          {toCamelCase(dataList.first_name)}{' '}
                          {toCamelCase(dataList.last_name)}
                        </Text>
                      </Flex>
                      <Flex
                        className={styles.svgView}
                        title={
                          isEmpty(dataList.applicant_view)
                            ? 'Yet to View'
                            : 'Profile Viewed'
                        }
                      >
                        <SvgView
                          height={15}
                          width={15}
                          nonView={isEmpty(dataList.applicant_view)}
                        />
                      </Flex>
                      {dataList.candidate_ai && (
                        <Flex marginLeft={5} center>
                          <SvgDoublestar />
                        </Flex>
                      )}
                    </Flex>
                    <LocationContainer
                      dataList={dataList}
                      filterFormik={filterFormik}
                      qaValue={qaValue}
                      skillsOptionsList={skillsOptionsList}
                      tabKey={tabKey}
                      isFav={isFav}
                      isSortOptions={isSortOptions}
                      isPage={isPage}
                      setnewdata={setnewdata}
                    />
                    <QualificationContainer
                      dataList={dataList}
                      filterFormik={filterFormik}
                      qaValue={qaValue}
                      skillsOptionsList={skillsOptionsList}
                      tabKey={tabKey}
                      isFav={isFav}
                      isSortOptions={isSortOptions}
                      isPage={isPage}
                      setnewdata={setnewdata}
                    />
                    <Flex marginTop={20}>
                      <SkillContainer
                        dataList={dataList}
                        // notesClick={() => setNotes(true)}
                        // showMatchClick={() => setShowMatch(true)}
                        notesClick={() => notesfunction()}
                        showMatchClick={() => matchfunction()}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="30%" style={{ borderLeft: '1px solid #A5889C' }}>
                <Flex className={styles.svgHeartaddtofavourites}>
                  {!isEmpty(filterFormik.values.jobTitle) && (
                    <>
                      {loadingFav[dataList.id || ''] ? (
                        <div className={styles.downloadspin}>
                          <span
                            className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                            role="status"
                          ></span>
                        </div>
                      ) : (
                        <CustomTooltip
                          title={
                            isEmpty(dataList.fav)
                              ? 'Add to Favourites'
                              : 'Remove from Favourites'
                          }
                        >
                          <div
                            role={'button'}
                            onKeyPress={() => {}}
                            onClick={() =>
                              handleFavAction(
                                dataList.id,
                                filterFormik.values.jobTitle,
                              )
                            }
                          >
                            {dataList.id === dataList.fav ? (
                              <SvgHeart
                                height={16}
                                width={16}
                                filled={!isEmpty(dataList.fav)}
                              />
                            ) : (
                              <SvgHeart height={16} width={16} />
                            )}
                          </div>
                        </CustomTooltip>
                      )}
                    </>
                  )}
                </Flex>
                <InviteContainer
                  inviteDisable={isEmpty(filterFormik.values.jobTitle)}
                  dataList={dataList}
                  jobId={jobId}
                  handleInviteView={handleInviteView}
                  handleApplicantView={handleApplicantView}
                />
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </>
  );
};
export default MyDataBaseCard;
