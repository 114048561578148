import { SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Tab from '../../../uikit/Tab/Tab';
import Tabs from '../../../uikit/Tab/Tabs';
import Loader from '../../../uikit/Loader/Loader';
// import { EmpPoolEntity } from '../bulkImportTypes';
// import CandidateDatabaseTab from './CandidateDatabaseTab';
import LinkedinSourcingScreen from '../../linkedinsourcingmodule/LinkedinSourcingScreen';
import UnlockedCandidates from '../../UnlockedCandidates/UnlockedCandidates';
import TalentSourcingScreen from '../../talentsourcingmodule/TalentSourcingScreen';
import { AppDispatch, RootState } from '../../../store';
import { ERROR_MESSAGE } from '../../constValue';
import { Toast } from '../../../uikit';
import { SkillListEntity } from '../../applicantpipelinemodule/applicantPipeLineTypes';
import { filterexperienceOption } from '../../bulkimportmodule/bulkImportScreenMock';
import { SourcingCandidatesMiddleWare } from '../../UnlockedCandidates/store/middleware/UnlockedCandidatesMiddleware';

// import Applicants from './applicants';
// import LogFileTab from './LogFileTab';

type Props = {
  isCandidateTab: any;
  setCandidateTab: any;
  setbulktab?: any;
  isBulktab?: any;
};

export type FormProps = {
  searchValue: string;
  parser: string;
  locationSearch: string;
  experience: SkillListEntity;
  skillValue: SkillListEntity[];
};
const initial: FormProps = {
  searchValue: '',
  parser: '',
  locationSearch: '',
  experience: filterexperienceOption[0],
  skillValue: [],
};

const CandidateSourcingTab = ({
  isCandidateTab,
  setCandidateTab,
  setbulktab,
  isBulktab,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [tabKey, setKey] = useState('0');
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const history = useHistory();
  const tab: any = query.get('tab');
  const [isFeaturesBalance, setFeaturesBalance] = useState<any>(null);
  const [isSearch, setSearch] = useState(1);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isCandiTableLoader, setCandiTableLoader] = useState(false);
  const [tabchange, settabchange] = useState(false);
  const [isexperiencevalue, setmainexperiencevalue] = useState<any>(null);
  const [ismainlocation, setmainlocation] = useState<any>(null);
  const [ismainskillsOptionsList, setmainskillsOptionsList] =
    useState<any>(null);
  const [ismainqaValue, setmainqaValue] = useState<any>(null);

  const currentParams = new URLSearchParams(window.location.search);
  const {
    datas,
    emp_pool,
    total_count,
    completed,
    incompleted,
    features_balance,
    upDateloader,
    current_plan,
    current_resume_count,
    data,
    import_candidates,
    params,
    permission,
    super_user,
    status,
    explore,
    candidate_location,
    permissionloader
  } = useSelector(
    ({
      unlockedCandidatesReducers,
      bulkImportReducers,
      dashboardEmpReducers,
      SubscriptionReducers,
      zitaMatchprogressReducers,
      permissionReducers,
      TourdataReducer,
    }: RootState) => {
      return {
        datas: unlockedCandidatesReducers,
        emp_pool: unlockedCandidatesReducers.emp_pool,
        total_count: unlockedCandidatesReducers.total_count,
        completed: unlockedCandidatesReducers.completed,
        incompleted: unlockedCandidatesReducers.incompleted,
        features_balance: bulkImportReducers.features_balance,
        upDateloader: unlockedCandidatesReducers.isLoading,
        current_plan: SubscriptionReducers.current_plan,
        current_resume_count: SubscriptionReducers.current_resume_count,
        data: zitaMatchprogressReducers.data,
        import_candidates: SubscriptionReducers.import_candidates,
        params: unlockedCandidatesReducers.params,
        permission: permissionReducers.Permission,
        permissionloader: permissionReducers.isLoading,
        super_user: permissionReducers.super_user,
        explore: TourdataReducer.is_first_login,
        status: TourdataReducer.is_steps,
        candidate_location: unlockedCandidatesReducers.candidate_location,
      };
    },
  );

  if (tab) {
    setKey(tab);
    if (query.has('tab')) {
      query.delete('tab');
      history.replace({
        search: query.toString(),
      });
    }
  }

  useEffect(() => {
    setCandidateTab(tabKey);
  }, [tabKey]);

  const handleSubmit = (values: FormProps) => {
    dispatch(
      SourcingCandidatesMiddleWare({
        search: formik.values.searchValue,
        pagecount: import_candidates,
        jd_id: undefined,
      }),
    )
      .then((response) => {
        setSearch(response.payload.search);
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  const formik = useFormik({
    initialValues: initial,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const handleTotal = () => {
    setCandiTableLoader(true);
    dispatch(
      SourcingCandidatesMiddleWare({
        total: total_count,
        search: formik.values.searchValue,
        page: pageNumber + 1,
        pagecount: import_candidates,
        jd_id: undefined,
        work_experience: isexperiencevalue,
        location: ismainlocation,
        skill_match: ismainskillsOptionsList,
        education_level: ismainqaValue,
      }),
    )
      .then(() => {
        setPageNumber(0);
        setCandiTableLoader(false);
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  // Filter Completed Candidates
  const handleCompleted = () => {
    dispatch(
      SourcingCandidatesMiddleWare({
        completed,
        search: formik.values.searchValue,
        page: pageNumber + 1,
        pagecount: import_candidates,
        jd_id: undefined,
        work_experience: isexperiencevalue,
        location: ismainlocation,
        skill_match: ismainskillsOptionsList,
        education_level: ismainqaValue,
      }),
    )
      .then(() => {
        setPageNumber(0);
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  // Filter InCompeleted Candidates
  const handleInCompeleted = () => {
    dispatch(
      SourcingCandidatesMiddleWare({
        incompleted,
        search: formik.values.searchValue,
        page: pageNumber + 1,
        pagecount: import_candidates,
        jd_id: undefined,
        work_experience: isexperiencevalue,
        location: ismainlocation,
        skill_match: ismainskillsOptionsList,
        education_level: ismainqaValue,
      }),
    )
      .then(() => {
        setPageNumber(0);
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  useEffect(() => {
    if (localStorage.getItem('tabkeymanagecandidate')) {
      if (localStorage.getItem('tabkeymanagecandidate') === '1') {
        setKey('1');
      } else if (localStorage.getItem('tabkeymanagecandidate') === '0') {
        setKey('0');
      } else if (localStorage.getItem('tabkeymanagecandidate') === '2') {
        setKey('2');
      }
    }
  }, []);

  const clearurlfilterurl = () => {
    const [baseUrl] = window.location.href.split('?');
    window.history.replaceState(null, '', baseUrl);
  };

  // useEffect(()=>{
  //   clearurlfilterurl();
  // },[localStorage.getItem('tabkeymanagecandidate')])

  const handleTabSelect = (key: any) => {
    setKey(key);
    localStorage.setItem('tabkeymanagecandidate', String(key)); // Store the selected tab key in localStorage
    clearurlfilterurl();
    settabchange(true);
  };  
  return (
    <>
      {permissionloader ? (
        <Loader />
      ) : (
        <>
          {permission.includes('talent_sourcing') ? (
            <Tabs activeKey={tabKey} onSelect={handleTabSelect}>
              <Tab title={'Resume Library'} eventKey={'0'}>
                {/* <ResumeLibrary /> */}
                <TalentSourcingScreen />
              </Tab>
              <Tab title={'LinkedIn Sourcing'} eventKey={'1'}>
                {tabKey === '1' && <LinkedinSourcingScreen />}
              </Tab>
              <Tab title={'Unlocked Candidates'} eventKey={'2'}>
                {tabKey === '2' && (
                  <UnlockedCandidates
                    handleTotal={handleTotal}
                    handleSubmit={handleSubmit}
                    handleCompleted={handleCompleted}
                    handleInCompeleted={handleInCompeleted}
                    searchValue={formik.values.searchValue}
                    searchHandleChange={formik.handleChange('searchValue')}
                    setKey={setKey}
                    setFeaturesBalance={setFeaturesBalance}
                    isSearch={isSearch}
                    formik={formik}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    isBulkTab={isBulktab}
                    isCandiTableLoader={isCandiTableLoader}
                    setCandiTableLoader={setCandiTableLoader}
                    settabchange={settabchange}
                    tabchange={tabchange}
                    setmainexperiencevalue={setmainexperiencevalue}
                    setmainlocation={setmainlocation}
                    setmainskillsOptionsList={setmainskillsOptionsList}
                    setmainqaValue={setmainqaValue}
                  />
                )}
              </Tab>
            </Tabs>
          ) : (
            <Tabs activeKey={tabKey} onSelect={(keys) => setKey(keys)}>
              <Tab title={'Unlocked Candidates'} eventKey={'2'}>
                {tabKey === '2' && (
                  <UnlockedCandidates
                    handleTotal={handleTotal}
                    handleSubmit={handleSubmit}
                    handleCompleted={handleCompleted}
                    handleInCompeleted={handleInCompeleted}
                    searchValue={formik.values.searchValue}
                    searchHandleChange={formik.handleChange('searchValue')}
                    setKey={setKey}
                    setFeaturesBalance={setFeaturesBalance}
                    isSearch={isSearch}
                    formik={formik}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    isBulkTab={isBulktab}
                    isCandiTableLoader={isCandiTableLoader}
                    setCandiTableLoader={setCandiTableLoader}
                    setmainexperiencevalue={setmainexperiencevalue}
                    setmainlocation={setmainlocation}
                    setmainskillsOptionsList={setmainskillsOptionsList}
                    setmainqaValue={setmainqaValue}
                  />
                )}
              </Tab>
            </Tabs>
          )}
        </>
      )}
    </>
  );
};

export default CandidateSourcingTab;
