import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import Tab from '../../uikit/Tab/Tab';
import Tabs from '../../uikit/Tab/Tabs';
import MyDataBaseList from './MyDataBaseList'; // eslint-disable-line
import { MyDataFormProps } from './MyDataBaseScreen'; // eslint-disable-line
import { DataEntity } from './myDataBaseTypes';

type Props = {
  data: DataEntity[];
  setTabKey: (arg: SetStateAction<string>) => void;
  tabKey: string;
  totalCount: number;
  filterFormik: FormikProps<MyDataFormProps>;
  qaValue: string;
  skillsOptionsList: any;
  jobId: string | boolean;
  hanldeInvite: (arg: number) => void;
  isFav: boolean;
  handleFav: () => void;
  handleSelectAll: () => void;
  isCheckAll: boolean;
  isCheck: string[];
  handleCheckBoxClick: (e: {
    target: { id: string; checked: boolean };
  }) => void;
  hanldeDownload: () => void;
  isSortOptions: {
    value: string;
    label: string;
  };
  setSortOptions: Dispatch<
    SetStateAction<{
      value: string;
      label: string;
    }>
  >;
  isPage: number;
  setPage: (arg: number) => void;
  addFavFilter: string;
  cleartick: () => void;
  pagination_count?: number;
  setpagecount?: (arg: number) => void;
  iscandidate?: any;
  isZitamatchfilter?: any;
  setInviteLoader?: (value: any) => void;
  ispagecount?: any;
  isMatchai?: any;
  Fav_loader?: (id: number, val: number) => void;
  loadingFav?: any;
  data_can?: any;
  setnewdata?: (val?: any) => void;
  settrigger?: (val?: any) => void;
  istrigger?: any;
  isInviteLoader?: any;
  handlesort?: (val?: any) => void;
};

const MyDataBaseTabs = ({
  data,
  setTabKey,
  tabKey,
  totalCount,
  filterFormik,
  qaValue,
  skillsOptionsList,
  jobId,
  hanldeInvite,
  isFav,
  handleFav,
  handleSelectAll,
  isCheck,
  isCheckAll,
  handleCheckBoxClick,
  hanldeDownload,
  setSortOptions,
  isSortOptions,
  isPage,
  setPage,
  addFavFilter,
  cleartick,
  pagination_count,
  setpagecount,
  iscandidate,
  isZitamatchfilter,
  setInviteLoader,
  ispagecount,
  isMatchai,
  Fav_loader,
  loadingFav,
  data_can,
  setnewdata,
  settrigger,
  istrigger,
  isInviteLoader,
  handlesort,
}: Props) => {
  return (
    <MyDataBaseList
      tab={'All'}
      data={data}
      tabKey={tabKey}
      totalCount={totalCount}
      filterFormik={filterFormik}
      qaValue={qaValue}
      skillsOptionsList={skillsOptionsList}
      jobId={jobId}
      hanldeInvite={hanldeInvite}
      isFav={isFav}
      handleFav={handleFav}
      handleSelectAll={handleSelectAll}
      isCheckAll={isCheckAll}
      isCheck={isCheck}
      handleCheckBoxClick={handleCheckBoxClick}
      hanldeDownload={hanldeDownload}
      setSortOptions={setSortOptions}
      isSortOptions={isSortOptions}
      isPage={isPage}
      setPage={setPage}
      addFavFilter={addFavFilter}
      cleartick={cleartick}
      pagination_count={pagination_count}
      setpagecount={setpagecount}
      iscandidate={iscandidate}
      isZitamatchfilter={isZitamatchfilter}
      setInviteLoader={setInviteLoader}
      ispagecount={ispagecount}
      isMatchai={isMatchai}
      Fav_loader={Fav_loader}
      loadingFav={loadingFav}
      data_can={data_can}
      setnewdata={setnewdata}
      settrigger={settrigger}
      istrigger={istrigger}
      isInviteLoader={isInviteLoader}
      handlesort={handlesort}
    />
  );
};

export default MyDataBaseTabs;
