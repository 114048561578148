import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames/bind';
import Flex from '../../uikit/Flex/Flex';
import Loader from '../../uikit/Loader/Loader';
import Pangination from '../../uikit/Pagination/Pangination';
import SingleButton from '../common/SingleButton';
import Toast from '../../uikit/Toast/Toast';
import { AppDispatch, RootState } from '../../store';
import { ERROR_MESSAGE } from '../constValue';
import SvgJobapplication from '../../icons/SvgJobapplication';
import SvgNotinterests from '../../icons/SvgNotinterests';
import SvgUserSearch from '../../icons/SvgUserSearch';
import SvgSearch from '../../icons/SvgSearch';
import { Text } from '../../uikit';
import Title from '../common/Title';
import Empty from '../common/Empty';
import CancelAndDeletePopup from '../common/CancelAndDeletePopup';
import { isEmpty } from '../../uikit/helper';
import { creditsPurchaseMiddleWare } from '../ordersummarymodule/store/ordersummarymiddleware';
import { permissionMiddleWare } from '../Login/store/middleware/loginMiddleWare';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import Tour from '../tour/tour';
import Highlighter from '../tour/highlighter';
import { tourdataget } from '../tour/store/middleware/middleware';
import TalentFilter from './TalentFilter';
import TalentAction from './TalentAction';
import TalentCardList from './TalentCardList';
import NoCountModal from './NoCountModal';
import styles from './talentsourcingscreen.module.css';
import {
  candidateViewMiddleWare,
  createCheckoutMiddleWare,
  parsedTextMiddleWare,
  paymentCanceledMiddleWare,
  stripeMiddleWare,
  talentSourcingMiddleWare,
  talentSourcingsessionIdMiddleWare,
  unlockCandidateMiddleWare,
} from './store/middleware/talentSoucringMiddleware';
import { filterCondition, talentFilterHelper } from './talentFilterHelper'; // eslint-disable-line
import CandiViewModal from './CandiViewModal';
import ContactCreditsModal from './ContactCreditsModal';
import UnlockLoaderModal from './UnlockLoaderModal';
import CandidateSuccessModal from './CandidateSuccessModal';
import { DataEntity } from './talentSourcingTypes';

const cx = classNames.bind(styles);

export type experienceOptionsType = { value: string; label: string };

const TalentSourcingScreen = () => {
  //subcription setstate
  const [isopensubcription, setopensubcription] = useState(false);

  const [isBachelors, setBachelors] = useState(false);
  const [isDoctorate, setDoctorate] = useState(false);
  const [isMasters, setMasters] = useState(false);
  const [isAny, setAny] = useState(true);
  const [isRelocate, setRelocate] = useState(false);
  const [isExperience, setExperience] = useState<experienceOptionsType>({
    label: 'All',
    value: 'all',
  });
  const [isSearchData, setSearchData] = useState<DataEntity[]>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isNoLimit, setNoLimit] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isNoPermission, setNoPermission] = useState(false);
  const [isContact, setContact] = useState(false);
  const [isSourceLimit, setSourceLimit] = useState(0);
  const [isCandidatesLimit, setCandidatesLimit] = useState('0');
  const [isCredit, setCredit] = useState(false);
  const [isShowPdf, setShowPdf] = useState(false);
  const [isCheckOutLoader, setCheckOutLoader] = useState(false);
  const [isNoCount, setNoCount] = useState(false);
  const [isCandiList, setCandiList] = useState<string[]>();
  const [isFind, setFind] = useState(true);
  const [isOther, setOther] = useState(false);
  const [isInitalCheckBox, setInitalCheckBox] = useState(false);
  const [isFree, setFree] = useState(false);
  const [isPdfLoader, setPdfLoader] = useState(false);
  const [isSubmitLoader, setSubmitLoader] = useState(false);
  const [isInitialLoader, setInitialLoader] = useState(true);
  const [visible, setvisible] = useState(false);
  const [show, setshow] = useState(false);
  const uselocation = useLocation();
  const history = useHistory();
  const [apply, setapply] = useState(false);
  const [change, setchange] = useState(false);
  const [cardloader, setcardloader] = useState(false);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [unlockcan, setunlockcan] = useState<any>([]);
  const [isformikdata, setformikdata] = useState<any>(false);
  const [ispagecount, setpagecount] = useState<any>(null);
  const [componentHeight, setComponentHeight] = useState(
    window.innerHeight - 178,
  );
  const [newexperience, setnewexperience] = useState(null);
  const [NewBachelors1, setnewBachelors1] = useState(false);
  const [Newmaster1, setnewmaster1] = useState(false);
  const [NewDoctorate1, setnewDoctorate1] = useState(false);
  const [Newothers1, setnewothers1] = useState(false);
  const [newrelocate, setnewrelocate] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 178);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 178);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSearchData]);

  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;
  const length: any = isSearchData?.length;
  const pageCount = Math.ceil(length / usersPerPage);
  // document.title = 'Source Candidates';
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem('freeCheck', 'true');
    dispatch(SubsriptionMiddleWare());
    dispatch(talentSourcingMiddleWare()).then(() => {
      setInitalCheckBox(true);
      setInitialLoader(false);
    });
  }, []);

  const update = (val) => {
    setshow(val);
  };

  // dispatching the API's when the user buy the add-on
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const session: any = query.get('session');
  const [step7, setstep7] = useState(false);
  const [currentindex, setcurrentindex] = useState(0);

  useEffect(() => {
    if (!isEmpty(session)) {
      dispatch(SubsriptionMiddleWare());
      dispatch(permissionMiddleWare()).then(() =>
        dispatch(SubsriptionMiddleWare()).then((res) => {
          setCredit(true);
          setSourceLimit(res.payload.user_credits.resume_unlock_credits);
        }),
      );
    }
  }, [session]);

  const {
    location,
    sourceLoader,
    searchData,
    searchLoader,
    unLockLoader,
    bulkUnlockLoader,
    bulkDownloadLoader,
    // candiViewLoader,
    stripeLoader,
    publicKey,
    candi_list,
    candiViewFile,
    plan,
    is_plan,
    add_on_plans,
    resume_unlock_credits,
    client_url,
    talent_sourcing,
    Permission1,
    super_user,
    status,
    explore,
    user1,
    pipelineData,
  } = useSelector(
    ({
      talentSourcingReducers,
      talentSourcingSearchReducers,
      talentUnlockCandidateReducers,
      bulkActionReducers,
      bulkDownloadActionReducers,
      candidateViewReducers,
      stripeReducers,
      permissionReducers,
      SubscriptionReducers,
      TourdataReducer,
      userProfileReducers,
      pipelinePageReducers,
    }: RootState) => {
      return {
        location: talentSourcingReducers.location,
        sourceLoader: talentSourcingReducers.isLoading,
        searchData: talentSourcingSearchReducers.data,
        searchLoader: talentSourcingSearchReducers.isLoading,
        unLockLoader: talentUnlockCandidateReducers.isLoading,
        bulkUnlockLoader: bulkActionReducers.isLoading,
        bulkDownloadLoader: bulkDownloadActionReducers.isLoading,
        candiViewLoader: candidateViewReducers.isLoading,
        publicKey: stripeReducers.publicKey,
        stripeLoader: stripeReducers.isLoading,
        candi_list: talentSourcingSearchReducers.candi_list,
        candiViewFile: candidateViewReducers.file,
        plan: talentSourcingSearchReducers.plan,
        is_plan: permissionReducers.is_plan,
        add_on_plans: SubscriptionReducers.add_on_plans,
        resume_unlock_credits:
          SubscriptionReducers.user_credits?.resume_unlock_credits,
        client_url: permissionReducers.client_url,
        talent_sourcing: SubscriptionReducers.talent_sourcing,
        Permission1: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        explore: TourdataReducer.is_first_login,
        status: TourdataReducer.is_steps,
        user1: userProfileReducers.user,
        pipelineData: pipelinePageReducers.pipeline,
      };
    },
  );

  // tour useeffect
  useEffect(() => {
    dispatch(tourdataget({}));
    if (status) {
      setstep7(true);
    }
  }, [status]);

  useEffect(() => {
    if (!is_plan) {
      history.push('/account_setting/subscription');
    }
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const val_isExperience = JSON.parse(params.get('isExperience'));
    const val_isBachelors = JSON.parse(params.get('isBachelors'));
    const val_isMasters = JSON.parse(params.get('isMasters'));
    const val_isDoctorate = JSON.parse(params.get('isDoctorate'));
    const val_isOther = JSON.parse(params.get('isOther'));
    const val_isRelocate = JSON.parse(params.get('isRelocate'));

    if (val_isExperience) {
      setnewexperience(val_isExperience);
      setExperience(val_isExperience);
    }
    if (val_isBachelors) {
      setnewBachelors1(val_isBachelors);
      setAny(false);
      setBachelors(val_isBachelors);
    }
    if (val_isMasters) {
      setMasters(val_isMasters);
      setAny(false);
      setnewmaster1(val_isMasters);
    }
    if (val_isDoctorate) {
      setDoctorate(val_isDoctorate);
      setAny(false);
      setnewDoctorate1(val_isDoctorate);
    }
    if (val_isOther) {
      setnewothers1(val_isOther);
      setOther(val_isOther);
      setAny(false);
    }
    if (val_isRelocate) {
      setnewrelocate(val_isRelocate);
      setRelocate(val_isRelocate);
      setAny(false);
    }
  }, []);

  useEffect(() => {
    if (isContact) {
      dispatch(stripeMiddleWare());
    }
  }, [isContact]);

  useEffect(() => {
    setCandiList(candi_list);
  }, [candi_list]);

  // store search data in local
  sessionStorage.setItem('storeSearchData', JSON.stringify(searchData));

  const getStoreSearchData = JSON.parse(
    sessionStorage.getItem('storeSearchData') || 'null',
  );

  const {
    searchBachelorsFilter,
    searchDoctorateFilter,
    searchMastersFilter,
    searchBachelorsMastersFilter,
    searchBachelorsDoctorateFilter,
    searchDoctorateMastersFilter,
    searchThreeFilter,
    searchRelocate,
    searchBachelorRelocate,
    searchMasterRelocate,
    searchDoctorateRelocate,
    searchBachelorMasterRelocate,
    searchBachelorDoctorateRelocate,
    searchMasterDoctorateRelocate,
    searchBachelorsMasterDoctorateRelocate,
    searchExperience,
    searchExperienceBachelor,
    searchExperienceDoctorateMaster,
    searchExperienceMaster,
    searchExperienceDoctorate,
    searchExperienceBachelorMaster,
    searchExperienceBachelorDoctorate,
    searchExperienceBachelorMasterDoctorate,
    searchExperienceRelocate,
    searchBachelorExperienceRelocate,
    searchDoctorateExperienceRelocate,
    searchMastersExperienceRelocate,
    searchMastersDoctorateExperienceRelocate,
    searchBachelorDoctorateExperienceRelocate,
    searchBachelorsMasterExperienceRelocate,
    searchBachelorsMasterDoctorateExperienceRelocate,
    searchUnselectCheckbox,
    searchUnCheckExperience,
    searchOther,
    searchOtherBachelor,
    searchOtherMasters,
    searchOtherDoctorate,
    searchOtherBachelorsMasters,
    searchOtherBachelorsDoctorate,
    searchOtherMastersDoctorate,
    searchOtherBachelorsMastersDoctorate,
    searchOtherExperience,
    searchOtherBachelorExperience,
    searchOtherMastersExperience,
    searchOtherDoctorateExperience,
    searchOtherBachelorsMastersExperience,
    searchOtherBachelorsDoctorateExperience,
    searchOtherMastersDoctorateExperience,
    searchOtherBachelorsMastersDoctorateExperience,
    searchOtherRelocate,
    searchOtherExperienceRelocate,
    searchOtherBachelorRelocate,
    searchOtherBachelorExperienceRelocate,
    searchOtherMastersRelocate,
    searchOtherMastersExperienceRelocate,
    searchOtherDoctorateRelocate,
    searchOtherDoctorateExperienceRelocate,
    searchOtherBachelorsMastersRelocate,
    searchOtherBachelorsMastersExperienceRelocate,
    searchOtherBachelorsDoctorateRelocate,
    searchOtherBachelorsDoctorateExperienceRelocate,
    searchOtherMastersDoctorateReloacate,
    searchOtherMastersDoctorateExperienceRelocate,
    searchOtherBachelorsMastersDoctorateRelocate,
    searchOtherBachelorsMastersDoctorateExperienceRelocate,
  } = talentFilterHelper(getStoreSearchData, isExperience);

  useEffect(() => {
    if (!change) {
      filterCondition(
        setSearchData,
        isAny,
        isRelocate,
        isExperience,
        isMasters,
        isDoctorate,
        isBachelors,
        getStoreSearchData,
        searchExperienceRelocate,
        searchRelocate,
        searchExperienceBachelorMasterDoctorate,
        searchBachelorsMasterDoctorateRelocate,
        searchExperienceBachelorMaster,
        searchExperienceBachelorDoctorate,
        searchExperienceDoctorateMaster,
        searchThreeFilter,
        searchBachelorDoctorateRelocate,
        searchMasterDoctorateRelocate,
        searchBachelorMasterRelocate,
        searchExperienceBachelor,
        searchExperienceMaster,
        searchExperienceDoctorate,
        searchBachelorsMastersFilter,
        searchBachelorsDoctorateFilter,
        searchDoctorateMastersFilter,
        searchBachelorRelocate,
        searchMasterRelocate,
        searchDoctorateRelocate,
        searchBachelorsFilter,
        searchDoctorateFilter,
        searchMastersFilter,
        searchExperience,
        searchBachelorExperienceRelocate,
        searchDoctorateExperienceRelocate,
        searchMastersExperienceRelocate,
        searchBachelorsMasterExperienceRelocate,
        searchBachelorDoctorateExperienceRelocate,
        searchMastersDoctorateExperienceRelocate,
        searchBachelorsMasterDoctorateExperienceRelocate,
        searchUnselectCheckbox,
        searchUnCheckExperience,
        isOther,
        searchOther,
        searchOtherBachelor,
        searchOtherMasters,
        searchOtherDoctorate,
        searchOtherBachelorsMasters,
        searchOtherBachelorsDoctorate,
        searchOtherMastersDoctorate,
        searchOtherBachelorsMastersDoctorate,
        searchOtherExperience,
        searchOtherBachelorExperience,
        searchOtherMastersExperience,
        searchOtherDoctorateExperience,
        searchOtherBachelorsMastersExperience,
        searchOtherBachelorsDoctorateExperience,
        searchOtherMastersDoctorateExperience,
        searchOtherBachelorsMastersDoctorateExperience,
        searchOtherRelocate,
        searchOtherExperienceRelocate,
        searchOtherBachelorRelocate,
        searchOtherBachelorExperienceRelocate,
        searchOtherMastersRelocate,
        searchOtherMastersExperienceRelocate,
        searchOtherDoctorateRelocate,
        searchOtherDoctorateExperienceRelocate,
        searchOtherBachelorsMastersRelocate,
        searchOtherBachelorsMastersExperienceRelocate,
        searchOtherBachelorsDoctorateRelocate,
        searchOtherBachelorsDoctorateExperienceRelocate,
        searchOtherMastersDoctorateReloacate,
        searchOtherMastersDoctorateExperienceRelocate,
        searchOtherBachelorsMastersDoctorateRelocate,
        searchOtherBachelorsMastersDoctorateExperienceRelocate,
      );
    }
  }, [
    isAny,
    isBachelors,
    isDoctorate,
    isMasters,
    searchLoader,
    isRelocate,
    isExperience,
    isOther,
  ]);
  const planId = plan && plan[0] && plan[0].plan_id_id;

  // single unloack submit function
  const handleUnlockSubmit = (values: string) => {
    dispatch(
      unlockCandidateMiddleWare({
        key: values,
      }),
    ).then((response) => {
      if (response.payload.unlock_can_list) {
        dispatch(
          parsedTextMiddleWare({
            unlock_can_list: response.payload.unlock_can_list,
          }),
        ).then((res) => {
          if (res.payload.success === false) {
            Toast(
              'Oops! Something went wrong. Please try again shortly.',
              'LONG',
              'error',
            );
          }
        });
        dispatch(SubsriptionMiddleWare());
        setCandiList(response.payload.candi_list);
        setSourceLimit(response.payload.source_limit);
        setCandidatesLimit(response.payload.candi_limit);
        if (
          response.payload.success === true &&
          response.payload.unlock_can_list.length !== 0
        ) {
          setSuccess(true);
        }
      }

      if (response.payload.success === 'no_limit') {
        setNoLimit(true);
      }
      if (response.payload.success === 'no_permission') {
        setNoPermission(true);
      }
      if (response.payload.success === 'no_count') {
        if (planId !== 1) {
          setopensubcription(true);
        } else {
          setopensubcription(true);
        }
      }
      if (response.payload.success === false) {
        Toast(
          'Oops! Something went wrong. Please try again shortly.',
          'LONG',
          'error',
        );
      }
    });
  };

  // open resume function
  const handleCandidateView = (hashKey: string) => {
    setPdfLoader(true);
    setcardloader(true);
    dispatch(
      candidateViewMiddleWare({
        key: hashKey,
      }),
    )
      .then((response) => {
        if (response.payload.file) {
          setShowPdf(true);
        }
        setPdfLoader(false);
        setcardloader(false);
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
        setPdfLoader(false);
        setcardloader(false);
      });
  };

  const hanldeContactModal = () => {
    setNoLimit(false);
    sessionStorage.setItem('MyAccountDropdown', '1');
    history.push('/account_setting/subscription?planFocus=focus');
  };

  const getFocus = () => {
    document.getElementById('selectAll')?.focus();
  };

  const handleSetPage = (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set('pagination', JSON.stringify(page));
    history.push({
      pathname: window.location.pathname,
      search: params.toString(),
    });
    setshow(false);
    setIsCheck([]);
    setPageNumber(page);
    getFocus();
  };

  const updatechckbox = () => {
    filterCondition(
      setSearchData,
      isAny,
      isRelocate,
      isExperience,
      isMasters,
      isDoctorate,
      isBachelors,
      getStoreSearchData,
      searchExperienceRelocate,
      searchRelocate,
      searchExperienceBachelorMasterDoctorate,
      searchBachelorsMasterDoctorateRelocate,
      searchExperienceBachelorMaster,
      searchExperienceBachelorDoctorate,
      searchExperienceDoctorateMaster,
      searchThreeFilter,
      searchBachelorDoctorateRelocate,
      searchMasterDoctorateRelocate,
      searchBachelorMasterRelocate,
      searchExperienceBachelor,
      searchExperienceMaster,
      searchExperienceDoctorate,
      searchBachelorsMastersFilter,
      searchBachelorsDoctorateFilter,
      searchDoctorateMastersFilter,
      searchBachelorRelocate,
      searchMasterRelocate,
      searchDoctorateRelocate,
      searchBachelorsFilter,
      searchDoctorateFilter,
      searchMastersFilter,
      searchExperience,
      searchBachelorExperienceRelocate,
      searchDoctorateExperienceRelocate,
      searchMastersExperienceRelocate,
      searchBachelorsMasterExperienceRelocate,
      searchBachelorDoctorateExperienceRelocate,
      searchMastersDoctorateExperienceRelocate,
      searchBachelorsMasterDoctorateExperienceRelocate,
      searchUnselectCheckbox,
      searchUnCheckExperience,
      isOther,
      searchOther,
      searchOtherBachelor,
      searchOtherMasters,
      searchOtherDoctorate,
      searchOtherBachelorsMasters,
      searchOtherBachelorsDoctorate,
      searchOtherMastersDoctorate,
      searchOtherBachelorsMastersDoctorate,
      searchOtherExperience,
      searchOtherBachelorExperience,
      searchOtherMastersExperience,
      searchOtherDoctorateExperience,
      searchOtherBachelorsMastersExperience,
      searchOtherBachelorsDoctorateExperience,
      searchOtherMastersDoctorateExperience,
      searchOtherBachelorsMastersDoctorateExperience,
      searchOtherRelocate,
      searchOtherExperienceRelocate,
      searchOtherBachelorRelocate,
      searchOtherBachelorExperienceRelocate,
      searchOtherMastersRelocate,
      searchOtherMastersExperienceRelocate,
      searchOtherDoctorateRelocate,
      searchOtherDoctorateExperienceRelocate,
      searchOtherBachelorsMastersRelocate,
      searchOtherBachelorsMastersExperienceRelocate,
      searchOtherBachelorsDoctorateRelocate,
      searchOtherBachelorsDoctorateExperienceRelocate,
      searchOtherMastersDoctorateReloacate,
      searchOtherMastersDoctorateExperienceRelocate,
      searchOtherBachelorsMastersDoctorateRelocate,
      searchOtherBachelorsMastersDoctorateExperienceRelocate,
    );
  };

  useEffect(() => {
    setSearchData([]);
  }, []);

  // filter refresh function
  const handleRefresh = () => {
    setBachelors(false);
    setAny(true);
    setDoctorate(false);
    setExperience({
      label: 'All',
      value: 'all',
    });
    setOther(false);
    setMasters(false);
    setRelocate(false);
  };

  if (isInitialLoader) {
    return <Loader />;
  }
  function countLockedCandidates(candidates) {
    let count = 0;
    for (let i = 0; i < candidates?.length; i++) {
      if (candidates[i].unlock_status === 'locked') {
        count++;
      }
    }
    return count;
  }
  const lockedCandidatesCount = countLockedCandidates(isSearchData);

  return (
    <>
      {status && (
        <Highlighter
          left={'38px'}
          top={
            Permission1.includes('bulkImport_candidates') ? '317px' : '317px'
          }
          text={'Resume Library'}
        />
      )}
      {step7 && (
        <Tour
          process={() => {
            if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '7/9';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post')
            ) {
              return '5/7';
            } else if (
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('create_post')
            ) {
              return '6/8';
            } else if (
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('manage_account_settings')
            ) {
              return '6/8';
            } else if (Permission1.includes('manage_account_settings')) {
              return '4/6';
            } else if (Permission1.includes('create_post')) {
              return '4/6';
            } else if (Permission1.includes('bulkImport_candidates')) {
              return '5/7';
            } else {
              return '3/5';
            }

            return null;
          }}
          skiponclick={() => {
            setstep7(false);
            setcurrentindex(7);
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '0');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '1');
            sessionStorage.setItem('SourcingCandidateDropdown', '0');
            history.push('/account_setting/profiles');
            dispatch(
              tourdataget({ skip_id: 7, restart_status: false, explore: 0 }),
            );
          }}
          previousonclick={() => {
            setstep7(false);
            if (Permission1.includes('bulkImport_candidates')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '1');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/bulk_import?tab=1');
            } else if (Permission1.includes('create_post')) {
              sessionStorage.setItem('JobsDropdown', '1');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              history.push('/jobs/create_your_job');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
            } else if (Permission1.includes('manage_account_settings')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '1');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/account_setting/build_careers_page');
            } else {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '1');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/account_setting/profiles');
            }
          }}
          nextonclick={() => {
            setstep7(false);
            if (super_user) {
              sessionStorage.setItem('JobsDropdown', '1');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              sessionStorage.setItem('template', '1');
              sessionStorage.setItem('pipeline', '1');
              sessionStorage.setItem(
                'wk_id',
                pipelineData[0]?.wk_id.toString(),
              );
              history.push('/account_setting/hiring_workflow');
            } else {
              if (Permission1.includes('manage_account_settings')) {
                sessionStorage.setItem('JobsDropdown', '1');
                sessionStorage.setItem('ViewJobsDropdown', '0');
                sessionStorage.setItem('CandidateDropdown', '0');
                sessionStorage.setItem('CommunicationDropdown', '0');
                sessionStorage.setItem('BrandDropdown', '0');
                sessionStorage.setItem('MyAccountDropdown', '0');
                sessionStorage.setItem('SourcingCandidateDropdown', '0');
                sessionStorage.setItem('template', '1');
                sessionStorage.setItem('pipeline', '1');
                sessionStorage.setItem(
                  'wk_id',
                  pipelineData[0]?.wk_id.toString(),
                );
                history.push('/account_setting/hiring_workflow');
              } else {
                sessionStorage.setItem('JobsDropdown', '1');
                sessionStorage.setItem('ViewJobsDropdown', '0');
                sessionStorage.setItem('CandidateDropdown', '0');
                sessionStorage.setItem('CommunicationDropdown', '0');
                sessionStorage.setItem('BrandDropdown', '0');
                sessionStorage.setItem('MyAccountDropdown', '0');
                sessionStorage.setItem('SourcingCandidateDropdown', '0');
                sessionStorage.setItem('template', '1');
                sessionStorage.setItem('pipeline', '1');
                sessionStorage.setItem(
                  'wk_id',
                  pipelineData[0]?.wk_id.toString(),
                );
                history.push('/account_setting/hiring_workflow');
              }
            }
          }}
          borderTop
          previous
          types={'arrowLeftTop'}
          top={
            Permission1.includes('bulkImport_candidates') ? '298px' : '298px'
          }
          left={'205px'}
          text={
            '<div style="font-weight:bold">Your candidate search is empowered with Zita.</div><div style="margin-top:10px;">Envision tapping into a pool of talent, finding the ideal candidates with precision based on <div style="font-weight:bold; display: contents">Job title</div> and <div style="font-weight:bold; display: contents">location.</div></div> <div style="margin-top:10px;">It’s like having a powerful magnet to attract the needles in the haystack.</div>'
          }
          // text={"Your candidate search is empowered with Zita. Envision tapping into a pool of talent, finding the ideal candidates with precision based on Job title and location. It’s like having a powerful magnet to attract the needles in the haystack."}
        />
      )}
      {isSubmitLoader && <Loader />}
      {isopensubcription && (
        <SubscriptionModal
          addon_name={['Resume Unlock']}
          openmodel={isopensubcription}
          differece_key={['talentsourcing']}
          setopensubcription={setopensubcription}
        />
      )}
      <Flex style={{ overflow: 'hidden !important' }}>
        {/* <Flex row className={styles.ribbon} between>
          <Flex marginTop={9} marginLeft={8}>
            <Text size={16} bold color="theme">
              Source Candidates
            </Text>
          </Flex>
          <Flex>
            <div className={styles.triangle}></div>
          </Flex>
        </Flex> */}
        <Flex row className={styles.overAll}>
          {(sourceLoader ||
            isPdfLoader ||
            searchLoader ||
            stripeLoader ||
            isCheckOutLoader) &&
            isSubmitLoader}
          <CandiViewModal
            open={isShowPdf}
            filePath={candiViewFile}
            cancel={() => setShowPdf(false)}
          />
          <UnlockLoaderModal
            title={
              'Exporting resumes... This might take a few minutes to process the files.'
            }
            open={bulkDownloadLoader}
          />
          <UnlockLoaderModal
            title={
              'Unlocking the profiles. Please wait until the process gets completed.'
            }
            open={unLockLoader || bulkUnlockLoader}
          />
          <ContactCreditsModal
            setCheckOutLoader={setCheckOutLoader}
            open={isContact}
            cancelBtnOnClick={() => setContact(false)}
            publicKey={publicKey}
          />

          <CancelAndDeletePopup
            title={
              'You do not have enough contact credits. Do you wish to buy credits to unlock contacts?'
            }
            btnRight={'Buy'}
            open={isNoLimit}
            btnCancel={() => {
              setNoLimit(false);
            }}
            btnDelete={() => {
              hanldeContactModal();
            }}
          />
          <CandidateSuccessModal
            open={isSuccess}
            btnOnclick={() => setSuccess(false)}
            credits={isSourceLimit}
          />
          <SingleButton
            btnTitle="OK"
            title={'Please contact your company admin to unlock contacts.'}
            open={isNoPermission}
            btnOnclick={() => setNoPermission(false)}
          />
          <SingleButton
            btnTitle="OK"
            title={`Contact credits purchased successfully. Your Available Contact Credits: ${isSourceLimit}`}
            open={isCredit}
            btnOnclick={() => setCredit(false)}
          />

          <Flex className={styles.titleContainer}>
            <div>
              <Flex className={styles.talentActionContainer}>
                <TalentAction
                  update={update}
                  handleSetPage={handleSetPage}
                  val={show}
                  setIsCheck={setIsCheck}
                  setInitalCheckBox={setInitalCheckBox}
                  setFind={setFind}
                  setPageNumber={setPageNumber}
                  isCandidatesLimit={isCandidatesLimit}
                  setCandidatesLimit={setCandidatesLimit}
                  setSourceLimit={setSourceLimit}
                  location={location}
                  setSubmitLoader={setSubmitLoader}
                  setvisible={setvisible}
                  isformikdata={isformikdata}
                  setformikdata={setformikdata}
                  ispagecount={ispagecount}
                  setpagecount={setpagecount}
                  talent_sourcing={talent_sourcing}
                />
              </Flex>

              {visible && isSearchData !== null && (
                <div className={cx('filterOverAll')}>
                  <TalentFilter
                    setchange={setchange}
                    updatechckbox={updatechckbox}
                    apply={apply}
                    setapply={setapply}
                    isInitalCheckBox={isInitalCheckBox}
                    setOther={setOther}
                    isOther={isOther}
                    isBachelors={isBachelors}
                    isDoctorate={isDoctorate}
                    isMasters={isMasters}
                    isAny={isAny}
                    setBachelors={setBachelors}
                    setDoctorate={setDoctorate}
                    setMasters={setMasters}
                    setAny={setAny}
                    isRelocate={isRelocate}
                    setRelocate={setRelocate}
                    isExperience={isExperience}
                    setExperience={setExperience}
                    setInitialPage={setPageNumber}
                    handleRefresh={handleRefresh}
                    newexperience={newexperience}
                    setnewexperience={setnewexperience}
                    NewBachelors1={NewBachelors1}
                    setnewBachelors1={setnewBachelors1}
                    Newmaster1={Newmaster1}
                    setnewmaster1={setnewmaster1}
                    NewDoctorate1={NewDoctorate1}
                    setnewDoctorate1={setnewDoctorate1}
                    Newothers1={Newothers1}
                    setnewothers1={setnewothers1}
                    newrelocate={newrelocate}
                    setnewrelocate={setnewrelocate}
                  />
                </div>
              )}

              {isSearchData?.length === 0 && isFind && !isSubmitLoader && (
                <Flex
                  center
                  middle
                  height={componentHeight}
                  className={styles.emptyStyle}
                >
                  <SvgSearch width={16} height={16} fill={'#888888'} />
                  <Text style={{ color: '#888888', marginTop: '2px' }}>
                    Please enter your search keywords in the required search
                    field to find the candidates
                  </Text>
                </Flex>
              )}
              {(isSearchData === null ||
                (isSearchData?.length === 0 && !isFind)) &&
                !isSubmitLoader && (
                  <Flex
                    className={styles.emptyStyle}
                    center
                    middle
                    height={window.innerHeight - 238}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <SvgNotinterests
                      width={18}
                      height={18}
                      style={{ filter: 'opacity(0.5)' }}
                    />
                    <div>
                      <Empty title="No Candidate Found" />
                    </div>
                  </Flex>
                )}
              {isSearchData?.length !== 0 &&
                isSearchData !== null &&
                isSubmitLoader !== true && (
                  <TalentCardList
                    handleSetPage={handleSetPage}
                    client_url={client_url}
                    pageNumber={pageNumber}
                    isSubmitLoader={isSubmitLoader}
                    add_on_plans={add_on_plans}
                    resume_unlock_credits={resume_unlock_credits}
                    setopensubcription={setopensubcription}
                    update={update}
                    val={show}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    setCandiList={setCandiList}
                    setNoCount={setNoCount}
                    candi_list={isCandiList}
                    setNoLimit={setNoLimit}
                    setNoPermission={setNoPermission}
                    setSuccess={setSuccess}
                    setCandidatesLimit={setCandidatesLimit}
                    isCandidatesLimit={isCandidatesLimit}
                    handleCandidateView={handleCandidateView}
                    isRelocate={isRelocate}
                    isExperience={isExperience}
                    isBachelors={isBachelors}
                    isDoctorate={isDoctorate}
                    isMasters={isMasters}
                    isAny={isAny}
                    searchLoader={searchLoader}
                    searchData={isSearchData}
                    handleUnlockSubmit={handleUnlockSubmit}
                    source_limit={isSourceLimit}
                    setSourceLimit={setSourceLimit}
                    planID={planId}
                    setFree={setFree}
                    lockedCandidatesCount={lockedCandidatesCount}
                    setunlockcan={setunlockcan}
                    unlockcan={unlockcan}
                    setSearchData={setSearchData}
                    setpagecount={setpagecount}
                    setformikdata={setformikdata}
                    talent_sourcing={talent_sourcing}
                  />
                )}
            </div>

            {cardloader && <Loader />}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TalentSourcingScreen;
